import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../app-material.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CardComponent } from './card/card.component';
import { CardStatusColorDirective } from './card/status-color.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { PrettyPrintPipe } from './pipes/pretty-print.pipe';
import { DummyComponent } from './dummy/dummy.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TooltipDirective } from './Directives/tooltip.directive';
import { TooltipComponent } from './tooltip/tooltip.component';
import { CountryCodeToCountryNamePipe } from './pipes/country-code-to-name.pipe';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
@NgModule({
    declarations: [
        CardComponent,
        CardStatusColorDirective,
        PrettyPrintPipe,
        DummyComponent,
        PageNotFoundComponent,
        TooltipComponent,
        TooltipDirective,
        CountryCodeToCountryNamePipe,
        FilterMenuComponent,
    ],
    imports: [CommonModule, ReactiveFormsModule, AppMaterialModule, AngularSvgIconModule],
    exports: [
        CardComponent,
        PrettyPrintPipe,
        TooltipComponent,
        TooltipDirective,
        CountryCodeToCountryNamePipe,
        FilterMenuComponent,
    ],
})
export class CommonComponentsModule {}
