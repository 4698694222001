import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActionStatus } from 'types';

export const FilterMenuType = {
    OPTION: 'select-menu',
};

@Component({
    selector: 'vc-filter-menu',
    templateUrl: './filter-menu.component.html',
    styleUrls: ['./filter-menu.component.scss'],
})
export class FilterMenuComponent implements OnInit {
    @ViewChild('filterMenuDom') filterMenuDomRef: ElementRef;
    @Input() title = 'Filter';
    @Input() allList = [];

    type = FilterMenuType.OPTION;

    @Output() apply = new EventEmitter<any>();

    readonly actionStatus = ActionStatus;

    searchFilter: FormControl;
    filterMenuType = FilterMenuType;
    selectedOption: string;
    isEmpty = false;

    constructor() {}

    ngOnInit() {
        this.searchFilter = new FormControl('');
    }

    setOption(id: string) {
        this.selectedOption = id;
        this.applyFilters();
    }

    applyFilters() {
        this.apply.emit(this.selectedOption);
    }

    closeFilterMenu() {
        this.apply.emit();
    }

    handleSearchInputChange() {
        this.isEmpty = false;
        if (this.searchFilter.value) {
            this.isEmpty = !this.allList.filter(item =>
                this.hasSearchTerm(item.name, this.searchFilter.value),
            ).length;
        }
    }

    hasSearchTerm(source: string | number, searchTerm: string | number) {
        if (!source || !searchTerm) {
            return false;
        }
        return source.toString().toLowerCase().includes(searchTerm.toString().toLowerCase());
    }
}
