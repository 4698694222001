<div class="filter-menu-list" (keydown)="$event.stopPropagation()" #filterMenuDom>
    <div class="menu-title item-row">
        <i class="zmdi zmdi-filter-list icon"></i>
        {{ title }}
        <i class="zmdi zmdi-close close cursor-pointer" (click)="closeFilterMenu()"></i>
    </div>

    <ng-container *ngIf="type == filterMenuType.OPTION">
        <div
            class="list-filter-wrapper"
            *ngIf="allList && allList.length"
            (click)="$event.stopPropagation()"
        >
            <div class="search-bar item-row">
                <i class="zmdi zmdi-search icon"></i>
                <input
                    type="text"
                    placeholder="Search"
                    [formControl]="searchFilter"
                    (input)="handleSearchInputChange()"
                />
            </div>
            <div *ngIf="busy" class="list-ldr-container">
                <div class="ph-ldr-item">
                    <div>
                        <div class="row ph-ldr-row">
                            <div class="col ph-ldr-col-1"></div>
                            <div class="col ph-ldr-col-1 empty"></div>
                            <div class="col ph-ldr-col-8"></div>
                        </div>
                        <div class="row ph-ldr-row">
                            <div class="col ph-ldr-col-1"></div>
                            <div class="col ph-ldr-col-1 empty"></div>
                            <div class="col ph-ldr-col-8"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="list-items-scroll-view" id="scroll-handler">
                <div class="empty-list" *ngIf="isEmpty">No results found</div>
                <div class="list-items-scroll-view-content" *ngIf="!isEmpty && allList.length">
                    <div
                        class="item-row select-option"
                        *ngFor="let item of allList"
                        [class.hide]="
                            !item.name.toLowerCase().includes(searchFilter.value.toLowerCase())
                        "
                        (click)="setOption(item.id || item.supporterId)"
                    >
                        <div class="label list-label">
                            <span>{{ item.name }}</span>
                            <span class="count" *ngIf="item.count"
                                >({{ item.count | number }})</span
                            >
                        </div>
                        <div class="action-status" *ngIf="item.status" [ngSwitch]="item.status">
                            <span *ngSwitchCase="actionStatus.INCOMPLETE" class="incomplete-marker"
                                >INCOMPLETE</span
                            >
                            <span *ngSwitchCase="actionStatus.TERMINATED" class="terminated-marker"
                                >TERMINATED</span
                            >
                            <span *ngSwitchCase="actionStatus.READY" class="ready-marker"
                                >READY</span
                            >
                            <span *ngSwitchCase="actionStatus.ACTIVE" class="live-marker"
                                >ACTIVE</span
                            >
                            <span *ngSwitchCase="actionStatus.INACTIVE" class="inactive-marker"
                                >INACTIVE</span
                            >
                            <span *ngSwitchCase="actionStatus.PAUSED" class="paused-marker"
                                >PAUSED</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-content *ngIf="!allList || !allList.length" select="[placeholder]"></ng-content>
</div>
