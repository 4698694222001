export interface AcquisitionTypesForOutreachCircleFilter {
    outreachCircleId: string;

    name?: string | null;

    isActive?: boolean | null;

    pagination: PaginationInput;
}

export interface PaginationInput {
    skip: number;

    limit: number;
}

export interface GetSupportersForActionAssignmentInput {
    actionId: string;

    skip: number;

    limit: number;

    filter?: SupporterViewFilterInput | null;
}

export interface SupporterViewFilterInput {
    searchFilter?: SupporterSearchFilterInput | null;

    actionTakenFilter?: SupporterActionTakenFilterInput | null;

    supporterJoinedDateFilter?: SupporterJoinedFilterInput | null;

    actionFilter?: SupporterActionFilterInput | null;

    groupFilter?: SupporterGroupFilterInput | null;

    appNotificationFilter?: SupporterAppNotificationFilterInput | null;

    aliasFilter?: SupporterAliasFilterInput | null;

    supporterStatusFilter?: SupporterStatusFilterInput | null;

    lastEmailStatusFilter?: SupporterLastEmailStatusFilterInput | null;

    supporterEmailSubscriptionFilter?: SupporterEmailSubscriptionFilterInput | null;

    stateFilter?: SupporterStateFilterInput | null;

    shiftFilter?: SupporterViewShiftFilterInput | null;

    pdiListFilter?: PdiListsFilterInput | null;

    assignedActionFilter?: SupporterActionFilterInput | null;

    isOrganizerFilter?: SupporterIsOrganizerFilter | null;

    assignedToOrganizerFilter?: SupporterAssignedToOrganizerFilter | null;

    levelFilter?: SupporterLevelFilter | null;

    zoneFilter?: SupporterZoneFilter | null;
}

export interface SupporterSearchFilterInput {
    firstName?: string | null;

    lastName?: string | null;

    emailAddress?: string | null;

    phoneNumber?: string | null;

    nameOrEmail?: string | null;

    pdiId?: string | null;

    zipCode?: string | null;

    precinct?: string | null;

    state?: string | null;

    city?: string | null;

    party?: string | null;

    address?: string | null;

    streetName?: string | null;

    county?: string | null;

    birthdate?: string | null;
}

export interface SupporterActionTakenFilterInput {
    minimum?: number | null;

    maximum?: number | null;
}

export interface SupporterJoinedFilterInput {
    from: Date;

    to: Date;

    isExclude: boolean;
}

export interface SupporterActionFilterInput {
    all: boolean;

    actionIds?: string[] | null;

    isExclude: boolean;
}

export interface SupporterGroupFilterInput {
    all: boolean;

    groupIds?: string[] | null;

    isExclude: boolean;
}

export interface SupporterAppNotificationFilterInput {
    appNotificationEnabled: boolean;
}

export interface SupporterAliasFilterInput {
    all: boolean;

    aliasIds?: string[] | null;

    isExclude: boolean;
}

export interface SupporterStatusFilterInput {
    isExclude: boolean;

    statusList?: VolunteerStatus[] | null;
}

export interface SupporterLastEmailStatusFilterInput {
    emailStatusList: LastEmailStatus[];

    isExclude: boolean;
}

export interface SupporterEmailSubscriptionFilterInput {
    isUnsubscribed?: boolean | null;
}

export interface SupporterStateFilterInput {
    all?: boolean | null;

    states: string[];

    isExclude: boolean;
}

export interface SupporterViewShiftFilterInput {
    shiftIds?: string[] | null;

    isExclude: boolean;
}

export interface PdiListsFilterInput {
    pdiListId: number;
}

export interface SupporterIsOrganizerFilter {
    isOrganizer?: boolean | null;
}

export interface SupporterAssignedToOrganizerFilter {
    assignedToOrganizerIds: string[];

    isExclude: boolean;
}

export interface SupporterLevelFilter {
    levelIds: string[];

    isExclude: boolean;
}

export interface SupporterZoneFilter {
    zoneIds: string[];

    isExclude: boolean;
}

export interface DateFilter {
    startDate: Date;

    endDate: Date;
}

export interface OrganizationFilterInput {
    outreachCircleIds?: string[] | null;

    organizationFilterId?: string | null;
}

export interface GetAssignedMembersForActionInput {
    actionId: string;

    pagination: PaginationInput;

    searchKey?: string | null;
}

export interface ContentForSendingToRecipientBySupporterInput {
    actionId: string;

    outreachChannel: OutreachChannel;

    recipientId: string;
}

export interface MemberFormFieldValidationsInput {
    actionId: string;

    memberId: string;
}

export interface ActionExistsInput {
    actionName: string;

    outreachCircleId: string;
}

export interface GetActionPreviewByGroupInput {
    groupId: string;

    skip: number;

    limit: number;
}

export interface GetActionsPreviewForSupporterInput {
    supporterId: string;

    skip: number;

    limit: number;
}

export interface ActionsForOutreachCircleInput {
    outreachCircleId: string;

    skip: number;

    limit: number;

    sortBy?: ActionsSortInput[] | null;

    filter?: ActionViewFilterInput | null;
}

export interface ActionsSortInput {
    sortField: ActionsSortBy;

    isAscending: boolean;
}

export interface ActionViewFilterInput {
    actionStatusFilter?: ActionStatusFilterInput | null;

    searchFilter?: ActionSearchFilterInput | null;

    typeFilter?: ActionTypeFilterInput | null;

    groupFilter?: ActionGroupFilterInput | null;

    nonRelationalFilter?: ActionIsNonRelationalFilter | null;

    eventDateRangeFilter?: ActionEventDateRangeFilterInput | null;

    eventFrequencyFilter?: ActionEventFrequencyFilterInput | null;

    eventLocationFilter?: ActionEventLocationFilterInput | null;

    eventConfigFilter?: ActionEventConfigFilterInput | null;
}

export interface ActionStatusFilterInput {
    status?: ActionStatus[] | null;

    isExclude: boolean;
}

export interface ActionSearchFilterInput {
    name?: string | null;

    title?: string | null;
}

export interface ActionTypeFilterInput {
    actionType?: ActionType[] | null;

    isExclude: boolean;
}

export interface ActionGroupFilterInput {
    groupIds?: string[] | null;

    isExclude: boolean;
}

export interface ActionIsNonRelationalFilter {
    isNonRelational: boolean;

    isExclude: boolean;
}

export interface ActionEventDateRangeFilterInput {
    fromDate?: Date | null;

    toDate?: Date | null;

    isExclude: boolean;
}

export interface ActionEventFrequencyFilterInput {
    frequency: EventFrequency[];

    isExclude: boolean;
}

export interface ActionEventLocationFilterInput {
    location: LocationType[];

    isExclude: boolean;
}

export interface ActionEventConfigFilterInput {
    configIds: string[];

    isExclude: boolean;
}

export interface ActionsForOrgActionInput {
    orgActionId: string;

    actionStatus?: ActionStatus | null;

    skip: number;

    limit: number;

    sortBy?: ActionsSortInput[] | null;
}

export interface PublicActionForEmbedInput {
    alias: string;

    skip: number;

    limit: number;
}

export interface PublicActionForOutreachCircleInput {
    outreachCircleId: string;

    supporterId?: string | null;

    alias?: string | null;

    skip: number;

    limit: number;

    filter?: PublicActionFilterInput | null;
}

export interface PublicActionFilterInput {
    nonRelationalFilter?: ActionIsNonRelationalFilter | null;

    searchFilter?: ActionSearchFilterInput | null;

    typeFilter?: ActionTypeFilterInput | null;

    dateFilter?: PublicEventDateFilterInput | null;
}

export interface PublicEventDateFilterInput {
    fromDate: Date;

    toDate?: Date | null;
}

export interface PublicActionForSupporterInput {
    skip: number;

    limit: number;

    appCode?: string | null;

    organizationId?: string | null;
}

export interface SupporterActionInput {
    actionId: string;

    supporterId: string;

    contactSourceId?: string | null;
}

export interface FacebookRequestInput {
    url: string;

    params: Json;

    method?: string | null;
}

export interface GetContactsForAffinityTextingInput {
    skip: number;

    limit: number;

    actionId: string;

    supporterId: string;

    filter?: AffinityTextingFilter | null;
}

export interface AffinityTextingFilter {
    name: string;
}

export interface GetTextContentForAffinityTextingInput {
    supporterId: string;

    actionId: string;

    memberId: string;

    mobileNumber: string;
}

export interface SupporterTextContentForTextingSupporterInput {
    supporterId: string;

    actionId: string;

    mobileNumber: string;
}

export interface TextSupporterSetInput {
    skip: number;

    limit: number;

    actionId: string;

    searchKey?: string | null;
}

export interface EligibleSupporterForSupporterTextingInput {
    outreachCircleId: string;

    selectedSupporterIds?: string[] | null;

    hasAllSelected: boolean;

    excludeSupporterIds?: string[] | null;

    filter?: SupporterViewFilterInput | null;
}

export interface GetEligibleActionForAssigningToGroupInput {
    groupId: string;

    outreachCircleId: string;

    searchText?: string | null;
}

export interface GetAssignedSupportersForActionInput {
    actionId: string;

    skip?: number | null;

    limit?: number | null;
}

export interface FetchAssignedSupportersToActionInput {
    actionId: string;

    skip: number;

    limit: number;

    sortBy: AssignedSupportersSortInput[];

    searchFilter?: AssignedSupportersSearchInput | null;

    filter?: FetchAssignedSupportersToActionFilters | null;
}

export interface AssignedSupportersSortInput {
    sortField: AssignedSupportersSortBy;

    isAscending: boolean;
}

export interface AssignedSupportersSearchInput {
    nameOrEmail?: string | null;
}

export interface FetchAssignedSupportersToActionFilters {
    eventSupporterFilters?: EventSupporterFilters | null;
}

export interface EventSupporterFilters {
    type?: EventParticipantType | null;
}

export interface PublicActionByAliasInput {
    actionAlias: string;

    outreachCircleAlias: string;
}

export interface GetActionsLinkedToAudienceActionInput {
    audienceActionId: string;

    pagination?: PaginationInput | null;

    status: ActionStatus[];
}

export interface ActionEmailNotificationPreviewInput {
    actionId: string;

    code: SupporterEmailTemplateCode;

    body: string;

    fromNameAlias: string;

    subject: string;
}

export interface FetchGroupsAssignedToActionInput {
    actionId: string;

    pagination: PaginationInput;

    sortBy: GroupsAssignedToActionSortInput[];
}

export interface GroupsAssignedToActionSortInput {
    sortField: AssignedGroupsToActionSortBy;

    isAscending: boolean;
}

export interface GetMemberNotesInput {
    actionId: string;

    memberId: string;
}

export interface GetPublicEventsInput {
    actionId: string;

    dateFilter: PublicEventDateFilterInput;
}

export interface GetDeviceTokenForPhoneBankInput {
    actionId: string;

    uniqueDeviceId: string;
}

export interface GetMemberForPhoneBankInput {
    actionId: string;
}

export interface GetRecipientsForTextingActionInput {
    actionId: string;

    limit?: number | null;
}

export interface GetTextingConversationsByActionInput {
    actionId: string;

    filter?: TextingConversationFilterInput | null;

    skip?: number | null;

    limit?: number | null;
}

export interface TextingConversationFilterInput {
    supporterIds?: (string | null)[] | null;

    searchText?: string | null;
}

export interface GetTextingConversationInboxInput {
    actionId: string;

    filter?: TextingConversationInboxFilterInput | null;

    skip: number;

    limit: number;
}

export interface TextingConversationInboxFilterInput {
    isAssignedToMe?: boolean | null;
}

export interface GetHouseholdsBasedonCurrentLocationInput {
    actionId: string;

    location: LocationCoOrdinateInput;

    rangeInMiles?: number | null;
}

export interface LocationCoOrdinateInput {
    latitude: number;

    longitude: number;
}

export interface CreateSearchAndSurveyContactInput {
    contact: SearchAndSurveyContactInput;

    actionId: string;
}

export interface SearchAndSurveyContactInput {
    first_name?: string | null;

    middle_name?: string | null;

    last_name?: string | null;

    emails?: (ContactEmailInput | null)[] | null;

    mobiles?: (ContactMobileInput | null)[] | null;

    street_number?: string | null;

    street_name?: string | null;

    city?: string | null;

    state?: string | null;

    country?: string | null;

    zip?: string | null;

    full_address?: string | null;

    dob?: Date | null;

    apt_number?: string | null;
}

export interface ContactEmailInput {
    type: ContactEmailTypeEnum;

    value: string;
}

export interface ContactMobileInput {
    type: ContactMobileTypeEnum;

    value: string;
}

export interface GetAmbiguousHouseholdMembersInput {
    actionId: string;

    pagination: PaginationInput;
}

export interface AudienceActionUrlRequestInput {
    fileName: string;

    fileType: string;

    audienceId: string;
}

export interface MemberSearchByNameInput {
    name: string;

    audienceId: string;
}

export interface GetAudienceActionsForOutreachCircleByIntegrationTypeInput {
    outreachCircleId: string;

    type: AllowedIntegrationDatabaseType;

    listType: ListType;
}

export interface GetAudienceActionsForActionByIntegrationTypeInput {
    actionId: string;

    type: AllowedIntegrationDatabaseType;

    listType: ListType;
}

export interface GetAudienceInfoByListTypeInput {
    outreachCircleId: string;

    listType: ListType;
}

export interface GetAudienceActionsForOutreachCircleByListTypeInput {
    outreachCircleId: string;

    listType: ListType;

    skip: number;

    limit: number;
}

export interface GetLiveActionsByAudienceActionInput {
    audienceActionId: string;

    skip?: number | null;

    limit?: number | null;
}

export interface GetMappingsByAudienceIdInput {
    audienceId: string;

    skip: number;

    limit: number;
}

export interface GetSearchAndSurveyMemberInput {
    actionId: string;

    memberId: string;
}

export interface SearchAndSurveyMemberInput {
    searchFilter: SearchAndSurveyMemberFilter;

    actionId: string;
}

export interface SearchAndSurveyMemberFilter {
    firstName?: string | null;

    lastName?: string | null;

    city?: string | null;

    zip?: string | null;

    phoneNumber?: string | null;
}

export interface SupporterContactInput {
    contactId: string;

    supporterId: string;

    actionId: string;
}

export interface MappedContactsInput {
    supporterId: string;

    actionId: string;

    skip: number;

    limit: number;

    searchText?: string | null;

    contactSourceId?: string | null;
}

export interface TextRecipientInput {
    contactId: string;

    supporterId: string;

    memberId: string;

    actionId: string;

    mobileNumber: string;
}

export interface GetContactsForActionInput {
    supporterId: string;

    actionId: string;

    skip: number;

    limit: number;

    searchText?: string | null;

    isCanvassedContact?: boolean | null;
}

export interface DuplicateEmailRecipientInput {
    supporterId: string;

    skip?: number | null;

    limit?: number | null;
}

export interface GetMatchesForContactInput {
    contactId: string;

    actionId: string;
}

export interface ContactFileUrlInput {
    fileName: string;

    fileType: string;
}

export interface ContactQueryInput {
    search?: ContactSearch | null;

    filter?: ContactFilter | null;

    skip: number;

    limit: number;
}

export interface ContactSearch {
    name: string;
}

export interface ContactFilter {
    contactSourceId: string;
}

export interface ContactQueryForOutreachMode {
    searchText?: string | null;

    actionId: string;

    skip: number;

    limit: number;

    supporterId: string;

    contactSourceId?: string | null;
}

export interface OutreachCircleDashboardFilterInput {
    outreachCircleId: string;

    dateFilter?: DateFilter | null;
}

export interface GetDailySupportIdsInput {
    outreachCircleId: string;

    previousDays: number;

    puproseId?: string | null;

    acquisitionTypeId?: string | null;
}

export interface GetPdiListsForOutreachCircleInput {
    outreachCircleId: string;

    skip: number;

    limit: number;

    filter?: PdiListFilterInput | null;
}

export interface PdiListFilterInput {
    searchText?: string | null;

    listType?: PdiListType | null;

    status?: PdiListStatus | null;

    isPeopleList?: boolean | null;
}

export interface GetPublicTurfAssignmentsByOutreachCircleInput {
    outreachCircleId: string;

    filter?: PublicTurfAssignmentFilterInput | null;
}

export interface PublicTurfAssignmentFilterInput {
    turfSource?: TurfSource | null;
}

export interface SearchTurfsInput {
    searchText: string;

    turfType?: TurfType | null;

    paginationInput: PaginationInput;
}

export interface GetDefaultTurfsByActionInput {
    actionId: string;

    turfType: TurfType;

    paginationInput: PaginationInput;

    filter?: DefaultTurfFilterInput | null;

    sortBy?: DefaultTurfSortByInput[] | null;
}

export interface DefaultTurfFilterInput {
    supporterFilter?: DefaultTurfSupporterFilterInput | null;

    turfGroupFilter?: DefaultTurfTurfGroupFilterInput | null;

    displayIdFilter?: DefaultTurfDisplayIdFilterInput | null;

    nameFilter?: DefaultTurfNameFilterInput | null;

    dateRangeFilter?: DefaultTurfDateRangeFilterInput | null;

    assignmentFilter?: DefaultTurfExpiredAssignmentFilterInput | null;
}

export interface DefaultTurfSupporterFilterInput {
    supporterIds: string[];

    isExclude: boolean;
}

export interface DefaultTurfTurfGroupFilterInput {
    turfGroupIds: string[];

    isExclude: boolean;
}

export interface DefaultTurfDisplayIdFilterInput {
    displayId: string;
}

export interface DefaultTurfNameFilterInput {
    name: string;
}

export interface DefaultTurfDateRangeFilterInput {
    fromDate?: Date | null;

    toDate?: Date | null;
}

export interface DefaultTurfExpiredAssignmentFilterInput {
    isExpired: boolean;
}

export interface DefaultTurfSortByInput {
    field: DefaultTurfSortField;

    isAscending: boolean;
}

export interface GetDefaultTursByActionInput {
    actionId: string;

    turfId: string;
}

export interface GetHistoryForCanvassingMemberInput {
    memberId: string;

    actionId: string;
}

export interface GetMembersByHouseholdInput {
    audienceActionId?: string | null;

    actionId?: string | null;

    householdId: string;
}

export interface GetCanvassedSurveyResponseSummaryInput {
    outreachCircleId: string;

    pagination: PaginationInput;

    filter: GetCanvassedSurveyResponsesFilterInput;
}

export interface GetCanvassedSurveyResponsesFilterInput {
    workStatus: CanvassedSurveyResponseStatus[];

    dateFilter?: DateFilterInput | null;

    supporterIds?: string[] | null;

    showExpiredCanvassing?: boolean | null;

    organizerIds?: string[] | null;

    actionIds?: string[] | null;

    searchText?: string | null;
}

export interface DateFilterInput {
    from: Date;

    to: Date;
}

export interface GetCanvassedSurveyResponsesBySupporterInput {
    supporterId: string;

    pagination: PaginationInput;

    filter?: GetCanvassedSurveyResponsesBySupporterFilterInput | null;
}

export interface GetCanvassedSurveyResponsesBySupporterFilterInput {
    statusFilter?: CanvassedSurveyResponseStatus[] | null;

    searchText?: string | null;
}

export interface VoterDensityBucketInput {
    actionId: string;

    audienceActionId: string;

    bucketInputs?: VoterDensityBucketPercentageInput[] | null;

    bucketCount?: number | null;

    turfType: TurfType;

    groupBy?: VoterDensityGroupBy | null;
}

export interface VoterDensityBucketPercentageInput {
    min: number;

    max: number;
}

export interface SupporterEmailTemplateInput {
    emailTemplateId: string;

    supporterId: string;

    actionId: string;
}

export interface EmailTemplateForOutreachCircleInput {
    outreachCircleId: string;

    actionId: string;

    searchKey?: string | null;

    pagination?: PaginationInput | null;
}

export interface UpdateEmailTemplateWithRecruitmentLinkInput {
    emailTemplateId: string;

    content: string;

    linkText: string;

    code?: string | null;
}

export interface UpdateEmailContentWithSurveyDetailsInput {
    actionId: string;

    content: string;
}

export interface ValidateEmailTemplateNameForActionInput {
    name: string;

    actionId: string;
}

export interface EmailTemplatesForOrganization {
    organizationId: string;

    organizationActionId?: string | null;

    searchKey?: string | null;
}

export interface ValidateOrganizationEmailTemplateNameInput {
    name: string;

    organizationId: string;
}

export interface SurveysByOutreachCircleIdInput {
    outreachCircleId: string;

    searchKey?: string | null;

    responseCountFilter?: ResponseCountFilter | null;

    integrationTypes: (AllowedIntegrationDatabaseType | null)[];
}

export interface ResponseCountFilter {
    ignoreWithoutResponses?: boolean | null;

    dateFilter?: DateFilter | null;
}

export interface GetSurveysEligibleForForActionByIntegrationInput {
    outreachCircleId: string;

    integrationType: AllowedIntegrationDatabaseType;

    searchKey?: string | null;
}

export interface SearchExternalLinkInput {
    outreachCircleId: string;

    searchKey: string;

    skip: number;

    limit: number;
}

export interface SurveyExistsInput {
    surveyName: string;

    outreachCircleId: string;
}

export interface SurveysForOrganizationInput {
    organizationId: string;

    skip: number;

    limit: number;

    sortBy: SurveySortByInput;

    responseCountFilter?: ResponseCountFilter | null;

    outreachCircleIds?: string[] | null;

    organizationFilterId?: string | null;
}

export interface SurveySortByInput {
    sortField: SurveySortBy;

    isAscending: boolean;
}

export interface GetActivitiesInput {
    searchkey?: string | null;

    outreachCircleId: string;
}

export interface GetEventConfigsForOutreachCircleInput {
    outreachCircleId: string;

    paginationInput: PaginationInput;

    sortBy?: EventConfigSortInput | null;

    filter?: GetEventConfigsFilter | null;
}

export interface EventConfigSortInput {
    shiftPresentInDateRange?: ShiftsPresentInDateRangeInput | null;

    sortField: EventConfigsSortBy;

    isAscending?: boolean | null;
}

export interface ShiftsPresentInDateRangeInput {
    fromDate: Date;

    toDate: Date;
}

export interface GetEventConfigsFilter {
    statusFilter?: ActionStatusFilterInput | null;

    hasAtleastOneActiveEvent?: boolean | null;
}

export interface GetPublicEventConfigsForOutreachCircleInput {
    outreachCircleId: string;

    paginationInput: PaginationInput;

    sortBy?: PublicEventConfigSortInput | null;

    filter?: PublicEventConfigFilterInput | null;
}

export interface PublicEventConfigSortInput {
    sortField: PublicEventConfigsSortBy;

    isAscending?: boolean | null;
}

export interface PublicEventConfigFilterInput {
    actionType?: ActionType | null;
}

export interface GetAssignedEventsForNonWorkingGuestInput {
    outreachCircleId: string;

    pagination: PaginationInput;

    filter?: AssignedEventsFilterInput | null;
}

export interface AssignedEventsFilterInput {
    startDate?: Date | null;

    endDate?: Date | null;
}

export interface AssignedSupportersForShiftInput {
    shiftId: string;

    pagination: PaginationInput;
}

export interface GetPublicShiftsByOutreachCircleInput {
    outreachCircleId: string;

    pagination: PaginationInput;

    filter?: PublicShiftFilterInput | null;
}

export interface PublicShiftFilterInput {
    shiftDateFilter?: ShiftDateFilter | null;

    assignedFilter?: ShiftAssignedFilter | null;
}

export interface ShiftDateFilter {
    startDate: Date;

    endDate?: Date | null;
}

export interface ShiftAssignedFilter {
    isAssignedToMe: boolean;
}

export interface GetShiftsByOutreachCircleInput {
    outreachCircleId: string;

    pagination: PaginationInput;

    filter?: ShiftFilterInput | null;
}

export interface ShiftFilterInput {
    shiftDateFilter?: ShiftDateFilter | null;

    activityFilter?: ShiftActivityFilter | null;
}

export interface ShiftActivityFilter {
    activityName?: string | null;
}

export interface GetAttendeesForShiftInput {
    shiftId: string;

    pagination: PaginationInput;

    filter?: ShiftAttendeeFilterInput | null;
}

export interface ShiftAttendeeFilterInput {
    searchText?: string | null;

    excludeLoggedInSupporter?: boolean | null;
}

export interface GetShiftsByActionTypeInput {
    outreachCircleId: string;

    actionType: ActionType;
}

export interface GetFilteredFlagsInput {
    outreachCircleId: string;

    searchFilter?: FlagSearchFilterInput | null;

    questionType?: SurveyQuestionType | null;

    status?: FlagStatus | null;

    skip?: number | null;

    limit?: number | null;
}

export interface FlagSearchFilterInput {
    value?: string | null;

    description?: string | null;

    association?: string | null;

    allowedTolinkToAliasFilter?: boolean | null;
}

export interface GetFlagAssociationsInput {
    questionType?: SurveyQuestionType | null;

    searchText?: string | null;
}

export interface GroSurveyFormsByOutreachCircleInput {
    outreachCircleId: string;

    searchKey?: string | null;

    integrationTypes: (GroSurveyFormIntegrationType | null)[];
}

export interface ValidateGroSurveyFormNameInput {
    name: string;

    groSurveyFormId?: string | null;

    outreachCircleId: string;
}

export interface GetGroSurveyResponseForMemberInput {
    actionId: string;

    memberId: string;
}

export interface GetPastGroSurveyResponseInput {
    actionId: string;

    memberId: string;

    pagination: PaginationInput;
}

export interface GetGroupsInput {
    outreachCircleId: string;

    skip?: number | null;

    limit?: number | null;

    sortBy?: GroupsSortInput[] | null;

    searchKey?: string | null;
}

export interface GroupsSortInput {
    sortField: GroupsSortBy;

    isAscending: boolean;
}

export interface GetActionsForGroupInput {
    groupId: string;

    filter?: GetActionsForGroupFilter | null;

    skip?: number | null;

    limit?: number | null;
}

export interface GetActionsForGroupFilter {
    status?: ActionStatus | null;
}

export interface GetAllGroupsForActionInput {
    actionId: string;

    pagination: PaginationInput;

    searchKey?: string | null;
}

export interface TestNgpIntegrationInput {
    appName: string;

    apiKey: string;

    outreachCircleId: string;

    database: AudienceActionDatabase;
}

export interface OnTheGoAssignmentsInput {
    actionId: string;

    location: LocationCoordinateInput;
}

export interface LocationCoordinateInput {
    latitude: number;

    longitude: number;
}

export interface GetQuestionsFromPdiInput {
    outreachCircleId: string;

    skip: number;

    limit: number;
}

export interface ValidateSoqlInput {
    outreachCircleId: string;

    soql: string;
}

export interface GetTcrBrandApplicableUsecasesInput {
    outreachCircleId: string;

    brandId: string;
}

export interface GetLatestNativeAppVersionInput {
    bundleId: string;

    platform: DeviceTypeEnum;

    version: string;

    buildNumber: number;
}

export interface ActionsForOrganizationInput {
    organizationId: string;

    skip: number;

    limit: number;
}

export interface DoesOrganizationActionWithSameNameExistInput {
    organizationActionName: string;

    organizationId: string;
}

export interface GetOutreachCirclesByOrganizationInput {
    organizationId: string;

    sortBy?: (OutreachCircleSortInput | null)[] | null;

    skip: number;

    limit: number;

    filter?: OutreachCirclesByOrganizationFilter | null;
}

export interface OutreachCircleSortInput {
    sortField: OutreachCircleSortBy;

    isAscending: boolean;
}

export interface OutreachCirclesByOrganizationFilter {
    searchFilter?: OutreachCirclesByOrganizationSearchFilter | null;

    stateFilter?: OutreachCirclesByOrganizationStateFilter | null;
}

export interface OutreachCirclesByOrganizationSearchFilter {
    outreachCircleName?: string | null;

    candidateFirstName?: string | null;

    candidateLastName?: string | null;
}

export interface OutreachCirclesByOrganizationStateFilter {
    states: (string | null)[];

    isExclude: boolean;
}

export interface GetOrganizationDashboardDataInput {
    organizationFilterId?: string | null;

    outreachCircleIds?: string[] | null;

    dateFilter?: DateFilter | null;

    organizationId: string;

    skip?: number | null;

    limit?: number | null;
}

export interface PublicOutreachCircleByOrganizationInput {
    organizationId: string;

    skip?: number | null;

    limit?: number | null;
}

export interface DoesOutreachCircleUserEmailTemplateExistsInput {
    id?: string | null;

    organizationId: string;

    name: string;

    code: OutreachCircleUserEmailTemplateCode;
}

export interface GetOutreachCircleUserEmailTemplatesForOrganizationInput {
    organizationId: string;

    code: OutreachCircleUserEmailTemplateCode;

    searchKey?: string | null;
}

export interface GetPreviewForOutreachCircleUserEmailTemplateInput {
    outreachCircleUserEmailTemplateId: string;

    content: string;

    organizationActionId?: string | null;
}

export interface GetUpdatedContentForOutreachCircleUserEmailTemplateWithActionLinkTextInput {
    outreachCircleUserEmailTemplateId: string;

    content: string;

    actionLinkText: string;

    organizationActionId: string;
}

export interface GetEligibleCountForOrganizationSendActionNotificationInput {
    organizationActionId: string;

    outreachCircleIds?: (string | null)[] | null;
}

export interface GetEligibleCountForNotifyOcAdminsForOrganizationInput {
    organizationId: string;

    outreachCircleIds?: (string | null)[] | null;

    selectAll: boolean;

    filter?: OutreachCirclesByOrganizationFilter | null;
}

export interface OutreachcircleUsersWithFiltersInput {
    outreachCircleId: string;

    filters?: OutreachCircleUserFilter | null;

    pagination?: PaginationInput | null;
}

export interface OutreachCircleUserFilter {
    username?: UsernameFilterType | null;

    email?: string | null;

    deactivatedUser?: boolean | null;
}

export interface UsernameFilterType {
    firstName?: string | null;

    lastName?: string | null;
}

export interface OutreachCircleUserGeographyListWithFilterInput {
    outreachCircleId: string;

    searchKey?: string | null;

    paginationInput?: PaginationInput | null;
}

export interface PublicOutreachCircleByAliasOrNameInput {
    aliasOrName: string;

    skip: number;

    limit: number;
}

export interface SearchPublicOutreachCircleInput {
    searchKey: string;

    skip: number;

    limit: number;

    organizationId?: string | null;
}

export interface AllPublicOutreachCirclesInput {
    skip: number;

    limit: number;
}

export interface DeepLinkInput {
    actionType?: ActionType | null;

    outreachCircleAlias?: string | null;

    outreachCircleId?: string | null;
}

export interface GetGeographyListForOutreachCircleInput {
    outreachCircleId: string;

    searchKey?: string | null;

    paginationInput?: PaginationInput | null;
}

export interface PdiVoterSearchInput {
    outreachCircleId: string;

    filter?: PdiVoterSearchFilterInput | null;

    pagination?: PagePaginationInput | null;
}

export interface PdiVoterSearchFilterInput {
    firstName?: string | null;

    lastName?: string | null;

    address?: string | null;

    streetName?: string | null;

    city?: string | null;

    precinct?: string | null;

    county?: string | null;

    state?: string | null;

    emailAddress?: string | null;

    party?: string | null;

    birthdate?: string | null;

    pdiId?: string | null;

    phoneNumber?: string | null;

    zipCode?: string | null;
}

export interface PagePaginationInput {
    pageSize: number;

    pageNumber: number;
}

export interface GetPdiVoterByIdInput {
    pdiId: string;

    outreachCircleId: string;
}

export interface P2PInboxFilterInput {
    actionId: string;

    skip: number;

    limit: number;

    searchKey?: string | null;

    showClosedConversations?: boolean | null;

    showUnreadOnly?: boolean | null;
}

export interface EligibleSupporterForP2PReplyAssignmentInput {
    supporterId: string;

    actionId: string;

    skip: number;

    limit: number;

    searchText?: string | null;
}

export interface P2PScriptSurveyQuestionsByOutreachCircleInput {
    outreachCircleId: string;

    p2pScriptSurveyQuestionType: P2PScriptSurveyQuestionType;

    searchKey?: string | null;
}

export interface ValidateP2PScriptSurveyQuestionNameInput {
    name: string;

    p2pScriptSurveyQuestionId?: string | null;

    outreachCircleId: string;

    p2pScriptSurveyQuestionType?: P2PScriptSurveyQuestionType | null;
}

export interface RecipientsForP2PTextingInput {
    actionId: string;

    limit?: number | null;

    sessionId: string;
}

export interface P2PWorkspacesByOutreachCircleInput {
    outreachCircleId: string;

    integrationType: AllowedIntegrationDatabaseType;

    searchKey?: string | null;

    skip: number;

    limit: number;
}

export interface ValidateP2PWorkspaceNameInput {
    name: string;

    p2pWorkspaceId?: string | null;

    outreachCircleId: string;
}

export interface GetBallotMeasureByIdInput {
    id: string;

    outreachCircleId: string;
}

export interface GetCandidateByIdInput {
    id: string;

    outreachCircleId: string;
}

export interface GetIssueByIdInput {
    id: string;

    outreachCircleId: string;
}

export interface GetFilteredPurposeListInput {
    outreachCircleId: string;

    filter?: CandidateIssuesFilterInput | null;

    skip?: number | null;

    limit?: number | null;
}

export interface CandidateIssuesFilterInput {
    value?: string | null;

    description?: string | null;

    cycle?: Date | null;

    status?: PurposeStatus | null;
}

export interface GetElectionCycleListForOutreachCircleInput {
    outreachCircleId: string;
}

export interface SearchAndSurveyFormsByOutreachCircleInput {
    outreachCircleId: string;

    searchKey?: string | null;

    integrationTypes: (SearchAndSurveyFormIntegrationType | null)[];
}

export interface ValidateSearchAndSurveyFormNameInput {
    name: string;

    searchAndSurveyFormId?: string | null;

    outreachCircleId: string;
}

export interface GetSocialTextContentForPreviewInput {
    editorContent: string;

    socialTemplateId: string;

    actionId: string;
}

export interface ValidateSocialTemplateNameInput {
    name: string;

    outreachCircleId: string;
}

export interface ValidateOrganizationSocialTemplateNameInput {
    name: string;

    organizationId: string;
}

export interface GetOrganizationSocialTextContentForPreviewInput {
    editorContent: string;

    organizationSocialTemplateId: string;
}

export interface GetSourcingLinkInput {
    skip: number;

    limit: number;

    outreachCircleId: string;
}

export interface SupporterActivityListInput {
    pagination: PaginationInput;

    filter?: SupporterActivityListFilter | null;

    outreachCircleId: string;
}

export interface SupporterActivityListFilter {
    searchKey?: string | null;
}

export interface UpdateReminderContentInput {
    actionId: string;

    content: string;

    linkText: string;
}

export interface SupporterEmailTemplateForOutreachCircle {
    outreachCircleId: string;

    code: SupporterEmailTemplateCode;

    searchKey?: string | null;

    pagination?: PaginationInput | null;
}

export interface ContentForUiPreviewByUiEditorContentForOutreachCircleSupporterEmailTemplateInput {
    editorContent: string;

    supporterEmailTemplateId: string;

    shiftId?: string | null;

    eventId?: string | null;
}

export interface UpdateSupporterEmailTemplateWithRecruitmentLinkInput {
    supporterEmailTemplateId: string;

    content: string;

    linkText: string;

    code?: string | null;
}

export interface ValidateSupporterEmailTemplateNameInput {
    name: string;

    outreachCircleId: string;
}

export interface DefaultSupporterEmailTemplateInput {
    code: SupporterEmailTemplateCode;

    actionId: string;
}

export interface SupporterFormsByOutreachCircleInput {
    outreachCircleId: string;

    searchKey?: string | null;

    responseCountFilter?: ResponseCountFilter | null;
}

export interface ValidateSupporterFormNameInput {
    name: string;

    supporterFormId?: string | null;

    outreachCircleId: string;
}

export interface GetSupporterFormsForOrganizationInput {
    organizationId: string;

    skip: number;

    limit: number;

    sortBy: SupporterFormSortByInput;

    responseCountFilter?: ResponseCountFilter | null;

    outreachCircleIds?: string[] | null;

    organizationFilterId?: string | null;
}

export interface SupporterFormSortByInput {
    sortField: SupporterFormSortBy;

    isAscending: boolean;
}

export interface GetSupporterViewFilterStatesInput {
    skip: number;

    limit: number;

    outreachCircleId: string;

    searchKey?: string | null;
}

export interface DoesSupporterViewFilterStateExistsInput {
    outreachCircleId: string;

    name: string;
}

export interface GetOrganizersByOutreachCircleInput {
    outreachCircleId: string;

    pagination: PaginationInput;

    filter?: OrganizersFilter | null;
}

export interface OrganizersFilter {
    fullName?: string | null;
}

export interface SupporterListInput {
    sortBy: SupporterSummarySortBy;

    isAscending: boolean;

    skip: number;

    limit: number;

    outreachCircleId: string;
}

export interface GetSupporterViewFilterInput {
    sortBy: SupporterViewSortInput[];

    skip: number;

    limit: number;

    outreachCircleId: string;

    filter?: SupporterViewFilterInput | null;
}

export interface SupporterViewSortInput {
    sortField: SupporterViewSortBy;

    isAscending: boolean;
}

export interface InvitedSupporterFileUrlInput {
    invitedSupporterSourceId: string;

    fileName: string;

    fileType: string;
}

export interface InvitedSupporterInformationInput {
    outreachCircleId: string;

    invitedSupporterId: string;
}

export interface ActionSummaryInput {
    actionId: string;

    skip?: number | null;

    limit?: number | null;
}

export interface SupporterFilterForPushNotificationInput {
    includeCampaignLogo: boolean;

    includeRecentlyNotifiedSupporter: boolean;

    outreachCircleId: string;

    selectedSupporterIds?: string[] | null;

    hasAllSelected: boolean;

    excludeSupporterIds?: string[] | null;

    supporterFilter?: SupporterViewFilterInput | null;
}

export interface SupporterEmailFilterInput {
    supporterIds?: (string | null)[] | null;

    excludeSupporterIds?: (string | null)[] | null;

    sendToAll: boolean;

    supporterEmailTemplateId?: string | null;

    outreachCircleId?: string | null;

    filter?: SupporterViewFilterInput | null;
}

export interface BlockFilteredSupportersInput {
    supporterIds?: (string | null)[] | null;

    excludeSupporterIds?: (string | null)[] | null;

    blockAll: boolean;

    outreachCircleId: string;

    filter?: SupporterViewFilterInput | null;
}

export interface IsSupportingOutreachCircleInput {
    outreachCircleId: string;

    email: string;
}

export interface GetSurveyListInput {
    outreachCircleId: string;

    filter?: SurveyListFilterInput | null;

    sort?: SurveyListSortInput | null;

    skip: number;

    limit: number;
}

export interface SurveyListFilterInput {
    searchText?: string | null;

    status?: SurveyStatus | null;

    typeList?: SurveyType[] | null;
}

export interface SurveyListSortInput {
    field: SurveyListSortField;

    isAscending: boolean;
}

export interface GetSurveyByIdInput {
    id: string;

    outreachCircleId: string;
}

export interface GetFilteredSurveyQuestionInput {
    outreachCircleId: string;

    searchText?: string | null;

    status?: SurveyQuestionStatus | null;

    type?: SurveyQuestionType | null;

    pagination?: PaginationInput | null;
}

export interface GetSurveyQuestionByIdInput {
    id: string;

    outreachCircleId: string;
}

export interface SupporterTextTemplateInput {
    textTemplateId: string;

    supporterId: string;

    actionId: string;
}

export interface TextTemplateForOutreachCircleInput {
    outreachCircleId: string;

    actionId?: string | null;

    searchKey?: string | null;
}

export interface ValidateOrganizationTextTemplateNameInput {
    name: string;

    organizationId: string;
}

export interface GetTextContentForPreviewInput {
    editorContent: string;

    textTemplateId: string;

    actionId: string;
}

export interface ValidateTextTemplateNameInput {
    name: string;

    outreachCircleId: string;

    actionId: string;
}

export interface GetOrganizationTextContentForPreviewInput {
    editorContent: string;

    textTemplateId: string;
}

export interface ValidateResetCodeInput {
    resetCode: string;

    userId: string;
}

export interface AllOutreachCirclesInput {
    skip: number;

    limit: number;

    filter?: AllOutreachCirclesFilterInput | null;
}

export interface AllOutreachCirclesFilterInput {
    searchFilter?: OutreachCircleSearchFilterInput | null;

    stateFilter?: OutreachCircleStateFilterInput | null;
}

export interface OutreachCircleSearchFilterInput {
    name?: string | null;

    adminFirstName?: string | null;

    adminLastName?: string | null;
}

export interface OutreachCircleStateFilterInput {
    all?: boolean | null;

    stateCodes?: (string | null)[] | null;

    isExclude?: boolean | null;
}

export interface CreateAcquisitionTypeInput {
    outreachCircleId: string;

    name: string;

    description: string;

    associateWith: AcquisitionTypeAssociateWith;

    pageDefault?: PageDefault[] | null;
}

export interface UpdateAcquisitionTypeInput {
    id: string;

    outreachCircleId: string;

    name: string;

    description: string;

    associateWith?: AcquisitionTypeAssociateWith | null;

    pageDefault?: PageDefault[] | null;
}

export interface UpdateAcquisitionTypeStatusInput {
    id: string;

    outreachCircleId: string;

    isActive: boolean;
}

export interface CopyAcquisitionTypesFromParentOutreachCircleInput {
    acquisitionTypeIds: string[];

    outreachCircleId: string;
}

export interface UpdateAcquisitionTypeForActionInput {
    actionId: string;

    acquisitionTypeId: string;
}

export interface CreateActionViewFilterStateInput {
    outreachCircleId: string;

    name: string;

    state: ActionViewFilterInput;
}

export interface UpdateActionViewFilterStateInput {
    id: string;

    name: string;

    filter: ActionViewFilterInput;
}

export interface GetActionViewFilterStateInput {
    skip: number;

    limit: number;

    outreachCircleId: string;

    searchKey?: string | null;
}

export interface UpdateListForActionInput {
    listId: number;

    actionId: string;

    listSelectionType: ListSelectionType;

    priority?: number | null;

    listType: PdiListType;

    tagIds?: string[] | null;
}

export interface UpdateAudienceActionForActionInput {
    audienceActionId: string;

    actionId: string;

    listSelectionType: ListSelectionType;

    priority?: number | null;

    tagIds?: string[] | null;
}

export interface MarkAssignedMemberAsContactedInput {
    actionId: string;

    outreachChannel: OutreachChannel;

    memberId: string;

    outreachChannelDetail?: string | null;

    script?: string | null;
}

export interface MarkAssignedContactAsContactedInput {
    actionId: string;

    outreachChannel: OutreachChannel;

    contactId: string;

    outreachChannelDetail?: string | null;

    script?: string | null;
}

export interface SaveGroSupporterEmailTemplateInput {
    actionId: string;

    subject?: string | null;

    body?: string | null;
}

export interface SaveGroSupporterTextTemplateInput {
    actionId: string;

    content: string;
}

export interface SaveGroSupporterCallOrVisitTemplateInput {
    actionId: string;

    content: string;
}

export interface UpdateGroMemberInput {
    actionId: string;

    assignedContactUpdates?: AssignedContactUpdatesInput | null;
}

export interface AssignedContactUpdatesInput {
    assignedContact?: AssignedContactInput | null;

    customFields?: (CustomFieldInput | null)[] | null;
}

export interface AssignedContactInput {
    id?: string | null;

    member_id: string;

    first_name?: string | null;

    middle_name?: string | null;

    last_name?: string | null;

    emails?: (ContactEmailInput | null)[] | null;

    mobiles?: (ContactMobileInput | null)[] | null;

    apt_number?: string | null;

    street_name?: string | null;

    street_number?: string | null;

    street_type?: string | null;

    full_address?: string | null;

    city?: string | null;

    state?: string | null;

    zip?: string | null;
}

export interface CustomFieldInput {
    key: string;

    value?: string | null;
}

export interface AssignSupporterToActionInput {
    supporterId: string;

    actionId: string;

    audienceActionId?: string | null;
}

export interface ActionInput {
    type: ActionType;

    name: string;

    outreachCircleId: string;

    title: string;

    description?: string | null;

    link?: string | null;

    endDate?: Date | null;

    startDate?: Date | null;

    outreachChannels?: (OutreachChannel | null)[] | null;

    postActionText?: string | null;

    callToActionText?: string | null;

    engagementId?: string | null;

    additionalDetails?: ActionAdditionalDetailsInput | null;

    isOutreachMode?: boolean | null;

    allowNonMappedContacts?: boolean | null;

    eligibleSupporterForSupporterTextingInput?: EligibleSupporterForSupporterTextingInput | null;

    mediaInput?: ActionMediaInput | null;

    supporterFormId?: string | null;

    audienceActionFilter?: AudienceActionFilterInput | null;

    tagIds?: (string | null)[] | null;

    p2pWorkspaceId?: string | null;

    integrationTypeForAffinityAction?: AllowedIntegrationDatabaseType | null;

    searchAndSurveyNewContactsAudienceActionId?: string | null;

    matchWithSearchAndSurveyLists?: boolean | null;

    searchAndSurveyAudienceActionFilter?: SearchAndSurveyAudienceActionFilterInput | null;

    searchAndSurveyFormId?: string | null;

    groSurveyFormId?: string | null;

    takenConfirmationText?: string | null;

    canSkipTakenConfirmation?: boolean | null;
}

export interface ActionAdditionalDetailsInput {
    isTreatmentEnabled?: boolean | null;

    treatment?: number | null;

    everyActionFormId?: string | null;

    everyActionFormHasRedirect?: boolean | null;

    eventLink?: string | null;

    allowContactImport?: boolean | null;

    eventName?: string | null;

    isEmbeddable?: boolean | null;

    playVideoInAppEnabled?: boolean | null;

    isSharingEnabled?: boolean | null;

    shareButtonText?: string | null;

    facebookConfig?: FacebookConfigInput | null;

    groConfig?: ActionGroConfigInput | null;

    contactTypeLabel?: ContactTypeLabelInput | null;

    isAnonymousSearchAndSurveyEnabled?: boolean | null;

    doorToDoorConfig?: DoorToDoorActionConfigInput | null;

    textingConfig?: TextingConfigInput | null;

    organizerConfig?: OrganizerConfigInput | null;
}

export interface FacebookConfigInput {
    pageUrl?: string | null;

    postId?: string | null;
}

export interface ActionGroConfigInput {
    turfingStrategy?: TurfingStrategyEnum | null;

    supporterIdFieldInList?: string | null;

    supporterEmailFieldInList?: string | null;
}

export interface ContactTypeLabelInput {
    matched?: ContactTypeLabelInfoInput | null;

    unmatched?: ContactTypeLabelInfoInput | null;

    ambiguous?: ContactTypeLabelInfoInput | null;
}

export interface ContactTypeLabelInfoInput {
    position?: number | null;

    title?: string | null;

    description?: string | null;
}

export interface DoorToDoorActionConfigInput {
    isGotvFollowupMode?: boolean | null;

    includeAllVotersInHouseholds?: boolean | null;

    enableHouseholdObservations?: boolean | null;
}

export interface TextingConfigInput {
    isAutoTextingEnabled?: boolean | null;
}

export interface OrganizerConfigInput {
    contactNumber?: string | null;

    name?: string | null;
}

export interface ActionMediaInput {
    fileName: string;

    fileSize: number;

    mediaId?: string | null;

    crop?: CropInput | null;
}

export interface CropInput {
    left: number;

    top: number;

    width: number;

    orientation: number;

    height: number;

    scale: number;

    originalWidth: number;

    originalHeight: number;
}

export interface AudienceActionFilterInput {
    hasAllSelected: boolean;

    integrationType: AllowedIntegrationDatabaseType;

    audienceActionIds?: (string | null)[] | null;
}

export interface SearchAndSurveyAudienceActionFilterInput {
    hasAllSelected: boolean;

    audienceActionIds: (string | null)[];
}

export interface ActionDatesUpdateInput {
    id: string;

    endDate?: Date | null;

    startDate?: Date | null;
}

export interface ActionEmailTemplateInput {
    emailTemplateId: string;

    actionId: string;
}

export interface ActionTextTemplateInput {
    textTemplateId: string;

    actionId: string;
}

export interface UpdateActionTitleAndDescription {
    actionId: string;

    title: string;

    description?: string | null;

    additionalDetails?: ActionAdditionalDetailsInput | null;
}

export interface UpdateActionLinkInput {
    link: string;

    actionId: string;

    additionalDetails?: ActionAdditionalDetailsInput | null;
}

export interface UpdateActionNameInput {
    actionId: string;

    name: string;
}

export interface SendActionEmailInput {
    supporterId: string;

    actionId: string;
}

export interface UpdateOutreachChannelsInput {
    actionId: string;

    outreachChannels: (OutreachChannel | null)[];
}

export interface AudienceActionFilterUpdateInput {
    audienceActionFilterInput: AudienceActionFilterInput;

    actionId: string;
}

export interface ActionImpressionInput {
    actionId: string;

    deviceType: DeviceTypeEnum;
}

export interface ActionStatusUpdateInput {
    actionId: string;

    supporterId: string;

    channel?: OutreachChannel | null;
}

export interface UpdateCallToActionTextInput {
    actionId: string;

    callToActionText: string;
}

export interface UpdatePostActionTextInput {
    actionId: string;

    postActionText: string;
}

export interface UpdateActionOutreachModeInput {
    actionId: string;

    isOutreachMode: boolean;
}

export interface UpdateAllowNonMappedContactsInput {
    actionId: string;

    allowNonMappedContacts: boolean;
}

export interface ActionSurveyLinkInput {
    surveyId: string;

    actionId: string;
}

export interface UpdateActionPositionInput {
    actionId: string;

    position: number;
}

export interface ActionImageInput {
    mediaId?: string | null;

    actionId: string;

    fileName?: string | null;

    fileSize?: number | null;

    crop?: CropInput | null;
}

export interface UpdateIsEngagementRequiredInput {
    actionId: string;

    isEngagementRequired: boolean;
}

export interface TextSentToSupporterInput {
    actionId: string;

    supporterId: string;

    mobileNumber: string;
}

export interface UpdateTreatmentForActionInput {
    actionId: string;

    isTreatmentEnabled: boolean;

    treatment?: number | null;
}

export interface UpdateContactImportPermissionInput {
    actionId: string;

    allowContactImport: boolean;
}

export interface ActionCampaignInput {
    actionId: string;

    campaignId: string;
}

export interface UpdateShareActionButtonTextInput {
    actionId: string;

    shareButtonText: string;
}

export interface RemoveReviewWarningInput {
    actionId: string;

    reviewKey?: ReviewWarningKey | null;
}

export interface AssignSupportersToActionInput {
    selectedSupporterIds?: string[] | null;

    hasAllSelected?: boolean | null;

    excludeSupporterIds?: string[] | null;

    actionId: string;

    filter?: SupporterViewFilterInput | null;
}

export interface AssignSupportersFromGroupToActionInput {
    actionId: string;

    groupId: string;
}

export interface SearchAndSurveyAudienceActionFilterUpdateInput {
    searchAndSurveyAudienceActionFilterUpdateInput: SearchAndSurveyAudienceActionFilterInput;

    actionId: string;
}

export interface UpdateSearchAndSurveyNewContactsListForActionInput {
    actionId: string;

    newContactsListId: string;
}

export interface CustomFieldForSearchAndSurveyActionInput {
    actionId: string;

    audienceCustomFieldId: string;

    displayType: SearchAndSurveyDisplayType;
}

export interface UpdateActionConfirmationInput {
    actionId: string;

    takenConfirmationText?: string | null;

    canSkipTakenConfirmation: boolean;
}

export interface UpdateCallOrVisitTemplate {
    actionId: string;

    callTemplate?: string | null;

    visitTemplate?: string | null;
}

export interface RemoveTemplateFromActionForOutreachChannelInput {
    outreachChannel: OutreachChannel;

    actionId: string;
}

export interface SendEmailNotificationForActionInput {
    actionId: string;

    code: SupporterEmailTemplateCode;
}

export interface SendPushNotificationForActionInput {
    actionId: string;

    code: SupporterPushNotificationTemplateCode;
}

export interface AssignGroupToActionInput {
    actionId: string;

    selectedGroupIds: string[];

    unSelectedGroupIds: string[];
}

export interface UpdateGroConfigInput {
    actionId: string;

    groConfig: ActionGroConfigInput;
}

export interface UpdateAddressBasedCanvassingConfigInput {
    actionId: string;

    config: ActionAddresBasedConfigInput;
}

export interface ActionAddresBasedConfigInput {
    maxMemberAssignmentForSupporter?: number | null;

    turfingRangeInMiles?: number | null;
}

export interface UpdatePdiOnTheGoCanvassingConfigInput {
    actionId: string;

    config: PdiOnTheGoCanvassingConfigInput;
}

export interface PdiOnTheGoCanvassingConfigInput {
    pdiProject?: PdiProjectInput | null;

    allowAddingMembers?: boolean | null;

    allowCensusBlocks?: boolean | null;
}

export interface PdiProjectInput {
    pdiProjectId?: string | null;

    pdiProjectName?: string | null;
}

export interface UpdateContactTypeLabelInput {
    actionId: string;

    contactTypeLabel: ContactTypeLabelInput;
}

export interface CreateMemberNoteInput {
    actionId: string;

    memberId: string;

    note: string;
}

export interface UpdateSurveyForActionInput {
    actionId: string;

    surveyId: string;

    type: ListSelectionType;

    priority?: number | null;
}

export interface RemoveSurveyFromActionInput {
    surveyId: string;

    actionId: string;
}

export interface UpdateSurveyQuestionForActionInput {
    actionId: string;

    type: ActionSurveyQuestionType;

    surveyQuestionId: string;
}

export interface UpdateMemberDisplayFieldsInput {
    actionId: string;

    displayFields: string[];
}

export interface UpdateActionVisibilityInput {
    actionId: string;

    visibility: Visibility;
}

export interface UpdateVolunteerAttendeesAttendanceInput {
    actionId: string;

    supporterIds?: string[] | null;

    shiftId?: string | null;

    status: AttendeeStatus;

    all: boolean;

    excludeSupporterIds?: (string | null)[] | null;
}

export interface UpdateActionAliasLinkInput {
    actionId: string;

    aliasId: string;

    allowDuplicates?: boolean | null;
}

export interface UpdateP2PConfigForActionInput {
    actionId: string;

    allowUninterruptedTexting?: boolean | null;

    disableManageReplies?: boolean | null;

    disableEditMessage?: boolean | null;
}

export interface PurchasePhoneNumberForP2PActionInput {
    areaCode: number;

    actionId: string;
}

export interface P2PScheduleInput {
    actionId: string;

    startTime: P2PTimeInput;

    endTime: P2PTimeInput;
}

export interface P2PTimeInput {
    hour: number;

    minute: number;

    timezone: string;
}

export interface UpdateActionDialerTypeInput {
    actionId: string;

    dialerType: DialerType;
}

export interface ConnectToPhoneBankInput {
    actionId: string;

    identity: string;

    phoneNumber?: string | null;
}

export interface CallPhoneBankMemberInput {
    actionId: string;

    memberId: string;
}

export interface HangupCallInput {
    actionId: string;

    callSid: string;
}

export interface ToggleMuteForCallInput {
    actionId: string;

    callSid: string;

    mute: boolean;
}

export interface UpdatePhoneBankMemberDetailsInput {
    memberId: string;

    actionId: string;

    phones: PhoneInput[];

    emails: string[];

    language?: string | null;

    pronouns?: Pronouns | null;
}

export interface PhoneInput {
    number: string;

    type: PhoneType;

    optIn: boolean;
}

export interface UpdateIsAnonymousSearchAndSurveyForActionInput {
    actionId: string;

    isAnonymousSearchAndSurveyEnabled: boolean;
}

export interface UpdateTextingConfigForActionInput {
    actionId: string;

    textingSchedule?: TextingScheduleInput | null;

    disableManageReplies?: boolean | null;

    disableEditMessage?: boolean | null;
}

export interface TextingScheduleInput {
    startTime: TextingTimeInput;

    endTime: TextingTimeInput;
}

export interface TextingTimeInput {
    hour: number;

    minute: number;

    timezone: string;
}

export interface UpdateInitialTextingScriptInput {
    actionId: string;

    textBody: string;
}

export interface SendBulkInitialTextsInput {
    actionId: string;

    textingItemList: TextingItemInput[];
}

export interface TextingItemInput {
    textBody: string;

    recipientId: string;
}

export interface UnAssignVolunteerFromTextingConversationInput {
    actionId: string;

    recipientIds?: string[] | null;

    filter?: TextingConversationFilterInput | null;
}

export interface TransferTextingConversationToVolunteerInput {
    actionId: string;

    recipientIds: string[];

    oldSupporterId: string;

    newSupporterId: string;

    transferDate?: TransferDateInput | null;
}

export interface TransferDateInput {
    fromDate: Date;

    toDate: Date;
}

export interface UpdateTextingConversationTransferToVolunteerInput {
    recipientId: string;

    actionId: string;

    oldSupporterId: string;

    newSupporterId: string;

    transferDate: TransferDateInput;
}

export interface CreateRecipientTextingConversationItemInput {
    conversationId: string;

    message?: string | null;

    questionResponse?: SubmitSurveyResponseInput | null;
}

export interface SubmitSurveyResponseInput {
    actionId: string;

    surveyId: string;

    surveyQuestionId: string;

    surveyOptionId: string;

    memberId: string;
}

export interface AddMemberToHouseHoldInput {
    searchAndSurveyMemberId: string;

    householdId: string;

    actionId: string;
}

export interface RecordTagForHouseholdInput {
    householdId: string;

    actionId: string;

    tagId: string;
}

export interface RecordTagForSearchAndSurveyMemberInput {
    householdId: string;

    searchAndSurveyMemberId: string;

    actionId: string;

    tagId: string;
}

export interface ResolveAmbiguousHouseholdMemberInput {
    id: string;

    actionId: string;

    householdId: string;

    memberId?: string | null;
}

export interface RetrySyncForHouseholdMembersInput {
    actionId: string;

    deviceId: string;
}

export interface CreateSearchAndSurveyListInput {
    name: string;

    outreachCircleId: string;
}

export interface ImportPdiListInput {
    id: number;

    listType: PdiListType;

    outreachCircleId: string;
}

export interface ImportProjectListFromFileInput {
    audienceId: string;

    audienceActionId: string;

    mapping: Mapping;

    fileName?: string | null;

    delimiter?: string | null;

    customfields?: (string | null)[] | null;

    listType: ListType;

    source?: AudienceActionSource | null;

    database?: AudienceActionDatabase | null;
}

export interface Mapping {
    mappingData: Json;

    name: string;
}

export interface AudienceActionInput {
    audienceId: string;

    audienceActionId: string;

    key?: string | null;

    mapping: Mapping;

    fileName?: string | null;

    delimiter?: string | null;

    customfields?: (string | null)[] | null;

    listType: ListType;

    source?: AudienceActionSource | null;

    database?: AudienceActionDatabase | null;

    migrate?: boolean | null;

    salesforceInput?: SalesforceInput | null;
}

export interface SalesforceInput {
    soql?: string | null;

    schedule: SoqlScheduleInput;
}

export interface SoqlScheduleInput {
    frequency?: Frequency | null;

    dayOfWeek?: number | null;

    dayOfMonth?: number | null;
}

export interface DeleteAudienceByListTypeInput {
    audienceId: string;

    listType: ListType;
}

export interface BlockMemberInput {
    memberId: string;

    audienceId: string;
}

export interface UpdateAudienceActionInput {
    id: string;

    operation: AudienceActionOperation;
}

export interface ActionOnMembersFromBlackbaudImportInput {
    outreachCircleId: string;

    constituentListId: string;

    audienceMappingName: string;

    database?: AudienceActionDatabase | null;
}

export interface SetEmailRecipientInput {
    contactId: string;

    supporterId: string;

    memberId: string;

    isEmailRecipient: boolean;

    actionId: string;
}

export interface ResolveAmbiguousMappedContactInput {
    supporterId: string;

    contactId: string;

    memberId: string;

    resolve: boolean;
}

export interface CreateMatchingJobInput {
    actionId?: string | null;

    supporterId: string;
}

export interface MarkEligibleContactAsEmailRecipientInput {
    actionId: string;

    supporterId: string;

    contactId: string;

    isEmailRecipient: boolean;
}

export interface ToggleA1CRecipientMappedInput {
    actionId: string;

    supporterId: string;

    contactId: string;

    memberId: string;

    isA1CRecipient: boolean;
}

export interface ToggleAllA1CRecipientInput {
    actionId: string;

    supporterId: string;

    isSet: boolean;
}

export interface ToggleA1CRecipientNonMappedInput {
    actionId: string;

    supporterId: string;

    contactId: string;

    isA1CRecipient: boolean;
}

export interface ToggleAmbiguousA1CRecipientInput {
    actionId: string;

    supporterId: string;

    contactId: string;

    memberId?: string | null;

    isA1CRecipient: boolean;
}

export interface DeleteMappedContactInput {
    contactId: string;

    actionId: string;
}

export interface ContactUsInput {
    name: string;

    emailId: string;

    phone?: string | null;

    message: string;

    deviceDetails: DeviceDetails;

    outreachCircleId?: string | null;

    messageSource?: string | null;

    outreachCircleAlias?: string | null;

    actionId?: string | null;
}

export interface DeviceDetails {
    browserName?: string | null;

    browserVersion?: string | null;

    currentRoute?: string | null;

    platform: string;

    phoneModelName?: string | null;

    phoneOSVersion?: string | null;

    appVersion?: string | null;

    phoneOS?: string | null;

    screenResolution?: string | null;
}

export interface ContactFileImportInput {
    fileName: string;

    key: string;

    mapping?: Json | null;

    delimiter?: string | null;

    fileType: string;

    platform?: ContactSourcePlatform | null;
}

export interface CreateContactInput {
    firstName?: string | null;

    middleName?: string | null;

    lastName?: string | null;

    emails?: (ContactEmailInput | null)[] | null;

    mobiles?: (ContactMobileInput | null)[] | null;

    streetNumber?: string | null;

    streetName?: string | null;

    city?: string | null;

    state?: string | null;

    country?: string | null;

    zip?: string | null;
}

export interface UpdateContactInput {
    id: string;

    firstName?: string | null;

    lastName?: string | null;

    emails?: (ContactEmailInput | null)[] | null;

    mobiles?: (ContactMobileInput | null)[] | null;

    streetNumber?: string | null;

    streetName?: string | null;

    city?: string | null;

    state?: string | null;

    country?: string | null;

    zip?: string | null;
}

export interface UpdatePrimaryEmailInput {
    primaryEmail: string;

    contactId: string;
}

export interface ResolveDuplicateEmailContactInput {
    email: string;

    contactId: string;
}

export interface UpdatePdiListStatusInput {
    outreachCircleId: string;

    listId: number;

    listType: PdiListType;

    isActive: boolean;
}

export interface CreateTurfGroupInput {
    name: string;

    description?: string | null;

    turfIds: string[];

    actionId: string;

    color?: string | null;
}

export interface UpdateTurfGroupInput {
    id: string;

    name: string;

    color?: string | null;
}

export interface AddTurfToTurfGroupInput {
    turfIds: string[];

    turfGroupId: string;
}

export interface AssignSupportersToTurfInput {
    supporters: SupporterTurfAssignmentInput[];

    turfId: string;
}

export interface SupporterTurfAssignmentInput {
    supporterId: string;

    isCaptain?: boolean | null;
}

export interface RemoveSupportersFromTurfInput {
    supporterIds: string[];

    turfId: string;
}

export interface CopyTurfForActionInput {
    turfId: string;

    actionId: string;
}

export interface SubmitSurveyResponseForHouseholdInput {
    householdId: string;

    actionId: string;

    turfAssignmentId: string;

    surveyQuestionId: string;

    surveyQuestionResponseIds: string[];

    location?: LocationInput | null;
}

export interface LocationInput {
    lat?: number | null;

    lon?: number | null;
}

export interface CreateTurfAssignmentInput {
    turfGroupId: string;

    locationCoordinates: LocationInput;

    shiftId?: string | null;
}

export interface MarkSurveyAsCompleteInput {
    memberId: string;

    actionId: string;

    canvassingAssignmentId: string;
}

export interface EditVoterContactDetailsInput {
    memberId: string;

    actionId: string;

    phones: PhoneInput[];

    emails: CanvassingMemberEmailInput[];

    language?: string | null;

    pronouns?: Pronouns | null;
}

export interface CanvassingMemberEmailInput {
    email: string;

    optIn: boolean;
}

export interface ShareTurfAssignmentInput {
    supporterIds: string[];

    turfAssignmentId: string;
}

export interface CreateCanvassingMemberInput {
    firstName: string;

    lastName: string;

    actionId: string;

    turfAssignmentId: string;

    externalId?: string | null;

    householdId?: string | null;

    city?: string | null;

    state?: string | null;

    zip?: string | null;

    fullAddress?: string | null;

    streetNumber?: string | null;

    streetName?: string | null;

    streetType?: string | null;

    apartment?: string | null;

    age?: number | null;

    gender?: string | null;

    politicalParty?: string | null;

    phones: PhoneInput[];

    emails: CanvassingMemberEmailInput[];

    location: LocationInput;
}

export interface CreateCanvassingHouseholdInput {
    actionId: string;

    externalId?: string | null;

    location: LocationInput;

    censusBlockId?: string | null;

    precinct?: string | null;

    fullAddress?: string | null;

    apartment?: string | null;

    streetNumber?: string | null;

    streetName?: string | null;

    streetType?: string | null;

    city?: string | null;

    state?: string | null;

    zip?: string | null;

    addressPreDirectional?: string | null;

    addressPostDirectional?: string | null;

    locationDescription?: string | null;

    prefix?: string | null;
}

export interface ApplyNotHomeOnHouseholdInput {
    householdId: string;

    actionId: string;

    turfAssignmentId: string;

    location?: LocationInput | null;
}

export interface CreateCustomTurfInput {
    outreachCircleId: string;

    geojson: Json;

    name?: string | null;

    color?: string | null;
}

export interface UpdateTurfInput {
    id: string;

    name?: string | null;

    color?: string | null;
}

export interface SubmitCanvassingSurveyResponseInput {
    actionId: string;

    turfAssignmentId: string;

    surveyId: string;

    surveyQuestionId: string;

    surveyQuestionResponseIds: string[];

    memberId: string;

    location: LocationInput;
}

export interface ApproveOrRejectCanvassedSurveyResponseForMemberInput {
    supporterId: string;

    memberIds: string[];
}

export interface ApproveOrRejectAllCanvassedSurveyResponsesInput {
    outreachCircleId?: string | null;

    surveyResponseStatus: CanvassedSurveyResponseStatus[];

    approvalStatus: CanvassedSurveyResponseStatus;
}

export interface ImportPdiListForCanvassingInput {
    pdiListId: number;

    listType: PdiListType;

    outreachCircleId: string;
}

export interface CreateEmailTemplateInput {
    name: string;

    subject: string;

    body?: string | null;

    actionId: string;

    fromNameAlias?: string | null;
}

export interface DuplicateEmailTemplateInput {
    actionId: string;

    name: string;

    emailTemplateId: string;
}

export interface UpdateEmailTemplateInput {
    id: string;

    name: string;

    subject: string;

    body: string;

    actionId: string;

    fromNameAlias?: string | null;
}

export interface UpdateEmailTemplateForSupporterInput {
    id: string;

    subject: string;

    body: string;

    supporterId: string;

    actionId: string;
}

export interface EditedEmailPreviewInput {
    body: string;

    subject: string;

    outreachCircleId: string;

    emailTemplateId: string;
}

export interface CreateOrganizationEmailTemplateInput {
    name: string;

    subject: string;

    body?: string | null;

    organizationId: string;

    organizationActionId: string;

    fromNameAlias?: string | null;
}

export interface UpdateOrganizationEmailTemplateInput {
    id: string;

    name: string;

    subject: string;

    body: string;

    organizationActionId: string;

    fromNameAlias?: string | null;
}

export interface UpdateEmailTemplateForOrgWithRecruitmentLinkInput {
    emailTemplateId: string;

    content: string;

    linkText: string;
}

export interface EditedOrganizationTemplateEmailPreviewInput {
    body: string;

    subject: string;

    organizationId: string;

    emailTemplateId: string;
}

export interface SurveyEngagementInput {
    id?: string | null;

    name: string;

    description?: string | null;

    outreachCircleId: string;

    addSupporterToInviterSupporterGroups?: boolean | null;

    questions?: (EngagementQuestionInput | null)[] | null;

    integrationType?: AllowedIntegrationDatabaseType | null;
}

export interface EngagementQuestionInput {
    id?: string | null;

    questionText: string;

    ngpSurveyQuestionId?: number | null;

    pdiQuestionId?: string | null;

    questionType: EngagementQuestionType;

    isMandatory: boolean;

    isMultipleOptionsAllowed: boolean;

    hasResponseLogic?: boolean | null;

    position: number;

    options?: EngagementOptionInput[] | null;
}

export interface EngagementOptionInput {
    id?: string | null;

    optionText: string;

    position: number;

    linkedEngagement?: DefaultEngagementInput | null;

    ngpSurveyResponseId?: number | null;

    pdiAnswerId?: string | null;

    linkedNgpActivistCode?: NgpActivistCodeInput | null;

    type?: EngagementOptionType | null;

    linkedQuestionPosition?: number | null;
}

export interface DefaultEngagementInput {
    name: string;

    type?: EngagementType | null;

    description?: string | null;

    outreachCircleId: string;

    url?: string | null;

    message?: string | null;

    id?: string | null;

    additionalDetails?: Json | null;
}

export interface NgpActivistCodeInput {
    activistCodeId?: number | null;

    type?: string | null;

    name?: string | null;

    mediumName?: string | null;

    shortName?: string | null;

    description?: string | null;

    scriptQuestion?: string | null;

    status?: string | null;

    isMultiAssign?: boolean | null;
}

export interface SaveSurveyResponseInput {
    responses: EngagementOptionResponseInput[];

    emailRecipientId?: string | null;

    textRecipientId?: string | null;
}

export interface EngagementOptionResponseInput {
    engagementOptionId: string;

    response?: FormResponse | null;
}

export interface FormResponse {
    text?: string | null;

    addressField?: AddressFields | null;
}

export interface AddressFields {
    address: string;

    cityAndState: string;

    zip: string;
}

export interface DuplicateSurveyEngagementInput {
    surveyId: string;

    name: string;
}

export interface AddActivityInput {
    outreachCircleId: string;

    name: string;

    color?: string | null;
}

export interface CreateEventConfigInput {
    outreachCircleId: string;

    name: string;

    title: string;
}

export interface UpdateEventConfigInput {
    id: string;

    name: string;

    title: string;

    description?: string | null;

    eventInformation?: EventConfigInformationInput | null;

    eventSchedule?: EventConfigScheduleInput | null;

    shifts?: EventConfigShiftInput[] | null;

    isLocationPublic?: boolean | null;

    isNonWorkingGuestsAllowed?: boolean | null;

    shiftAssignmentAvailability?: ShiftAssignmentAvailability | null;

    eventVisibility?: Visibility | null;
}

export interface EventConfigInformationInput {
    locationType?: LocationType | null;

    venue?: string | null;

    locationId?: string | null;

    contactNumber?: string | null;

    contactEmail?: string | null;

    locationCoordinates?: LocationInput | null;
}

export interface EventConfigScheduleInput {
    frequency?: EventFrequency | null;

    startDate?: Date | null;

    endDate?: Date | null;

    customConfigs?: CustomEventConfigScheduleInput[] | null;

    eventTime?: EventTimeInput | null;

    recurrenceNumber?: number | null;
}

export interface CustomEventConfigScheduleInput {
    dayOfTheWeek?: DayOfTheWeek | null;

    startTime?: TimeWithTimezoneInput | null;

    endTime?: TimeWithTimezoneInput | null;
}

export interface TimeWithTimezoneInput {
    hour: number;

    minute: number;

    timezone: string;
}

export interface EventTimeInput {
    startTime?: TimeWithTimezoneInput | null;

    endTime?: TimeWithTimezoneInput | null;
}

export interface EventConfigShiftInput {
    id: string;

    supporterId?: string | null;

    schedule?: ShiftScheduleInput | null;

    description?: string | null;

    goalCount?: number | null;

    dayOfTheWeek?: DayOfTheWeek | null;

    activityId?: string | null;
}

export interface ShiftScheduleInput {
    startTime: TimeWithTimezoneInput;

    endTime: TimeWithTimezoneInput;
}

export interface EventConfigImageInput {
    mediaId?: string | null;

    eventConfigId: string;

    fileName?: string | null;

    fileSize?: number | null;

    crop?: CropInput | null;
}

export interface UpdateActionEventInformationInput {
    actionId: string;

    locationType?: LocationType | null;

    locationCoordinates?: LocationInput | null;

    venue?: string | null;

    locationId?: string | null;

    contactNumber?: string | null;

    contactEmail?: string | null;
}

export interface UpdateActionEventScheduleInput {
    actionId: string;

    eventDate: Date;

    startTime: TimeWithTimezoneInput;

    endTime: TimeWithTimezoneInput;
}

export interface UpdateEventLocationVisibilityInput {
    actionId: string;

    isLocationPublic: boolean;
}

export interface UpdateActionEventParticipationInput {
    actionId: string;

    isNonWorkingGuestsAllowed: boolean;
}

export interface NotifyAssignedSupportersViaEmailForEventInput {
    actionId: string;

    supporterEmailTemplateId: string;

    hasEmailConsent: boolean;

    supporterIds: string[];

    shiftIds?: string[] | null;
}

export interface UpdateShiftAssignmentAvailabilityInput {
    actionId: string;

    shiftAssignmentAvailability: ShiftAssignmentAvailability;
}

export interface UpdateEventSeriesInput {
    id: string;

    name: string;

    title: string;

    description?: string | null;

    eventInformation?: EventConfigInformationInput | null;

    eventSchedule?: EventSeriesScheduleInput | null;

    isLocationPublic?: boolean | null;

    isNonWorkingGuestsAllowed?: boolean | null;

    shiftAssignmentAvailability?: ShiftAssignmentAvailability | null;

    eventVisibility?: Visibility | null;
}

export interface EventSeriesScheduleInput {
    frequency?: EventFrequency | null;

    startDate?: Date | null;

    endDate?: Date | null;

    recurrenceNumber?: number | null;

    dayOfTheWeek?: DayOfTheWeek | null;

    eventTime?: EventTimeInput | null;
}

export interface EventSeriesImageInput {
    mediaId?: string | null;

    eventSeriesId: string;

    fileName?: string | null;

    fileSize?: number | null;

    crop?: CropInput | null;
}

export interface AddShiftSeriesInput {
    eventSeriesId: string;

    supporterId?: string | null;

    schedule: ShiftScheduleInput;

    description?: string | null;

    goalCount?: number | null;

    activityId: string;
}

export interface UpdateShiftSeriesInput {
    id: string;

    supporterId?: string | null;

    schedule: ShiftScheduleInput;

    description?: string | null;

    goalCount?: number | null;

    activityId: string;
}

export interface AssignTurfGroupToShiftSeriesInput {
    shiftSeriesId: string;

    turfGroupIds: string[];
}

export interface AddShiftInput {
    actionId: string;

    activityId?: string | null;

    supporterId?: string | null;

    startTime: TimeWithTimezoneInput;

    endTime: TimeWithTimezoneInput;

    goalCount?: number | null;

    description?: string | null;
}

export interface UpdateShiftInput {
    id: string;

    activityId?: string | null;

    supporterId?: string | null;

    startTime: TimeWithTimezoneInput;

    endTime: TimeWithTimezoneInput;

    goalCount?: number | null;

    description?: string | null;
}

export interface AssignTurfGroupToShiftInput {
    shiftId: string;

    turfGroupIds: string[];
}

export interface AssignActionsToShiftInput {
    shiftId: string;

    actionIds: string[];
}

export interface UpdateAttendanceStatusForShiftInput {
    shiftId: string;

    status?: AttendeeStatus | null;
}

export interface SaveAttendanceStatusForSupporterInput {
    shiftId?: string | null;

    actionId?: string | null;

    supporterId: string;

    type: EventParticipantType;

    status: AttendeeStatus;
}

export interface UpdateAttendanceStatusForShiftEmbedInput {
    shiftId: string;

    status: AttendeeStatus;
}

export interface UpdateShiftAliasLinkInput {
    shiftId: string;

    aliasId: string;
}

export interface CreateFlagInput {
    value: string;

    description: string;

    outreachCircleId: string;

    flagAssociationId?: string | null;

    isDefault?: boolean | null;

    isContact?: boolean | null;
}

export interface UpdateFlagInput {
    id: string;

    outreachCircleId: string;

    value: string;

    description: string;

    flagAssociationId?: string | null;

    isDefault?: boolean | null;

    isContact?: boolean | null;
}

export interface UpdateFlagStatusInput {
    id: string;

    outreachCircleId: string;

    status: FlagStatus;
}

export interface CopyFlagsFromParentOutreachCircleInput {
    flagIds: string[];

    outreachCircleId: string;
}

export interface AddFlagsToAliasInput {
    aliasId: string;

    flagIds: string[];
}

export interface RemoveFlagsFromAliasInput {
    aliasId: string;

    flagIds: string[];
}

export interface UpdateGroSurveyFormInput {
    id: string;

    name: string;

    groSurveyFormQuestions: GroSurveyFormQuestionInput[];
}

export interface GroSurveyFormQuestionInput {
    id?: string | null;

    questionText: string;

    questionType: GroSurveyFormQuestionType;

    isMandatory: boolean;

    position: number;

    options?: GroSurveyFormOptionInput[] | null;

    questionIntegrationType: GroSurveyFormQuestionIntegrationType;

    pdiSurveyQuestionId?: string | null;

    ngpSurveyQuestionId?: number | null;
}

export interface GroSurveyFormOptionInput {
    id?: string | null;

    optionText: string;

    position: number;

    optionType?: GroSurveyFormOptionType | null;

    pdiAnswerId?: string | null;

    ngpSurveyResponseId?: number | null;

    linkedNgpActivistCode?: NgpActivistCodeInput | null;
}

export interface CreateGroSurveyFormInput {
    outreachCircleId: string;

    name: string;

    groSurveyFormIntegrationType?: GroSurveyFormIntegrationType | null;
}

export interface GroSurveyFormActionInput {
    actionId: string;

    groSurveyFormId: string;
}

export interface DuplicateGroSurveyFormInput {
    groSurveyFormId: string;

    name: string;
}

export interface SubmitGroSurveyFormQuestionResponseInput {
    supporterId: string;

    actionId: string;

    groSurveyFormQuestionId: string;

    responses: GroSurveyFormResponseInput[];

    memberId: string;
}

export interface GroSurveyFormResponseInput {
    groSurveyFormOptionId: string;

    response?: GroSurveyFormResponseDetails | null;
}

export interface GroSurveyFormResponseDetails {
    text?: string | null;

    addressField?: AddressFields | null;
}

export interface SubmitGroSurveyFormQuestionResponseForContactInput {
    supporterId: string;

    actionId: string;

    groSurveyFormQuestionId: string;

    responses: GroSurveyFormResponseInput[];

    contactId: string;
}

export interface CreateGroupInput {
    outreachCircleId: string;

    name: string;
}

export interface UpdateGroupInput {
    id: string;

    name: string;
}

export interface AssignActionToGroupInput {
    actionId: string;

    groupId: string;
}

export interface AssignAliasToGroupInput {
    groupId: string;

    aliasId: string;
}

export interface UnAssignActionFromGroupInput {
    actionId: string;

    groupId: string;
}

export interface UnAssignAliasForGroupInput {
    groupId: string;

    aliasId: string;
}

export interface AssignSupportersToGroupsInput {
    supporterIds?: (string | null)[] | null;

    assignAll: boolean;

    excludeSupporterIds?: (string | null)[] | null;

    groupIds: (string | null)[];

    filter?: SupporterViewFilterInput | null;
}

export interface UnAssignSupporterFromGroupInput {
    supporterId: string;

    groupId: string;
}

export interface FacebookPixelIntegrationInput {
    outreachCircleId: string;

    facebookPixelCode: string;
}

export interface CreateOrUpdateNationBuilderIntegrationInput {
    outreachCircleId: string;

    slug: string;

    apiToken: string;
}

export interface AddOrUpdateNationBuilderTagsForAliasInput {
    outreachCircleId: string;

    alias: string;

    peopleTags: (NationBuilderPeopleTagsInput | null)[];

    applyToAllCustomAliases: boolean;
}

export interface NationBuilderPeopleTagsInput {
    name: string;
}

export interface SaveNgpIntegrationInput {
    appName: string;

    apiKey: string;

    outreachCircleId: string;

    myVoter: boolean;

    myCampaign: boolean;
}

export interface ActionOnNgpMembersInput {
    outreachCircleId: string;

    savedListId: string;

    audienceMappingName: string;

    database?: AudienceActionDatabase | null;

    listType: ListType;
}

export interface AddNgpActivistCodesForAliasInput {
    aliasId: string;

    activistCode: NgpActivistCodeInput;
}

export interface RemoveNgpActivistCodesForAliasInput {
    aliasId: string;

    activistCodeId: number;
}

export interface UpdateApplyToAllCustomAliasesForNgpActivistCodeInput {
    outreachCircleId: string;

    applyToAllCustomAliases: boolean;
}

export interface AssignPdiZoneInput {
    actionId: string;

    zoneIds: number[];

    location: LocationCoordinateInput;
}

export interface CreateOrUpdatePdiIntegrationInput {
    outreachCircleId: string;

    username: string;

    password: string;

    apiToken: string;
}

export interface AddPdiFlagIdForAliasInput {
    aliasId: string;

    flagId: PdiFlagIdInput;
}

export interface PdiFlagIdInput {
    id: string;

    flagId: string;

    flagIdDescription: string;

    compile: string;

    isDefault?: boolean | null;
}

export interface RemovePdiFlagIdForAliasInput {
    aliasId: string;

    pdiFlagId: string;
}

export interface UpdateApplyPdiFlagsGloballyInput {
    outreachCircleId: string;

    applyGlobally: boolean;
}

export interface RegisterTcrBrandInput {
    organizationType: TcrBrandOrganizationType;

    companyName: string;

    ein: string;

    phone: string;

    street: string;

    city: string;

    state: string;

    postalCode: string;

    country: string;

    email: string;

    website: string;

    vertical: TcrBrandVertical;

    outreachCircleId: string;

    optInUrl: string;
}

export interface CreateTcrBrandPoliticalVettingInput {
    brandId: string;

    cvToken: string;

    outreachCircleId: string;
}

export interface RegisterTcrCampaignInput {
    brandId: string;

    usecase: string;

    subUsecaseList?: (string | null)[] | null;

    description: string;

    sample1: string;

    sample2: string;

    outreachCircleId: string;

    messageFlow: string;

    helpMessage: string;

    optoutMessage: string;

    termsAndConditions: boolean;
}

export interface MediaInputType {
    fileName: string;

    fileType: string;

    fileSize: number;

    outreachCircleId: string;

    mediaId?: string | null;
}

export interface OrgMediaInputType {
    fileName: string;

    fileType: string;

    fileSize: number;

    organizationId: string;

    mediaId?: string | null;
}

export interface PresignedP2PScriptMediaUploadRequestInput {
    outreachCircleId: string;

    media?: P2PScriptMediaInputType[] | null;
}

export interface P2PScriptMediaInputType {
    fileName: string;

    fileType: string;

    fileSize: number;

    mediaId?: string | null;
}

export interface OrganizationActionMediaInputType {
    fileName: string;

    fileType: string;

    fileSize: number;

    organizationId: string;

    mediaId?: string | null;
}

export interface EditorMediaInputType {
    fileName: string;

    fileType: string;

    fileSize: number;

    outreachCircleId: string;

    emailTemplateId?: string | null;

    supporterEmailTemplateId?: string | null;

    mediaId?: string | null;
}

export interface OrgEditorMediaInputType {
    fileName: string;

    fileType: string;

    fileSize: number;

    organizationId: string;

    emailTemplateId: string;

    mediaId?: string | null;
}

export interface PresignedTextingScriptMediaUploadRequestInput {
    outreachCircleId: string;

    media: TextingScriptMediaInputType[];
}

export interface TextingScriptMediaInputType {
    fileName: string;

    fileType: string;

    fileSize: number;

    mediaId?: string | null;
}

export interface EventConfigMediaInputType {
    fileName: string;

    fileType: string;

    fileSize: number;

    eventConfigId: string;
}

export interface EventSeriesMediaInputType {
    fileName: string;

    fileType: string;

    fileSize: number;

    eventSeriesId: string;
}

export interface UserProfileImageMediaInputType {
    fileName: string;

    fileType: string;

    fileSize: number;
}

export interface PushNotificationImageMediaInputType {
    outreachCircleId: string;

    fileName: string;

    fileType: string;

    fileSize: number;
}

export interface OrganizationActionInput {
    type: OrganizationActionType;

    name: string;

    organizationId: string;

    title: string;

    description?: string | null;

    link?: string | null;

    isOutreachMode?: boolean | null;

    allowNonMappedContacts?: boolean | null;

    postActionText?: string | null;

    callToActionText?: string | null;

    mediaInput?: ActionMediaInput | null;

    additionalDetails?: OrganizationActionAdditionalDetailsInput | null;

    integrationType?: AllowedIntegrationDatabaseType | null;

    outreachChannels?: (OutreachChannel | null)[] | null;

    takenConfirmationText?: string | null;

    canSkipTakenConfirmation?: boolean | null;
}

export interface OrganizationActionAdditionalDetailsInput {
    eventLink?: string | null;

    eventName?: string | null;

    playVideoInAppEnabled?: boolean | null;

    isSharingEnabled?: boolean | null;

    shareButtonText?: string | null;

    facebookConfig?: FacebookConfigInput | null;
}

export interface UpdateOrganizationActionNameInput {
    organizationActionName: string;

    organizationActionId: string;
}

export interface UpdateOrganizationActionLinkInput {
    link: string;

    actionId: string;

    additionalDetails?: OrganizationActionAdditionalDetailsInput | null;
}

export interface UpdateOrganizationActionTitleAndDescriptionInput {
    actionId: string;

    title: string;

    description?: string | null;

    additionalDetails?: OrganizationActionAdditionalDetailsInput | null;
}

export interface UpdateCallToActionTextForOrganizationActionInput {
    actionId: string;

    callToActionText: string;
}

export interface UpdateShareActionButtonTextForOrgActionInput {
    organizationActionId: string;

    shareButtonText: string;
}

export interface UpdatePostActionTextForOrganizationActionInput {
    actionId: string;

    postActionText: string;
}

export interface OrganizationActionEmailTemplateInput {
    emailTemplateId: string;

    organizationActionId: string;
}

export interface OrganizationActionTextTemplateInput {
    textTemplateId: string;

    organizationActionId: string;
}

export interface PublishOrganizationActionInput {
    organizationActionId: string;

    organizationFilterId?: string | null;

    outreachCircleIds?: string[] | null;
}

export interface UpdateOrganizationActionAllowNonMappedContactsInput {
    organizationActionId: string;

    allowNonMappedContacts: boolean;
}

export interface UpdateOrganizationActionOutreachChannelsInput {
    organizationActionId: string;

    outreachChannels: (OutreachChannel | null)[];
}

export interface UpdateOrganizationActionIntegrationTypeInput {
    organizationActionId: string;

    integrationType?: AllowedIntegrationDatabaseType | null;
}

export interface UpdateOrganizationActionConfirmationInput {
    organizationActionId: string;

    takenConfirmationText?: string | null;

    canSkipTakenConfirmation: boolean;
}

export interface InviteOrganizationUserInput {
    email: string;

    organizationId: string;

    firstName: string;

    lastName?: string | null;

    readOnlyAccessToOutreachCircle: boolean;
}

export interface ResendInviteToOrgUserInput {
    organizationUserId: string;

    updatedEmail?: string | null;
}

export interface UpdateOrganizationUserInput {
    id: string;

    email: string;

    firstName: string;

    lastName: string;
}

export interface UpdateOcAccessForOrgUserInput {
    id: string;

    readOnlyAccessToOutreachCircle: boolean;
}

export interface UpdateOrganizationUserUiPreferenceInput {
    organizationUserId: string;

    key: OrganizationUserUiPreferences;

    value: Any;
}

export interface UpdateOrganizationInput {
    id: string;

    name: string;
}

export interface OrganizationDashboardFilterInput {
    outreachCircleIds?: string[] | null;

    dateFilter?: DateFilter | null;

    organizationId: string;

    name: string;
}

export interface UpdateOrganizationDashboardFilterInput {
    outreachCircleIds?: string[] | null;

    organizationId: string;

    id: string;
}

export interface MainOutreachCircleInput {
    organizationId: string;

    outreachCircleId: string;

    mainOCButtonText?: string | null;
}

export interface OrgIntroductionInput {
    organizationId: string;

    introductionText: string;
}

export interface OrgLogoInput {
    organizationId: string;

    mediaId: string;

    fileName: string;

    fileSize: number;

    crop?: CropInput | null;
}

export interface OrgDisclaimerInput {
    organizationId: string;

    disclaimer?: string | null;
}

export interface UpdateOrganizationAliasInput {
    organizationId: string;

    aliasValue: string;
}

export interface CreateOutreachCircleUserEmailTemplateInput {
    organizationId: string;

    name: string;

    code: OutreachCircleUserEmailTemplateCode;
}

export interface UpdateOutreachCircleUserEmailTemplateInput {
    id: string;

    name: string;

    subject: string;

    content: string;

    fromNameAlias?: string | null;
}

export interface DuplicateOutreachCircleUserEmailTemplateInput {
    outreachCircleUserEmailTemplateId: string;

    name: string;
}

export interface NotifyOutreachCircleAdminsForOrganizationActionInput {
    outreachCircleIds?: (string | null)[] | null;

    organizationActionId: string;

    outreachCircleUserEmailTemplateId: string;
}

export interface NotifyOutreachCircleAdminsForOrganizationInput {
    outreachCircleUserEmailTemplateId: string;

    notificationFilter: OrganizationNotificationFilter;
}

export interface OrganizationNotificationFilter {
    outreachCircleIds?: (string | null)[] | null;

    selectAll: boolean;

    filter?: OutreachCirclesByOrganizationFilter | null;
}

export interface CreateOutreachCircleRoleInput {
    outreachCircleId: string;

    label: string;

    isDefault?: boolean | null;

    permissionIds: string[];
}

export interface UpdateOutreachCircleRoleInput {
    id: string;

    outreachCircleId: string;

    isDefault?: boolean | null;

    permissionIds: string[];
}

export interface OutreachCircleUserInput {
    email: string;

    outreachCircleId: string;

    firstName: string;

    lastName: string;

    phoneNumber?: string | null;

    isMFAEnabled?: boolean | null;

    accountOwner?: boolean | null;

    accessExpiry?: Date | null;

    accessToVoters?: string | null;

    geographyIdList?: string[] | null;

    roleIdList?: string[] | null;
}

export interface UpdateOutreachCircleUserInput {
    id: string;

    email: string;

    firstName: string;

    lastName: string;

    phoneNumber?: string | null;

    isMFAEnabled?: boolean | null;

    accountOwner?: boolean | null;

    accessExpiry?: Date | null;

    accessToVoters?: string | null;

    geographyIdList?: string[] | null;

    roleIdList?: string[] | null;
}

export interface OutreachCircleAdminEmailUnsubscribeInput {
    emailRecipientId: string;

    emailTemplateId: string;

    outreachCircleId: string;
}

export interface ToggleMfaInput {
    outreachCircleId: string;

    outreachCircleUserIds: string[];
}

export interface ToggleUserInput {
    outreachCircleId: string;

    outreachCircleUserIds: string[];
}

export interface UpdateWorkingGeographiesForOutreachCircleInput {
    outreachCircleId: string;

    geographyIdList: string[];
}

export interface OutreachCircleInput {
    name: string;

    industry: string;

    category?: string | null;

    paymentToken?: string | null;

    plan: string;

    coupon?: string | null;

    platformDetails?: PlatformDetails | null;
}

export interface PlatformDetails {
    screenResolution: string;

    osName: string;

    osVersion: string;

    currentRoute?: string | null;

    browserName?: string | null;

    browserVersion?: string | null;

    phoneModelName?: string | null;

    appVersion?: string | null;
}

export interface UpdateOutreachCircleBasicInfoInput {
    id: string;

    name: string;

    industry: string;

    category?: string | null;
}

export interface UpdateOutreachCircleContactAndLocationInput {
    city: string;

    state?: string | null;

    country?: string | null;

    zip?: string | null;

    phoneNumber?: string | null;

    outreachCircleId: string;

    headquarterInfo?: HeadquarterInfoInput | null;
}

export interface HeadquarterInfoInput {
    latitude: number;

    longitude: number;
}

export interface OutreachCircleLogoInput {
    outreachCircleId: string;

    mediaId: string;

    fileName: string;

    fileSize: number;

    crop?: CropInput | null;
}

export interface BlockedDomainsInput {
    outreachCircleId: string;

    blockedDomains: string[];
}

export interface DisclaimerOutreachCircleInput {
    outreachCircleId: string;

    disclaimer?: string | null;
}

export interface OutreachCircleEmailUnsubscribeInput {
    outreachCircleId: string;

    emailRecipientId: string;

    emailTemplateId: string;

    userType: string;
}

export interface ReferredByOutreachCircleInput {
    outreachCircleId: string;

    referredBy?: string | null;
}

export interface VisibilityOutreachCircleInput {
    outreachCircleId: string;

    isVisible: boolean;
}

export interface OutreachCircleAliasInput {
    outreachCircleId: string;

    name: string;

    isRapidOnboardingEnabled?: boolean | null;

    description: string;

    isLandingPage?: boolean | null;

    logo?: AliasLogoInput | null;
}

export interface AliasLogoInput {
    mediaId: string;

    fileName: string;

    fileSize: number;

    crop?: CropInput | null;
}

export interface EditOutreachCircleAliasInput {
    aliasId: string;

    name: string;

    description: string;

    isRapidOnboardingEnabled?: boolean | null;

    isLandingPage?: boolean | null;

    logo?: AliasLogoInput | null;

    isAppOnly?: boolean | null;

    languagePreference?: LanguagePreferenceType | null;
}

export interface IncrementOutreachCircleImpressionsInput {
    alias: string;

    deviceType: DeviceTypeEnum;
}

export interface OutreachCircleEmailAliasInput {
    outreachCircleId: string;

    name: string;
}

export interface SupporterReplyToEmailOutreachCircleInput {
    outreachCircleId: string;

    supporterReplyToEmail: string;
}

export interface SupporterFromNameEmailOutreachCircleInput {
    outreachCircleId: string;

    supporterAutoEmailsFromName?: string | null;
}

export interface ArchiveSurveyResponseInput {
    outreachCircleId: string;

    userId: string;

    archiveSurveyResponseData: ArchiveSurveyResponseDataInput;
}

export interface ArchiveSurveyResponseDataInput {
    reasonToArchiveOutreachCircle?: string | null;

    otherReasonText?: string | null;

    netPromoterScore?: number | null;

    thingsToImprove?: string | null;

    hasWonElection?: string | null;

    personalEmail?: string | null;
}

export interface UpdateKeyDateInput {
    outreachCircleId: string;

    keyDates?: KeyDateInput[] | null;
}

export interface KeyDateInput {
    id?: string | null;

    date?: Date | null;

    name: string;

    isApplicable: boolean;
}

export interface ToggleMfaForOutreachCircleInput {
    outreachCircleId: string;

    isMFAEnabled: boolean;
}

export interface LinkNonRelationalDataProviderSourceForOutreachCircleInput {
    outreachCircleId: string;

    source: AudienceActionSource;
}

export interface OutreachCircleLocationInput {
    outreachCircleId: string;

    name: string;

    address?: string | null;

    lat?: number | null;

    lon?: number | null;

    landmark?: string | null;
}

export interface UpdateOutreachCircleLocationInput {
    id: string;

    outreachCircleId?: string | null;

    name?: string | null;

    address?: string | null;

    lat?: number | null;

    lon?: number | null;

    landmark?: string | null;
}

export interface UpdateOutreachCircleCampaignDetailsInput {
    outreachCircleId: string;

    campaignName: string;

    campaignEmail?: string | null;

    campaignNameForBilling?: string | null;

    campaignWebsite?: string | null;

    phoneNumber?: string | null;
}

export interface UpdateOutreachCircleElectionDateInput {
    outreachCircleId: string;

    electionCycleId: string;
}

export interface UpdateOutreachCircleMiscellaneousSettingInput {
    outreachCircleId: string;

    bypassFilePickupPassword: boolean;

    bypassBillingCode: boolean;

    yunMode: boolean;

    processWebFormTransAutomatically: boolean;

    matchPercentage?: number | null;

    dataSharePassword?: string | null;
}

export interface UpdateOutreachCircleActionVisibilityInput {
    outreachCircleId: string;

    actionVisibility: ActionVisibilityInput[];
}

export interface ActionVisibilityInput {
    type?: ActionHeaderType | null;

    enabled?: boolean | null;
}

export interface UpdatePaymentSourceInput {
    outreachCircleId: string;

    sourceId: string;

    data: PaymentSourceDataInput;
}

export interface PaymentSourceDataInput {
    name?: string | null;

    exp_month?: number | null;

    exp_year?: number | null;

    address_city?: string | null;

    address_country?: string | null;

    address_line1?: string | null;

    address_line2?: string | null;

    address_state?: string | null;

    address_zip?: string | null;
}

export interface ChangePlanInput {
    outreachCircleId: string;

    plan: string;

    cardToken?: string | null;

    coupon?: string | null;

    existingCardId?: string | null;
}

export interface CancelAutoRenewForOutreachCircleInput {
    outreachCircleId: string;

    setToPrivateConfirmation: boolean;

    surveyActionDeactivateConfirmation: boolean;

    emailTextActionWithMatchingDeactivateConfirmation: boolean;

    recruitmentLinkDeactivateConfirmation: boolean;
}

export interface CaptureFlagForVotersInput {
    pdiIds: string[];

    outreachCircleId: string;

    surveyQuestionId?: string | null;

    flagId?: string | null;

    acquisitionTypeId?: string | null;

    supporterId?: string | null;

    date?: Date | null;
}

export interface CaptureFlagForPeopleInput {
    outreachCircleId: string;

    supporterIds: string[];

    surveyQuestionId?: string | null;

    flagId?: string | null;

    acquisitionTypeId?: string | null;
}

export interface CopyVotersToPeopleInput {
    pdiIds: string[];

    outreachCircleId: string;
}

export interface AddDonorInfoInput {
    outreachCircleId: string;

    transactionType: string;

    date: Date;

    method: string;

    amount: number;

    account: string;

    election: string;

    reportingCode: string;

    budgetCategory: string;

    complianceNote?: string | null;

    internalNote?: string | null;

    checkNote?: string | null;
}

export interface EditPdiVoterInput {
    pdiId: string;

    outreachCircleId: string;

    firstName?: string | null;

    lastName?: string | null;

    address?: string | null;

    streetName?: string | null;

    city?: string | null;

    precinct?: string | null;

    county?: string | null;

    state?: string | null;

    email?: string | null;

    party?: string | null;

    birthdate?: string | null;
}

export interface DeactivatePdiMemberInput {
    pdiId: string;

    outreachCircleId: string;
}

export interface AddCommentToPdiVoterInput {
    pdiId: string;

    outreachCircleId: string;

    comment: string;
}

export interface DeleteCommentFromPdiVoterInput {
    pdiId: string;

    outreachCircleId: string;

    commentId: string;
}

export interface CreateP2PConversationItemInput {
    conversationId: string;

    latestScriptId: string;

    message?: string | null;

    tagIds?: string[] | null;

    questionResponses?: P2PQuestionResponseList[] | null;
}

export interface P2PQuestionResponseList {
    p2pScriptSurveyQuestionId: string;

    p2pScriptSurveyQuestionOptionId: string;
}

export interface ReassignP2PConversationsInput {
    oldSupporterId: string;

    newSupporterId: string;

    actionId: string;
}

export interface UnTagP2PConversationInput {
    tagIds: string[];

    conversationId: string;
}

export interface P2PScriptSurveyQuestionInput {
    id?: string | null;

    name: string;

    questionText: string;

    outreachCircleId: string;

    ngpSurveyQuestionId?: number | null;

    pdiQuestionId?: string | null;

    integrationType?: AllowedIntegrationDatabaseType | null;

    p2pScriptSurveyQuestionType: P2PScriptSurveyQuestionType;

    options: P2PScriptSurveyQuestionOptionInput[];
}

export interface P2PScriptSurveyQuestionOptionInput {
    id?: string | null;

    optionText: string;

    position: number;

    ngpSurveyResponseId?: number | null;

    pdiAnswerId?: string | null;

    linkedNgpActivistCode?: NgpActivistCodeInput | null;

    type?: EngagementOptionType | null;
}

export interface SendInitialP2PTextInput {
    actionId: string;

    textBody: string;

    recipientId: string;
}

export interface UpdateInitialP2PTextInput {
    actionId: string;

    textBody: string;
}

export interface SendBulkInitialP2PTextsInput {
    actionId: string;

    p2pTextItemList: P2pTextItemInput[];
}

export interface P2pTextItemInput {
    textBody: string;

    recipientId: string;
}

export interface P2PWorkspaceInput {
    id?: string | null;

    name: string;

    outreachCircleId: string;

    firstP2PScriptId: string;

    integrationType: AllowedIntegrationDatabaseType;

    p2pScripts: P2PScriptInput[];

    tags?: P2PWorkspaceTagInput[] | null;

    linkedP2PScripts?: LinkedP2PScriptInput[] | null;
}

export interface P2PScriptInput {
    id?: string | null;

    name: string;

    p2pScriptSurveyQuestionId?: string | null;

    message: string;

    p2pScriptType: P2PScriptType;

    attachments?: MediaInput[] | null;
}

export interface MediaInput {
    mediaId: string;

    fileName: string;

    fileSize: number;
}

export interface P2PWorkspaceTagInput {
    tagId: string;

    p2pScriptId?: string | null;

    position?: number | null;
}

export interface LinkedP2PScriptInput {
    p2pScriptId: string;

    p2pScriptSurveyQuestionOptionId: string;

    nextP2PScriptId: string;
}

export interface DuplicateP2PWorkspaceInput {
    p2pWorkspaceId: string;

    name: string;
}

export interface CreateBallotMeasureInput {
    campaignName: string;

    outreachCircleId: string;

    issueCategoryId?: string | null;

    progressiveResponseIndicatorId?: string | null;

    description?: string | null;

    electionCycleIds: string[];

    isDefault?: boolean | null;
}

export interface UpdateBallotMeasureInput {
    id: string;

    outreachCircleId: string;

    campaignName: string;

    issueCategoryId?: string | null;

    progressiveResponseIndicatorId?: string | null;

    description?: string | null;

    electionCycleIds: string[];

    isDefault?: boolean | null;
}

export interface UpdateCandidateForOutreachCircleInput {
    id: string;

    firstName?: string | null;

    lastName?: string | null;

    enabled: boolean;
}

export interface AddCandidateInput {
    firstName?: string | null;

    lastName?: string | null;

    enabled: boolean;

    outreachCircleId: string;
}

export interface CandidateImageInput {
    candidateId: string;

    mediaId: string;

    fileName: string;

    fileSize: number;

    crop?: CropInput | null;
}

export interface CreateCandidateInput {
    name: string;

    isDefault?: boolean | null;

    outreachCircleId: string;

    candidateOfficeId?: string | null;

    description?: string | null;

    electionCycleIds: string[];
}

export interface UpdateCandidateInput {
    id: string;

    isDefault?: boolean | null;

    outreachCircleId: string;

    name: string;

    candidateOfficeId?: string | null;

    description?: string | null;

    electionCycleIds: string[];
}

export interface CreateIssueInput {
    isDefault?: boolean | null;

    issueCategoryId?: string | null;

    outreachCircleId: string;

    progressiveResponseIndicatorId?: string | null;

    description?: string | null;

    electionCycleIds: string[];
}

export interface UpdateIssueInput {
    id: string;

    isDefault?: boolean | null;

    outreachCircleId: string;

    progressiveResponseIndicatorId?: string | null;

    description?: string | null;

    electionCycleIds: string[];

    issueCategoryId?: string | null;
}

export interface UpdatePurposeStatusInput {
    id: string;

    outreachCircleId: string;

    status: PurposeStatus;
}

export interface CopyPurposesFromParentOutreachCircleInput {
    purposeIds: string[];

    outreachCircleId: string;
}

export interface RequestReport {
    outreachCircleId: string;

    reportCode: ReportCode;

    actionIds?: string[] | null;

    supporterFormResponseReportFilter?: SupporterFormResponseReportFilter | null;

    surveyResponseReportFilter?: SurveyResponseReportFilter | null;

    reportName?: string | null;
}

export interface SupporterFormResponseReportFilter {
    supporterFormIds: string[];

    fromDate?: Date | null;

    toDate?: Date | null;
}

export interface SurveyResponseReportFilter {
    surveyIds: string[];

    fromDate?: Date | null;

    toDate?: Date | null;
}

export interface OrganizationReportInput {
    organizationId: string;

    code: ReportCode;
}

export interface UpdateSearchAndSurveyFormInput {
    id: string;

    name: string;

    searchAndSurveyFormQuestions: SearchAndSurveyFormQuestionInput[];
}

export interface SearchAndSurveyFormQuestionInput {
    id?: string | null;

    questionText: string;

    questionType: SearchAndSurveyFormQuestionType;

    isMandatory: boolean;

    position: number;

    options?: SearchAndSurveyFormOptionInput[] | null;

    questionIntegrationType: SearchAndSurveyFormQuestionIntegrationType;

    ngpSurveyQuestionId?: number | null;

    pdiSurveyQuestionId?: string | null;
}

export interface SearchAndSurveyFormOptionInput {
    id?: string | null;

    optionText: string;

    position: number;

    optionType?: SearchAndSurveyFormOptionType | null;

    ngpSurveyResponseId?: number | null;

    linkedNgpActivistCode?: NgpActivistCodeInput | null;

    assignedGroupId?: string | null;

    pdiAnswerId?: string | null;
}

export interface CreateSearchAndSurveyFormInput {
    outreachCircleId: string;

    name: string;

    searchAndSurveyFormIntegrationType?: SearchAndSurveyFormIntegrationType | null;
}

export interface SearchAndSurveyFormActionInput {
    actionId: string;

    searchAndSurveyFormId: string;
}

export interface DuplicateSearchAndSurveyFormInput {
    searchAndSurveyFormId: string;

    name: string;
}

export interface SubmitSearchAndSurveyFormQuestionResponseInput {
    supporterId: string;

    actionId: string;

    searchAndSurveyFormQuestionId: string;

    responses: SearchAndSurveyFormResponseInput[];

    memberId: string;
}

export interface SearchAndSurveyFormResponseInput {
    searchAndSurveyFormOptionId: string;

    response?: SearchAndSurveyFormResponseDetails | null;
}

export interface SearchAndSurveyFormResponseDetails {
    text?: string | null;

    addressField?: AddressFields | null;
}

export interface CreateSocialTemplateInput {
    name: string;

    description: string;

    outreachCircleId: string;

    actionId: string;
}

export interface UpdateSocialTemplateInput {
    id: string;

    name: string;

    description: string;

    actionId: string;
}

export interface CreateOrganizationSocialTemplateInput {
    name: string;

    description: string;

    organizationId: string;
}

export interface UpdateOrganizationSocialTemplateInput {
    id: string;

    name: string;

    description: string;
}

export interface SourcingLinkInput {
    name: string;

    link: string;

    useShortLink: boolean;

    outreachCircleId: string;
}

export interface AssignSupporterWithSourceToActionInput {
    actionIds: string[];

    outreachCircleId: string;

    supporterWithSourceList: SupporterWithSourceInput[];
}

export interface SupporterWithSourceInput {
    supporterId: string;

    pdiListId?: string | null;

    actionId?: string | null;

    shiftId?: string | null;
}

export interface AssignSupporterWithSourceToShiftInput {
    shiftIds: string[];

    outreachCircleId: string;

    supporterWithSourceList: SupporterWithSourceInput[];
}

export interface UnassignShiftsFromSupporterInput {
    shiftIds: string[];

    supporterId: string;

    outreachCircleId: string;
}

export interface CreateSupporterEmailTemplateInput {
    outreachCircleId: string;

    subject: string;

    content: string;

    name: string;

    code: SupporterEmailTemplateCode;

    fromNameAlias?: string | null;

    actionId?: string | null;
}

export interface UpdateSupporterEmailTemplateInput {
    supporterEmailTemplateId: string;

    subject: string;

    content: string;

    name: string;

    fromNameAlias?: string | null;
}

export interface DuplicateSupporterEmailTemplateInput {
    supporterEmailTemplateId: string;

    name: string;
}

export interface UpdateSupporterFormInput {
    id: string;

    name: string;

    supporterFormQuestions: SupporterFormQuestionInput[];
}

export interface SupporterFormQuestionInput {
    id?: string | null;

    questionText: string;

    questionType: SupporterFormQuestionType;

    isMandatory: boolean;

    position: number;

    options?: SupporterFormOptionInput[] | null;

    questionIntegrationType: SupporterFormQuestionIntegrationType;

    ngpSurveyQuestionId?: number | null;
}

export interface SupporterFormOptionInput {
    id?: string | null;

    optionText: string;

    position: number;

    optionType?: SupporterFormOptionType | null;

    ngpSurveyResponseId?: number | null;

    linkedNgpActivistCode?: NgpActivistCodeInput | null;

    assignedGroupId?: string | null;
}

export interface CreateSupporterFormInput {
    outreachCircleId: string;

    name: string;

    supporterFormIntegrationType?: SupporterFormIntegrationType | null;
}

export interface SubmitSupporterFormQuestionResponseInput {
    supporterId: string;

    actionId: string;

    supporterFormQuestionId: string;

    responses: SupporterFormResponseInput[];
}

export interface SupporterFormResponseInput {
    supporterFormOptionId: string;

    response?: FormResponse | null;
}

export interface SupporterFormActionInput {
    actionId: string;

    supporterFormId: string;
}

export interface DuplicateSupporterFormInput {
    supporterFormId: string;

    name: string;
}

export interface CreateSupporterPushNotificationTemplateInput {
    actionId: string;

    code: SupporterPushNotificationTemplateCode;

    title: string;

    body: string;
}

export interface UpdateSupporterPushNotificationTemplateInput {
    supporterPushNotificationTemplateId: string;

    title: string;

    body: string;
}

export interface CreateSupporterViewFilterStateInput {
    outreachCircleId: string;

    name: string;

    state: SupporterViewFilterStateInput;
}

export interface SupporterViewFilterStateInput {
    actionTakenFilter?: SupporterActionTakenFilterInput | null;

    supporterJoinedDateFilter?: SupporterJoinedFilterInput | null;

    actionFilter?: SupporterActionFilterInput | null;

    groupFilter?: SupporterGroupFilterInput | null;

    appNotificationFilter?: SupporterAppNotificationFilterInput | null;

    aliasFilter?: SupporterAliasFilterInput | null;

    supporterStatusFilter?: SupporterStatusFilterInput | null;

    lastEmailStatusFilter?: SupporterLastEmailStatusFilterInput | null;

    supporterEmailSubscriptionFilter?: SupporterEmailSubscriptionFilterInput | null;
}

export interface UpdateSupporterViewFilterStateInput {
    filterId: string;

    name: string;

    state: SupporterViewFilterStateInput;
}

export interface CreateSupporterActivityInput {
    outreachCircleId: string;

    name: string;
}

export interface UpdateSupporterActivityInput {
    id: string;

    name: string;

    isActive: boolean;
}

export interface UpdateSupporterActivityActiveStatus {
    ids: string[];

    isActive: boolean;
}

export interface CreateSupporterAvailabilityInput {
    outreachCircleId: string;

    name: string;
}

export interface UpdateSupporterAvailabilityInput {
    id: string;

    name: string;

    isActive: boolean;
}

export interface UpdateSupporterAvailabilityActiveStatus {
    ids: string[];

    isActive: boolean;
}

export interface CreateSupporterLevelInput {
    outreachCircleId: string;

    name: string;
}

export interface UpdateSupporterLevelInput {
    id: string;

    name: string;

    isActive: boolean;
}

export interface UpdateSupporterLevelActiveStatus {
    ids: string[];

    isActive: boolean;
}

export interface CreateSupporterOrganizationInput {
    outreachCircleId: string;

    name: string;
}

export interface UpdateSupporterOrganizationInput {
    id: string;

    name: string;

    isActive: boolean;
}

export interface UpdateSupporterOrganizationActiveStatus {
    ids: string[];

    isActive: boolean;
}

export interface CreateSupporterSkillInput {
    outreachCircleId: string;

    name: string;
}

export interface UpdateSupporterSkillInput {
    id: string;

    name: string;

    isActive: boolean;
}

export interface UpdateSupporterSkillActiveStatus {
    ids: string[];

    isActive: boolean;
}

export interface CreateSupporterTeamInput {
    outreachCircleId: string;

    organizerId: string;

    name: string;
}

export interface UpdateSupporterTeamInput {
    id: string;

    name: string;

    isActive: boolean;

    organizerId: string;
}

export interface UpdateSupporterTeamActiveStatus {
    ids: string[];

    isActive: boolean;
}

export interface CreateSupporterZoneInput {
    outreachCircleId: string;

    name: string;
}

export interface UpdateSupporterZoneInput {
    id: string;

    name: string;

    isActive: boolean;
}

export interface UpdateSupporterZoneActiveStatus {
    ids: string[];

    isActive: boolean;
}

export interface SupportOutreachCircleInput {
    outreachCircleId: string;

    origin: Origin;

    sourceAlias: string;

    supporterId?: string | null;

    inviterUserId?: string | null;

    addSupporterToInviterSupporterGroups?: boolean | null;

    queryParams?: Json | null;
}

export interface CreateInvitedSupporterSourceInput {
    outreachCircleId: string;

    supporterEmailTemplateId: string;
}

export interface ImportInvitedSupporterFromFileInput {
    invitedSupporterSourceId: string;

    mapping: Json;

    delimiter?: string | null;

    customFields?: (string | null)[] | null;
}

export interface InviteSupporterInput {
    outreachCircleId: string;

    supporters: CreateSupporterInput[];

    supporterEmailTemplateId: string;

    hasEmailConsent: boolean;

    suppressExistingSupporterException?: boolean | null;
}

export interface CreateSupporterInput {
    email: string;

    externalId?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    addressLine1?: string | null;

    city?: string | null;

    state?: string | null;

    zip?: string | null;

    primaryLanguage?: string | null;

    phone?: string | null;

    customFieldData?: (CreateSupporterCustomFieldDataInput | null)[] | null;

    levelId?: string | null;

    zoneId?: string | null;

    assignedToOrganizerId?: string | null;

    isOrganizer?: boolean | null;

    communicationEnabled?: boolean | null;

    teamIds?: string[] | null;

    activityIds?: string[] | null;

    skillIds?: string[] | null;

    availabilityIds?: string[] | null;

    organizationId?: string | null;

    recruitedBySupporterId?: string | null;

    status?: VolunteerStatus | null;

    appStatus?: SupporterAppStatus | null;
}

export interface CreateSupporterCustomFieldDataInput {
    key?: string | null;

    value?: string | null;
}

export interface AddSupporterInput {
    outreachCircleId: string;

    supporters: CreateSupporterInput[];
}

export interface UpdateSupporterInput {
    supporterId: string;

    firstName?: string | null;

    lastName?: string | null;

    addressLine1?: string | null;

    externalId?: string | null;

    city?: string | null;

    state?: string | null;

    zip?: string | null;

    primaryLanguage?: string | null;

    phone?: string | null;

    isOrganizer?: boolean | null;

    levelId?: string | null;

    zoneId?: string | null;

    assignedToOrganizerId?: string | null;

    communicationEnabled?: boolean | null;

    customFieldData?: (CreateSupporterCustomFieldDataInput | null)[] | null;

    teamIds?: string[] | null;

    activityIds?: string[] | null;

    skillIds?: string[] | null;

    availabilityIds?: string[] | null;

    organizationId?: string | null;

    recruitedBySupporterId?: string | null;

    status?: VolunteerStatus | null;

    appStatus?: SupporterAppStatus | null;
}

export interface EligibleSupporterForSendingPushNotificationInput {
    notificationTitle: string;

    notificationDescription?: string | null;

    filter: SupporterFilterForPushNotificationInput;

    actionId?: string | null;

    media?: MediaInput | null;
}

export interface SupporterWelcomeEmailFilterInput {
    supporterIds?: (string | null)[] | null;

    excludeSupporterIds?: (string | null)[] | null;

    sendToAll: boolean;

    outreachCircleId: string;

    filter?: SupporterViewFilterInput | null;
}

export interface CreateSurveyInput {
    name: string;

    outreachCircleId: string;

    type: SurveyType;
}

export interface UpdateSurveyInput {
    id?: string | null;

    outreachCircleId: string;

    name: string;

    segments?: (SurveySegmentInput | null)[] | null;
}

export interface SurveySegmentInput {
    id?: string | null;

    questionId?: string | null;

    script?: string | null;

    position: number;

    segmentBranches?: (SegmentBranchInput | null)[] | null;

    attachments?: MediaInput[] | null;
}

export interface SegmentBranchInput {
    surveyQuestionResponseId?: string | null;

    nextSegmentId?: string | null;
}

export interface UpdateSurveyStatusInput {
    id: string;

    outreachCircleId: string;

    status: SurveyStatus;
}

export interface CreateSurveyQuestionInput {
    text: string;

    label?: string | null;

    description?: string | null;

    outreachCircleId: string;

    responses: (SurveyQuestionResponseInput | null)[];

    type?: SurveyQuestionType | null;

    purposeId?: string | null;
}

export interface SurveyQuestionResponseInput {
    id?: string | null;

    flagID: string;

    code?: string | null;

    description?: string | null;

    position: number;
}

export interface UpdateSurveyQuestionInput {
    id: string;

    outreachCircleId: string;

    text: string;

    type?: SurveyQuestionType | null;

    label?: string | null;

    description?: string | null;

    responses: (SurveyQuestionResponseInput | null)[];

    purposeId?: string | null;
}

export interface UpdateSurveyQuestionStatusInput {
    id: string;

    outreachCircleId: string;

    status: SurveyQuestionStatus;
}

export interface TagRecipientInput {
    supporterId: string;

    actionId: string;

    tagId: string;

    memberId?: string | null;

    contactId?: string | null;

    tagResource: TagResourceType;

    tagAction: TagActionType;
}

export interface CreateTagInput {
    name: string;

    outreachCircleId: string;

    type?: TagType | null;
}

export interface UpdateTagInput {
    id: string;

    name: string;

    integrations?: (TagIntegrationInput | null)[] | null;
}

export interface TagIntegrationInput {
    type: IntegrationType;

    data?: TagIntegrationDataInput | null;
}

export interface TagIntegrationDataInput {
    ngpActivistCodes?: (NgpActivistCodeInput | null)[] | null;

    pdiFlagIds?: (PdiFlagIdInput | null)[] | null;
}

export interface ApplyTagToAffinityTextRecipient {
    memberId: string;

    supporterId: string;

    actionId: string;

    tagId: string;
}

export interface ApplyTagToRelationalTextRecipient {
    contactId: string;

    supporterId: string;

    memberId?: string | null;

    actionId: string;

    tagId: string;
}

export interface ApplyTagToAmbiguousTextRecipient {
    contactId: string;

    supporterId: string;

    memberId: string;

    actionId: string;

    tagId: string;
}

export interface ActionTagLinkInput {
    actionId: string;

    tagId: string;

    cannedResponseText?: string | null;

    cannedResponseId?: string | null;
}

export interface CannedResponseInput {
    response?: string | null;

    tagId: string;

    actionId: string;
}

export interface CreateTextTemplateInput {
    name: string;

    content?: string | null;

    outreachCircleId: string;

    actionId: string;
}

export interface UpdateTextTemplateInput {
    id: string;

    name: string;

    content: string;

    actionId: string;
}

export interface UpdateTextTemplateForSupporterInput {
    id: string;

    content: string;

    supporterId: string;

    actionId: string;
}

export interface CreateOrganizationTextTemplateInput {
    name: string;

    content?: string | null;

    organizationId: string;
}

export interface UpdateOrganizationTextTemplateInput {
    id: string;

    name: string;

    content: string;
}

export interface SignupOrLoginInput {
    username: string;

    password: string;
}

export interface RapidOnboardingLoginInput {
    firstName?: string | null;

    lastName?: string | null;

    username: string;

    zip?: string | null;

    phoneNumber?: string | null;

    aliasValue: string;
}

export interface NameInput {
    firstName: string;

    lastName: string;
}

export interface PreferenceInput {
    key: string;

    value: Any;
}

export interface ContactDetailsInput {
    city?: string | null;

    state?: string | null;

    country?: string | null;

    zip?: string | null;

    phoneNumber?: string | null;

    optInForText?: boolean | null;
}

export interface UpdatePasswordInput {
    resetCode: string;

    userId: string;

    password: string;
}

export interface SignupWithDetailsInput {
    firstName: string;

    lastName: string;

    phoneNumber?: string | null;

    username: string;

    password: string;

    verifyEmail: boolean;

    zipCode?: string | null;
}

export interface SignupWithDetailsFromAdsInput {
    firstName: string;

    lastName: string;

    username: string;

    password: string;
}

export interface VerifyUserWithCardOrCouponInput {
    coupon?: string | null;

    plan?: string | null;

    cardToken?: string | null;
}

export interface EmailVerificationInput {
    verificationCode: string;

    userId: string;
}

export interface UserEmailUnsubscribeInput {
    emailRecipientId: string;

    emailTemplateId: string;
}

export interface PushNotificationTokenInput {
    registrationToken?: string | null;

    additionalDetails: PushNotificationAdditionalDetails;
}

export interface PushNotificationAdditionalDetails {
    deviceId: string;

    deviceType: DeviceTypeEnum;

    mobileAppBundleId?: string | null;
}

export interface UserNameAndContactDetailsInput {
    name: NameInput;

    contact: ContactDetailsInput;

    password?: string | null;
}

export interface SendSupportEmailForLeadFromAdLandingScreenInput {
    userEmail: string;

    adLandingScreen: string;

    adLandingPageLink: string;
}

export interface PasswordlessLoginStartInput {
    email: string;
}

export interface PasswordlessLoginInput {
    email: string;

    loginCode: string;
}

export interface UpdateUserProfileImageInput {
    fileName?: string | null;

    fileSize?: number | null;

    mediaId?: string | null;

    crop?: CropInput | null;
}

export interface SignUpWithDetailsForEmbedInput {
    firstName: string;

    lastName: string;

    phoneNumber?: string | null;

    username: string;

    password?: string | null;

    zipCode: string;
}

export interface AddSurveyForActionInput {
    surveyId: string;

    actionId: string;
}

export interface MarkActionAsCompletedInput {
    supporterId: string;

    actionId: string;
}

export interface CallPhoneBankMemberForActionInput {
    actionId: string;

    memberId: string;

    supporterId: string;

    outreachCircleId: string;
}

export interface UnAssignTextingConversationFilterInput {
    supporterIds?: (string | null)[] | null;

    searchText?: string | null;
}

export interface AudienceCustomfieldInput {
    audienceId: string;

    name: string;
}

export interface MappedContactInput {
    contactId: string;

    supporterId: string;

    memberId: string;
}

export interface AmbiguousMappedContactInput {
    contactId: string;

    supporterId: string;
}

export interface EmailTemplateEngagementInput {
    emailTemplateId: string;

    htmlBody: string;
}

export interface UpdateApplyFlagsGloballyInput {
    applyFlagsGlobally: boolean;

    outreachCircleId: string;
}

export interface PdiQuestionInput {
    id: string;

    organizationName: string;

    question: string;

    questionLabel?: string | null;

    questionDescription?: string | null;

    type?: string | null;

    category?: string | null;

    candidate?: string | null;

    default?: boolean | null;
}

export interface PdiAnswerOptionInput {
    id: string;

    flagId: string;

    flagIdDescription?: string | null;

    displayCode?: string | null;
}

export interface GetDefaultContentForOutreachCircleUserEmailTemplateInput {
    code: OutreachCircleUserEmailTemplateCode;

    organizationId: string;
}

export interface OnboardOutreachCircleUserInput {
    outreachCircleUserId: string;

    userId: string;
}

export interface UpdateOutreachCircleIntroductionInput {
    introduction: string;

    outreachCircleId: string;
}

export interface UpdateListsLinkedToSurveyInput {
    actionId: string;

    surveyId: string;

    listIds: string[];
}

export interface UpdateTextTemplateWithRecruitmentLinkInput {
    textTemplateId: string;

    content: string;

    linkText: string;
}

export enum AcquisitionTypeAssociateWith {
    PHONE = 'PHONE',
    CANVAS = 'CANVAS',
    MAIL = 'MAIL',
    IVR = 'IVR',
    TEXT_MESSAGE = 'TEXT_MESSAGE',
    EMAIL = 'EMAIL',
    EVENT = 'EVENT',
    ONLINE = 'ONLINE',
    SOCIAL = 'SOCIAL',
    SITE = 'SITE',
    OTHER = 'OTHER',
}

export enum PageDefault {
    Lookup = 'Lookup',
    WalkList = 'WalkList',
    PhoneList = 'PhoneList',
    PhoneBank = 'PhoneBank',
    Canvassing = 'Canvassing',
    Import = 'Import',
    AutomaticSMS = 'AutomaticSMS',
}

export enum OutreachChannel {
    EMAIL = 'EMAIL',
    TEXT = 'TEXT',
    SOCIAL = 'SOCIAL',
    CALL = 'CALL',
    VISIT = 'VISIT',
}

export enum VolunteerStatus {
    Prospect = 'Prospect',
    Active = 'Active',
    Rejected = 'Rejected',
    Inactive = 'Inactive',
    Paid = 'Paid',
    None = 'None',
}

export enum LastEmailStatus {
    Opened = 'Opened',
    Delivered = 'Delivered',
    Bounced = 'Bounced',
    Sent = 'Sent',
    Unsubscribed = 'Unsubscribed',
}

export enum ActionType {
    FACEBOOK_SOCIAL_SHARE = 'FACEBOOK_SOCIAL_SHARE',
    FACEBOOK_EVENT_SHARE = 'FACEBOOK_EVENT_SHARE',
    SEND_UPDATE = 'SEND_UPDATE',
    EMAIL = 'EMAIL',
    ATTENDANCE_CHECKIN = 'ATTENDANCE_CHECKIN',
    DONATION = 'DONATION',
    TASK = 'TASK',
    PETITION = 'PETITION',
    EVENT = 'EVENT',
    REDIRECT_LINK = 'REDIRECT_LINK',
    WATCH_VIDEO = 'WATCH_VIDEO',
    SURVEY = 'SURVEY',
    AFFINITY_TEXTING = 'AFFINITY_TEXTING',
    TEXT_SUPPORTER_TASK = 'TEXT_SUPPORTER_TASK',
    PHONE_2_ACTION_FORM = 'PHONE_2_ACTION_FORM',
    EVERY_ACTION_FORM = 'EVERY_ACTION_FORM',
    SUPPORTER_FORM = 'SUPPORTER_FORM',
    P2P_TEXTING = 'P2P_TEXTING',
    SEARCH_AND_SURVEY = 'SEARCH_AND_SURVEY',
    GRASS_ROOT_ORGANIZING = 'GRASS_ROOT_ORGANIZING',
    GRASS_ROOT_ORGANIZING_RELATIONAL = 'GRASS_ROOT_ORGANIZING_RELATIONAL',
    ADDRESS_BASED_CANVASSING = 'ADDRESS_BASED_CANVASSING',
    PDI_ON_THE_GO_CANVASSING = 'PDI_ON_THE_GO_CANVASSING',
    CLICK_TO_CONNECT = 'CLICK_TO_CONNECT',
    TEXTING = 'TEXTING',
    PHONE_BANK = 'PHONE_BANK',
    DOOR_TO_DOOR_CANVASSING = 'DOOR_TO_DOOR_CANVASSING',
    EVENTX = 'EVENTX',
}

export enum ActionStatus {
    INACTIVE = 'INACTIVE',
    READY = 'READY',
    ACTIVE = 'ACTIVE',
    PAUSED = 'PAUSED',
    INCOMPLETE = 'INCOMPLETE',
    TERMINATED = 'TERMINATED',
    ACTIVATING = 'ACTIVATING',
}

export enum AllowedIntegrationDatabaseType {
    OUTREACH_CIRCLE = 'OUTREACH_CIRCLE',
    NGP_MY_VOTER = 'NGP_MY_VOTER',
    NGP_MY_CAMPAIGN = 'NGP_MY_CAMPAIGN',
    PDI = 'PDI',
    SALESFORCE = 'SALESFORCE',
}

export enum P2PPauseReason {
    ACCOUNT_SUSPENDED = 'ACCOUNT_SUSPENDED',
    MESSAGE_FILTERED = 'MESSAGE_FILTERED',
    US_A2P_10DLC_RATE_LIMIT_EXCEEDED = 'US_A2P_10DLC_RATE_LIMIT_EXCEEDED',
    US_A2P_10DLC_DAILY_MESSAGE_CAP_REACHED = 'US_A2P_10DLC_DAILY_MESSAGE_CAP_REACHED',
    UNABLE_TO_BUY_NUMBER = 'UNABLE_TO_BUY_NUMBER',
    USE_CASE_REGISTRATION_FAILED = 'USE_CASE_REGISTRATION_FAILED',
    USE_CASE_REGISTRATION_IN_PROGRESS = 'USE_CASE_REGISTRATION_IN_PROGRESS',
    US_A2P_10DLC_INVALID_TAG_DATA = 'US_A2P_10DLC_INVALID_TAG_DATA',
    ACCOUNT_BLOCKED_BY_SPECIFIC_CARRIER = 'ACCOUNT_BLOCKED_BY_SPECIFIC_CARRIER',
    CONSECUTIVE_ERRORS = 'CONSECUTIVE_ERRORS',
}

export enum TurfingStrategyEnum {
    ZIP_CODE = 'ZIP_CODE',
    GEO_RADIUS = 'GEO_RADIUS',
    MANUAL = 'MANUAL',
    SUPPORTER_ID = 'SUPPORTER_ID',
    SUPPORTER_EMAIL = 'SUPPORTER_EMAIL',
}

export enum DialerType {
    CLICK_TO_CALL_BROWSER_ONLY = 'CLICK_TO_CALL_BROWSER_ONLY',
    CLICK_TO_CALL_BROWSER_AND_DIAL_IN = 'CLICK_TO_CALL_BROWSER_AND_DIAL_IN',
    MANUAL = 'MANUAL',
    AUTO_DIALER = 'AUTO_DIALER',
}

export enum ShiftAssignmentAvailability {
    ENTIRE_SHIFT_DAY = 'ENTIRE_SHIFT_DAY',
    WHEN_SHIFT_STARTS = 'WHEN_SHIFT_STARTS',
    ONE_HOUR_BEFORE_SHIFT_STARTS = 'ONE_HOUR_BEFORE_SHIFT_STARTS',
    TWO_HOURS_BEFORE_SHIFT_STARTS = 'TWO_HOURS_BEFORE_SHIFT_STARTS',
}

export enum AudienceActionStatus {
    uploading = 'uploading',
    active = 'active',
    failed = 'failed',
    not_started = 'not_started',
}

export enum AudienceActionDatabase {
    VOTER = 'VOTER',
    VOLUNTEER = 'VOLUNTEER',
}

export enum AudienceActionSource {
    NGP = 'NGP',
    PDI = 'PDI',
    CATALYST = 'CATALYST',
    TARGET_SMART = 'TARGET_SMART',
    BLACKBAUD = 'BLACKBAUD',
    NATION_BUILDER = 'NATION_BUILDER',
    SALESFORCE = 'SALESFORCE',
    OTHER = 'OTHER',
}

export enum IntegrationType {
    EVERY_ACTION_GHOST_FORM = 'EVERY_ACTION_GHOST_FORM',
    BLACKBAUD = 'BLACKBAUD',
    NGP_MY_CAMPAIGN = 'NGP_MY_CAMPAIGN',
    NGP_MY_VOTER = 'NGP_MY_VOTER',
    NATION_BUILDER = 'NATION_BUILDER',
    FACEBOOK_PIXEL = 'FACEBOOK_PIXEL',
    EVERY_ACTION = 'EVERY_ACTION',
    PHONE_2_ACTION = 'PHONE_2_ACTION',
    OUTREACH_CIRCLE = 'OUTREACH_CIRCLE',
    PDI = 'PDI',
    TCR = 'TCR',
    BLUELINK = 'BLUELINK',
    SALESFORCE = 'SALESFORCE',
    GOOGLE_BIGQUERY = 'GOOGLE_BIGQUERY',
}

export enum AudienceActionTransaction {
    ADD = 'ADD',
    DELETE = 'DELETE',
    UPDATE = 'UPDATE',
}

export enum AudienceActionFormat {
    FILE_UPLOAD = 'FILE_UPLOAD',
    API = 'API',
}

export enum ListType {
    MEMBER_LIST = 'MEMBER_LIST',
    ASSIGNED_CONTACT_LIST = 'ASSIGNED_CONTACT_LIST',
    P2P_CONTACT_LIST = 'P2P_CONTACT_LIST',
    SEARCH_AND_SURVEY_LIST = 'SEARCH_AND_SURVEY_LIST',
    ADDRESS_BASED_CANVASSING_LIST = 'ADDRESS_BASED_CANVASSING_LIST',
    SALESFORCE_LIST = 'SALESFORCE_LIST',
    PROJECT_LIST = 'PROJECT_LIST',
}

export enum Frequency {
    ONCE = 'ONCE',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
}

export enum SupporterFormQuestionType {
    SINGLE_CHOICE = 'SINGLE_CHOICE',
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    CONTACT_INFO = 'CONTACT_INFO',
    TEXT = 'TEXT',
    SINGLE_CHOICE_GROUP_ASSIGNMENT = 'SINGLE_CHOICE_GROUP_ASSIGNMENT',
    MULTIPLE_CHOICE_GROUP_ASSIGNMENT = 'MULTIPLE_CHOICE_GROUP_ASSIGNMENT',
}

export enum SupporterFormOptionType {
    CHOICE = 'CHOICE',
    CONTACT_EMAIL_ADDRESS = 'CONTACT_EMAIL_ADDRESS',
    CONTACT_PHONE_NUMBER = 'CONTACT_PHONE_NUMBER',
    CONTACT_ADDRESS = 'CONTACT_ADDRESS',
    TEXT = 'TEXT',
}

export enum SupporterFormQuestionIntegrationType {
    VAN_SURVEY_QUESTION = 'VAN_SURVEY_QUESTION',
    OUTREACHCIRCLE = 'OUTREACHCIRCLE',
    VAN_ACTIVIST_CODES = 'VAN_ACTIVIST_CODES',
}

export enum SupporterFormIntegrationType {
    OUTREACH_CIRCLE = 'OUTREACH_CIRCLE',
    NGP_MY_CAMPAIGN = 'NGP_MY_CAMPAIGN',
}

export enum EngagementType {
    SURVEY = 'SURVEY',
    VOLUNTEER_ACTION = 'VOLUNTEER_ACTION',
    EXTERNAL_LINK = 'EXTERNAL_LINK',
    RECRUITMENT_LINK = 'RECRUITMENT_LINK',
    THANK_YOU = 'THANK_YOU',
}

export enum EngagementOptionType {
    CHOICE = 'CHOICE',
    CONTACT_EMAIL_ADDRESS = 'CONTACT_EMAIL_ADDRESS',
    CONTACT_PHONE_NUMBER = 'CONTACT_PHONE_NUMBER',
    CONTACT_ADDRESS = 'CONTACT_ADDRESS',
    TEXT = 'TEXT',
}

export enum EngagementQuestionType {
    VAN_SURVEY_QUESTION = 'VAN_SURVEY_QUESTION',
    OUTREACHCIRCLE = 'OUTREACHCIRCLE',
    VAN_ACTIVIST_CODES = 'VAN_ACTIVIST_CODES',
    PDI_QUESTION = 'PDI_QUESTION',
    CONTACT_INFO = 'CONTACT_INFO',
    TEXT = 'TEXT',
}

export enum TagType {
    CUSTOM = 'CUSTOM',
    DISPOSITION = 'DISPOSITION',
    VOTER_TAG = 'VOTER_TAG',
}

export enum P2PScriptSurveyQuestionType {
    VAN_SURVEY_QUESTION = 'VAN_SURVEY_QUESTION',
    OUTREACHCIRCLE = 'OUTREACHCIRCLE',
    VAN_ACTIVIST_CODES = 'VAN_ACTIVIST_CODES',
    PDI_QUESTION = 'PDI_QUESTION',
}

export enum P2PScriptType {
    INITIAL = 'INITIAL',
    ALTERNATE_RESPONSE = 'ALTERNATE_RESPONSE',
    CANNED_RESPONSE = 'CANNED_RESPONSE',
}

export enum SearchAndSurveyFormQuestionType {
    SINGLE_CHOICE = 'SINGLE_CHOICE',
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    CONTACT_INFO = 'CONTACT_INFO',
    TEXT = 'TEXT',
}

export enum SearchAndSurveyFormOptionType {
    CHOICE = 'CHOICE',
    CONTACT_EMAIL_ADDRESS = 'CONTACT_EMAIL_ADDRESS',
    CONTACT_PHONE_NUMBER = 'CONTACT_PHONE_NUMBER',
    CONTACT_ADDRESS = 'CONTACT_ADDRESS',
    TEXT = 'TEXT',
}

export enum SearchAndSurveyFormQuestionIntegrationType {
    VAN_SURVEY_QUESTION = 'VAN_SURVEY_QUESTION',
    OUTREACH_CIRCLE = 'OUTREACH_CIRCLE',
    VAN_ACTIVIST_CODES = 'VAN_ACTIVIST_CODES',
    PDI_QUESTION = 'PDI_QUESTION',
}

export enum SearchAndSurveyFormIntegrationType {
    OUTREACH_CIRCLE = 'OUTREACH_CIRCLE',
    NGP_MY_CAMPAIGN = 'NGP_MY_CAMPAIGN',
    NGP_MY_VOTER = 'NGP_MY_VOTER',
    PDI = 'PDI',
}

export enum GroSurveyFormQuestionType {
    SINGLE_CHOICE = 'SINGLE_CHOICE',
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    CONTACT_INFO = 'CONTACT_INFO',
    TEXT = 'TEXT',
}

export enum GroSurveyFormOptionType {
    CHOICE = 'CHOICE',
    CONTACT_EMAIL_ADDRESS = 'CONTACT_EMAIL_ADDRESS',
    CONTACT_PHONE_NUMBER = 'CONTACT_PHONE_NUMBER',
    CONTACT_ADDRESS = 'CONTACT_ADDRESS',
    TEXT = 'TEXT',
}

export enum GroSurveyFormQuestionIntegrationType {
    OUTREACH_CIRCLE = 'OUTREACH_CIRCLE',
    PDI_QUESTION = 'PDI_QUESTION',
    VAN_SURVEY_QUESTION = 'VAN_SURVEY_QUESTION',
    VAN_ACTIVIST_CODES = 'VAN_ACTIVIST_CODES',
}

export enum GroSurveyFormIntegrationType {
    OUTREACH_CIRCLE = 'OUTREACH_CIRCLE',
    PDI = 'PDI',
    NGP_MY_CAMPAIGN = 'NGP_MY_CAMPAIGN',
    NGP_MY_VOTER = 'NGP_MY_VOTER',
}

export enum SupporterPushNotificationTemplateCode {
    SUPPORTER_NOTIFY_ON_ACTION_LIVE_PN = 'SUPPORTER_NOTIFY_ON_ACTION_LIVE_PN',
    SUPPORTER_REMINDER_FOR_ACTION_PN = 'SUPPORTER_REMINDER_FOR_ACTION_PN',
}

export enum SupporterEmailTemplateCode {
    SUPPORTER_WELCOME = 'SUPPORTER_WELCOME',
    SUPPORTER_NOTIFY_ON_ACTION_LIVE = 'SUPPORTER_NOTIFY_ON_ACTION_LIVE',
    SUPPORTER_REMINDER_FOR_ACTION = 'SUPPORTER_REMINDER_FOR_ACTION',
    INVITE_OUTREACH_CIRCLE_CONTACTS_TO_JOIN_AS_SUPPORTER = 'INVITE_OUTREACH_CIRCLE_CONTACTS_TO_JOIN_AS_SUPPORTER',
    THANK_YOU_ON_COMPLETE_FIRST_ACTION_SUPPORTER_EMAIL = 'THANK_YOU_ON_COMPLETE_FIRST_ACTION_SUPPORTER_EMAIL',
    SUPPORTER_EMAIL_NOTIFICATION = 'SUPPORTER_EMAIL_NOTIFICATION',
    SUPPORTER_NOTIFY_ON_EVENT_ASSIGNMENT = 'SUPPORTER_NOTIFY_ON_EVENT_ASSIGNMENT',
    SUPPORTER_NOTIFY_ON_SHIFT_ASSIGNMENT = 'SUPPORTER_NOTIFY_ON_SHIFT_ASSIGNMENT',
}

export enum PurchasedPhoneNumberStatus {
    ACTIVE = 'ACTIVE',
    ACTIVATING = 'ACTIVATING',
    INACTIVE = 'INACTIVE',
}

export enum ListSelectionType {
    Primary = 'Primary',
    Priority = 'Priority',
    Tag = 'Tag',
    Gotv = 'Gotv',
}

export enum SurveyType {
    BRANCHED = 'BRANCHED',
    BASIC = 'BASIC',
    TEXTING = 'TEXTING',
}

export enum SurveyStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum SurveyQuestionStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum FlagStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum SurveyQuestionType {
    OTHER = 'OTHER',
    CANDIDATE_CAMPAIGN_BALLOT_MEASURE_VOLUNTEER = 'CANDIDATE_CAMPAIGN_BALLOT_MEASURE_VOLUNTEER',
    CANDIDATE_CAMPAIGN_BALLOT_MEASURE_DONOR = 'CANDIDATE_CAMPAIGN_BALLOT_MEASURE_DONOR',
    CANDIDATE_CAMPAIGN_BALLOT_MEASURE_YARD_SIGN = 'CANDIDATE_CAMPAIGN_BALLOT_MEASURE_YARD_SIGN',
    CANDIDATE_CAMPAIGN_BALLOT_MEASURE_SUPPORT = 'CANDIDATE_CAMPAIGN_BALLOT_MEASURE_SUPPORT',
    INTENT_TO_VOTE = 'INTENT_TO_VOTE',
    NON_CONTACT_FIELD_CANVASSING_LIST = 'NON_CONTACT_FIELD_CANVASSING_LIST',
    NON_CONTACT_PHONE_CANVASSING_LIST = 'NON_CONTACT_PHONE_CANVASSING_LIST',
    NON_CONTACT_MOBILE_CANVASSING = 'NON_CONTACT_MOBILE_CANVASSING',
    NON_CONTACT_ONLINE_PHONE_BANK = 'NON_CONTACT_ONLINE_PHONE_BANK',
    NON_CONTACT_CLICK_TO_DIAL = 'NON_CONTACT_CLICK_TO_DIAL',
    NON_CONTACT_POWER_DIALER_BETA = 'NON_CONTACT_POWER_DIALER_BETA',
    HOUSEHOLD_OBSERVATION = 'HOUSEHOLD_OBSERVATION',
    ISSUE = 'ISSUE',
}

export enum PurposeStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum TextingStatus {
    NOT_STARTED = 'NOT_STARTED',
    LIST_IMPORT_IN_PROGRESS = 'LIST_IMPORT_IN_PROGRESS',
    LIST_IMPORT_FAILED = 'LIST_IMPORT_FAILED',
    LIST_IMPORT_COMPLETED = 'LIST_IMPORT_COMPLETED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    PAUSED = 'PAUSED',
    FAILED = 'FAILED',
}

export enum Visibility {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
}

export enum LocationType {
    IN_PERSON = 'IN_PERSON',
    VIRTUAL = 'VIRTUAL',
}

export enum EventFrequency {
    ONCE = 'ONCE',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    CUSTOM = 'CUSTOM',
}

export enum DayOfTheWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export enum TurfType {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
    CUSTOM = 'CUSTOM',
    CENSUS_BLOCK = 'CENSUS_BLOCK',
    PRECINCT = 'PRECINCT',
}

export enum CanvassingMemberStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FOLLOW_UP = 'FOLLOW_UP',
}

export enum TurfAssignmentSource {
    MANUAL = 'MANUAL',
    AUTOMATIC = 'AUTOMATIC',
}

export enum TurfSource {
    MANUAL = 'MANUAL',
    TURF_GROUP = 'TURF_GROUP',
    DEFAULT = 'DEFAULT',
}

export enum ActionSurveyQuestionType {
    HOUSEHOLD_OBSERVATION = 'HOUSEHOLD_OBSERVATION',
    NON_CONTACT_FIELD_CANVASSING_LIST = 'NON_CONTACT_FIELD_CANVASSING_LIST',
    NON_CONTACT_PHONE_CANVASSING_LIST = 'NON_CONTACT_PHONE_CANVASSING_LIST',
}

export enum LanguagePreferenceType {
    EN = 'EN',
    ES = 'ES',
    FR = 'FR',
}

export enum MasterPermissionType {
    Module = 'Module',
    Administrative = 'Administrative',
}

export enum OutreachCircleUserStatus {
    Active = 'Active',
    Inactive = 'Inactive',
}

export enum P2PMessagingServiceArchitecture {
    ISV1 = 'ISV1',
    ISV3 = 'ISV3',
}

export enum ActionHeaderType {
    DOOR_TO_DOOR_CANVASSING = 'DOOR_TO_DOOR_CANVASSING',
    PHONE_BANK = 'PHONE_BANK',
    REALATIONAL_ORGANIZING = 'REALATIONAL_ORGANIZING',
    SEARCH_AND_SURVEY = 'SEARCH_AND_SURVEY',
    VOTER_REGISTRATION = 'VOTER_REGISTRATION',
    TEXTING = 'TEXTING',
}

export enum EventParticipantType {
    Working = 'Working',
    Guest = 'Guest',
}

export enum SupporterAppStatus {
    None = 'None',
    Invited = 'Invited',
    InvitedBounced = 'InvitedBounced',
    InvitedInstalled = 'InvitedInstalled',
    Onboarded = 'Onboarded',
}

export enum ContactEmailTypeEnum {
    WORK = 'WORK',
    HOME = 'HOME',
    OTHER = 'OTHER',
}

export enum ContactMobileTypeEnum {
    WORK = 'WORK',
    HOME = 'HOME',
    MOBILE = 'MOBILE',
    OTHER = 'OTHER',
}

export enum AttendeeStatus {
    Yes = 'Yes',
    Maybe = 'Maybe',
    No = 'No',
}

export enum ActionsSortBy {
    Name = 'Name',
    Position = 'Position',
    Updated = 'Updated',
    Created = 'Created',
    StartDate = 'StartDate',
    Status = 'Status',
}

export enum AssignedSupportersSortBy {
    supporterName = 'supporterName',
    textsSent = 'textsSent',
    pendingReplies = 'pendingReplies',
    emailsSent = 'emailsSent',
    status = 'status',
    canvassedCount = 'canvassedCount',
    callCount = 'callCount',
    visitCount = 'visitCount',
    assignedContactsCount = 'assignedContactsCount',
    groEmailsSent = 'groEmailsSent',
    groTextsSent = 'groTextsSent',
}

export enum AssignedGroupsToActionSortBy {
    groupName = 'groupName',
    actionsTakenCount = 'actionsTakenCount',
    emailsSentCount = 'emailsSentCount',
    textsSentCount = 'textsSentCount',
}

export enum PhoneType {
    HOME = 'HOME',
    OFFICE = 'OFFICE',
    OTHER = 'OTHER',
}

export enum Pronouns {
    HE_HIM = 'HE_HIM',
    SHE_HER = 'SHE_HER',
    THEY_THEM = 'THEY_THEM',
    OTHER = 'OTHER',
}

export enum TextingConversationLogSentBy {
    SUPPORTER = 'SUPPORTER',
    RECIPIENT = 'RECIPIENT',
}

export enum AddressBasedCanvassStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
}

export enum ContactSourcePlatform {
    web = 'web',
    android = 'android',
    ios = 'ios',
}

export enum DuplicateStatus {
    RESOLVED = 'RESOLVED',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
}

export enum Origin {
    ORGANIC = 'ORGANIC',
    OUTREACH = 'OUTREACH',
    INVITED = 'INVITED',
    Phone2Action = 'Phone2Action',
    WEBSITE_EMBED = 'WEBSITE_EMBED',
}

export enum MatchingJobStatusEnum {
    MATCHING_JOB_NOT_STARTED = 'MATCHING_JOB_NOT_STARTED',
    MATCHING_JOB_IN_PROGRESS = 'MATCHING_JOB_IN_PROGRESS',
    MATCHING_JOB_FAILED = 'MATCHING_JOB_FAILED',
    MATCHING_JOB_SUCCESSFUL = 'MATCHING_JOB_SUCCESSFUL',
}

export enum OrganizationUpdatesType {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    INFO = 'INFO',
}

export enum OrganizationUpdatesStatus {
    ACTIVE = 'ACTIVE',
    COMPLETED = 'COMPLETED',
}

export enum TodaysActivityType {
    TOTAL_CONTACTS = 'TOTAL_CONTACTS',
    TOTAL_MESSAGES_SENT = 'TOTAL_MESSAGES_SENT',
    PHONE_CANVASSING = 'PHONE_CANVASSING',
    TEXT_MESSAGE_RESPONSES = 'TEXT_MESSAGE_RESPONSES',
    DOOR_TO_DOOR_CANVASSING = 'DOOR_TO_DOOR_CANVASSING',
    OTHERS = 'OTHERS',
}

export enum PdiListType {
    Dynamic = 'Dynamic',
    Static = 'Static',
}

export enum PdiListStatus {
    Active = 'Active',
    InActive = 'InActive',
}

export enum CanvassingMemberSource {
    MANUAL = 'MANUAL',
    IMPORTED = 'IMPORTED',
}

export enum DefaultTurfSortField {
    NAME = 'NAME',
    DISPLAY_ID = 'DISPLAY_ID',
    ASSIGNMENT_DATE = 'ASSIGNMENT_DATE',
    TURF_GROUP = 'TURF_GROUP',
}

export enum SupportSentiment {
    POSITIVE_FLAG = 'POSITIVE_FLAG',
    NEGATIVE_FLAG = 'NEGATIVE_FLAG',
    NEUTRAL_FLAG = 'NEUTRAL_FLAG',
}

export enum CanvassedSurveyResponseStatus {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
}

export enum VoterDensityGroupBy {
    Member = 'Member',
    Household = 'Household',
}

export enum SurveySortBy {
    Name = 'Name',
    Responses = 'Responses',
    Created = 'Created',
}

export enum EventConfigsSortBy {
    Name = 'Name',
    Updated = 'Updated',
    Created = 'Created',
}

export enum PublicEventConfigsSortBy {
    Name = 'Name',
    FirstFutureInstanceDate = 'FirstFutureInstanceDate',
}

export enum GroupsSortBy {
    Name = 'Name',
    Updated = 'Updated',
}

export enum NgpIntegrationType {
    NGP_MY_CAMPAIGN = 'NGP_MY_CAMPAIGN',
    NGP_MY_VOTER = 'NGP_MY_VOTER',
}

export enum TcrBrandOrganizationType {
    NON_PROFIT = 'NON_PROFIT',
    PUBLIC_PROFIT = 'PUBLIC_PROFIT',
    PRIVATE_PROFIT = 'PRIVATE_PROFIT',
    GOVERNMENT = 'GOVERNMENT',
    SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
}

export enum TcrBrandVertical {
    POLITICAL = 'POLITICAL',
    NGO = 'NGO',
    GOVERNMENT = 'GOVERNMENT',
}

export enum TcrBrandIdentityStatus {
    SELF_DECLARED = 'SELF_DECLARED',
    UNVERIFIED = 'UNVERIFIED',
    VERIFIED = 'VERIFIED',
    VETTED_VERIFIED = 'VETTED_VERIFIED',
}

export enum TcrVettingStatus {
    PENDING = 'PENDING',
    UNSCORE = 'UNSCORE',
    ACTIVE = 'ACTIVE',
    FAILED = 'FAILED',
    EXPIRED = 'EXPIRED',
}

export enum TcrCampaignStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
}

export enum TcrCampaignUsecase {
    ACCOUNT_NOTIFICATION = 'ACCOUNT_NOTIFICATION',
    AGENTS_FRANCHISES = 'AGENTS_FRANCHISES',
    CARRIER_EXEMPT = 'CARRIER_EXEMPT',
    CHARITY = 'CHARITY',
    CUSTOMER_CARE = 'CUSTOMER_CARE',
    DELIVERY_NOTIFICATION = 'DELIVERY_NOTIFICATION',
    EMERGENCY = 'EMERGENCY',
    FRAUD_ALERT = 'FRAUD_ALERT',
    HIGHER_EDUCATION = 'HIGHER_EDUCATION',
    K12_EDUCATION = 'K12_EDUCATION',
    LOW_VOLUME = 'LOW_VOLUME',
    MARKETING = 'MARKETING',
    MIXED = 'MIXED',
    POLITICAL = 'POLITICAL',
    POLLING_VOTING = 'POLLING_VOTING',
    PROXY = 'PROXY',
    PUBLIC_SERVICE_ANNOUNCEMENT = 'PUBLIC_SERVICE_ANNOUNCEMENT',
    SECURITY_ALERT = 'SECURITY_ALERT',
    SOCIAL = 'SOCIAL',
    SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
    SWEEPSTAKE = 'SWEEPSTAKE',
    TRIAL = 'TRIAL',
    UCAAS_HIGH = 'UCAAS_HIGH',
    UCAAS_LOW = 'UCAAS_LOW',
}

export enum DeviceTypeEnum {
    DESKTOP = 'DESKTOP',
    MOBILE_WEB = 'MOBILE_WEB',
    IOS_NATIVE = 'IOS_NATIVE',
    ANDROID_NATIVE = 'ANDROID_NATIVE',
}

export enum OrganizationActionStatus {
    UNPUBLISHED = 'UNPUBLISHED',
    PUBLISHED = 'PUBLISHED',
    TERMINATED = 'TERMINATED',
}

export enum OutreachCircleSortBy {
    Created = 'Created',
    Name = 'Name',
}

export enum OutreachCircleUserEmailTemplateCode {
    OC_ADMIN_NOTIFY_FOR_ORGANIZATION_ACTION = 'OC_ADMIN_NOTIFY_FOR_ORGANIZATION_ACTION',
    OC_ADMIN_NOTIFICATION = 'OC_ADMIN_NOTIFICATION',
}

export enum SubscriptionStatus {
    incomplete = 'incomplete',
    incomplete_expired = 'incomplete_expired',
    trialing = 'trialing',
    active = 'active',
    past_due = 'past_due',
    canceled = 'canceled',
    unpaid = 'unpaid',
}

export enum SupporterActivityType {
    event = 'event',
    shift = 'shift',
}

export enum P2PConversationLogSentBy {
    SUPPORTER = 'SUPPORTER',
    RECIPIENT = 'RECIPIENT',
}

export enum ReportType {
    RELATIONAL = 'RELATIONAL',
    PEER_TO_PEER = 'PEER_TO_PEER',
    ORGANIZATION = 'ORGANIZATION',
}

export enum ReportCode {
    supporter_report = 'supporter_report',
    email_performance_report = 'email_performance_report',
    action_report = 'action_report',
    survey_response_report = 'survey_response_report',
    texting_report = 'texting_report',
    email_messages_to_supporters = 'email_messages_to_supporters',
    supporter_form_report = 'supporter_form_report',
    campaign_report = 'campaign_report',
    tag_report = 'tag_report',
    p2p_message_log_report = 'p2p_message_log_report',
    p2p_recipient_summary_report = 'p2p_recipient_summary_report',
    p2p_action_summary_report = 'p2p_action_summary_report',
    p2p_survey_response_report = 'p2p_survey_response_report',
    p2p_tag_report = 'p2p_tag_report',
    canvassing_person_report = 'canvassing_person_report',
    canvass_survey_response_report = 'canvass_survey_response_report',
    admin_report = 'admin_report',
    weekly_app_statistics = 'weekly_app_statistics',
    achievement_report_by_supporter = 'achievement_report_by_supporter',
    response_report_by_person = 'response_report_by_person',
    a1c_members_assignment_report = 'a1c_members_assignment_report',
    network_report = 'network_report',
}

export enum SupporterFormSortBy {
    Name = 'Name',
    Responses = 'Responses',
    Created = 'Created',
}

export enum SupporterSummarySortBy {
    FullName = 'FullName',
    Email = 'Email',
    PhoneNumber = 'PhoneNumber',
    ZipCode = 'ZipCode',
    JoinLink = 'JoinLink',
    Location = 'Location',
    Origin = 'Origin',
    InvitedOn = 'InvitedOn',
    JoinedOn = 'JoinedOn',
    TotalActionCompleted = 'TotalActionCompleted',
    TotalActionInProgress = 'TotalActionInProgress',
    TotalUniqueContactsInvited = 'TotalUniqueContactsInvited',
    TotalUniqueContactsJoined = 'TotalUniqueContactsJoined',
}

export enum SupporterViewSortBy {
    FullName = 'FullName',
    LastName = 'LastName',
    ActionsCompleted = 'ActionsCompleted',
    Email = 'Email',
    Source = 'Source',
    JoinedOn = 'JoinedOn',
    PhoneNumber = 'PhoneNumber',
    Zip = 'Zip',
    Location = 'Location',
    MessagesSentBySupporter = 'MessagesSentBySupporter',
    RecruitmentLink = 'RecruitmentLink',
    StatusOfLastEmailSentToSupporter = 'StatusOfLastEmailSentToSupporter',
    Groups = 'Groups',
    RecruitedBy = 'RecruitedBy',
    Status = 'Status',
}

export enum SurveyListSortField {
    CREATED = 'CREATED',
    NAME = 'NAME',
}

export enum ReviewWarningKey {
    emailTemplate = 'emailTemplate',
    textTemplate = 'textTemplate',
    socialTextTemplate = 'socialTextTemplate',
}

export enum SearchAndSurveyDisplayType {
    SEARCH = 'SEARCH',
    SURVEY = 'SURVEY',
}

export enum AudienceActionOperation {
    ENABLE = 'ENABLE',
    DISABLE = 'DISABLE',
}

export enum OrganizationActionType {
    FACEBOOK_SOCIAL_SHARE = 'FACEBOOK_SOCIAL_SHARE',
    SEND_UPDATE = 'SEND_UPDATE',
    EMAIL = 'EMAIL',
    ATTENDANCE_CHECKIN = 'ATTENDANCE_CHECKIN',
    DONATION = 'DONATION',
    TASK = 'TASK',
    PETITION = 'PETITION',
    EVENT = 'EVENT',
    REDIRECT_LINK = 'REDIRECT_LINK',
    WATCH_VIDEO = 'WATCH_VIDEO',
}

export enum OrganizationUserUiPreferences {
    HideSectionForHiddenAndTerminatedActions = 'HideSectionForHiddenAndTerminatedActions',
}

export enum TagResourceType {
    MatchedTextRecipient = 'MatchedTextRecipient',
    MatchedEmailRecipient = 'MatchedEmailRecipient',
    AmbiguousTextRecipient = 'AmbiguousTextRecipient',
    AmbiguousEmailRecipient = 'AmbiguousEmailRecipient',
    NVFMTextRecipient = 'NVFMTextRecipient',
    NVFMEmailRecipient = 'NVFMEmailRecipient',
    AffinityTextingRecipient = 'AffinityTextingRecipient',
    P2PRecipient = 'P2PRecipient',
}

export enum TagActionType {
    TAG = 'TAG',
    UNTAG = 'UNTAG',
}

export enum ActionUpdateUseCase {
    TURFING_IN_PROGRESS = 'TURFING_IN_PROGRESS',
    TURFING_COMPLETE = 'TURFING_COMPLETE',
    P2P_LIST_PROGRESS = 'P2P_LIST_PROGRESS',
    EMAIL_TEMPLATE_UPDATED = 'EMAIL_TEMPLATE_UPDATED',
    TEXT_TEMPLATE_UPDATED = 'TEXT_TEMPLATE_UPDATED',
    TEXTING_UPDATE = 'TEXTING_UPDATE',
    STATUS_UPDATE = 'STATUS_UPDATE',
    PHONE_BANK_UPDATE = 'PHONE_BANK_UPDATE',
    LIST_PROGRESS_UPDATE = 'LIST_PROGRESS_UPDATE',
}

export enum PublicActionUpdateUseCase {
    HOUSE_HOLD_CANVASSING_COMPLETED = 'HOUSE_HOLD_CANVASSING_COMPLETED',
    HOUSE_HOLD_CANVASSING_STARTED = 'HOUSE_HOLD_CANVASSING_STARTED',
    MEMBER_CANVASS_IN_PROGRESS = 'MEMBER_CANVASS_IN_PROGRESS',
    MEMBER_CANVASS_COMPLETED = 'MEMBER_CANVASS_COMPLETED',
    MEMBER_CANVASS_STATUS_UPDATED = 'MEMBER_CANVASS_STATUS_UPDATED',
    HOUSE_HOLD_CANVASSING_STATUS_UPDATED = 'HOUSE_HOLD_CANVASSING_STATUS_UPDATED',
    VOLUNTEER_ENTER_CONVERSATION = 'VOLUNTEER_ENTER_CONVERSATION',
    VOLUNTEER_LEAVE_CONVERSATION = 'VOLUNTEER_LEAVE_CONVERSATION',
}

export enum CallStatus {
    QUEUED = 'QUEUED',
    RINGING = 'RINGING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    BUSY = 'BUSY',
    FAILED = 'FAILED',
    NO_ANSWER = 'NO_ANSWER',
    CANCELED = 'CANCELED',
    UNKNOWN = 'UNKNOWN',
}

export enum AudienceImportStatusEvent {
    IMPORT_STARTED = 'IMPORT_STARTED',
    IMPORT_COMPLETED = 'IMPORT_COMPLETED',
    IMPORT_FAILED = 'IMPORT_FAILED',
}

export enum TcrEntityType {
    BRAND_UPDATED = 'BRAND_UPDATED',
    VETTING_UPDATED = 'VETTING_UPDATED',
    CAMPAIGN_UPDATED = 'CAMPAIGN_UPDATED',
}
/** NOT_STARTED - on integration creationBRAND_REGISTRATION_IN_PROGRESS - on submission of brand registration formBRAND_VERIFIED - on receiving BRAND_ADD status from webhookBRAND_ERROR - on any error/failure state related to brandVETTING_IN_PROGRESS - on manually/automatic triggering of vettingVETTING_VERIFIED - on receiving VETTED_VERIFIED/VERIFIED status from webhookVETTING_ERROR - on any error/failure state related to vettingCAMPAIGN_REGISTRATION_IN_PROGRESS - on submission of campaign registration formCAMPAIGN_SHARED_WITH_TEXTING_PROVIDER - on receiving CAMPAIGN_ADD status from webhook and sharing campaign with texting providerCAMPAIGN_DCA_IN_PROGRESS - after texting provider has verified campaign and campaign has been shared with DCACAMPAIGN_READY_TO_USE - on receiving approval from DCACAMPAIGN_ERROR - on any error/failure state related to campaign */
export enum TcrCurrentStatus {
    NOT_STARTED = 'NOT_STARTED',
    BRAND_REGISTRATION_IN_PROGRESS = 'BRAND_REGISTRATION_IN_PROGRESS',
    BRAND_VERIFIED = 'BRAND_VERIFIED',
    BRAND_ERROR = 'BRAND_ERROR',
    VETTING_IN_PROGRESS = 'VETTING_IN_PROGRESS',
    VETTING_VERIFIED = 'VETTING_VERIFIED',
    VETTING_ERROR = 'VETTING_ERROR',
    CAMPAIGN_REGISTRATION_IN_PROGRESS = 'CAMPAIGN_REGISTRATION_IN_PROGRESS',
    CAMPAIGN_SHARED_WITH_TEXTING_PROVIDER = 'CAMPAIGN_SHARED_WITH_TEXTING_PROVIDER',
    CAMPAIGN_DCA_IN_PROGRESS = 'CAMPAIGN_DCA_IN_PROGRESS',
    CAMPAIGN_READY_TO_USE = 'CAMPAIGN_READY_TO_USE',
    CAMPAIGN_ERROR = 'CAMPAIGN_ERROR',
}

export enum OutreachCircleUpdateResponseType {
    PLAN_UPDATED = 'PLAN_UPDATED',
    ADMIN_CHANGES = 'ADMIN_CHANGES',
    ACTION_ACTIVATED = 'ACTION_ACTIVATED',
    LIST_IMPORT_PROGRESS = 'LIST_IMPORT_PROGRESS',
}

export enum SupporterSubscriptionResponseEvent {
    MATCHING_JOB_STARTED = 'MATCHING_JOB_STARTED',
    CONTACT_IMPORT_COMPLETED = 'CONTACT_IMPORT_COMPLETED',
    CONTACT_IMPORT_STARTED = 'CONTACT_IMPORT_STARTED',
    MATCHING_JOB_UPDATE = 'MATCHING_JOB_UPDATE',
    NEW_P2P_MESSAGE = 'NEW_P2P_MESSAGE',
    P2P_CONVERSATION_MARKED_AS_READ = 'P2P_CONVERSATION_MARKED_AS_READ',
    NEW_TEXTING_MESSAGE = 'NEW_TEXTING_MESSAGE',
    TEXTING_CONVERSATION_MARKED_AS_READ = 'TEXTING_CONVERSATION_MARKED_AS_READ',
}

export enum InvitedSupporterImportProgressState {
    ImportStarted = 'ImportStarted',
    CleaningCompleted = 'CleaningCompleted',
    MergingDuplicateContactsCompleted = 'MergingDuplicateContactsCompleted',
    ContactsSaved = 'ContactsSaved',
    ImportCompleted = 'ImportCompleted',
    ImportFailed = 'ImportFailed',
}

export enum ShiftActionSupporterAssignmentSource {
    ADMIN = 'ADMIN',
    SUPPORTER = 'SUPPORTER',
}
/** READY # reuse & updateLIVE # reuse but cannot updateARCHIVED # no reuse obviously no update */
export enum TemplateStatus {
    READY = 'READY',
    LIVE = 'LIVE',
    ARCHIVED = 'ARCHIVED',
}

export enum ContactSourceType {
    Google = 'Google',
    Apple = 'Apple',
    Yahoo = 'Yahoo',
    Microsoft = 'Microsoft',
    AOL = 'AOL',
    File = 'File',
    Manual = 'Manual',
    Phone = 'Phone',
    PreviouslyTexted = 'PreviouslyTexted',
}

export enum CanvassingSurveyResponseReviewSource {
    MANUAL = 'MANUAL',
    AUTOMATIC = 'AUTOMATIC',
}

export enum NgpDbMode {
    NGP_MY_CAMPAIGN = 'NGP_MY_CAMPAIGN',
    NGP_MY_VOTER = 'NGP_MY_VOTER',
}

export enum PurposeType {
    Issue = 'Issue',
    Candidate = 'Candidate',
    BallotMeasure = 'BallotMeasure',
}

export enum SupporterCustomStatus {
    Invited = 'Invited',
    Active = 'Active',
    Paid = 'Paid',
    Disabled = 'Disabled',
}

export enum SupporterStatus {
    Yes = 'Yes',
    No = 'No',
    Blocked = 'Blocked',
    Invited = 'Invited',
    Paid = 'Paid',
}

export enum TagIntegrationType {
    NGP_MY_CAMPAIGN = 'NGP_MY_CAMPAIGN',
    NGP_MY_VOTER = 'NGP_MY_VOTER',
    OUTREACH_CIRCLE = 'OUTREACH_CIRCLE',
}

export enum ErrorCodes {
    ACCESS_DENIED = 'ACCESS_DENIED',
    ACTION_ALIAS_DOES_NOT_EXIST = 'ACTION_ALIAS_DOES_NOT_EXIST',
    ACTION_ALLOW_CONTACT_IMPORT_DISABLED = 'ACTION_ALLOW_CONTACT_IMPORT_DISABLED',
    ACTION_ALREADY_ASSIGNED_TO_SUPPORTER = 'ACTION_ALREADY_ASSIGNED_TO_SUPPORTER',
    ACTION_ALREADY_COMPLETED = 'ACTION_ALREADY_COMPLETED',
    ACTION_ALREADY_EXISTS = 'ACTION_ALREADY_EXISTS',
    ACTION_ALREADY_EXPIRED = 'ACTION_ALREADY_EXPIRED',
    ACTION_ASSIGNMENT_ERROR = 'ACTION_ASSIGNMENT_ERROR',
    ACTION_AUDIENCE_ACTION_NOT_FOUND = 'ACTION_AUDIENCE_ACTION_NOT_FOUND',
    ACTION_CANNOT_BE_ACTIVATED = 'ACTION_CANNOT_BE_ACTIVATED',
    ACTION_CANNOT_BE_ARCHIVED = 'ACTION_CANNOT_BE_ARCHIVED',
    ACTION_CANNOT_BE_DEACTIVATED = 'ACTION_CANNOT_BE_DEACTIVATED',
    ACTION_CANNOT_BE_EMBEDDED = 'ACTION_CANNOT_BE_EMBEDDED',
    ACTION_CANNOT_BE_LINKED_TO_GROUP = 'ACTION_CANNOT_BE_LINKED_TO_GROUP',
    ACTION_CANNOT_BE_PAUSED = 'ACTION_CANNOT_BE_PAUSED',
    ACTION_CANNOT_BE_UPDATED = 'ACTION_CANNOT_BE_UPDATED',
    ACTION_CANNOT_GO_LIVE = 'ACTION_CANNOT_GO_LIVE',
    ACTION_ENGAGEMENT_AND_AUDIENCE_FILTER_MISMATCH = 'ACTION_ENGAGEMENT_AND_AUDIENCE_FILTER_MISMATCH',
    ACTION_INTEGRATION_TYPE_MISMATCH = 'ACTION_INTEGRATION_TYPE_MISMATCH',
    ACTION_MAXIMUM_CUSTOM_FIELD_LIMIT_REACHED = 'ACTION_MAXIMUM_CUSTOM_FIELD_LIMIT_REACHED',
    ACTION_NOT_ALLOWED = 'ACTION_NOT_ALLOWED',
    ACTION_NOT_ASSIGNED_TO_SUPPORTER = 'ACTION_NOT_ASSIGNED_TO_SUPPORTER',
    ACTION_NOT_AVAILABLE = 'ACTION_NOT_AVAILABLE',
    ACTION_NOT_FOUND = 'ACTION_NOT_FOUND',
    ACTION_NOT_IN_LIVE_OR_PAUSED_STATE = 'ACTION_NOT_IN_LIVE_OR_PAUSED_STATE',
    ACTION_NOT_IN_LIVE_STATE = 'ACTION_NOT_IN_LIVE_STATE',
    ACTION_NOT_IN_READY_STATE = 'ACTION_NOT_IN_READY_STATE',
    ACTION_PARTIALLY_COMPLETED = 'ACTION_PARTIALLY_COMPLETED',
    ACTION_SURVEY_DOES_NOT_EXIST = 'ACTION_SURVEY_DOES_NOT_EXIST',
    ACTION_SURVEY_IS_ALREADY_LINKED_TO_DIFFERENT_LIST = 'ACTION_SURVEY_IS_ALREADY_LINKED_TO_DIFFERENT_LIST',
    ACTION_TEXT_ALREADY_SENT_TO_SUPPORTER = 'ACTION_TEXT_ALREADY_SENT_TO_SUPPORTER',
    ACTION_TREATMENT_IS_MANDATORY = 'ACTION_TREATMENT_IS_MANDATORY',
    ACTION_VIEW_FILTER_STATE_ALREADY_EXIST = 'ACTION_VIEW_FILTER_STATE_ALREADY_EXIST',
    ACTIVE_CONFERENCE_ALREADY_EXISTS = 'ACTIVE_CONFERENCE_ALREADY_EXISTS',
    ACTIVITY_WITH_NAME_ALREADY_EXISTS = 'ACTIVITY_WITH_NAME_ALREADY_EXISTS',
    ADD_CARD_FAILED = 'ADD_CARD_FAILED',
    AFFINITY_VOTER_FILE_LIMIT_EXCEEDED = 'AFFINITY_VOTER_FILE_LIMIT_EXCEEDED',
    ALIAS_DOES_NOT_EXIST = 'ALIAS_DOES_NOT_EXIST',
    ALIAS_NAME_ALREADY_EXISTS = 'ALIAS_NAME_ALREADY_EXISTS',
    ALIAS_NOT_ASSOCIATED_TO_OUTREACH_CIRCLE = 'ALIAS_NOT_ASSOCIATED_TO_OUTREACH_CIRCLE',
    ALIAS_NOT_EMBEDDABLE = 'ALIAS_NOT_EMBEDDABLE',
    ALREADY_REGISTERED_FOR_SHIFT = 'ALREADY_REGISTERED_FOR_SHIFT',
    AMBIGUOUS_MAPPED_CONTACT_NOT_FOUND = 'AMBIGUOUS_MAPPED_CONTACT_NOT_FOUND',
    ARCHIVE_FEEDBACK_TEXT_EXCEEDS_ALLOWED_LENGTH_LIMIT = 'ARCHIVE_FEEDBACK_TEXT_EXCEEDS_ALLOWED_LENGTH_LIMIT',
    ASSIGNMENT_GROUP_NAME_ALREADY_EXISTS = 'ASSIGNMENT_GROUP_NAME_ALREADY_EXISTS',
    ASSIGNMENT_NOT_FOUND = 'ASSIGNMENT_NOT_FOUND',
    AUDIENCE_ACTION_ALREADY_EXISTS = 'AUDIENCE_ACTION_ALREADY_EXISTS',
    AUDIENCE_ACTION_NOT_FOUND = 'AUDIENCE_ACTION_NOT_FOUND',
    AUDIENCE_ACTION_WITH_SAME_NAME_ALREADY_EXISTS = 'AUDIENCE_ACTION_WITH_SAME_NAME_ALREADY_EXISTS',
    AUDIENCE_ALREADY_EXISTS = 'AUDIENCE_ALREADY_EXISTS',
    AUDIENCE_NOT_FOUND = 'AUDIENCE_NOT_FOUND',
    AUDIENCE_VERSION_BLOCKED = 'AUDIENCE_VERSION_BLOCKED',
    BLACKBAUD_ACCESS_TOKEN_EXPIRED = 'BLACKBAUD_ACCESS_TOKEN_EXPIRED',
    BLACKBAUD_INTEGRATION_NOT_FOUND = 'BLACKBAUD_INTEGRATION_NOT_FOUND',
    BLACKBAUD_LIST_DOES_NOT_EXIST = 'BLACKBAUD_LIST_DOES_NOT_EXIST',
    BOOKMARK_ALREADY_EXISTS = 'BOOKMARK_ALREADY_EXISTS',
    BOOKMARK_NOT_FOUND = 'BOOKMARK_NOT_FOUND',
    CALLING_ACCOUNT_ALREADY_EXISTS = 'CALLING_ACCOUNT_ALREADY_EXISTS',
    CALLING_ACCOUNT_NOT_FOUND = 'CALLING_ACCOUNT_NOT_FOUND',
    CALL_TEMPLATE_CONTENT_CANNOT_BE_EMPTY = 'CALL_TEMPLATE_CONTENT_CANNOT_BE_EMPTY',
    CALL_TO_ACTION_IN_NON_OUTREACH_MODE = 'CALL_TO_ACTION_IN_NON_OUTREACH_MODE',
    CAMPAIGN_UNSUPPORTED = 'CAMPAIGN_UNSUPPORTED',
    CANDIDATE_ALREADY_EXISTS = 'CANDIDATE_ALREADY_EXISTS',
    CANDIDATE_DOES_NOT_EXIST = 'CANDIDATE_DOES_NOT_EXIST',
    CANDIDATE_IMAGE_ALREADY_EXISTS = 'CANDIDATE_IMAGE_ALREADY_EXISTS',
    CANNED_RESPONSE_NOT_FOUND = 'CANNED_RESPONSE_NOT_FOUND',
    CANNOT_ACTIVATE_QUESTION_LINKED_TO_INACTIVE_ISSUE = 'CANNOT_ACTIVATE_QUESTION_LINKED_TO_INACTIVE_ISSUE',
    CANNOT_ASSIGN_SUPPORTERS_AS_ACTION_IS_PART_OF_GROUP = 'CANNOT_ASSIGN_SUPPORTERS_AS_ACTION_IS_PART_OF_GROUP',
    CANNOT_CHANGE_LINKED_AUDIENCE_ACTION = 'CANNOT_CHANGE_LINKED_AUDIENCE_ACTION',
    CANNOT_CHANGE_LIST_DATABASE = 'CANNOT_CHANGE_LIST_DATABASE',
    CANNOT_CHANGE_PDI_LIST = 'CANNOT_CHANGE_PDI_LIST',
    CANNOT_CREATE_EMPTY_P2P_CONVERSATION_ITEM = 'CANNOT_CREATE_EMPTY_P2P_CONVERSATION_ITEM',
    CANNOT_DEACTIVATE_PURPOSE_LINKED_TO_ACTIVE_QUESTION = 'CANNOT_DEACTIVATE_PURPOSE_LINKED_TO_ACTIVE_QUESTION',
    CANNOT_DEACTIVATE_QUESTION_LINKED_TO_ACTION = 'CANNOT_DEACTIVATE_QUESTION_LINKED_TO_ACTION',
    CANNOT_DEACTIVATE_QUESTION_LINKED_TO_ACTIVE_SURVEY = 'CANNOT_DEACTIVATE_QUESTION_LINKED_TO_ACTIVE_SURVEY',
    CANNOT_DEACTIVATE_SURVEY_WITH_ACTIVE_ACTIONS = 'CANNOT_DEACTIVATE_SURVEY_WITH_ACTIVE_ACTIONS',
    CANNOT_DELETE_OUTREACH_CIRCLE_USER = 'CANNOT_DELETE_OUTREACH_CIRCLE_USER',
    CANNOT_DISABLE_AUDIENCE_ACTION = 'CANNOT_DISABLE_AUDIENCE_ACTION',
    CANNOT_DISABLE_MFA_WHEN_OUTREACH_CIRCLE_MFA_ENABLED = 'CANNOT_DISABLE_MFA_WHEN_OUTREACH_CIRCLE_MFA_ENABLED',
    CANNOT_DISABLE_OUTREACH_MODE = 'CANNOT_DISABLE_OUTREACH_MODE',
    CANNOT_ENABLE_MFA_WITHOUT_PHONE_NUMBER = 'CANNOT_ENABLE_MFA_WITHOUT_PHONE_NUMBER',
    CANNOT_REMOVE_LAST_ORGANIZATION_USER = 'CANNOT_REMOVE_LAST_ORGANIZATION_USER',
    CANNOT_RESEND_INVIT_FOR_E_OUTREACH_CIRCLE_USER = 'CANNOT_RESEND_INVIT_FOR_E_OUTREACH_CIRCLE_USER',
    CANNOT_SEND_P2P_TEXT_MESSAGES_OUTSIDE_THE_ALLOTTED_TIME_PERIOD = 'CANNOT_SEND_P2P_TEXT_MESSAGES_OUTSIDE_THE_ALLOTTED_TIME_PERIOD',
    CANNOT_SET_DEFAULT_FOR_INACTIVE_PURPOSE = 'CANNOT_SET_DEFAULT_FOR_INACTIVE_PURPOSE',
    CANNOT_SHARE_TURF_ASSIGNMENT_WITH_SELF = 'CANNOT_SHARE_TURF_ASSIGNMENT_WITH_SELF',
    CANNOT_TOGGLE_ALLOW_NON_MAPPED_CONTACTS = 'CANNOT_TOGGLE_ALLOW_NON_MAPPED_CONTACTS',
    CANNOT_TOGGLE_OUTREACH_MODE = 'CANNOT_TOGGLE_OUTREACH_MODE',
    CANNOT_UPDATE_ORGANIZATION_USER = 'CANNOT_UPDATE_ORGANIZATION_USER',
    CANNOT_UPDATE_OUTREACH_CIRCLE_USER = 'CANNOT_UPDATE_OUTREACH_CIRCLE_USER',
    CANNOT_UPDATE_OUTREACH_CIRCLE_USER_EMAIL = 'CANNOT_UPDATE_OUTREACH_CIRCLE_USER_EMAIL',
    CANNOT_UPDATE_QUESTION_LINKED_TO_ACTIVE_SURVEY = 'CANNOT_UPDATE_QUESTION_LINKED_TO_ACTIVE_SURVEY',
    CANNOT_UPDATE_START_DATE_FOR_LIVE_ACTION = 'CANNOT_UPDATE_START_DATE_FOR_LIVE_ACTION',
    CANNOT_UPDATE_STATUS_FOR_DEFAULT_PURPOSE = 'CANNOT_UPDATE_STATUS_FOR_DEFAULT_PURPOSE',
    CANNOT_UPDATE_TAG_DATABASE = 'CANNOT_UPDATE_TAG_DATABASE',
    CANNOT_UPDATE_TAG_NAME = 'CANNOT_UPDATE_TAG_NAME',
    CANT_REMOVE_LAST_OUTREACH_CIRCLE_USER = 'CANT_REMOVE_LAST_OUTREACH_CIRCLE_USER',
    CANT_REMOVE_LOGGED_IN_OUTREACH_CIRCLE_USER = 'CANT_REMOVE_LOGGED_IN_OUTREACH_CIRCLE_USER',
    CARD_ALREADY_EXISTS = 'CARD_ALREADY_EXISTS',
    CONTACT_ALREADY_SUPPORTER = 'CONTACT_ALREADY_SUPPORTER',
    CONTACT_NOT_FOUND = 'CONTACT_NOT_FOUND',
    CONTACT_SOURCE_NOT_FOUND = 'CONTACT_SOURCE_NOT_FOUND',
    CONTACT_WITH_EMAIL_ALREADY_EXISTS = 'CONTACT_WITH_EMAIL_ALREADY_EXISTS',
    CONTACT_WITH_PHONE_NUMBER_ALREADY_EXISTS = 'CONTACT_WITH_PHONE_NUMBER_ALREADY_EXISTS',
    CONVERSATION_IS_ASSIGNED_TO_OTHER_SUPPORTER = 'CONVERSATION_IS_ASSIGNED_TO_OTHER_SUPPORTER',
    COUPON_HAS_EXPIRED = 'COUPON_HAS_EXPIRED',
    COUPON_NOT_APPLICABLE = 'COUPON_NOT_APPLICABLE',
    CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED',
    CREATE_SUBSCRIPTION_FAILED = 'CREATE_SUBSCRIPTION_FAILED',
    CV_TOKEN_ALREADY_IN_USE = 'CV_TOKEN_ALREADY_IN_USE',
    DANGLING_BRANCHES_IN_SURVEY = 'DANGLING_BRANCHES_IN_SURVEY',
    DANGLING_QUESTIONS_ARE_NOT_ALLOWED = 'DANGLING_QUESTIONS_ARE_NOT_ALLOWED',
    DATABASE_NOT_UPDATED_FOR_DEFAULT_TAGS = 'DATABASE_NOT_UPDATED_FOR_DEFAULT_TAGS',
    DATE_CANNOT_BE_NULL_FOR_KEY_DATE = 'DATE_CANNOT_BE_NULL_FOR_KEY_DATE',
    DB_INTEGRATION_CANNOT_BE_CHANGED = 'DB_INTEGRATION_CANNOT_BE_CHANGED',
    DEFAULT_ALIAS_CANNOT_BE_LINKED_TO_GROUP = 'DEFAULT_ALIAS_CANNOT_BE_LINKED_TO_GROUP',
    DEFAULT_TAG_CANNOT_BE_DELETED = 'DEFAULT_TAG_CANNOT_BE_DELETED',
    DELETED_ORGANIZATION_USER_CANNOT_ONBOARD_WITH_OLD_INVITE = 'DELETED_ORGANIZATION_USER_CANNOT_ONBOARD_WITH_OLD_INVITE',
    DELETED_OUTREACH_CIRCLE_USER_CANNOT_ONBOARD_WITH_OLD_INVITE = 'DELETED_OUTREACH_CIRCLE_USER_CANNOT_ONBOARD_WITH_OLD_INVITE',
    DUPLICATES_IN_BLOCK_DOMAINS = 'DUPLICATES_IN_BLOCK_DOMAINS',
    DUPLICATE_POSITIONS_IN_SURVEY = 'DUPLICATE_POSITIONS_IN_SURVEY',
    DUPLICATE_QUESTIONS_IN_SURVEY = 'DUPLICATE_QUESTIONS_IN_SURVEY',
    EMAIL_OR_PHONE_IS_MANDATORY = 'EMAIL_OR_PHONE_IS_MANDATORY',
    EMAIL_OUTREACH_CHANNEL_NOT_FOUND = 'EMAIL_OUTREACH_CHANNEL_NOT_FOUND',
    EMAIL_RECIPIENT_NOT_FOUND = 'EMAIL_RECIPIENT_NOT_FOUND',
    EMAIL_TEMPLATE_IN_ARCHIVED_STATE = 'EMAIL_TEMPLATE_IN_ARCHIVED_STATE',
    EMAIL_TEMPLATE_NAME_CANNOT_BE_EMPTY = 'EMAIL_TEMPLATE_NAME_CANNOT_BE_EMPTY',
    EMAIL_TEMPLATE_NOT_FOUND = 'EMAIL_TEMPLATE_NOT_FOUND',
    EMAIL_TEMPLATE_NOT_IN_READY_STATE = 'EMAIL_TEMPLATE_NOT_IN_READY_STATE',
    EMAIL_TEMPLATE_NOT_LINKED = 'EMAIL_TEMPLATE_NOT_LINKED',
    EMAIL_TEMPLATE_WITH_SAME_ACTION_AND_CODE_EXISTS = 'EMAIL_TEMPLATE_WITH_SAME_ACTION_AND_CODE_EXISTS',
    EMAIL_TEMPLATE_WITH_SAME_NAME_EXISTS = 'EMAIL_TEMPLATE_WITH_SAME_NAME_EXISTS',
    END_DATE_BEFORE_START_DATE = 'END_DATE_BEFORE_START_DATE',
    ENGAGEMENT_NAME_ALREADY_EXIST = 'ENGAGEMENT_NAME_ALREADY_EXIST',
    ENGAGEMENT_NOT_FOUND = 'ENGAGEMENT_NOT_FOUND',
    ENGAGEMENT_OPTION_NOT_FOUND = 'ENGAGEMENT_OPTION_NOT_FOUND',
    ENGAGEMENT_QUESTION_NOT_FOUND = 'ENGAGEMENT_QUESTION_NOT_FOUND',
    ERROR_ALLOWING_ACCESS_TO_ORGANIZATION = 'ERROR_ALLOWING_ACCESS_TO_ORGANIZATION',
    ERROR_SAVING_SURVEY_QUESTION_RESPONSE = 'ERROR_SAVING_SURVEY_QUESTION_RESPONSE',
    EVENT_CONFIG_CANNOT_BE_PUBLISHED = 'EVENT_CONFIG_CANNOT_BE_PUBLISHED',
    EVENT_CONFIG_CANNOT_BE_TERMINATED = 'EVENT_CONFIG_CANNOT_BE_TERMINATED',
    EVENT_CONFIG_CANNOT_BE_UPDATED = 'EVENT_CONFIG_CANNOT_BE_UPDATED',
    EVENT_SERIES_CANNOT_BE_TERMINATED = 'EVENT_SERIES_CANNOT_BE_TERMINATED',
    EVENT_SERIES_CANNOT_BE_UPDATED = 'EVENT_SERIES_CANNOT_BE_UPDATED',
    EVENT_SERIES_NOT_FOUND = 'EVENT_SERIES_NOT_FOUND',
    EXCEEDED_MAX_NUMBER_OF_LISTS_LINKED_TO_SURVEY = 'EXCEEDED_MAX_NUMBER_OF_LISTS_LINKED_TO_SURVEY',
    EXCEEDED_MAX_NUMBER_OF_NON_CONTACT_QUESTION_TYPE = 'EXCEEDED_MAX_NUMBER_OF_NON_CONTACT_QUESTION_TYPE',
    EXPIRED_ACTION_CANNOT_BE_LINKED_TO_GROUP = 'EXPIRED_ACTION_CANNOT_BE_LINKED_TO_GROUP',
    FAILED_TO_FETCH_TAGS_FROM_NATION_BUILDER = 'FAILED_TO_FETCH_TAGS_FROM_NATION_BUILDER',
    FIRST_NAME_ALLOWED_LENGTH_EXCEEDS_LIMIT = 'FIRST_NAME_ALLOWED_LENGTH_EXCEEDS_LIMIT',
    FLAG_ALREADY_EXISTS = 'FLAG_ALREADY_EXISTS',
    GRASS_ROOT_ORGANIZING_ACTION_NOT_ENABLED_FOR_OUTREACH_CIRCLE = 'GRASS_ROOT_ORGANIZING_ACTION_NOT_ENABLED_FOR_OUTREACH_CIRCLE',
    GROUPS_CANNOT_BE_EMPTY = 'GROUPS_CANNOT_BE_EMPTY',
    GROUP_CANNOT_BE_DELETED = 'GROUP_CANNOT_BE_DELETED',
    GROUP_NAME_ALREADY_EXISTS = 'GROUP_NAME_ALREADY_EXISTS',
    GRO_RELATIONAL_ACTION_NOT_ENABLED_FOR_OUTREACH_CIRCLE = 'GRO_RELATIONAL_ACTION_NOT_ENABLED_FOR_OUTREACH_CIRCLE',
    GRO_SURVEY_FORM_NOT_FOUND = 'GRO_SURVEY_FORM_NOT_FOUND',
    GRO_SURVEY_FORM_OPTION_NOT_FOUND = 'GRO_SURVEY_FORM_OPTION_NOT_FOUND',
    GRO_SURVEY_FORM_QUESTION_NOT_FOUND = 'GRO_SURVEY_FORM_QUESTION_NOT_FOUND',
    GRO_SURVEY_FORM_QUESTION_TEXT_LENGTH_EXCEEDED = 'GRO_SURVEY_FORM_QUESTION_TEXT_LENGTH_EXCEEDED',
    GRO_SURVEY_FORM_WITH_SAME_NAME_EXISTS = 'GRO_SURVEY_FORM_WITH_SAME_NAME_EXISTS',
    INACTIVE_OUTREACH_CIRCLE = 'INACTIVE_OUTREACH_CIRCLE',
    INCORRECT_MFA_CODE = 'INCORRECT_MFA_CODE',
    INTEGRATION_NOT_FOUND = 'INTEGRATION_NOT_FOUND',
    INTEGRATION_TYPE_CANNOT_BE_NULL = 'INTEGRATION_TYPE_CANNOT_BE_NULL',
    INVALID_ACQUISITION_TYPE_NAME = 'INVALID_ACQUISITION_TYPE_NAME',
    INVALID_ACTION = 'INVALID_ACTION',
    INVALID_ACTION_CONFIRMATION_TEXT = 'INVALID_ACTION_CONFIRMATION_TEXT',
    INVALID_ACTION_DESCRIPTION = 'INVALID_ACTION_DESCRIPTION',
    INVALID_ACTION_FOR_SMS = 'INVALID_ACTION_FOR_SMS',
    INVALID_ACTION_LINK = 'INVALID_ACTION_LINK',
    INVALID_ACTION_NAME = 'INVALID_ACTION_NAME',
    INVALID_ACTION_POSITION = 'INVALID_ACTION_POSITION',
    INVALID_ACTION_TITLE = 'INVALID_ACTION_TITLE',
    INVALID_ACTION_TYPE = 'INVALID_ACTION_TYPE',
    INVALID_ADD_SURVEY_FOR_ACTION_INPUT = 'INVALID_ADD_SURVEY_FOR_ACTION_INPUT',
    INVALID_ALIAS_DESCRIPTION = 'INVALID_ALIAS_DESCRIPTION',
    INVALID_ALIAS_NAME = 'INVALID_ALIAS_NAME',
    INVALID_ALIAS_OR_NAME = 'INVALID_ALIAS_OR_NAME',
    INVALID_API_TOKEN_FOR_NATION_BUILDER_INTEGRATION = 'INVALID_API_TOKEN_FOR_NATION_BUILDER_INTEGRATION',
    INVALID_APPLY_TO_ALL_CUSTOM_ALIASES_OPTION = 'INVALID_APPLY_TO_ALL_CUSTOM_ALIASES_OPTION',
    INVALID_AREA_CODE = 'INVALID_AREA_CODE',
    INVALID_ASSIGNMENT_GROUP_NAME = 'INVALID_ASSIGNMENT_GROUP_NAME',
    INVALID_AUDIENCE_ACTION = 'INVALID_AUDIENCE_ACTION',
    INVALID_AUDIENCE_ACTION_FILTER = 'INVALID_AUDIENCE_ACTION_FILTER',
    INVALID_AUDIENCE_ACTION_INPUT = 'INVALID_AUDIENCE_ACTION_INPUT',
    INVALID_AUDIENCE_MAPPING_NAME = 'INVALID_AUDIENCE_MAPPING_NAME',
    INVALID_AUTH_TOKEN = 'INVALID_AUTH_TOKEN',
    INVALID_BRAND = 'INVALID_BRAND',
    INVALID_CALL_TO_ACTION_TEXT = 'INVALID_CALL_TO_ACTION_TEXT',
    INVALID_CANCEL_AUTO_RENEW_CONFIRMATION = 'INVALID_CANCEL_AUTO_RENEW_CONFIRMATION',
    INVALID_CANDIDATE_IMAGE = 'INVALID_CANDIDATE_IMAGE',
    INVALID_CANNED_RESPONSE_FOR_TAG = 'INVALID_CANNED_RESPONSE_FOR_TAG',
    INVALID_CATEGORY_INPUT = 'INVALID_CATEGORY_INPUT',
    INVALID_CENSUS_BLOCK_ID = 'INVALID_CENSUS_BLOCK_ID',
    INVALID_CONTACT_FILE = 'INVALID_CONTACT_FILE',
    INVALID_CONTACT_FILE_input = 'INVALID_CONTACT_FILE_input',
    INVALID_COUPON = 'INVALID_COUPON',
    INVALID_CREATE_FLAG_INPUT = 'INVALID_CREATE_FLAG_INPUT',
    INVALID_CREATE_SURVEY_QUESTION_INPUT = 'INVALID_CREATE_SURVEY_QUESTION_INPUT',
    INVALID_CV_TOKEN = 'INVALID_CV_TOKEN',
    INVALID_DATE_FORMAT = 'INVALID_DATE_FORMAT',
    INVALID_DISPLAY_FIELD = 'INVALID_DISPLAY_FIELD',
    INVALID_DOMAIN = 'INVALID_DOMAIN',
    INVALID_EMAIL_ID = 'INVALID_EMAIL_ID',
    INVALID_EMAIL_INPUT = 'INVALID_EMAIL_INPUT',
    INVALID_EMAIL_MESSAGE = 'INVALID_EMAIL_MESSAGE',
    INVALID_END_DATE = 'INVALID_END_DATE',
    INVALID_ENGAGEMENT_INPUT = 'INVALID_ENGAGEMENT_INPUT',
    INVALID_EVENT_CONFIG_NAME = 'INVALID_EVENT_CONFIG_NAME',
    INVALID_EVENT_SCHEDULE = 'INVALID_EVENT_SCHEDULE',
    INVALID_EVERY_ACTION_FORM_ID = 'INVALID_EVERY_ACTION_FORM_ID',
    INVALID_GROUP_ID = 'INVALID_GROUP_ID',
    INVALID_GRO_SURVEY_FORM_QUESTION = 'INVALID_GRO_SURVEY_FORM_QUESTION',
    INVALID_GRO_SURVEY_FORM_QUESTION_TYPE = 'INVALID_GRO_SURVEY_FORM_QUESTION_TYPE',
    INVALID_INDUSTRY_INPUT = 'INVALID_INDUSTRY_INPUT',
    INVALID_INPUT_FOR_CREATE_OUTREACH_CIRCLE_ROLE = 'INVALID_INPUT_FOR_CREATE_OUTREACH_CIRCLE_ROLE',
    INVALID_INPUT_FOR_SAVE_NGP_CONFIGURATION = 'INVALID_INPUT_FOR_SAVE_NGP_CONFIGURATION',
    INVALID_INPUT_FOR_SUPPORTER_ACTIVITY = 'INVALID_INPUT_FOR_SUPPORTER_ACTIVITY',
    INVALID_INPUT_FOR_SUPPORTER_AVAILABILITY = 'INVALID_INPUT_FOR_SUPPORTER_AVAILABILITY',
    INVALID_INPUT_FOR_SUPPORTER_LEVEL = 'INVALID_INPUT_FOR_SUPPORTER_LEVEL',
    INVALID_INPUT_FOR_SUPPORTER_ORGANIZATION = 'INVALID_INPUT_FOR_SUPPORTER_ORGANIZATION',
    INVALID_INPUT_FOR_SUPPORTER_SKILL = 'INVALID_INPUT_FOR_SUPPORTER_SKILL',
    INVALID_INPUT_FOR_SUPPORTER_TEAM = 'INVALID_INPUT_FOR_SUPPORTER_TEAM',
    INVALID_INPUT_FOR_SUPPORTER_ZONE = 'INVALID_INPUT_FOR_SUPPORTER_ZONE',
    INVALID_INPUT_FOR_UPDATE_VOLUNTEER_ATTENDANCE = 'INVALID_INPUT_FOR_UPDATE_VOLUNTEER_ATTENDANCE',
    INVALID_KEY_DATE = 'INVALID_KEY_DATE',
    INVALID_LINK_TEXT = 'INVALID_LINK_TEXT',
    INVALID_LOCATION_DETAILS = 'INVALID_LOCATION_DETAILS',
    INVALID_LOGIN_CODE = 'INVALID_LOGIN_CODE',
    INVALID_MEMBER_ID = 'INVALID_MEMBER_ID',
    INVALID_MERGE_FIELDS_IN_SEGMENT = 'INVALID_MERGE_FIELDS_IN_SEGMENT',
    INVALID_MERGE_FIELDS_IN_TEMPLATE = 'INVALID_MERGE_FIELDS_IN_TEMPLATE',
    INVALID_MERGE_FIELD_IN_PUSH_NOTIFICATION = 'INVALID_MERGE_FIELD_IN_PUSH_NOTIFICATION',
    INVALID_MOBILE_NUMBER = 'INVALID_MOBILE_NUMBER',
    INVALID_NAME_FOR_SUPPORTER_VIEW_FILTER_STATE = 'INVALID_NAME_FOR_SUPPORTER_VIEW_FILTER_STATE',
    INVALID_NAME_FOR_URL = 'INVALID_NAME_FOR_URL',
    INVALID_NAME_INPUT = 'INVALID_NAME_INPUT',
    INVALID_NATION_BUILDER_CREDENTIALS = 'INVALID_NATION_BUILDER_CREDENTIALS',
    INVALID_NEW_PASSWORD = 'INVALID_NEW_PASSWORD',
    INVALID_NGP_ACTIVIST_CODE_ID = 'INVALID_NGP_ACTIVIST_CODE_ID',
    INVALID_NGP_CREDENTIALS = 'INVALID_NGP_CREDENTIALS',
    INVALID_NGP_SAVED_LIST_ID = 'INVALID_NGP_SAVED_LIST_ID',
    INVALID_OAUTH_PROVIDER = 'INVALID_OAUTH_PROVIDER',
    INVALID_OPTIONS_INPUT_FOR_SEARCH_AND_SURVEY_FORM_QUESTION = 'INVALID_OPTIONS_INPUT_FOR_SEARCH_AND_SURVEY_FORM_QUESTION',
    INVALID_OPTIONS_INPUT_FOR_SUPPORTER_FORM_QUESTION = 'INVALID_OPTIONS_INPUT_FOR_SUPPORTER_FORM_QUESTION',
    INVALID_OPTION_SELECTED_FOR_QUESTION = 'INVALID_OPTION_SELECTED_FOR_QUESTION',
    INVALID_OPTOUT_TEXT_IN_SEGMENT = 'INVALID_OPTOUT_TEXT_IN_SEGMENT',
    INVALID_ORGANIZATION_ALIAS_VALUE = 'INVALID_ORGANIZATION_ALIAS_VALUE',
    INVALID_ORGANIZATION_FILTER_NAME = 'INVALID_ORGANIZATION_FILTER_NAME',
    INVALID_ORGANIZATION_NAME = 'INVALID_ORGANIZATION_NAME',
    INVALID_ORIGIN = 'INVALID_ORIGIN',
    INVALID_OUTREACH_CHANNEL = 'INVALID_OUTREACH_CHANNEL',
    INVALID_OUTREACH_CHANNEL_TEMPLATE_TEXT = 'INVALID_OUTREACH_CHANNEL_TEMPLATE_TEXT',
    INVALID_OUTREACH_CIRCLE_LOGO = 'INVALID_OUTREACH_CIRCLE_LOGO',
    INVALID_OUTREACH_CIRCLE_USER = 'INVALID_OUTREACH_CIRCLE_USER',
    INVALID_OUTREACH_CIRCLE_USER_EMAIL_TEMPLATE = 'INVALID_OUTREACH_CIRCLE_USER_EMAIL_TEMPLATE',
    INVALID_OUTREACH_CIRLCE_LOCATION = 'INVALID_OUTREACH_CIRLCE_LOCATION',
    INVALID_P2P_SCRIPT_SURVEY_QUESTION_INPUT = 'INVALID_P2P_SCRIPT_SURVEY_QUESTION_INPUT',
    INVALID_P2P_TEXT_CONTENT = 'INVALID_P2P_TEXT_CONTENT',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    INVALID_PASSWORD_RESET_CODE = 'INVALID_PASSWORD_RESET_CODE',
    INVALID_PDI_CREDENTIALS = 'INVALID_PDI_CREDENTIALS',
    INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
    INVALID_PLAN = 'INVALID_PLAN',
    INVALID_POST_ACTION_TEXT = 'INVALID_POST_ACTION_TEXT',
    INVALID_PRECINCT_ID = 'INVALID_PRECINCT_ID',
    INVALID_PUSH_NOTIFICATION_DESCRIPTION = 'INVALID_PUSH_NOTIFICATION_DESCRIPTION',
    INVALID_PUSH_NOTIFICATION_REGISTRATION_TOKEN = 'INVALID_PUSH_NOTIFICATION_REGISTRATION_TOKEN',
    INVALID_PUSH_NOTIFICATION_TEMPLATE_BODY = 'INVALID_PUSH_NOTIFICATION_TEMPLATE_BODY',
    INVALID_PUSH_NOTIFICATION_TEMPLATE_TITLE = 'INVALID_PUSH_NOTIFICATION_TEMPLATE_TITLE',
    INVALID_PUSH_NOTIFICATION_TITLE = 'INVALID_PUSH_NOTIFICATION_TITLE',
    INVALID_QUESTION_TYPE_FOR_GRO_SURVEY_FORM_QUESTION_INTEGRATION = 'INVALID_QUESTION_TYPE_FOR_GRO_SURVEY_FORM_QUESTION_INTEGRATION',
    INVALID_QUESTION_TYPE_FOR_SEARCH_AND_SURVEY_FORM_QUESTION_INTEGRATION = 'INVALID_QUESTION_TYPE_FOR_SEARCH_AND_SURVEY_FORM_QUESTION_INTEGRATION',
    INVALID_QUESTION_TYPE_FOR_SUPPORTER_FORM_QUESTION_INTEGRATION = 'INVALID_QUESTION_TYPE_FOR_SUPPORTER_FORM_QUESTION_INTEGRATION',
    INVALID_RESPONSE_FOR_MANDATORY_QUESTION = 'INVALID_RESPONSE_FOR_MANDATORY_QUESTION',
    INVALID_SEARCH_AND_SURVEY_FORM_QUESTION = 'INVALID_SEARCH_AND_SURVEY_FORM_QUESTION',
    INVALID_SEARCH_AND_SURVEY_FORM_QUESTION_TYPE = 'INVALID_SEARCH_AND_SURVEY_FORM_QUESTION_TYPE',
    INVALID_SEARCH_AND_SURVEY_LIST_NAME = 'INVALID_SEARCH_AND_SURVEY_LIST_NAME',
    INVALID_SEARCH_AND_SURVEY_NEW_CONTACTS_AUDIENCE_ACTION_ID = 'INVALID_SEARCH_AND_SURVEY_NEW_CONTACTS_AUDIENCE_ACTION_ID',
    INVALID_SEARCH_KEY = 'INVALID_SEARCH_KEY',
    INVALID_SHARE_BUTTON_TEXT = 'INVALID_SHARE_BUTTON_TEXT',
    INVALID_SLUG_FOR_NATION_BUILDER_INTEGRATION = 'INVALID_SLUG_FOR_NATION_BUILDER_INTEGRATION',
    INVALID_SOCIAL_TEMPLATE_IMAGE = 'INVALID_SOCIAL_TEMPLATE_IMAGE',
    INVALID_SOQL = 'INVALID_SOQL',
    INVALID_SOQL_SCHEDULE = 'INVALID_SOQL_SCHEDULE',
    INVALID_SOURCE_ALIAS = 'INVALID_SOURCE_ALIAS',
    INVALID_SOURCING_LINK = 'INVALID_SOURCING_LINK',
    INVALID_SUPPORTER_FORM_QUESTION = 'INVALID_SUPPORTER_FORM_QUESTION',
    INVALID_SUPPORTER_FORM_QUESTION_TYPE = 'INVALID_SUPPORTER_FORM_QUESTION_TYPE',
    INVALID_SURVEY_RESPONSE_INPUT = 'INVALID_SURVEY_RESPONSE_INPUT',
    INVALID_SURVEY_TEXT_RECIPIENT = 'INVALID_SURVEY_TEXT_RECIPIENT',
    INVALID_TAG_APPLICATION = 'INVALID_TAG_APPLICATION',
    INVALID_TAG_INPUT = 'INVALID_TAG_INPUT',
    INVALID_TAG_NAME = 'INVALID_TAG_NAME',
    INVALID_TCR_CURRENT_STATUS = 'INVALID_TCR_CURRENT_STATUS',
    INVALID_TCR_INPUT = 'INVALID_TCR_INPUT',
    INVALID_TEXTING_SCRIPT = 'INVALID_TEXTING_SCRIPT',
    INVALID_TEXT_SUPPORTER_SET = 'INVALID_TEXT_SUPPORTER_SET',
    INVALID_TURFING_STRATEGY = 'INVALID_TURFING_STRATEGY',
    INVALID_TURF_ID = 'INVALID_TURF_ID',
    INVALID_UPDATE_FLAG_INPUT = 'INVALID_UPDATE_FLAG_INPUT',
    INVALID_UPDATE_SURVEY_FOR_ACTION_INPUT = 'INVALID_UPDATE_SURVEY_FOR_ACTION_INPUT',
    INVALID_UPDATE_USER_INPUT = 'INVALID_UPDATE_USER_INPUT',
    INVALID_USERNAME = 'INVALID_USERNAME',
    INVALID_VERIFICATION_CODE = 'INVALID_VERIFICATION_CODE',
    INVALID_ZIP_CODE = 'INVALID_ZIP_CODE',
    INVITED_SUPPORTERS_ALREADY_IMPORTED = 'INVITED_SUPPORTERS_ALREADY_IMPORTED',
    INVITED_SUPPORTERS_IMPORT_IN_PROGRESS = 'INVITED_SUPPORTERS_IMPORT_IN_PROGRESS',
    INVITED_SUPPORTER_INFORMATION_NOT_FOUND = 'INVITED_SUPPORTER_INFORMATION_NOT_FOUND',
    INVITED_SUPPORTER_SOURCE_NOT_FOUND = 'INVITED_SUPPORTER_SOURCE_NOT_FOUND',
    INVITED_USER_CONSENT = 'INVITED_USER_CONSENT',
    INVITE_ALREADY_EXISTS = 'INVITE_ALREADY_EXISTS',
    LABEL_EMPTY_FOR_CUSTOM_TAG_ERROR = 'LABEL_EMPTY_FOR_CUSTOM_TAG_ERROR',
    LABEL_EMPTY_FOR_TAG_WITH_PDI_INTEGRATION = 'LABEL_EMPTY_FOR_TAG_WITH_PDI_INTEGRATION',
    LAST_NAME_ALLOWED_LENGTH_EXCEEDS_LIMIT = 'LAST_NAME_ALLOWED_LENGTH_EXCEEDS_LIMIT',
    MAPPED_CONTACT_NOT_FOUND = 'MAPPED_CONTACT_NOT_FOUND',
    MASTER_ELECTION_CYCLE_NOT_FOUND = 'MASTER_ELECTION_CYCLE_NOT_FOUND',
    MATCHING_JOB_IN_PROGRESS = 'MATCHING_JOB_IN_PROGRESS',
    MATCHING_JOB_NOT_FOUND = 'MATCHING_JOB_NOT_FOUND',
    MAXIMUM_ATTACHMENTS_FOR_TEXTING_SCRIPT_EXCEEDED = 'MAXIMUM_ATTACHMENTS_FOR_TEXTING_SCRIPT_EXCEEDED',
    MAXIMUM_DATE_RANGE_EXCEEDED_FOR_P2P_ACTION = 'MAXIMUM_DATE_RANGE_EXCEEDED_FOR_P2P_ACTION',
    MAXIMUM_FILE_SIZE_FOR_PUSH_NOTIFICATION_EXCEEDED = 'MAXIMUM_FILE_SIZE_FOR_PUSH_NOTIFICATION_EXCEEDED',
    MAXIMUM_FILE_SIZE_FOR_TEXTING_SCRIPT_EXCEEDED = 'MAXIMUM_FILE_SIZE_FOR_TEXTING_SCRIPT_EXCEEDED',
    MFA_LOGIN_REQUIRED = 'MFA_LOGIN_REQUIRED',
    MFA_SETUP_REQUIRED = 'MFA_SETUP_REQUIRED',
    MISSING_PLAN_INPUT = 'MISSING_PLAN_INPUT',
    MULTIPLE_SELECTIONS_NOT_ALLOWED = 'MULTIPLE_SELECTIONS_NOT_ALLOWED',
    NAME_CANNOT_BE_EMPTY_FOR_KEY_DATE = 'NAME_CANNOT_BE_EMPTY_FOR_KEY_DATE',
    NAME_CANNOT_BE_UPDATED_FOR_SYSTEM_ADDED_KEY_DATE = 'NAME_CANNOT_BE_UPDATED_FOR_SYSTEM_ADDED_KEY_DATE',
    NATION_BUILDER_INTEGRATION_NOT_FOUND = 'NATION_BUILDER_INTEGRATION_NOT_FOUND',
    NGP_CREDENTIALS_NOT_FOUND = 'NGP_CREDENTIALS_NOT_FOUND',
    NGP_MY_CAMPAIGN_INTEGRATION_NOT_FOUND = 'NGP_MY_CAMPAIGN_INTEGRATION_NOT_FOUND',
    NGP_SURVEY_NOT_FOUND = 'NGP_SURVEY_NOT_FOUND',
    NOT_ABLE_TO_DELETE_CONTACTS = 'NOT_ABLE_TO_DELETE_CONTACTS',
    NOT_ABLE_TO_DELETE_P2P_SCRIPT_SURVEY_QUESTION_OPTION_WITH_CANNED_RESPONSE = 'NOT_ABLE_TO_DELETE_P2P_SCRIPT_SURVEY_QUESTION_OPTION_WITH_CANNED_RESPONSE',
    NOT_ABLE_TO_DELETE_P2P_SCRIPT_SURVEY_QUESTION_WITH_CANNED_RESPONSE = 'NOT_ABLE_TO_DELETE_P2P_SCRIPT_SURVEY_QUESTION_WITH_CANNED_RESPONSE',
    NOT_ABLE_TO_FETCH_CONTACT_SOURCES = 'NOT_ABLE_TO_FETCH_CONTACT_SOURCES',
    NOT_ABLE_TO_IMPORT_FILE = 'NOT_ABLE_TO_IMPORT_FILE',
    NOT_ABLE_TO_REQUEST_REPORT = 'NOT_ABLE_TO_REQUEST_REPORT',
    NOT_ABLE_UPDATE_ACTION_TO_EXPIRED = 'NOT_ABLE_UPDATE_ACTION_TO_EXPIRED',
    NOT_ABLE_UPDATE_ACTION_TO_LIVE = 'NOT_ABLE_UPDATE_ACTION_TO_LIVE',
    NOT_AUTHORIZED_DELETE_CONTACT_SOURCE = 'NOT_AUTHORIZED_DELETE_CONTACT_SOURCE',
    NO_CALL_TO_ACTION_IN_OUTREACH_MODE = 'NO_CALL_TO_ACTION_IN_OUTREACH_MODE',
    NO_CARD_ADDED = 'NO_CARD_ADDED',
    NO_CONTACT_FOUND = 'NO_CONTACT_FOUND',
    NO_ELIGIBLE_SUPPORTERS_AVAILABLE_FOR_NOTIFICATION = 'NO_ELIGIBLE_SUPPORTERS_AVAILABLE_FOR_NOTIFICATION',
    NO_ELIGIBLE_SUPPORTERS_FOR_PUSH_NOTIFICATION = 'NO_ELIGIBLE_SUPPORTERS_FOR_PUSH_NOTIFICATION',
    NO_ELIGIBLE_SUPPORTERS_FOUND = 'NO_ELIGIBLE_SUPPORTERS_FOUND',
    NO_EMAIL_FROM_OAUTH_PROVIDER = 'NO_EMAIL_FROM_OAUTH_PROVIDER',
    NO_EMAIL_RECIPIENTS_FOUND = 'NO_EMAIL_RECIPIENTS_FOUND',
    NO_EMAIL_TEMPLATE_LINKED_TO_ACTION = 'NO_EMAIL_TEMPLATE_LINKED_TO_ACTION',
    NO_LINKED_PDI_ORGANIZATION_FOUND = 'NO_LINKED_PDI_ORGANIZATION_FOUND',
    NO_MEMBER_FOUND_FOR_CALLING = 'NO_MEMBER_FOUND_FOR_CALLING',
    NO_OUTREACH_CHANNEL_IS_SELECTED_FOR_SHARING_ACTION = 'NO_OUTREACH_CHANNEL_IS_SELECTED_FOR_SHARING_ACTION',
    NO_P2P_RECIPIENTS_AVAILABLE_RIGHT_NOW_TO_SEND_INITIAL_TEXT = 'NO_P2P_RECIPIENTS_AVAILABLE_RIGHT_NOW_TO_SEND_INITIAL_TEXT',
    NO_PERSONAL_EMAIL_FOUND_TO_SEND_COUPON_CODE = 'NO_PERSONAL_EMAIL_FOUND_TO_SEND_COUPON_CODE',
    NO_PHONE_NUMBER_AVAILABLE_FOR_CALLING = 'NO_PHONE_NUMBER_AVAILABLE_FOR_CALLING',
    NO_PHONE_NUMBER_FOUND_FOR_AREA_CODE = 'NO_PHONE_NUMBER_FOUND_FOR_AREA_CODE',
    NO_PHONE_NUMBER_TO_SEND_P2P_TEXT = 'NO_PHONE_NUMBER_TO_SEND_P2P_TEXT',
    NO_SUPPORTER_CONTACT_FOUND = 'NO_SUPPORTER_CONTACT_FOUND',
    NO_SUPPORTER_ELIGIBLE_TO_SEND_REMINDER = 'NO_SUPPORTER_ELIGIBLE_TO_SEND_REMINDER',
    NO_SURVEY_LINKED_TO_ACTION = 'NO_SURVEY_LINKED_TO_ACTION',
    NO_TEXT_TEMPLATE_LINKED_TO_ACTION = 'NO_TEXT_TEMPLATE_LINKED_TO_ACTION',
    NO_UNASSIGNED_TURF_IN_TURF_GROUP = 'NO_UNASSIGNED_TURF_IN_TURF_GROUP',
    ORGANIZATION_ACTION_CANNOT_BE_PUBLISHED = 'ORGANIZATION_ACTION_CANNOT_BE_PUBLISHED',
    ORGANIZATION_ADMIN_INVITE_ALREADY_EXISTS = 'ORGANIZATION_ADMIN_INVITE_ALREADY_EXISTS',
    ORGANIZATION_ALIAS_ALREADY_EXIST = 'ORGANIZATION_ALIAS_ALREADY_EXIST',
    ORGANIZATION_ALIAS_DOES_NOT_EXIST = 'ORGANIZATION_ALIAS_DOES_NOT_EXIST',
    ORGANIZATION_FILTER_NOT_FOUND = 'ORGANIZATION_FILTER_NOT_FOUND',
    ORGANIZATION_FILTER_WITH_SAME_NAME_EXISTS = 'ORGANIZATION_FILTER_WITH_SAME_NAME_EXISTS',
    ORGANIZATION_LOGO_DOES_NOT_EXIST = 'ORGANIZATION_LOGO_DOES_NOT_EXIST',
    ORGANIZATION_NOT_FOUND = 'ORGANIZATION_NOT_FOUND',
    ORGANIZATION_USER_ALREADY_ONBOARDED = 'ORGANIZATION_USER_ALREADY_ONBOARDED',
    ORGANIZER_NOT_FOUND = 'ORGANIZER_NOT_FOUND',
    OUTREACH_CHANNEL_DETAIL_IS_REQUIRED = 'OUTREACH_CHANNEL_DETAIL_IS_REQUIRED',
    OUTREACH_CHANNEL_NOT_ADDED = 'OUTREACH_CHANNEL_NOT_ADDED',
    OUTREACH_CIRCLES_CANNOT_BE_EMPTY = 'OUTREACH_CIRCLES_CANNOT_BE_EMPTY',
    OUTREACH_CIRCLES_MISMATCH = 'OUTREACH_CIRCLES_MISMATCH',
    OUTREACH_CIRCLE_ALREADY_ARCHIVED = 'OUTREACH_CIRCLE_ALREADY_ARCHIVED',
    OUTREACH_CIRCLE_ARCHIVED = 'OUTREACH_CIRCLE_ARCHIVED',
    OUTREACH_CIRCLE_DEFAULT_ALIAS_LOGO_DOES_NOT_EXIST = 'OUTREACH_CIRCLE_DEFAULT_ALIAS_LOGO_DOES_NOT_EXIST',
    OUTREACH_CIRCLE_DEFAULT_ALIAS_LOGO_EXISTS = 'OUTREACH_CIRCLE_DEFAULT_ALIAS_LOGO_EXISTS',
    OUTREACH_CIRCLE_EMAIL_ALIAS_EXISTS = 'OUTREACH_CIRCLE_EMAIL_ALIAS_EXISTS',
    OUTREACH_CIRCLE_IS_NOT_PRESENT_IN_ORGANIZATION = 'OUTREACH_CIRCLE_IS_NOT_PRESENT_IN_ORGANIZATION',
    OUTREACH_CIRCLE_LOCATION_NOT_FOUND = 'OUTREACH_CIRCLE_LOCATION_NOT_FOUND',
    OUTREACH_CIRCLE_LOCATION_WITH_SAME_NAME_EXISTS = 'OUTREACH_CIRCLE_LOCATION_WITH_SAME_NAME_EXISTS',
    OUTREACH_CIRCLE_NOT_FOUND = 'OUTREACH_CIRCLE_NOT_FOUND',
    OUTREACH_CIRCLE_ROLE_WITH_SAME_NAME_ALREADY_EXISTS = 'OUTREACH_CIRCLE_ROLE_WITH_SAME_NAME_ALREADY_EXISTS',
    OUTREACH_CIRCLE_USER_ALREADY_EXISTS = 'OUTREACH_CIRCLE_USER_ALREADY_EXISTS',
    OUTREACH_CIRCLE_USER_ALREADY_ONBOARDED = 'OUTREACH_CIRCLE_USER_ALREADY_ONBOARDED',
    OUTREACH_CIRCLE_USER_DOES_NOT_EXIST = 'OUTREACH_CIRCLE_USER_DOES_NOT_EXIST',
    OUTREACH_CIRCLE_USER_EMAIL_TEMPALTE_ALREADY_EXISTS = 'OUTREACH_CIRCLE_USER_EMAIL_TEMPALTE_ALREADY_EXISTS',
    P2P_ACTION_IS_PAUSED = 'P2P_ACTION_IS_PAUSED',
    P2P_ACTION_NOT_ENABLED_FOR_OUTREACH_CIRCLE = 'P2P_ACTION_NOT_ENABLED_FOR_OUTREACH_CIRCLE',
    P2P_CANNED_RESPONSE_CANNOT_HAVE_PUBLIC_SHORT_LINK = 'P2P_CANNED_RESPONSE_CANNOT_HAVE_PUBLIC_SHORT_LINK',
    P2P_CONTACT_FILE_LIMIT_EXCEEDED = 'P2P_CONTACT_FILE_LIMIT_EXCEEDED',
    P2P_CONVERSATION_UNSUBSCRIBED = 'P2P_CONVERSATION_UNSUBSCRIBED',
    P2P_INITIAL_TEXT_CAN_NOT_HAVE_URL = 'P2P_INITIAL_TEXT_CAN_NOT_HAVE_URL',
    P2P_MAXIMUM_PHONE_NUMBER_CAMPAIGN_REGISTRATION_LIMIT_REACHED = 'P2P_MAXIMUM_PHONE_NUMBER_CAMPAIGN_REGISTRATION_LIMIT_REACHED',
    P2P_REPLIES_ALREADY_REASSIGNED = 'P2P_REPLIES_ALREADY_REASSIGNED',
    P2P_REPLIES_CANNOT_BE_REASSIGNED = 'P2P_REPLIES_CANNOT_BE_REASSIGNED',
    P2P_REPLY_REASSIGNMENT_NOT_FOUND = 'P2P_REPLY_REASSIGNMENT_NOT_FOUND',
    P2P_SCRIPT_HAS_INVALID_MEDIA = 'P2P_SCRIPT_HAS_INVALID_MEDIA',
    P2P_SCRIPT_HAS_SURVEY_QUESTION_FROM_INVALID_OUTREACH_CIRCLE = 'P2P_SCRIPT_HAS_SURVEY_QUESTION_FROM_INVALID_OUTREACH_CIRCLE',
    P2P_SCRIPT_INVALID_OPTOUT_TEXT = 'P2P_SCRIPT_INVALID_OPTOUT_TEXT',
    P2P_SCRIPT_MISSING = 'P2P_SCRIPT_MISSING',
    P2P_SCRIPT_SURVEY_QUESTION_HAS_INVALID_NAME = 'P2P_SCRIPT_SURVEY_QUESTION_HAS_INVALID_NAME',
    P2P_SCRIPT_SURVEY_QUESTION_HAS_INVALID_QUESTION_TEXT = 'P2P_SCRIPT_SURVEY_QUESTION_HAS_INVALID_QUESTION_TEXT',
    P2P_SCRIPT_SURVEY_QUESTION_NAME_ALREADY_EXISTS = 'P2P_SCRIPT_SURVEY_QUESTION_NAME_ALREADY_EXISTS',
    P2P_SCRIPT_SURVEY_QUESTION_NOT_FOUND = 'P2P_SCRIPT_SURVEY_QUESTION_NOT_FOUND',
    P2P_SCRIPT_SURVEY_QUESTION_OPTION_HAS_INVALID_OPTION_TEXT = 'P2P_SCRIPT_SURVEY_QUESTION_OPTION_HAS_INVALID_OPTION_TEXT',
    P2P_WORKSPACE_HAS_INVALID_LINKED_NEXT_P2P_SCRIPT = 'P2P_WORKSPACE_HAS_INVALID_LINKED_NEXT_P2P_SCRIPT',
    P2P_WORKSPACE_HAS_INVALID_LINKED_SURVEY_QUESTION_OPTION = 'P2P_WORKSPACE_HAS_INVALID_LINKED_SURVEY_QUESTION_OPTION',
    P2P_WORKSPACE_HAS_INVALID_NAME = 'P2P_WORKSPACE_HAS_INVALID_NAME',
    P2P_WORKSPACE_INVALID_INITIAL_SCRIPT = 'P2P_WORKSPACE_INVALID_INITIAL_SCRIPT',
    P2P_WORKSPACE_INVALID_INITIAL_SCRIPT_MESSAGE = 'P2P_WORKSPACE_INVALID_INITIAL_SCRIPT_MESSAGE',
    P2P_WORKSPACE_NAME_ALREADY_EXISTS = 'P2P_WORKSPACE_NAME_ALREADY_EXISTS',
    P2P_WORKSPACE_NOT_FOUND = 'P2P_WORKSPACE_NOT_FOUND',
    P2P_WORKSPACE_NOT_LINKED_TO_ACTION = 'P2P_WORKSPACE_NOT_LINKED_TO_ACTION',
    P2P_WORKSPACE_TAG_HAS_INVALID_CANNED_RESPONSE_P2P_SCRIPT = 'P2P_WORKSPACE_TAG_HAS_INVALID_CANNED_RESPONSE_P2P_SCRIPT',
    P2P_WORKSPACE_TAG_HAS_INVALID_POSITION = 'P2P_WORKSPACE_TAG_HAS_INVALID_POSITION',
    PDI_INTEGRATION_NOT_FOUND = 'PDI_INTEGRATION_NOT_FOUND',
    PDI_LIST_IMPORT_ALREADY_IN_PROGRESS = 'PDI_LIST_IMPORT_ALREADY_IN_PROGRESS',
    PDI_LIST_IMPORT_NOT_ALLOWED = 'PDI_LIST_IMPORT_NOT_ALLOWED',
    PDI_LIST_NOT_FOUND = 'PDI_LIST_NOT_FOUND',
    PDI_QUESTION_NOT_FOUND = 'PDI_QUESTION_NOT_FOUND',
    PENDING_SEND_EMAIL_JOB_EXISTS = 'PENDING_SEND_EMAIL_JOB_EXISTS',
    PHONE_NUMBER_ALREADY_PURCHASED_FOR_CALLING = 'PHONE_NUMBER_ALREADY_PURCHASED_FOR_CALLING',
    PUSH_NOTIFICATION_TEMPLATE_DOES_NOT_EXIST = 'PUSH_NOTIFICATION_TEMPLATE_DOES_NOT_EXIST',
    PUSH_NOTIFICATION_TEMPLATE_EXISTS_FOR_ACTION = 'PUSH_NOTIFICATION_TEMPLATE_EXISTS_FOR_ACTION',
    RAPID_ONBOARDING_NOT_ALLOWED = 'RAPID_ONBOARDING_NOT_ALLOWED',
    REMOVE_CARD_FAILED = 'REMOVE_CARD_FAILED',
    REPORT_NOT_FOUND = 'REPORT_NOT_FOUND',
    RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED',
    RESPONSE_LOGIC_CANNOT_BE_BLANK_FOR_OPTIONS = 'RESPONSE_LOGIC_CANNOT_BE_BLANK_FOR_OPTIONS',
    SEARCH_AND_SURVEY_FORM_NOT_FOUND = 'SEARCH_AND_SURVEY_FORM_NOT_FOUND',
    SEARCH_AND_SURVEY_FORM_OPTION_NOT_FOUND = 'SEARCH_AND_SURVEY_FORM_OPTION_NOT_FOUND',
    SEARCH_AND_SURVEY_FORM_QUESTION_NOT_FOUND = 'SEARCH_AND_SURVEY_FORM_QUESTION_NOT_FOUND',
    SEARCH_AND_SURVEY_FORM_QUESTION_TEXT_LENGTH_EXCEEDED = 'SEARCH_AND_SURVEY_FORM_QUESTION_TEXT_LENGTH_EXCEEDED',
    SEARCH_AND_SURVEY_FORM_WITH_SAME_NAME_EXISTS = 'SEARCH_AND_SURVEY_FORM_WITH_SAME_NAME_EXISTS',
    SEARCH_EXCEEDS_MAXIMUM_RESULTS_WITH_INCOMPLETE_DATA = 'SEARCH_EXCEEDS_MAXIMUM_RESULTS_WITH_INCOMPLETE_DATA',
    SHIFT_DO_NOT_BELONG_TO_ACTION = 'SHIFT_DO_NOT_BELONG_TO_ACTION',
    SHIFT_NOT_FOUND = 'SHIFT_NOT_FOUND',
    SHIFT_SUPPORTER_ASSIGNMENT_NOT_FOUND = 'SHIFT_SUPPORTER_ASSIGNMENT_NOT_FOUND',
    SHIFT_TIME_IS_OUTSIDE_EVENT_TIME = 'SHIFT_TIME_IS_OUTSIDE_EVENT_TIME',
    SOCIAL_TEMPLATE_DOES_NOT_EXIST = 'SOCIAL_TEMPLATE_DOES_NOT_EXIST',
    SOCIAL_TEMPLATE_IMAGE_ALREADY_EXISTS = 'SOCIAL_TEMPLATE_IMAGE_ALREADY_EXISTS',
    SOCIAL_TEMPLATE_IN_ARCHIVED_STATE = 'SOCIAL_TEMPLATE_IN_ARCHIVED_STATE',
    SOCIAL_TEMPLATE_NOT_FOUND = 'SOCIAL_TEMPLATE_NOT_FOUND',
    SOCIAL_TEMPLATE_NOT_IN_READY_STATE = 'SOCIAL_TEMPLATE_NOT_IN_READY_STATE',
    SOCIAL_TEMPLATE_NOT_LINKED = 'SOCIAL_TEMPLATE_NOT_LINKED',
    SOCIAL_TEMPLATE_WITH_SAME_NAME_EXISTS = 'SOCIAL_TEMPLATE_WITH_SAME_NAME_EXISTS',
    SOURCING_LINK_NAME_TOO_LONG = 'SOURCING_LINK_NAME_TOO_LONG',
    SOURCING_LINK_WITH_SAME_NAME_EXISTS = 'SOURCING_LINK_WITH_SAME_NAME_EXISTS',
    SPECIAL_ACTION_NOT_ENABLED = 'SPECIAL_ACTION_NOT_ENABLED',
    SUPPORTER_ACTIVITY_ALREADY_EXISTS = 'SUPPORTER_ACTIVITY_ALREADY_EXISTS',
    SUPPORTER_ALREADY_EXISTS = 'SUPPORTER_ALREADY_EXISTS',
    SUPPORTER_AVAILABILTY_ALREADY_EXISTS = 'SUPPORTER_AVAILABILTY_ALREADY_EXISTS',
    SUPPORTER_CANNOT_BE_EMPTY = 'SUPPORTER_CANNOT_BE_EMPTY',
    SUPPORTER_EMAIL_IS_SUPPRESSED = 'SUPPORTER_EMAIL_IS_SUPPRESSED',
    SUPPORTER_EMAIL_MAPPING_IS_MANDATORY = 'SUPPORTER_EMAIL_MAPPING_IS_MANDATORY',
    SUPPORTER_FORM_NOT_FOUND = 'SUPPORTER_FORM_NOT_FOUND',
    SUPPORTER_FORM_OPTION_NOT_FOUND = 'SUPPORTER_FORM_OPTION_NOT_FOUND',
    SUPPORTER_FORM_QUESTION_NOT_FOUND = 'SUPPORTER_FORM_QUESTION_NOT_FOUND',
    SUPPORTER_FORM_QUESTION_TEXT_LENGTH_EXCEEDED = 'SUPPORTER_FORM_QUESTION_TEXT_LENGTH_EXCEEDED',
    SUPPORTER_FORM_WITH_SAME_NAME_EXISTS = 'SUPPORTER_FORM_WITH_SAME_NAME_EXISTS',
    SUPPORTER_ID_MAPPING_IS_MANDATORY = 'SUPPORTER_ID_MAPPING_IS_MANDATORY',
    SUPPORTER_IS_BLOCKED_FOR_OUTREACH_CIRCLE = 'SUPPORTER_IS_BLOCKED_FOR_OUTREACH_CIRCLE',
    SUPPORTER_LEVEL_ALREADY_EXISTS = 'SUPPORTER_LEVEL_ALREADY_EXISTS',
    SUPPORTER_LEVEL_NOT_FOUND = 'SUPPORTER_LEVEL_NOT_FOUND',
    SUPPORTER_NOT_FOUND = 'SUPPORTER_NOT_FOUND',
    SUPPORTER_ORGANIZATION_ALREADY_EXISTS = 'SUPPORTER_ORGANIZATION_ALREADY_EXISTS',
    SUPPORTER_SKILL_ALREADY_EXISTS = 'SUPPORTER_SKILL_ALREADY_EXISTS',
    SUPPORTER_TEAM_ALREADY_EXISTS = 'SUPPORTER_TEAM_ALREADY_EXISTS',
    SUPPORTER_VIEW_FILTER_STATE_ALREADY_EXISTS = 'SUPPORTER_VIEW_FILTER_STATE_ALREADY_EXISTS',
    SUPPORTER_ZONE_ALREADY_EXISTS = 'SUPPORTER_ZONE_ALREADY_EXISTS',
    SUPPORTER_ZONE_NOT_FOUND = 'SUPPORTER_ZONE_NOT_FOUND',
    SURVEY_DOES_NOT_EXISTS = 'SURVEY_DOES_NOT_EXISTS',
    SURVEY_HAS_NO_SEGMENTS = 'SURVEY_HAS_NO_SEGMENTS',
    SURVEY_NAME_ALREADY_EXISTS = 'SURVEY_NAME_ALREADY_EXISTS',
    SURVEY_NAME_IS_REQUIRED = 'SURVEY_NAME_IS_REQUIRED',
    SURVEY_NOT_FOUND = 'SURVEY_NOT_FOUND',
    SURVEY_QUESTION_DOES_NOT_EXISTS = 'SURVEY_QUESTION_DOES_NOT_EXISTS',
    SUSPICIOUS_MATCHING_FOUND = 'SUSPICIOUS_MATCHING_FOUND',
    TAG_ALREADY_LINKED_TO_ACTION = 'TAG_ALREADY_LINKED_TO_ACTION',
    TAG_LINKED_TO_LIVE_ACTION_CANNOT_BE_DELETED = 'TAG_LINKED_TO_LIVE_ACTION_CANNOT_BE_DELETED',
    TAG_MAXIMUM_LIMIT_REACHED = 'TAG_MAXIMUM_LIMIT_REACHED',
    TAG_NOT_FOUND = 'TAG_NOT_FOUND',
    TAG_NOT_LINKED_TO_ACTION = 'TAG_NOT_LINKED_TO_ACTION',
    TAG_WITH_SAME_NAME_EXISTS = 'TAG_WITH_SAME_NAME_EXISTS',
    TEXTING_ACCOUNT_ALREADY_EXISTS = 'TEXTING_ACCOUNT_ALREADY_EXISTS',
    TEXTING_ACCOUNT_NOT_FOUND = 'TEXTING_ACCOUNT_NOT_FOUND',
    TEXTING_SCRIPT_MISSING = 'TEXTING_SCRIPT_MISSING',
    TEXTING_SCRIPT_UPDATE_DISABLED = 'TEXTING_SCRIPT_UPDATE_DISABLED',
    TEXT_NOT_SENT = 'TEXT_NOT_SENT',
    TEXT_OUTREACH_CHANNEL_NOT_FOUND = 'TEXT_OUTREACH_CHANNEL_NOT_FOUND',
    TEXT_RECIPIENT_NOT_FOUND = 'TEXT_RECIPIENT_NOT_FOUND',
    TEXT_SUPPORTER_SET_NOT_FOUND = 'TEXT_SUPPORTER_SET_NOT_FOUND',
    TEXT_TEMPLATE_CONTENT_CANNOT_BE_EMPTY = 'TEXT_TEMPLATE_CONTENT_CANNOT_BE_EMPTY',
    TEXT_TEMPLATE_IN_ARCHIVED_STATE = 'TEXT_TEMPLATE_IN_ARCHIVED_STATE',
    TEXT_TEMPLATE_NAME_CANNOT_BE_EMPTY = 'TEXT_TEMPLATE_NAME_CANNOT_BE_EMPTY',
    TEXT_TEMPLATE_NOT_FOUND = 'TEXT_TEMPLATE_NOT_FOUND',
    TEXT_TEMPLATE_NOT_IN_READY_STATE = 'TEXT_TEMPLATE_NOT_IN_READY_STATE',
    TEXT_TEMPLATE_NOT_LINKED = 'TEXT_TEMPLATE_NOT_LINKED',
    TEXT_TEMPLATE_WITH_SAME_NAME_EXISTS = 'TEXT_TEMPLATE_WITH_SAME_NAME_EXISTS',
    TURF_ASSIGNMENT_LIMIT_REACHED_FOR_THE_DAY = 'TURF_ASSIGNMENT_LIMIT_REACHED_FOR_THE_DAY',
    TURF_ASSIGNMENT_NOT_FOUND = 'TURF_ASSIGNMENT_NOT_FOUND',
    TURF_GROUP_ALREADY_EXISTS = 'TURF_GROUP_ALREADY_EXISTS',
    TURF_GROUP_IN_USE_CANNOT_BE_DELETED = 'TURF_GROUP_IN_USE_CANNOT_BE_DELETED',
    TURF_INTERSECTS_WITH_OTHER_TURF = 'TURF_INTERSECTS_WITH_OTHER_TURF',
    TURF_NAME_ALREADY_EXISTS = 'TURF_NAME_ALREADY_EXISTS',
    UNABLE_TO_ADD_AUDIENCE_ACTION = 'UNABLE_TO_ADD_AUDIENCE_ACTION',
    UNABLE_TO_BLOCK_MEMBER = 'UNABLE_TO_BLOCK_MEMBER',
    UNABLE_TO_DELETE_AUDIENCE = 'UNABLE_TO_DELETE_AUDIENCE',
    UNABLE_TO_DELETE_AUDIENCE_ACTION = 'UNABLE_TO_DELETE_AUDIENCE_ACTION',
    UNABLE_TO_DELETE_AUDIENCE_AS_LIVE_ACTION_EXISTS = 'UNABLE_TO_DELETE_AUDIENCE_AS_LIVE_ACTION_EXISTS',
    UNABLE_TO_UNASSIGN_AS_REPLIES_NOT_REASSIGNED = 'UNABLE_TO_UNASSIGN_AS_REPLIES_NOT_REASSIGNED',
    UNABLE_TO_UNASSIGN_P2P_SUPPORTER_FROM_ACTION = 'UNABLE_TO_UNASSIGN_P2P_SUPPORTER_FROM_ACTION',
    UNAUTHORIZED = 'UNAUTHORIZED',
    UNSUPPORTED_TRANSFORMER_TYPE = 'UNSUPPORTED_TRANSFORMER_TYPE',
    UPGRADE_PLAN = 'UPGRADE_PLAN',
    USER_ALREADY_DELETED = 'USER_ALREADY_DELETED',
    USER_ALREADY_VERIFIED = 'USER_ALREADY_VERIFIED',
    USER_NAME_EXISTS = 'USER_NAME_EXISTS',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    USER_NOT_OCADMIN = 'USER_NOT_OCADMIN',
    USER_NOT_VERIFIED = 'USER_NOT_VERIFIED',
    USER_VERIFICATION_SENDING_FAILED = 'USER_VERIFICATION_SENDING_FAILED',
    USE_SOCIAL_LOGIN = 'USE_SOCIAL_LOGIN',
    VISIT_TEMPLATE_CONTENT_CANNOT_BE_EMPTY = 'VISIT_TEMPLATE_CONTENT_CANNOT_BE_EMPTY',
}

export type Date = any;

export type Json = any;

export type Any = any;

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Types
// ====================================================

export interface Query {
    /** Get all acquisition types for a given outreach circle */
    getAcquisitionTypesForOutreachCircle: AcquisitionTypeList;
    /** Get Acquisition types from parent outreach circle */
    getAcquisitionTypesFromParentOutreachCircle: AcquisitionType[];
    /** Get eligible supporters for action assignment based on action typeGet canvass summary for GRO action */
    getGROCanvassSummary: GroCanvassSummary;
    /** Get eligible supporters for action assignment based on action type */
    getSupportersForActionAssignment: SupporterViewList;
    /** Get members assigned to supporter for action */
    getAssignedMembers: AssignedMemberList;
    /** Get members canvassed by supporter for action */
    getCanvassedMembers: AssignedMemberList;
    /** Get content for sending to recipient by supporter */
    getContentForSendingToRecipientBySupporter: string;
    /** Get member field validation */
    getMemberFormFieldValidations: MemberFormFieldValidations[];
    /** Check whether action exists with given namethrows error:OUTREACH_CIRCLE_NOT_FOUND - When outreach circle is not found */
    doesActionExists: boolean;
    /** Get public details for an action to previewAction with any status is allowed to previewthrows error:ACTION_NOT_FOUND */
    getActionForPreview: PublicAction;
    /** Get actions for preview for a particular group */
    getActionsForPreviewByGroup: PublicActionList;
    /** Get actions for preview for a particular supporter */
    getActionsForPreviewForSupporter: PublicActionList;
    /** Get actions for outreach circlethrows error:OUTREACH_CIRCLE_NOT_FOUND - When outreach circle is not found */
    getActionsForOutreachCircle: ActionList;
    /** Get actions for a given organization action id */
    getActionsForOrganizationAction: ActionList;
    /** Get current actions for outreach circlethrows error:OUTREACH_CIRCLE_NOT_FOUND - When outreach circle is not found */
    getCurrentActionsForOutreachCircle: ActionList;
    /** Get p2p actions where at least 1 initial message is sent */
    getP2PActionsWhereInitialTextIsSent?: ActionList | null;
    /** Get inactive actions for outreach circle (actions which are not archived) */
    getInactiveUnarchivedActionsForOutreachCircle: ActionList;
    /** Get archived actions for outreach circle */
    getArchivedActionsForOutreachCircle: ActionList;
    /** Get public actions for outreach circleReturns only LIVE actionsthrows error:OUTREACH_CIRCLE_NOT_FOUND - When outreach circle is not foundALIAS_DOES_NOT_EXIST: Alias is not found */
    getPublicActionsForEmbed: PublicActionList;
    /** Get public actions for outreach circleReturns only LIVE actionsthrows error:OUTREACH_CIRCLE_NOT_FOUND - When outreach circle is not found */
    getPublicActionsForOutreachCircle: PublicActionList;
    /** Get public actions for supporter(Returns Public LIVE actions and the actions that are assigned to the supporter) */
    getPublicActionsForSupporter: PublicActionList;
    /** Get public actionthrows error:ACTION_NOT_FOUNDACTION_NOT_AVAILABLE - When the action is not active/available */
    getPublicAction: PublicAction;
    /** Get action by idthrows error:ACTION_NOT_FOUND */
    getAction: Action;
    /** Get supporter status for an action */
    getSupporterActionStatus?: SupporterActionStatus | null;
    /** Proxy for FB */
    getFacebookData: Json;
    /** Get contacts for affinity textingErrors thrown:ACTION_NOT_ASSIGNED_TO_SUPPORTER - Action is not assigned to the supporterINVALID_ACTION_TYPE - When action is not of type affinity textingACTION_NOT_IN_LIVE_STATE - Action is not in LIVE state */
    getContactsForAffinityTexting: TextingContactsList;
    /** Get contacts and the text content for affinity textingErrors thrown:ACTION_NOT_ASSIGNED_TO_SUPPORTER - Action is not assigned to the supporterINVALID_ACTION_TYPE - When action is not of type affinity textingACTION_NOT_IN_LIVE_STATE - Action is not in LIVE state */
    getContactsForAffinityTextingV1: TextingContactsList;
    /** Get Text content for sending sms for a member using affinity texting actionErrors thrown:ACTION_NOT_ASSIGNED_TO_SUPPORTER - Action is not assigned to the supporterINVALID_ACTION_TYPE - When action is not of type affinity textingACTION_NOT_IN_LIVE_STATE - Action is not in LIVE stateINVALID_MOBILE_NUMBER - Mobile number in the input is invalid */
    getTextContentForAffinityTexting: string;
    /** Get Text content for sending sms for a member using affinity texting actionErrors thrown:ACTION_NOT_ASSIGNED_TO_SUPPORTER - Action is not assigned to the supporterINVALID_ACTION_TYPE - When action is not of type affinity textingACTION_NOT_IN_LIVE_STATE - Action is not in LIVE stateINVALID_MOBILE_NUMBER - Mobile number in the input is invalid */
    getSupporterTextContentForTextingSupporter: string;
    /** Get actions for which emails sent by supporter */
    getActionsForWhichEmailsSentBySupporter: PublicActionList;
    /** Get actions for which text messages sent by supporter */
    getActionsForWhichTextMessagesSentBySupporter: PublicActionList;
    /** Get list of all actions taken by supporter */
    getActionsTakenBySupporter: PublicActionList;
    /** Get list of all supporters set linked to text supporter action */
    getTextSupporterSetList?: TextSupporterSetList | null;
    /** Get eligible supporter count for supporter texting. Excluding opt out and no phone numbers. */
    getEligibleSupporterCountForSupporterTexting: number;
    /** Get eligible actions for assigning to a group */
    getEligibleActionsForAssigningToGroup?: (Action | null)[] | null;
    /** Get the action for embedding in an iframe */
    getEmbedAction: PublicAction;
    /** Get the assigned supporters and the audience actions with pagination */
    getAssignedSupportersForAction?: AssignedSupportersForActionList | null;
    /** fetch assigned supporters to action default order by name */
    fetchAssignedSupportersToAction: ActionSupporterList;
    /** Get estimated cost acceptance info for action like when and by whom it got accepted */
    getEstimatedCostAcceptanceInfoForAction?: ActionEstimatedCostAck | null;
    /** Get custom audience custom field list for actions */
    getAudienceCustomFieldListForAction: (AudienceCustomField | null)[];
    /** Returns true when SMS from real mobile is sent for all eligible recipients else falseErrors thrown:INVALID_ACTION_FOR_SMS - When action type or its configuration is not eligible for sending SMS or its considered */
    hasSMSSentToAllEligibleRecipients: boolean;
    /** Get the public action by Alias(Returns action only if it is in active state)ErrorsALIAS_DOES_NOT_EXIST - outreachcircle alias does not existALIAS_NOT_ASSOCIATED_TO_OUTREACH_CIRCLE - Alias not associated to outreach circleACTION_ALIAS_DOES_NOT_EXIST - There is no alias by the key or no action associated to the aliasACTION_NOT_IN_LIVE_STATE - Action is not in active state - throw name in the error message */
    publicActionByAlias: PublicAction;
    /** Get the list of actions linked to audience action (Returns maximum 3 actions by default) */
    getActionsLinkedToAudienceAction: (Action | null)[];
    /** Send Action Preview Email to Admin */
    sendActionEmailNotificationPreview?: boolean | null;
    /** Get original action call template by action id */
    getOriginalActionCallTemplateForSupporter: string;
    /** Get original action visit template by action id */
    getOriginalActionVisitTemplateForSupporter: string;
    /** Fetch groups assigned to action */
    fetchGroupsAssignedToAction: ActionGroupList;
    /** Get Member Notes */
    getMemberNotes: MemberNoteList;
    /** Get Audience Action Headers */
    getAudienceActionHeaders: (string | null)[];
    /** Get Public Events in given date range */
    getPublicEvents: PublicAction[];
    /** Get the device token for a phone bank. */
    getDeviceTokenForPhoneBank: DeviceToken;
    /** Get a member for a phone bank.throws errors:NO_MEMBER_FOUND_FOR_CALLING */
    getMemberForPhoneBank: PhoneBankMember;
    /** Get Recipients for manual texting event */
    getRecipientsForTextingAction?: TextingRecipient[] | null;
    /** Get initial texting script for action */
    getInitialTextingScript: InitialTextingScript;
    /** Get initial texting script for action for restore */
    getInitialTextingScriptForRestore: string;
    /** Get number of recipients texted for action by supporter */
    getNumberOfRecipientsTexted: number;
    /** Get all texting conversations */
    getTextingConversationsByAction: TextingConversationList;
    /** Get texting conversation inbox count */
    getTextingConversationInboxUnReadCount: TextingConversationInboxUnReadCount;
    /** Get texting conversation inbox */
    getTextingConversationInbox: TextingConversationInbox;
    /** Get texting conversation */
    getRecipientTextingConversation: RecipientTextingConversation;
    /** Get households basedon supporters currentLocation */
    getHouseholdsBasedonCurrentLocation: AddressBasedCanvassingHouseHold[];
    /** Get matched members for the data given by the user */
    getMatchedMembersForAddressBasedCanvassing: SearchAndSurveyMemberList;
    /** Get ambiguous members for the data given by the user */
    getAmbiguousHouseholdMembers: AmbiguousHouseholdMembersList;
    /** Get mapping by audience action ids */
    getAudienceAction: AudienceAction;
    /** Get the presigned request fields for uploading audience file */
    presignedAudienceActionUploadUrl: AudienceActionFileUploadUrl;
    /** search for a member of audience by name */
    audienceMemberByName: MemberSearchResult;
    /** check if the audience mapping name already exists */
    doesAudienceMappingNameExist: boolean;
    /** Get the audience actions  by database that can be used to link to an action while creating */
    getAudienceActionsForOutreachCircleByIntegrationType: (AudienceAction | null)[];
    /** Get the audience actions  by database that can be used to link to an action while updating */
    getAudienceActionsForActionByIntegrationType: (AudienceAction | null)[];
    /** Get the data to be displayed for a particular listType */
    getAudienceInfoByListType?: AudienceInfo | null;
    /** Get audience actions by list type for an outreach circle */
    getAudienceActionsForOutreachCircleByListType: AudienceActionList;
    /** Get the list of LIVE actions using the audience action in the input (Returns maximum 3 actions) */
    getLiveActionsByAudienceAction: (Action | null)[];
    /** Get mappings by audience id */
    getMappingsByAudienceId: AudienceMappingList;
    /** Will be used to show the member while taking the surveyErrors thrown:INVALID_MEMBER_ID */
    getSearchAndSurveyMemberById: SearchAndSurveyMember;
    /** get matched members for survey and survey actionErrors thrown:ACTION_NOT_IN_LIVE_STATESEARCH_EXCEEDS_MAXIMUM_RESULTS_WITH_INCOMPLETE_DATA */
    getMatchedMembersForSearchAndSurveyAction: SearchAndSurveyMemberList;
    /** Get the constituent lists from blackbaud */
    getBlackbaudConstituentLists?: (BlackbaudConstituentList | null)[] | null;
    /** Get the details of a contactErrors thrown:ACTION_NOT_FOUND */
    supporterContact?: SupporterContact | null;
    /** Get matched contacts for the supporterReturns only the matched contacts for which user has not yet taken any action */
    mappedContacts?: MappedContactsList | null;
    /** Get matched contacts for the supporter for textingReturns only the matched contacts for which user has not yet taken any action */
    mappedContactsForText?: MappedContactsList | null;
    /** Get email recipients for the supporterErrors thrown:ACTION_NOT_FOUND */
    emailRecipients?: MappedContactsList | null;
    /** Get ambiguous mapped contacts for the supporterErrors thrown:ACTION_NOT_FOUND */
    ambiguousMappedContacts?: AmbiguousMappedContactList | null;
    /** Get ambiguous mapped contacts for texting for the supporterErrors thrown:ACTION_NOT_FOUND */
    ambiguousMappedContactsForText?: AmbiguousMappedContactList | null;
    /** Create the text recipient and content for sending a text messageErrors thrown:CONTACT_NOT_FOUNDINVALID_PHONE_NUMBERACTION_NOT_IN_LIVE_STATETEXT_TEMPLATE_NOT_LINKED */
    getContentForTextRecipient?: string | null;
    /** Mark text recipient as sentErrors thrown:INVALID_TEXT_RECIPIENT - Invalid text recipient */
    markTextRecipientAsSent?: boolean | null;
    /** Count for mapped contacts */
    mappedContactsCount: MappedContactsCountResponse;
    /** Count for mapped contacts for text */
    mappedContactsCountForText: MappedContactsCountResponseForText;
    /** Count for email recipients for outreach mode */
    mappedContactsCountForOutreachMode: MappedContactsCountResponseForOutreachMode;
    /** Count for text recipients for outreach mode */
    mappedContactsCountForTextOutreachMode: MappedContactsCountResponseForTextOutreachMode;
    /** Get matching job by id */
    matchingJob: MatchingJob;
    /** Contact sources with mapped contacts count */
    contactSourcesWithMappedContactCount: (ContactSource | null)[];
    /** Contact sources with ambiguous mapped contacts count */
    contactSourcesWithAmbiguousMappedContactCount: (ContactSource | null)[];
    /** Contact sources with mapped contacts count for text */
    contactSourcesWithMappedContactCountForText: (ContactSource | null)[];
    /** Contact sources with ambiguous mapped contacts count for text */
    contactSourcesWithAmbiguousMappedContactCountForText: (ContactSource | null)[];
    /** Get the non mapped contacts for email that are be added as recipients for VFM action with NVFM enabledErrors thrown:INVALID_ACTION - If actionId in input is not VFM with NVFM enabled */
    getSelectedNonMappedContactsForEmail: ContactEmailRecipientList;
    /** Get the non mapped contacts for text that are be added as recipients for VFM action with NVFM enabledErrors thrown:INVALID_ACTION - If actionId in input is not VFM with NVFM enabled */
    getSelectedNonMappedContactsForText: ContactTextRecipientList;
    /** Get the non mapped contacts for email that can be added as recipients for VFM action with NVFM enabledErrors thrown:INVALID_ACTION - If actionId in input is not VFM with NVFM enabled */
    getEligibleOutreachModeRecipientsForEmail: ContactEmailRecipientList;
    /** Get the non mapped contacts for text that can be added as recipients for VFM action with NVFM enabledErrors thrown:INVALID_ACTION - If actionId in input is not VFM with NVFM enabled */
    getEligibleOutreachModeRecipientsForText: ContactTextRecipientList;
    /** Contacts that cannot be recipients for email for NVFM flow */
    getUnavailableContactsForEmailOutreachMode: ContactList;
    /** Contacts that cannot be recipients for text for NVFM flow */
    getUnavailableContactsForTextOutreachMode: ContactList;
    /** Get matched contacts for the supporter - A1CR */
    getMappedContactsForAction: MappedContactsListForAction;
    /** Get unmatched contacts for the supporter - A1CR */
    getNonMappedContactsForAction: NonMappedContactList;
    /** Get ambiguous mapped contacts for the supporter - A1CR */
    getAmbiguousContactsForAction: AmbiguousMappedContactList;
    /** Errors thrown:ACTION_NOT_FOUND - If actionId in input is not validSUPPORTER_NOT_FOUND - If suppoterId in input is not validGet A1C Recipient List */
    getA1CRecipients: MappedContactsListForAction;
    /** Get Duplicate Email Recipients List */
    getDuplicateEmailRecipients: DuplicateEmailRecipientList;
    /** Get Matches for the contact */
    getMatchesForContact: MemberList;

    getContactFileUploadUrl: Json;

    contactSources?: (ContactSource | null)[] | null;
    /** Check if contact exists with given email in logged-in user's contact list */
    doesContactExistsForEmail: boolean;
    /** Check if contact exists with given phone number in logged-in user's contact list */
    doesContactExistsForPhoneNumber: boolean;
    /** Get contacts for logged in userAllows users to search contacts by normalized nameAllows users to filter contacts by contact sourceAllows pagination */
    getContacts: ContactList;
    /** Get contacts for logged in userAllows users to search contacts by normalized nameAllows users to filter contacts by contact sourceAllows pagination */
    getContactsWithEmail: ContactEmailRecipientList;
    /** Get contacts for logged in userAllows users to search contacts by normalized nameAllows users to filter contacts by contact sourceAllows pagination */
    getContactsWithPhone: ContactTextRecipientList;
    /** Get contact by idErrors Thrown:CONTACT_NOT_FOUND - No contact with this id exists for the logged in user */
    getContactById: Contact;
    /** Returns the count of supporters for the top 3 recruitment links for an outreach circle */
    getTotalSupportersByRecruitmentLink: SupporterCountByRecruitmentLink;
    /** Total actions taken for an outreach circle */
    totalActionsTaken: number;
    /** Total supporters for an outreach circle */
    totalSupporters: number;
    /** Action impressions for an outreach circle */
    totalActionImpressions: number;
    /** Return the most engaged supporters for an outreach circle */
    mostEngagedSupporters: MostEngagedSupportersResponse;
    /** Actions taken by supporters */
    actionsTakenBySupporters: ActionsTakenBySupportersResponse;
    /** Email summary for an outreach circle */
    emailSummary: EmailSummaryResponse;
    /** Get the total messages sent for an outreach circle */
    textMessagesSent: number;
    /** Action statistics by type for an outreach circle */
    actionsByType: ActionsByTypeResponse;
    /** Response summary for a survey */
    surveyResponseSummary: SurveyResponseSummary;
    /** Get the organization updates for an outreach circle */
    getOrganizationUpdates?: OrganizationUpdate[] | null;
    /** Get daily support ids for an outreach circle */
    getDailySupportIdsForOutreachCircle: DailySupportIds[];
    /** Get daily support ids count for an outreach circle */
    getSupportIdsCountForOutreachCircle: SupportIdsCount[];
    /** Get system updates for an outreach circle */
    getSystemUpdatesForOutreachCircle: SystemUpdate[];
    /** Get today's activity for an outreach circle */
    todaysActivityForOutreachCircle: TodaysActivityForOutreachCircle[];
    /** Get static or dynamic list from PDI with filters */
    getPDIListsForOutreachCircle: PdiListsItemList;
    /** Get all assignment groups by action */
    getTurfGroupsByAction: TurfGroup[];
    /** Get all turfs by action */
    getTurfsByAction: Turf[];
    /** Get a turf group by id */
    getTurfGroupById: TurfGroup;
    /** Get a turf by id */
    getTurfById: Turf;
    /** Get all public turf groups by action */
    getPublicTurfGroupsByAction: PublicTurfGroup[];
    /** Get all turfs by outreach circle */
    getTurfGroupsByOutreachCircle: TurfGroup[];
    /** Get turfs by outreach circle */
    getTurfsByOutreachCircle: Turf[];
    /** Get all public turf assignments by action */
    getPublicTurfAssignmentsByAction: CanvassingAssignment[];
    /** Get a public turf assignment by id */
    getPublicTurfAssignmentById: CanvassingAssignment;
    /** Get all public turf assignments by outreach circle */
    getPublicTurfAssignmentsByOutreachCircle: CanvassingAssignment[];
    /** Get all public turf groups by outreach circle */
    getPublicTurfGroupsByOutreachCircle: PublicTurfGroup[];
    /** Get all public turf groups by shift */
    getPublicTurfGroupsByShift: PublicTurfGroup[];
    /** Search for places */
    searchPlaces: Place[];
    /** Get recently canvassed turfs */
    getRecentlyCanvassedTurfs?: TurfGroup[] | null;
    /** Search for turfs by display id */
    searchTurfs: TurfList;
    /** Get default turfs by action */
    getDefaultTurfsByAction: DefaultTurfList;
    /** Get default turf by id */
    getDefaultTurfById: DefaultTurf;
    /** Get communication history for canvassing member */
    getCanvassingMemberCommunicationHistory: CanvassingMemberCommunicationHistory;
    /** Get communication history for canvassing member */
    getCanvassingMemberSupportHistory: CanvassingMemberSupportHistory;
    /** Get custom turfs by action */
    getCustomTurfsByAction: Turf[];
    /** Get custom turfs by outreach circle */
    getCustomTurfsByOutreachCircle: Turf[];
    /** Get members by household */
    getMembersByHousehold: CanvassingMember[];
    /** Get all canvassed work by canvassers */
    getCanvassedSurveyResponseSummaryBySupporters: CanvassedSurveyResponseSummaryBySupportersList;
    /** Get all canvassed work by assignment */
    getCanvassedSurveyResponseSummaryByAssignment: CanvassedSurveyResponseSummaryByAssignmentsList;
    /** Get Survey Responses by Supporter */
    getCanvassedSurveyResponsesBySupporter?: CanvassedSurveyResponsesBySupporterList | null;
    /** Get the voter density buckets for a given action */
    getVoterDensityBuckets: VoterDensityBucket[];
    /** Get original action email template subject by template id and supporter idErrors thrown:EMAIL_TEMPLATE_NOT_FOUND */
    getOriginalActionEmailTemplateSubjectForSupporter: string;
    /** Get original action email template content by template id and supporter idErrors thrown:EMAIL_TEMPLATE_NOT_FOUND */
    getOriginalActionEmailTemplateContentForSupporter: string;
    /** Get HTML for engagement by idErrors thrown:ENGAGEMENT_NOT_FOUND */
    editHtmlForEngagementById: string;
    /** Returns the preview html for the given editor content */
    getContentForPreview: string;
    /** Get email template by idErrors thrown:EMAIL_TEMPLATE_NOT_FOUND */
    emailTemplate: EmailTemplate;
    /** Get the email templates for an outreach circle in context of action */
    emailTemplatesByOutreachCircle?: EmailTemplateList | null;
    /** Returns default HTML for recruitment link */
    defaultEditHtmlForRecruitmentLink: string;
    /** Returns default HTML for action link */
    getDefaultEditorContentForActionLink: string;
    /** Returns HTML with updated recruitment link text */
    updatedEmailTemplateWithRecruitmentLink: string;
    /** Returns HTML with updated outreachcircle branding */
    updatedEmailTemplateWithOutreachCircleBranding: string;
    /** Returns HTML with disclaimer */
    editHtmlForDisclaimer: string;
    /** Returns HTML with updated disclaimer text */
    updatedEmailTemplateWithDisclaimer: string;
    /** Returns HTML with updated shift card text */
    getEmailTemplateForShiftCard: string;
    /** Returns HTML with updated event card text */
    getEmailTemplateForEventCard: string;
    /** Returns HTML with updated survey details */
    getEmailContentForUIEditorWithUpdatedSurvey: string;
    /** Returns true if the email template name is uniqueErrors thrown:EMAIL_TEMPLATE_WITH_SAME_NAME_EXISTS */
    validateEmailTemplateNameForAction: boolean;
    /** Get all email templates for an organization */
    emailTemplatesByOrganization?: EmailTemplateForOrganizationList | null;
    /** Returns default HTML for recruitment link email editor addon */
    defaultHtmlForRecruitmentLinkForOrgEmailTemplate: string;
    /** Returns default HTML for disclaimer email editor addon */
    defaultHtmlForDisclaimerForOrgEmailTemplate: string;
    /** Returns true if the email template name is uniqueErrors thrown:EMAIL_TEMPLATE_WITH_SAME_NAME_EXISTS */
    validateOrganizationEmailTemplateName?: boolean | null;
    /** Returns the preview html for the given editor content for organization */
    getContentForUIPreviewByUIEditorContentForOrganization: string;
    /** Get the survey engagement by idErrorsSURVEY_ENGAGEMENT_NOT_FOUND - Survey engagement not found */
    surveyById: Engagement;
    /** Get the surveys by outreachcircle id */
    surveysByOutreachCircleId: Engagement[];
    /** Get the surveys by outreachcircle id */
    getSurveysEligibleForForActionByIntegration: Engagement[];
    /** Get all external links for outreachcircle */
    searchExternalLinksByOutreachCircleId: ExternalLinkList;
    /** Get the survey engagement by idErrorsSURVEY_ENGAGEMENT_NOT_FOUND - Survey engagement not foundINVALID_SURVEY_TEXT_RECIPIENT - When text recipient id is not valid. */
    surveyByIdForTextRecipient: PublicEngagement;
    /** Get the survey engagement by idErrorsSURVEY_ENGAGEMENT_NOT_FOUND - Survey engagement not foundINVALID_SURVEY_TEXT_RECIPIENT - When text recipient id is not valid. */
    surveyByIdForEmailRecipient: PublicEngagement;
    /** Get preview for survey engagement */
    surveyEngagementForPreview: PublicEngagement;
    /** Get the preview data of a suvery engagment questionErrorsENGAGEMENT_QUESTION_NOT_FOUND - Survey engagement question is not found */
    getSupporterPreviewForSurveyQuestion: EngagementQuestion;
    /** Get the preview data for suvery engagmentErrorsSURVEY_ENGAGEMENT_NOT_FOUND - Survey engagement not found */
    getSupporterPreviewForSurvey: PublicEngagement;
    /** Checks whether survey with given name already exist for given outreachcircle */
    doesSurveyByNameAndOutreachCircleIdExist: boolean;
    /** Get surveys from all the outreachcircles that belong to organization */
    getSurveysForOrganization: EngagementList;
    /** Get all activitiesReturns: Default activities; custom activities */
    getActivities: Activity[];
    /** Get event config by id */
    getEventConfig: EventConfig;
    /** Get event configs for an outreach circle */
    getEventConfigsByOutreachCircle: EventConfigList;
    /** Get public event configs for an outreach circle */
    getPublicEventConfigsByOutreachCircle: PublicEventConfigList;
    /** Get assigned actions for non working guest */
    getAssignedEventsForNonWorkingGuest: PublicActionList;
    /** Get event series by action */
    getEventSeriesByAction: EventSeries;
    /** Get event series by id */
    getEventSeriesById: EventSeries;
    /** Get supporters assigned to a shift */
    getAssignedSupportersForShift: AssignedSupporterListForShift;
    /** Get public shifts for an outreach circle */
    getPublicShiftsByOutreachCircle: PublicShiftList;
    /** Get shifts for an outreach circle */
    getShiftsByOutreachCircle: ShiftList;
    /** Get attendees for a shift */
    getAttendeesForShift: AssignedSupporterListForShift;
    /** Get shift count by action type */
    getShiftsByActionType: ShiftsListByActionType;
    /** Get shift by id */
    getPublicShiftById: PublicShift;
    /** Get list of flags with search text */
    getFilteredFlags: FlagList;
    /** Get Flags from parent */
    getFlagsFromParentOutreachCircle: Flag[];
    /** Get list of flag associations */
    getFlagAssociations: (FlagAssociation | null)[];
    /** Get all the gro survey forms of the outreach circle */
    groSurveyFormsByOutreachCircle?: GroSurveyFormList | null;
    /** Get the preview data of a gro survey form questionErrors thrown:GRO_SURVEY_FORM_QUESTION_NOT_FOUND */
    getSupporterPreviewForGroSurveyFormQuestion: GroSurveyFormQuestion;
    /** Get the preview data for gro survey formErrors thrown:GRO_SURVEY_FORM_NOT_FOUND */
    getSupporterPreviewForGroSurveyForm: GroSurveyForm;
    /** Get the gro survey form by idErrors thrown:GRO_SURVEY_FORM_NOT_FOUND */
    getGroSurveyFormById: GroSurveyForm;
    /** Returns true if the gro survey form name is uniqueErrors thrown:GRO_SURVEY_FORM_WITH_SAME_NAME_EXISTS */
    validateGroSurveyFormName?: boolean | null;
    /** Get the survey responses submitted by the member for a gro survey form */
    getGROSurveyResponseForMember: (GroSurveyFormResponse | null)[];
    /** Get list of gro survey form responses for a member */
    getPastGroSurveyResponsesForMember: GroSurveyFormResponseList;
    /** Get groups for an outreach circle */
    getGroupsByOutreachCircle: GroupList;
    /** Get actions for group */
    getActionsForGroup?: ActionList | null;
    /** Get group by id */
    group: Group;
    /** Get all eligible groups for a specific action */
    getEligibleGroupsForAction: GroupList;
    /** Get all groups for outreachCircle with isAssignedToAction information */
    getAllGroupsForAction: GroupList;
    /** Fetch all integrations for an outreach circle */
    fetchIntegrations: IntegrationList;
    /** Fetch NationBuilder people tagsErrors thrown:NATION_BUILDER_INTEGRATION_NOT_FOUND - When the OutreachCircle doesnot have a NationBuilder integrationINVALID_NATION_BUILDER_CREDENTIALS - When the NationBuilder credential is invalid */
    fetchNationBuilderPeopleTags: NationBuilderPeopleTagsList;
    /** Test NGP integration */
    testNgpIntegration: TestNgpResponse;
    /** Fetch NGP listsErrors thrown:INVALID_NGP_CREDENTIALS - NGP credentials are invalid */
    fetchNgpLists: NgpLists;
    /** Fetch NGP listsErrors thrown:INVALID_NGP_CREDENTIALS - NGP credentials are invalidINVALID_NGP_ACTIVIST_CODE_ID - NGP activist code is invalid */
    fetchNgpActivistCodes: NgpActivistCodeList;
    /** Fetch NGP listErrors thrown:INVALID_NGP_CREDENTIALS - NGP credentials are invalid */
    fetchNgpSurveyList: NgpSurveyList;
    /** Fetch NGP listsErrors thrown:INVALID_NGP_CREDENTIALS - NGP credentials are invalid */
    fetchNgpSurveyById: NgpSurveyQuestion;
    /** Get PDI On The Go AssignmentsErrors thrown:AssignmentNotFound */
    getPDIOnTheGoAssignments: PdiAssignmentResponse;
    /** Get PDI assignment zones */
    getPDIAssignmentZones: (PdiAssignmentZone | null)[];
    /** Get matched PDI members for the data given by the user */
    getMatchedPDIMembers: SearchAndSurveyMemberList;
    /** Get PDI QuestionsErrors thrown:PDI_INTEGRATION_NOT_FOUND - When the OutreachCircle doesnot have a PDI integration */
    getPDIQuestions: PdiQuestionList;
    /** Get Flag IDsErrors thrown:PDI_INTEGRATION_NOT_FOUND - When the OutreachCircle does not have a PDI integration */
    getPDIFlagIds?: (PdiFlagId | null)[] | null;
    /** Get PDI Projects for outreachcircle */
    getPdiProjectsForOutreachcircle: (PdiProjectInfo | null)[];
    /** Validate SOQLErrors thrown:INVALID_SOQL - When the soql contains any invalid keywords or salesforce soql query failsINTEGRATION_NOT_FOUND - When integration does not exist */
    validateSOQL: Json;
    /** Get TCR Registration details */
    getTCRRegistrationDetails: GetTcrRegistrationDetailsOutput;
    /** Get all applicable usecases for specific TCR Brand */
    getTCRBrandApplicableUsecases: TcrBrandApplicableUsecase[];
    /** Get base64 for image media */
    getBase64ForAnImage: string;
    /** Get Engagement and SearchAndSurveyForm for an outreach circle */
    memberSurveysByOutreachCircleId: MemberSurvey[];
    /** Get Engagement and SearchAndSurveyForm for an organization */
    memberSurveysByOrganizationId: MemberSurveyList;
    /** Get latest native app version information */
    getLatestNativeAppVersion?: NativeAppVersion | null;
    /** Get public details for an organization action to previewAction with any status is allowed to previewthrows error:ACTION_NOT_FOUND */
    getOrganizationActionForPreview: PublicOrganizationAction;
    /** Get action by idthrows error:ACTION_NOT_FOUND */
    getOrganizationAction: OrganizationAction;
    /** Get actions for organization */
    getActionsForOrganization: OrganizationActionList;
    /** Get all the outreach circles for which the organization action is published */
    getOutreachCirclesByOrganizationActionPublished: OutreachCircle[];
    /** Get list of organization actions that are on terminated status or hidden */
    getTerminatedAndHiddenOrganizationActions: OrganizationActionList;
    /** Get list of organization actions that are not on terminated status or hidden */
    getCurrentOrganizationActions: OrganizationActionList;
    /** Check whether organization action exists with given name */
    doesOrganizationActionWithSameNameExist: boolean;
    /** Get the all orzanization usersErrors thrown:ORGANIZATION_NOT_FOUND: orzanization with given id not found */
    getAllOrganizationUsers?: OrganizationUser[] | null;
    /** Get organizations of the user */
    getAllOrganizations: OrganizationList;
    /** Get an organization by its ID */
    organizationById: Organization;
    /** Get the public organization by AliasReturns organization with default aliasErrorsORGANIZATION_ALIAS_DOES_NOT_EXIST - There is no alias by the key */
    publicOrganizationByAlias: PublicOrganization;
    /** Get all outreach circles of the organization */
    getOutreachCirclesByOrganization: OutreachCircleDataList;
    /** Get all outreach circles of the organization excluding the archived ones */
    getActiveOutreachCirclesByOrganization: OutreachCircleDataList;
    /** Get filters created by the user for the organization */
    getFiltersByOrganization: OrganizationDashboardFilter[];
    /** Total actions taken by all supporters in the organization */
    totalActionsTakenForOrganization: number;
    /** Actions taken by supporters */
    actionsTakenCountByOutreachcircleForOrganization: ActionsTakenCountByOutreachcircleForOrganization[];
    /** Most engaged supporters for organization */
    mostEngagedSupportersForOrganization: MostEngagedSupportersForOrganization[];
    /** Get count of supporters by recruitment link */
    getSupporterCountByOutreachcircleForOrganization: SupporterCountByOutreachcircleForOrganization[];
    /** Get counts of actions based on their type */
    actionsByTypeForOrganization: ActionsByTypeResponse;
    /** Get email summary for organization */
    emailSummaryForOrganization: EmailSummaryResponse;
    /** Get total texts sent for organization */
    textMessagesSentForOrganization: number;
    /** Get email open rate for organization */
    emailOpenRateForOrganization: number;
    /** Total supporters for organization */
    totalSupportersCountForOrganization: number;
    /** Get OutreachCircles for a user by organization */
    getOutreachCirclesForUserByOrganization?: OutreachCircle[] | null;
    /** Get public organization */
    publicOrganization: PublicOrganization;
    /** Get all public outreach circles of the organizationthrows error:ORGANIZATION_NOT_FOUND - Organization not found */
    getPublicOutreachCirclesByOrganization: PublicOutreachCircleList;
    /** Check whether there exists a template with same name, code and organization */
    doesOutreachCircleUserEmailTemplateExists: boolean;
    /** Get all organization email aliases */
    getAllEmailAliasesForOrganization: string[];
    /** Get the organization email templates for sendingfrom Organization to Outreach circle admins */
    getOutreachCircleUserEmailTemplatesForOrganization?: OutreachCircleUserEmailTemplateList | null;
    /** Get preview with the updated content */
    getPreviewForOutreachCircleUserEmailTemplate: string;
    /** Get the editor content after updating the action link text */
    getUpdatedContentForOutreachCircleUserEmailTemplateWithActionLinkText: string;
    /** Get the email template linked for the organization action */
    getEmailTemplateForOrganizationAction?: OutreachCircleUserEmailTemplate | null;
    /** Get the eligible recipients count for sending organization action notification email */
    getEligibleCountForOrganizationActionNotification: number;
    /** Get eligible count for notify admins for organization */
    getEligibleCountForNotifyOcAdminsForOrganization: number;
    /** Get all the roles for outreachcircleErrors thrown: OUTREACH_CIRCLE_USER_DOES_NOT_EXIST */
    getOutreachCircleRoles: Role[];
    /** Get all the combined permissions for outreachcircleErrors thrown: OUTREACH_CIRCLE_USER_DOES_NOT_EXIST */
    getOutreachCirclePermissions: MasterPermission[];
    /** Get the outreach circle userErrors thrown:INVALID_OUTREACH_CIRCLE_USER: Outreach circle user with the given id not found */
    outreachCircleUser?: OutreachCircleUser | null;
    /** Get outreachcircle users with filter */
    getOutreachcircleUsersWithFilters: OutreachCircleUserList;
    /** Get outreachcircle users with filter */
    getOutreachCircleUserGeographyListWithFilter: GeographyList;
    /** TODO: Need to be deprecated in future and will be handeled by publicOutreachCircleByAliasOrNameGet the public outreachCircle by Alias(Returns outreachCircle only if it is in active state)ErrorsALIAS_DOES_NOT_EXIST - There is no alias by the keyALIAS_NOT_ASSOCIATED_TO_OUTREACH_CIRCLE - There is no outreach circle associated to the aliasINACTIVE_OUTREACH_CIRCLE - Outreach circle of the matching alias is in INACTIVE status */
    publicOutreachCircleByAlias: PublicOutreachCircle;
    /** Get the public outreachCircle by Alias or Name(Returns outreachCircle only if it is in active state)ErrorsALIAS_OR_NAME_DOES_NOT_EXIST - There is no alias by the keyALIAS_OR_NAME_NOT_ASSOCIATED_TO_OUTREACH_CIRCLE - There is no outreach circle associated to the alias or nameINACTIVE_OUTREACH_CIRCLE - Outreach circle of the matching alias is in INACTIVE status */
    publicOutreachCircleByAliasOrName: PublicOutreachCircleList;
    /** Public Outreach circle for embedded flowThrow Errors:ALIAS_NOT_EMBEDDABLE: Alias is not embeddedableALIAS_DOES_NOT_EXIST: Alias is not found */
    publicOutreachCircleForEmbed: PublicOutreachCircle;
    /** Get the public outreachCircle by Alias or Name or City or State or Zip(Returns outreachCircle only if it is in active state)ErrorsINVALID_SEARCH_KEY - Search Key is not specifiedOUTREACH_CIRCLE_NOT_FOUND - There is no outreach circle associated to the search key */
    searchPublicOutreachCircle: PublicOutreachCircleSearchResultList;
    /** Get the outreachCircle by AliasErrorsALIAS_DOES_NOT_EXIST - There is no alias by the keyALIAS_NOT_ASSOCIATED_TO_OUTREACH_CIRCLE - There is no outreach circle associated to the alias */
    outreachCircleByAlias: OutreachCircle;
    /** Get the outreachCircle by idErrorsOUTREACH_CIRCLE_NOT_FOUND - Outreach circle not found */
    outreachCircleById: OutreachCircle;
    /** Get all public active outreach circles */
    allPublicOutreachCircles: PublicOutreachCircleList;
    /** Get all aliases for outreachCircle */
    allAliasesForOutreachCircle: AliasList;
    /** Get all email aliases for outreachCircle */
    allEmailAliasesForOutreachCircle: OutreachCircleEmailAlias[];
    /** Fetch all campaigns for given outreachCircle */
    getAllCampaignsForOutreachCircle: (Campaign | null)[];
    /** Get OutreachCircle Access for logged in user */
    getOutreachCircleAccessInfo: OutreachCircleAccessInfo;
    /** Get public alias detailsErrors thrown:ALIAS_DOES_NOT_EXIST - When no alias exists with of that value */
    getPublicAliasDetails: PublicAlias;
    /** Get app logo associated with outreach circle of aliasErrors thrown:ALIAS_DOES_NOT_EXIST - When no alias exists with of that value */
    getAppLogoByAlias?: string | null;
    /** Get the archived public outreachCircle by idErrorsOUTREACH_CIRCLE_NOT_FOUND - Outreach circle not found */
    getArchivedPublicOutreachCircle: ArchivedPublicOutreachCircle;
    /** Get deep link object */
    getDeepLinkObject: DeepLinkObject;
    /** Get outreach circle locations */
    getOutreachCircleLocations: (OutreachCircleLocation | null)[];
    /** Get outreach circle locationErrors thrown:OUTREACH_CIRCLE_LOCATION_NOT_FOUND - Outreach circle location not found */
    getOutreachCircleLocation: OutreachCircleLocation;
    /** Get geography filters applicable for outreachcircle user */
    getGeographyListForOutreachCircle: GeographyList;
    /** Fetch all billing cards for given outreachCircleId */
    billingCardsByOutreachCircleId: (BillingCardDetails | null)[];
    /** Get discounted amount for a plan using valid couponthrows error:INVALID_PLAN - Wrong plan inputINVALID_COUPON - Wrong coupon code inputCOUPON_HAS_EXPIRED - If redeem date is over or max redemptions are completedCOUPON_NOT_APPLICABLE - If coupon is not applicable to the outreachCircle */
    couponDiscountedAmount: number;
    /** Get upcoming invoice details for an outreach circle */
    upcomingInvoiceDetailsOfOutreachCircle: OutreachCircleUpcomingInvoiceDetails;
    /** Get old outreach circle invoices */
    oldInvoicesOfOutreachCircle?: (OutreachCircleInvoice | null)[] | null;
    /** Search for PDI voters */
    pdiVoterSearch: PdiVoterList;
    /** Get a PDI voter by ID */
    getPdiVoterById: PdiVoter;
    /** Get filtered p2p inbox based on pagination and search key */
    getFilteredP2PInbox: P2PInbox;
    /** Get one conversation and its full details */
    getP2PConversation: P2PConversation;
    /** Get eligible supporters for assigning replies */
    getEligibleSupporterForP2PReplyAssignment: SupporterViewList;
    /** Get all the p2p script survey question by the outreach circle and search key for specific question type */
    getP2PScriptSurveyQuestionsByOutreachCircle: P2PScriptSurveyQuestionList;
    /** Get the p2p script survey question by idErrors thrown:P2P_SCRIPT_SURVEY_QUESTION_NOT_FOUND */
    getP2PScriptSurveyQuestionById: P2PScriptSurveyQuestion;
    /** Returns true if the p2p script survey question name is uniqueErrors thrown:P2P_SCRIPT_SURVEY_QUESTION_WITH_SAME_NAME_EXISTS */
    isValidP2PScriptSurveyQuestionName: boolean;
    /** Get recipients for p2p action */
    getRecipientsForP2PTexting: P2PRecipientsForTexting;
    /** Get number of recipients already p2p-texted by supporter for the action. */
    getNumberOfRecipientsP2PTexted: number;
    /** Get initial p2p item */
    getInitialP2PItem: InitialP2PItem;
    /** Get initial p2p text for restoring modified text by supporter */
    getInitialP2PTextForRestore: string;
    /** Returns true if supporter is allowed to send p2p text messageThrows error:CANNOT_SEND_P2P_TEXT_MESSAGES_OUTSIDE_THE_ALLOTTED_TIME_PERIODACTION_NOT_ASSIGNED_TO_SUPPORTERP2P_ACTION_IS_PAUSEDACTION_NOT_IN_LIVE_STATENO_RECIPIENTS_AVAILABLE_RIGHT_NOW_TO_SEND_INITIAL_TEXT */
    canSendP2PTextMessage: boolean;
    /** Get all the p2p workspace by the outreach circle and search key */
    getP2PWorkspacesByOutreachCircle: P2PWorkspaceList;
    /** Get the p2p workspace by idErrors thrown:P2P_WORKSPACE_NOT_FOUND */
    getP2PWorkspaceById: P2PWorkspace;
    /** Returns true if the p2p workspace name is unique */
    isValidP2PWorkspaceName: boolean;
    /** Returns true if the p2p workspace is linked to any Action and acknowledged estimated cost */
    isP2PWorkspaceLinkedToActionAndAcknowledgedCost: boolean;
    /** Get Ballot Measure By Id */
    getBallotMeasureById: BallotMeasure;
    /** Get the candidate */
    getCandidateOffice: (CandidateOffice | null)[];
    /** Get the candidate by id */
    getCandidateById: Candidate;
    /** Get issue categories */
    getIssueCategories: (IssueCategory | null)[];
    /** Get Isuse by id */
    getIssueById: Issue;
    /** Get progressive response indicators */
    getProgressiveResponseIndicators: (ProgressiveResponseIndicator | null)[];
    /** get Filtered Purpose List */
    getFilteredPurposeList: PurposeList;
    /** Get Candidate Office List */
    getCandidateOfficeList: (CandidateOffice | null)[];
    /** Get Election Cycle List */
    getElectionCycleList: (ElectionCycle | null)[];
    /** Get Progressive Response Indicator List */
    getProgressiveResponseIndicatorList: (ProgressiveResponseIndicator | null)[];
    /** Get Election Cycle List for Outreach Circle */
    getElectionCycleListForOutreachCircle: OutreachCircleElectionCycle[];
    /** Get Purpose list from parent outreach circle */
    getPurposeListFromParentOutreachCircle: Purpose[];
    /** Get reports along with last created report data if available. */
    getReportsByOutreachCircle?: ReportInfo[] | null;
    /** Get reports along with last created report data if available. */
    getReportsByOrganization?: ReportInfo[] | null;
    /** Get all the search and survey forms of the outreach circle */
    searchAndSurveyFormsByOutreachCircle?: SearchAndSurveyFormList | null;
    /** Get the preview data of a search and survey form questionErrors thrown:SEARCH_AND_SURVEY_FORM_QUESTION_NOT_FOUND */
    getSupporterPreviewForSearchAndSurveyFormQuestion: SearchAndSurveyFormQuestion;
    /** Get the preview data for search and survey formErrors thrown:SEARCH_AND_SURVEY_FORM_NOT_FOUND */
    getSupporterPreviewForSearchAndSurveyForm: SearchAndSurveyForm;
    /** Get the search and survey form by idErrors thrown:SEARCH_AND_SURVEY_FORM_NOT_FOUND */
    getSearchAndSurveyFormById: SearchAndSurveyForm;
    /** Returns true if the search and survey form name is uniqueErrors thrown:SEARCH_AND_SURVEY_FORM_WITH_SAME_NAME_EXISTS */
    validateSearchAndSurveyFormName?: boolean | null;
    /** Returns default HTML for recruitment link for social templates */
    defaultEditHtmlForRecruitmentLinkForSocialText: string;
    /** Returns the preview html for the given editor content */
    getSocialTextContentForPreview: string;
    /** Get the social templates for an outreach circle */
    socialTemplatesByOutreachCircle?: SocialTemplateList | null;
    /** Returns true if the social template name is uniqueErrors thrown:SOCIAL_TEMPLATE_WITH_SAME_NAME_EXISTS */
    validateSocialTemplateName?: boolean | null;
    /** Get the social templates for an organization */
    socialTemplatesByOrganization?: SocialTemplateForOrganizationList | null;
    /** Returns true if the organization social template name is uniqueErrors thrown:SOCIAL_TEMPLATE_WITH_SAME_NAME_EXISTS */
    validateOrganizationSocialTemplateName?: boolean | null;
    /** Get organization social template by idErrors thrown:SOCIAL_TEMPLATE_DOES_NOT_EXIST */
    socialTemplateByIdForOrganization: SocialTemplateForOrganization;
    /** Returns default HTML for recruitment link for organization social templates */
    defaultHtmlForRecruitmentLinkForOrgSocialText: string;
    /** Get social template by idErrors thrown:SOCIAL_TEMPLATE_DOES_NOT_EXIST */
    socialTemplate: SocialTemplate;
    /** Returns the preview html for the given editor content */
    getOrganizationSocialTextContentForPreview: string;
    /** Get Sourcing Link for a given outreach circle. Default sort is by name */
    getSourcingLinkForOutreachCircle: SourcingLinkList;
    /** Get list of supporters participated in recent event activities */
    getSupporterActivityList: SupporterActivityList;
    /** Returns the content with the link text replacedErrors thrown:ACTION_NOT_FOUND - Action was not found */
    getUpdatedReminderContent: string;
    /** Get the supporter template for the outreach circle */
    getSupporterTemplatesForOutreachCircle?: SupporterEmailTemplateList | null;
    /** Get the defalt editor content to be inserted into supporter invitation template */
    getDefaultEditorContentForSupporterRecruitmentLink: string;
    /** Get editor content for preview when the are not yet saved to database for outreach circle supporter email template */
    getContentForUIPreviewByUIEditorContentForOutreachCircleSupporterEmailTemplate: string;
    /** Returns HTML with updated recruitment link text */
    getUpdatedEditorContentForSupporterRecruitmentLink: string;
    /** Returns true if the email template name is uniqueErrors thrown:EMAIL_TEMPLATE_WITH_SAME_NAME_EXISTS */
    validateSupporterEmailTemplateName?: boolean | null;
    /** Returns Default Email template for supporter notify on live */
    getDefaultContentForActionEmailNotification: SupporterEmailTemplate;
    /** Get all the supporter forms of the outreach circle */
    supporterFormsByOutreachCircle?: SupporterFormList | null;
    /** Get the preview data of a supporter form questionErrors thrown:SUPPORTER_FORM_QUESTION_NOT_FOUND */
    getSupporterPreviewForSupporterFormQuestion: SupporterFormQuestion;
    /** Get the preview data for supporter formErrors thrown:SUPPORTER_FORM_NOT_FOUND */
    getSupporterPreviewForSupporterForm: SupporterForm;
    /** Get the supporter form by idErrors thrown:SUPPORTER_FORM_NOT_FOUND */
    getSupporterFormById: SupporterForm;
    /** Returns true if the email template name is uniqueErrors thrown:SUPPORTER_FORM_WITH_SAME_NAME_EXISTS */
    validateSupporterFormName?: boolean | null;
    /** Get supporter forms from all the outreachcircles that belong to organization */
    getSupporterFormsForOrganization: SupporterFormList;
    /** Get default content/template of push notification for action */
    getDefaultContentForActionPushNotification: SupporterPushNotificationTemplate;
    /** Get saved supporter filters for the admin */
    getSupporterViewFilterStates: SupporterViewFilterStateList;
    /** Does supporter view filter with same name exists */
    doesSupporterViewFilterStateExists: boolean;
    /** Gets all SupporterActivities for an outreach circle */
    getSupporterActivitiesByOutreachCircle: SupporterActivity[];
    /** Gets all SupporterAvailabilitys for an outreach circle */
    getSupporterAvailabilitiesByOutreachCircle: SupporterAvailability[];
    /** Gets all SupporterLevels for an outreach circle */
    getSupporterLevelsByOutreachCircle: SupporterLevel[];
    /** Gets all SupporterOrganizations for an outreach circle */
    getSupporterOrganizationsByOutreachCircle: SupporterOrganization[];
    /** Get a list of organizers by outreach circle */
    getOrganizersByOutreachCircle: OrganizerList;
    /** Gets all SupporterSkills for an outreach circle */
    getSupporterSkillsByOutreachCircle: SupporterSkill[];
    /** Gets all SupporterTeams for an outreach circle */
    getSupporterTeamsByOutreachCircle: SupporterTeam[];
    /** Gets all SupporterZones for a given outreach circle */
    getSupporterZonesByOutreachCircle: SupporterZone[];
    /** gets a supporter if exists if the logged in user is a supporter.Must be AuthorizedErrorsALIAS_DOES_NOT_EXIST - There is no alias by the key */
    getSupporterByOutreachCircleAlias?: Supporter | null;
    /** gets a supporter if exists by supporterId.ErrorsSUPPORTER_NOT_FOUND - The supporterId provided does not match a supporter */
    getSupporterById: Supporter;
    /** Gets a supporter view by supporter id */
    getSupporterViewById: SupporterView;
    /** get supporter's detailed summary */
    getSupporterSummaryList?: SupporterSummaryList | null;
    /** Get filtered supporter view */
    getFilteredSupporterView: SupporterViewList;
    /** Get signed url to upload file to s3 for invited supporter */
    getInvitedSupporterFileUploadUrl: Json;
    /** Get Total number of invited supporters */
    getTotalInvitedSupportersByOutreachCircle: number;
    /** Get invited supporter information from invitedSupporterId and outreachCircleIdErrors thrown:INVITED_SUPPORTER_INFORMATION_NOT_FOUND: When either invitedSupporterId or outreachCircleId is invalid or not found in supporter table */
    getInvitedSupporterInformation?: InvitedSupporterInformation | null;
    /** Get total number of text message sent by logged in user */
    getTotalTextMessagesSentByLoggedInUser: number;
    /** Count of actions taken by logged in user in all outreach circles */
    getTotalActionsTakenByLoggedInUser: number;
    /** Supporter email summary for all outreach circles */
    supporterEmailSummary: EmailSummaryData;
    /** Get email summary data for supporter for particular action */
    getActionEmailSummaryForSupporter: ActionEmailSummaryForSupporterData;
    /** Get email recipients for supporter for particular action */
    getEmailRecipientsForAction: ActionEmailRecipientsLists;
    /** Get only email summary data for supporter for particular action */
    getEmailSummaryForAction: EmailSummaryData;
    /** Get text message summary data for supporter for particular action */
    getActionTextMessageSummaryForSupporter: ActionTextMessageSummaryForSupporterData;
    /** Get count of all eligible supporters for sending push notification based on the filter provided by user */
    getEligibleSupporterCountForSendingPushNotification: number;
    /** Get count of all eligible supporters for sending email notification based on the filter provided by admin */
    getEligibleSupporterCountForSendingEmailNotification: number;
    /** Get number of supporters who can be blocked */
    getNumberOfSupportersWhoCanBeBlocked: number;
    /** Get outreach circle sharable link uniquely generated for the supporter */
    getOutreachCircleSharableLink: string;
    /** Check if the given email is a supporter of the outreach circle */
    isSupportingOutreachCircle: boolean;
    /** Get most recent invite supporter mapping. Also get all custom fields added in all the previous invites in same order. */
    getRecentInviteSupporterMappingWithAllCustomFields?: RecentInviteSupporterMappingWithAllCustomFields | null;
    /** Get supporter impact data */
    supporterImpactData: SupporterImpactData;
    /** Get list of active surveys */
    getSurveyList: SurveyList;
    /** Get survey by idThrow Errors:SURVEY_DOES_NOT_EXISTS: Survey with given id does not exists */
    getSurveyById: Survey;
    /** Get list of survey questions with search text */
    getFilteredSurveyQuestions: SurveyQuestionList;
    /** Get survey question by idThrow Errors:SURVEY_QUESTION_DOES_NOT_EXISTS: SurveyQuestion with given id does not exists */
    getSurveyQuestionById: SurveyQuestion;
    /** Get all tags for an outreachCircle */
    getAllTagsForOutreachCircle: TagList;
    /** Get tags for a public action */
    getTagsForPublicAction: PublicTagList;
    /** Get tag info with respect to an actionErrors thrown:TAG_NOT_FOUND: When not able to find tag */
    getTagInfoForAction: Tag;
    /** Get list of canned response linked to a tag */
    getCannedResponsesForTag: CannedResponseList;
    /** Get tags eligible for linking to action */
    getTagsEligibleToBeLinkedToAction: TagList;

    testDelay: boolean;
    /** Get original action text template content by template id and supporter idErrors thrown:TEXT_TEMPLATE_NOT_FOUND */
    getOriginalActionTextTemplateContentForSupporter: string;
    /** Get text template by idErrors thrown:TEXT_TEMPLATE_NOT_FOUND */
    textTemplate: TextTemplate;
    /** Get the text templates for an outreach circle */
    textTemplatesByOutreachCircle?: TextTemplateList | null;
    /** Get organization text template by idErrors thrown:TEXT_TEMPLATE_NOT_FOUND */
    textTemplateByIdForOrganization: TextTemplateForOrganization;
    /** Get the text templates for an organization */
    textTemplatesByOrganization?: TextTemplateForOrganizationList | null;
    /** Returns default HTML for recruitment link for organization text templates */
    defaultHtmlForRecruitmentLinkForOrgTextTemplate: string;
    /** Returns true if the organization text template name is uniqueErrors thrown:TEXT_TEMPLATE_WITH_SAME_NAME_EXISTS */
    validateOrganizationTextTemplateName?: boolean | null;
    /** Get the supporter text templates for an outreach circle */
    supporterTextTemplatesByOutreachCircle?: TextTemplateList | null;
    /** Returns default HTML for recruitment link for text templates */
    defaultEditHtmlForRecruitmentLinkForText: string;
    /** Returns default HTML for recipient first name for text templates */
    defaultEditHtmlForRecipientFirstNameForText: string;
    /** Returns default HTML for supporter first name for text templates */
    defaultEditHtmlForSupporterFirstNameForText: string;
    /** Returns default HTML for supporter last name for text templates */
    defaultEditHtmlForSupporterLastNameForText: string;
    /** Returns default HTML for recipient last name for text templates */
    defaultEditHtmlForRecipientLastNameForText: string;
    /** Returns the preview html for the given editor content */
    getTextContentForPreview: string;
    /** Returns true if the text template name is uniqueErrors thrown:TEXT_TEMPLATE_WITH_SAME_NAME_EXISTS */
    validateTextTemplateName?: boolean | null;
    /** Returns the preview html for the given editor content in context of organization */
    getOrganizationTextContentForPreview: string;
    /** Checks whether a username (email) exists.Returns Boolean */
    usernameExists: boolean;
    /** When user clicks on the reset password link,we will validate if the resetcode is valid for the user,because, we change the reset code everytime user tries to reset passwordErrors thrown:USER_NOT_FOUND - User id in the request does not belong to any user */
    validateResetCode?: boolean | null;
    /** Get the user object by auth tokenErrorsINVALID_AUTH_TOKEN - Token passed in the request is invalid */
    userByToken: User;
    /** validate auth token */
    validateToken: boolean;
    /** validate auth token and return User id */
    validateTokenAndReturnUserId?: string | null;
    /** Get outreachCircles for which the logged in user is the adminOf */
    outreachCirclesByLoggedInUser: OutreachCircleList;
    /** Get outreachCircles for which the logged in user is the adminOf */
    archivedOutreachCirclesByLoggedInUser: OutreachCircleList;
    /** Get outreachCircles which the logged in user is supporting */
    supportingOutreachCirclesByLoggedInUser: SupporterList;
    /** Check if user existsCheck if user supports the outreach circle */
    verifyNewSupporterEmail: boolean;
    /** verify new email for embedded actionError thrown: USER_NAME_EXISTS - if user already existsSUPPORTER_ALREADY_EXISTS - if user exists and supports the outreach circleSUPPORTER_IS_BLOCKED_FOR_OUTREACH_CIRCLE - if user exists and blocked for the outreach circle */
    verifyNewSupporterEmailForEmbeddedAction: boolean;
    /** Get all bookmarks for the logged in user */
    getBookmarks: BookmarkList;
}

export interface AcquisitionTypeList {
    items: AcquisitionType[];

    total: number;
}

export interface AcquisitionType {
    id: string;

    name: string;

    description: string;

    associateWith: AcquisitionTypeAssociateWith;

    pageDefault: PageDefault[];

    isActive: boolean;

    isAcquisitionTypeEditable: boolean;

    created: Date;

    updated?: Date | null;
}

export interface GroCanvassSummary {
    summary: GroCanvassChannelSummary[];

    totalCanvassed: number;
}

export interface GroCanvassChannelSummary {
    count: number;

    outreachChannel: OutreachChannel;
}

export interface SupporterViewList {
    total: number;

    items: (SupporterView | null)[];

    activeAndBlockedSupportersCount: number;

    filteredSupporterCount: number;
}

export interface SupporterView {
    supporterId: string;

    outreachCircleId: string;

    fullName?: string | null;

    phoneNumber?: string | null;

    email?: string | null;

    origin: string;

    actionsCompleted: number;

    messagesSentBySupporter: number;

    recruitmentLink?: string | null;

    statusOfLastEmailSentToSupporter?: LastEmailStatus | null;

    joinedOn?: Date | null;

    zip?: string | null;

    location?: string | null;

    isUnsubscribed?: boolean | null;

    optInForText?: boolean | null;

    emailMessagesSent?: number | null;

    emailOpenedCount?: number | null;

    groups?: (GroupSupporter | null)[] | null;

    recruitedBySupporter?: string | null;

    nativeAppUser?: boolean | null;

    pushNotificationsEnabled?: boolean | null;

    numberOfAppInstalls?: number | null;

    inactiveAppUser?: boolean | null;

    nativeAppPlatform?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    isAssignedToAction: boolean;

    blockedOn?: Date | null;

    blockedBy?: User | null;

    isEmailBounced?: boolean | null;

    isOrganizer?: boolean | null;

    assignedToOrganizerId?: string | null;

    assignedToOrganizer?: string | null;

    levelId?: string | null;

    zoneId?: string | null;

    countOfCommunication?: number | null;

    communicationEnabled?: boolean | null;

    turfAssignments?: SupporterTurfAssignment[] | null;

    isAssignedToShift?: boolean | null;

    isAssignedToAtLeastOneShift?: boolean | null;

    participantType?: EventParticipantType | null;

    teamIds?: string[] | null;

    activityIds?: string[] | null;

    skillIds?: string[] | null;

    availabilityIds?: string[] | null;

    organizationId?: string | null;

    pdiVoterId?: string | null;

    recruitedBySupporterId?: string | null;

    status?: VolunteerStatus | null;

    age?: string | null;

    precinct?: string | null;

    party?: string | null;

    gender?: string | null;

    city?: string | null;

    county?: string | null;

    appStatus?: SupporterAppStatus | null;
}

export interface GroupSupporter {
    id: string;

    name: string;

    isManualAssignment?: boolean | null;
}

export interface User {
    id: string;

    username?: string | null;

    firstName?: string | null;

    lastName?: string | null;

    city?: string | null;

    state?: string | null;

    country?: string | null;

    zip?: string | null;

    phoneNumber?: string | null;

    isVerified: boolean;

    uiPreferences: Json;

    optInForText?: boolean | null;

    profileImage?: Media | null;

    outreachCircleCodes?: string[] | null;

    isRapidlyOnboarded?: boolean | null;

    isPasswordSet?: boolean | null;

    allowAccessToOrganization?: boolean | null;

    defaultOutreachCircle?: OutreachCircle | null;

    defaultVolunteerOutreachCircle?: OutreachCircle | null;
}

export interface Media {
    id: string;

    url: string;

    fileName: string;

    fileSize: number;

    created: Date;

    updated?: Date | null;

    thumbnail: string;
}

export interface OutreachCircle {
    id: string;

    name: string;

    city?: string | null;

    state?: string | null;

    stateName?: string | null;

    country?: string | null;

    zip?: string | null;

    phoneNumber?: string | null;

    industry: string;

    introduction?: string | null;

    category?: string | null;

    logo?: Media | null;

    code?: Alias | null;

    defaultAlias?: Alias | null;

    audience?: Audience | null;

    admins?: (OutreachCircleUser | null)[] | null;

    activeAdminCount: number;

    currentAdminInfo?: OutreachCircleUser | null;

    candidate?: Candidate | null;

    status?: OutreachCircleStatus | null;

    supporterCount: number;

    supporting: boolean;

    actionCount: number;

    blockedDomains?: string[] | null;

    disclaimer?: string | null;

    referredBy?: string | null;

    emailTemplates: EmailTemplateList;

    textTemplates: TextTemplateList;

    supporterTextTemplates: TextTemplateList;

    isPublic?: boolean | null;

    product: string;

    plan: string;

    impressions?: number | null;

    trialDaysLeft: number;

    productPlanInfo?: ProductPlanInfo | null;

    supporterReplyToEmail?: string | null;

    archived?: boolean | null;

    archivedOn?: Date | null;

    groups?: GroupList | null;

    groupsCount?: number | null;

    aliasCount?: number | null;

    activeSupportersCount?: number | null;

    liveActionsCount?: number | null;

    additionalDetails?: OutreachCircleAdditionalDetails | null;

    facebookPixelCode?: string | null;

    integrations?: IntegrationList | null;

    tags?: TagList | null;

    created?: Date | null;

    hasMovedToMonthlyPlanFromOriginial?: boolean | null;

    hasAnyTagBeenApplied: boolean;

    keyDates?: KeyDate[] | null;

    totalUniqueSupportersRespondedToForms: number;

    totalUniqueRecipientsRespondedToSurvey: number;

    totalUniqueRecipientsRespondedToSearchAndSurvey: number;

    totalUniqueRecipientsRespondedToGroSurvey: number;

    isEligibleForRecruitingInfluencers: boolean;

    children?: OutreachCircle[] | null;

    pdiOrgId?: number | null;

    electionCycle?: ElectionCycle | null;

    isMFAEnabled?: boolean | null;

    geographies?: Geography[] | null;
}

export interface Alias {
    id: string;

    value?: string | null;

    created?: Date | null;

    isCode?: boolean | null;

    name?: string | null;

    description?: string | null;

    supporterCount?: number | null;

    groupCount?: number | null;

    isDefault?: boolean | null;

    isRapidOnboardingEnabled?: boolean | null;

    groups?: (Group | null)[] | null;

    isLandingPage?: boolean | null;

    logo?: Media | null;

    sharableUrl?: string | null;

    isAppOnly?: boolean | null;

    languagePreference?: LanguagePreferenceType | null;

    additionalDetails?: AliasAdditionalDetails | null;

    longURL?: string | null;

    shortURL?: string | null;

    linkedFlags: Flag[];
}

export interface Group {
    id: string;

    name: string;

    created: Date;

    updated?: Date | null;

    supporterCount: number;

    actionCount: number;

    aliasCount?: number | null;

    actions?: ActionList | null;

    aliases?: (Alias | null)[] | null;

    isAssignedToAction?: boolean | null;

    isDeleted: boolean;
}

export interface ActionList {
    items: (Action | null)[];

    total: number;
}

export interface Action {
    id: string;

    type: ActionType;

    name: string;

    alias: string;

    title?: string | null;

    description?: string | null;

    outreachCircleId?: string | null;

    outreachChannels: (OutreachChannel | null)[];

    status: ActionStatus;

    callToActionText?: string | null;

    startDate?: Date | null;

    endDate?: Date | null;

    created?: Date | null;

    updated?: Date | null;

    link?: string | null;

    totalCompletions: number;

    totalTakenCount: number;

    liveAt?: Date | null;

    expiredAt?: Date | null;

    emailTemplate?: EmailTemplate | null;

    textTemplate?: TextTemplate | null;

    totalReminders: number;

    allReminders?: (SupporterEmailMessage | null)[] | null;

    lastReminder?: SupporterEmailMessage | null;

    impression?: number | null;

    postActionText?: string | null;

    engagementId?: string | null;

    additionalDetails?: ActionAdditionalDetails | null;

    isOutreachMode?: boolean | null;

    matchWithSearchAndSurveyLists?: boolean | null;

    allowNonMappedContacts?: boolean | null;

    audienceActions?: (AudienceAction | null)[] | null;

    searchAndSurveyAudienceActions?: (AudienceAction | null)[] | null;

    filterCount?: number | null;

    position?: number | null;

    media?: Media | null;

    statusLogs?: (ActionStatusLog | null)[] | null;

    isEngagementRequired?: boolean | null;

    groups?: (Group | null)[] | null;

    groupsCount?: number | null;

    textSupporterSetCount: number;

    embedCode?: string | null;

    socialTemplate?: SocialTemplate | null;

    supporterForm?: SupporterForm | null;

    isVoterFileSetupRequired?: boolean | null;

    isVoterFileSetupComplete?: boolean | null;

    campaign?: Campaign | null;

    engagement?: Engagement | null;

    integrationType?: AllowedIntegrationDatabaseType | null;

    linkedTags?: TagList | null;

    canToggleOutreachMode?: boolean | null;

    canChangeListDatabase?: boolean | null;

    canAssignToSupporters?: boolean | null;

    canAssignToGroups?: boolean | null;

    p2pWorkspace?: P2PWorkspace | null;

    assignedSupportersCount: number;

    isSupporterListAssignmentComplete?: boolean | null;

    p2pListProgress?: P2PListProgress | null;

    searchAndSurveyNewContactsAudienceAction?: AudienceAction | null;

    searchAndSurveyForm?: SearchAndSurveyForm | null;

    groSurveyForm?: GroSurveyForm | null;

    uniqueSelectedAudienceCustomFieldsCount: number;

    selectedAudienceCustomFieldsForSearch?: (AudienceCustomField | null)[] | null;

    selectedAudienceCustomFieldsForSurvey?: (AudienceCustomField | null)[] | null;

    isArchived: boolean;

    deactivatedAt?: Date | null;

    terminatedAt?: Date | null;

    takenConfirmationText?: string | null;

    canSkipTakenConfirmation: boolean;

    turfingActionData?: TurfingActionData | null;

    initialPushNotificationTemplate?: SupporterPushNotificationTemplate | null;

    reminderPushNotificationTemplate?: SupporterPushNotificationTemplate | null;

    initialEmailNotificationTemplate?: SupporterEmailTemplate | null;

    reminderEmailNotificationTemplate?: SupporterEmailTemplate | null;

    notificationLogs?: (ActionNotificationLog | null)[] | null;

    syndicatedActionInfo?: SyndicatedActionInfo | null;

    p2pPhoneNumberInfo?: P2PPhoneNumberInfo | null;

    textingPhoneNumberInfo: P2PPhoneNumberInfo[];

    sharableLinks?: SharableLinks | null;

    isNonRelational: boolean;

    surveys?: ActionSurvey[] | null;

    textingInfo?: ActionTextingInfo | null;

    visibility: Visibility;

    phoneBankInfo?: ActionPhoneBankInfo | null;

    eventInformation?: EventInformation | null;

    eventSchedule?: EventSchedule | null;

    eventShifts?: Shift[] | null;

    actionAudienceActions?: ActionAudienceAction[] | null;

    turfGroups: TurfGroup[];

    isTurfingAssignmentComplete: boolean;

    linkedAlias?: Alias | null;

    actionSurveyQuestions: ActionSurveyQuestion[];

    doorToDoorMapCenter?: Location | null;

    doorToDoorMapFunction?: DoorToDoorMapFunction | null;

    acquisitionTypeId?: string | null;
}

export interface EmailTemplate {
    id: string;

    name: string;

    subject: string;

    created: Date;

    updated?: Date | null;

    contentForEditor: string;

    contentForPreview: string;

    isOutreachCircleBrandExist: boolean;

    fromNameAlias?: string | null;
}

export interface TextTemplate {
    id: string;

    name: string;

    created: Date;

    updated?: Date | null;

    contentForEditor: string;

    contentForPreview: string;
}

export interface SupporterEmailMessage {
    sentBy: string;

    created: Date;

    supportersCount: number;
}

export interface ActionAdditionalDetails {
    isTreatmentEnabled?: boolean | null;

    treatment?: number | null;

    everyActionFormId?: string | null;

    everyActionFormHasRedirect?: boolean | null;

    eventLink?: string | null;

    allowContactImport?: boolean | null;

    openUrl?: string | null;

    eventName?: string | null;

    isEmbeddable?: boolean | null;

    phone2ActionUrl?: string | null;

    playVideoInAppEnabled?: boolean | null;

    videoId?: string | null;

    videoSource?: string | null;

    previousStatus?: string | null;

    audienceFilter?: string | null;

    isSharingEnabled?: boolean | null;

    shareButtonText?: string | null;

    isSyndicatedAction?: boolean | null;

    reviewWarningInfo?: ReviewWarningInfo | null;

    requiredIntegrationType?: AllowedIntegrationDatabaseType | null;

    p2pTextingInfo?: P2PTextingInfo | null;

    callTemplate?: string | null;

    visitTemplate?: string | null;

    facebookConfig?: FacebookConfig | null;

    groConfig?: ActionGroConfig | null;

    contactTypeLabel?: ContactTypeLabel | null;

    addressBasedCanvassingConfig?: AddressBasedCanvassingActionConfig | null;

    pdiOnTheGoCanvassingConfig?: PdiOnTheGoCanvassingConfig | null;

    isAnonymousSearchAndSurveyEnabled?: boolean | null;

    createdByAdminName?: string | null;

    memberDisplayFields?: (string | null)[] | null;

    phoneBankingConfig?: PhoneBankingConfig | null;

    eventXConfig?: EventXConfig | null;

    doorToDoorConfig?: DoorToDoorActionConfig | null;

    organizerConfig?: OrganizerConfig | null;

    textingConfig?: TextingConfig | null;
}

export interface ReviewWarningInfo {
    emailTemplate?: boolean | null;

    textTemplate?: boolean | null;

    socialTextTemplate?: boolean | null;
}

export interface P2PTextingInfo {
    preferredAreaCodes?: (number | null)[] | null;

    p2pSchedule?: P2PSchedule | null;

    p2pError?: P2PError | null;

    allowUninterruptedTexting?: boolean | null;

    disableManageReplies?: boolean | null;

    disableEditMessage?: boolean | null;
}

export interface P2PSchedule {
    startTime: P2PTime;

    endTime: P2PTime;
}

export interface P2PTime {
    hour: number;

    minute: number;

    timezone: string;
}

export interface P2PError {
    oldAreaCodes?: (number | null)[] | null;

    pauseReason?: P2PPauseReason | null;
}

export interface FacebookConfig {
    pageUrl?: string | null;

    postId?: string | null;
}

export interface ActionGroConfig {
    turfingStrategy?: TurfingStrategyEnum | null;

    supporterIdFieldInList?: string | null;

    supporterEmailFieldInList?: string | null;
}

export interface ContactTypeLabel {
    matched?: ContactTypeLabelInfo | null;

    unmatched?: ContactTypeLabelInfo | null;

    ambiguous?: ContactTypeLabelInfo | null;
}

export interface ContactTypeLabelInfo {
    title?: string | null;

    description?: string | null;

    position?: number | null;
}

export interface AddressBasedCanvassingActionConfig {
    maxMemberAssignmentForSupporter?: number | null;

    turfingRangeInMiles?: number | null;
}

export interface PdiOnTheGoCanvassingConfig {
    pdiProject?: PdiProject | null;

    allowAddingMembers?: boolean | null;

    allowCensusBlocks?: boolean | null;
}

export interface PdiProject {
    pdiProjectId?: string | null;

    pdiProjectName?: string | null;
}

export interface PhoneBankingConfig {
    dialerType: DialerType;
}

export interface EventXConfig {
    isLocationPublic?: boolean | null;

    isNonWorkingGuestsAllowed?: boolean | null;

    shiftAssignmentAvailability?: ShiftAssignmentAvailability | null;
}

export interface DoorToDoorActionConfig {
    isGotvFollowupMode?: boolean | null;

    includeAllVotersInHouseholds?: boolean | null;

    enableHouseholdObservations?: boolean | null;
}

export interface OrganizerConfig {
    contactNumber?: string | null;

    name?: string | null;
}

export interface TextingConfig {
    isAutoTextingEnabled?: boolean | null;

    textingSchedule?: TextingSchedule | null;

    disableManageReplies?: boolean | null;

    disableEditMessage?: boolean | null;
}

export interface TextingSchedule {
    startTime: TextingTime;

    endTime: TextingTime;
}

export interface TextingTime {
    hour: number;

    minute: number;

    timezone: string;
}

export interface AudienceAction {
    id: string;

    status: AudienceActionStatus;

    count?: number | null;

    created?: Date | null;

    updated?: Date | null;

    createdBy?: string | null;

    failureReason?: string | null;

    displayName: string;

    database: AudienceActionDatabase;

    source?: AudienceActionSource | null;

    integrationType?: IntegrationType | null;

    transaction: AudienceActionTransaction;

    format: AudienceActionFormat;

    listType: ListType;

    isEnabled: boolean;

    additionalDetails?: AudienceActionAdditionalDetails | null;

    mapping?: AudienceActionMapping | null;

    houseCounts?: number | null;

    projectCounts?: number | null;

    latestListImportLog?: ListImportLog | null;
}

export interface AudienceActionAdditionalDetails {
    schedule?: SoqlSchedule | null;

    pdiInternalListDetails?: PdiList | null;

    importedForVoterDensity?: boolean | null;
}

export interface SoqlSchedule {
    frequency: Frequency;

    dayOfWeek?: number | null;

    dayOfMonth?: number | null;
}

export interface PdiList {
    id: number;

    name?: string | null;

    listType?: string | null;
}

export interface AudienceActionMapping {
    id: string;

    name: string;

    mappingData: Json;
}

export interface ListImportLog {
    id: string;

    message: string;

    created: Date;
}

export interface ActionStatusLog {
    fromStatus?: string | null;

    toStatus?: string | null;

    created?: Date | null;
}

export interface SocialTemplate {
    id: string;

    name: string;

    created: Date;

    updated?: Date | null;

    contentForEditor: string;

    contentForPreview: string;
}

export interface SupporterForm {
    id: string;

    name: string;

    supporterFormQuestions?: SupporterFormQuestion[] | null;

    copyOf?: string | null;

    created: Date;

    updated?: Date | null;

    supporterFormIntegrationType?: SupporterFormIntegrationType | null;

    supporterSubmissionsCount?: number | null;

    outreachCircle: OutreachCircle;
}

export interface SupporterFormQuestion {
    id: string;

    supporterFormId: string;

    questionText: string;

    questionType: SupporterFormQuestionType;

    isMandatory: boolean;

    position: number;

    options: SupporterFormOption[];

    ngpSurveyQuestionId?: number | null;

    ngpSurveyQuestion?: NgpSurveyQuestion | null;

    questionIntegrationType?: SupporterFormQuestionIntegrationType | null;

    totalUniqueSupportersResponded: number;
}

export interface SupporterFormOption {
    id: string;

    optionText: string;

    position: number;

    optionType: SupporterFormOptionType;

    ngpSurveyResponseId?: number | null;

    ngpActivistCode?: NgpActivistCode | null;

    assignedGroupId?: string | null;

    totalUniqueSupportersResponded: number;
}

export interface NgpActivistCode {
    activistCodeId?: number | null;

    type?: string | null;

    name?: string | null;

    mediumName?: string | null;

    shortName?: string | null;

    description?: string | null;

    scriptQuestion?: string | null;

    status?: string | null;

    isMultiAssign?: boolean | null;
}

export interface NgpSurveyQuestion {
    surveyQuestionId: number;

    type: string;

    cycle: number;

    name: string;

    mediumName: string;

    shortName: string;

    scriptQuestion: string;

    status: string;

    responses?: NgpSurveyResponse[] | null;
}

export interface NgpSurveyResponse {
    surveyResponseId: number;

    name: string;

    mediumName: string;

    shortName: string;
}

export interface Campaign {
    id: string;

    name: string;
}

export interface Engagement {
    id: string;

    name: string;

    type: EngagementType;

    url?: string | null;

    description?: string | null;

    outreachCircleId: string;

    questions?: (EngagementQuestion | null)[] | null;

    created: Date;

    message?: string | null;

    additionalDetails?: Json | null;

    recipientSubmissionsCount?: number | null;

    copyOf?: string | null;

    integrationType?: AllowedIntegrationDatabaseType | null;

    outreachCircle: OutreachCircle;
}

export interface EngagementQuestion {
    id: string;

    questionText: string;

    engagementId: string;

    options: EngagementOption[];

    isMandatory: boolean;

    ngpSurveyQuestionId?: number | null;

    pdiQuestionId?: string | null;

    position?: number | null;

    type?: EngagementQuestionType | null;

    hasResponseLogic?: boolean | null;

    ngpSurveyQuestion?: NgpSurveyQuestion | null;

    pdiQuestion?: PdiQuestion | null;

    isMultipleOptionsAllowed?: boolean | null;

    additionalDetails?: Json | null;

    totalUniqueRecipientsResponded: number;
}

export interface EngagementOption {
    id: string;

    engagementQuestionId: string;

    optionText: string;

    linkedEngagement?: Engagement | null;

    linkedEngagementId?: string | null;

    ngpSurveyResponseId?: number | null;

    pdiAnswerId?: string | null;

    additionalDetails?: Json | null;

    totalResponseCount?: number | null;

    position: number;

    type: EngagementOptionType;

    ngpActivistCode?: NgpActivistCode | null;

    linkedEngagementQuestionId?: string | null;

    totalUniqueRecipientsResponded: number;
}

export interface PdiQuestion {
    id: string;

    organizationName: string;

    question: string;

    questionLabel?: string | null;

    questionDescription?: string | null;

    type?: string | null;

    category?: string | null;

    candidate?: string | null;

    default?: boolean | null;

    answerOptions: (PdiAnswerOption | null)[];
}

export interface PdiAnswerOption {
    id?: string | null;

    flagId: string;

    flagIdDescription?: string | null;

    displayCode?: string | null;
}

export interface TagList {
    total: number;

    items: (Tag | null)[];
}

export interface Tag {
    id: string;

    name: string;

    outreachCircleId: string;

    isDefault: boolean;

    linkedCannedResponse?: CannedResponse | null;

    actions?: ActionList | null;

    integrations?: (TagIntegrations | null)[] | null;

    type: TagType;
}

export interface CannedResponse {
    id: string;

    response: string;
}

export interface TagIntegrations {
    type?: IntegrationType | null;

    data?: TagIntegrationData | null;
}

export interface TagIntegrationData {
    ngpActivistCodes?: (NgpActivistCode | null)[] | null;

    pdiFlagIds?: (PdiFlagId | null)[] | null;
}

export interface PdiFlagId {
    id: string;

    flagId: string;

    flagIdDescription: string;

    compile: string;

    isDefault?: boolean | null;
}

export interface P2PWorkspace {
    id: string;

    name: string;

    outreachCircleId: string;

    integrationType: AllowedIntegrationDatabaseType;

    p2pScripts: P2PScript[];

    tags?: P2PWorkspaceTag[] | null;

    linkedP2PScripts?: LinkedP2PScript[] | null;

    firstP2PScriptId: string;

    created?: Date | null;
}

export interface P2PScript {
    id: string;

    p2pWorkspaceId: string;

    name: string;

    p2pScriptSurveyQuestionId?: string | null;

    p2pScriptSurveyQuestion?: P2PScriptSurveyQuestion | null;

    message: string;

    p2pScriptType: P2PScriptType;

    attachments?: Media[] | null;
}

export interface P2PScriptSurveyQuestion {
    id: string;

    name: string;

    outreachCircleId: string;

    questionText: string;

    options: P2PScriptSurveyQuestionOption[];

    ngpSurveyQuestionId?: number | null;

    pdiQuestionId?: string | null;

    integrationType?: AllowedIntegrationDatabaseType | null;

    ngpSurveyQuestion?: NgpSurveyQuestion | null;

    pdiQuestion?: PdiQuestion | null;

    p2pScriptSurveyQuestionType: P2PScriptSurveyQuestionType;

    created?: Date | null;

    updated?: Date | null;
}

export interface P2PScriptSurveyQuestionOption {
    id: string;

    optionText: string;

    position: number;

    ngpSurveyResponseId?: number | null;

    pdiAnswerId?: string | null;

    ngpActivistCode?: NgpActivistCode | null;

    type: EngagementOptionType;
}

export interface P2PWorkspaceTag {
    p2pWorkspaceId: string;

    tagId: string;

    p2pScriptId?: string | null;

    name: string;

    cannedResponseText?: string | null;

    isDefault: boolean;

    position?: number | null;
}

export interface LinkedP2PScript {
    p2pScriptId: string;

    p2pScriptSurveyQuestionOptionId: string;

    nextP2PScriptId: string;
}

export interface P2PListProgress {
    totalInitialTextSent: number;

    totalListSize: number;
}

export interface SearchAndSurveyForm {
    id: string;

    name: string;

    searchAndSurveyFormQuestions?: SearchAndSurveyFormQuestion[] | null;

    copyOf?: string | null;

    created: Date;

    updated?: Date | null;

    searchAndSurveyFormIntegrationType?: SearchAndSurveyFormIntegrationType | null;

    outreachCircle: OutreachCircle;

    totalUniqueResponses?: number | null;
}

export interface SearchAndSurveyFormQuestion {
    id: string;

    searchAndSurveyFormId: string;

    questionText: string;

    questionType: SearchAndSurveyFormQuestionType;

    isMandatory: boolean;

    position: number;

    options: SearchAndSurveyFormOption[];

    ngpSurveyQuestionId?: number | null;

    ngpSurveyQuestion?: NgpSurveyQuestion | null;

    questionIntegrationType?: SearchAndSurveyFormQuestionIntegrationType | null;

    totalUniqueResponses?: number | null;

    pdiQuestion?: PdiQuestion | null;
}

export interface SearchAndSurveyFormOption {
    id: string;

    optionText: string;

    position: number;

    optionType: SearchAndSurveyFormOptionType;

    ngpSurveyResponseId?: number | null;

    ngpActivistCode?: NgpActivistCode | null;

    totalUniqueResponses?: number | null;

    pdiAnswerId?: string | null;
}

export interface GroSurveyForm {
    id: string;

    name: string;

    groSurveyFormQuestions?: GroSurveyFormQuestion[] | null;

    copyOf?: string | null;

    created: Date;

    updated?: Date | null;

    groSurveyFormIntegrationType?: GroSurveyFormIntegrationType | null;

    outreachCircle: OutreachCircle;

    totalUniqueResponses?: number | null;
}

export interface GroSurveyFormQuestion {
    id: string;

    groSurveyFormId: string;

    questionText: string;

    questionType: GroSurveyFormQuestionType;

    isMandatory: boolean;

    position: number;

    options: GroSurveyFormOption[];

    questionIntegrationType?: GroSurveyFormQuestionIntegrationType | null;

    totalUniqueResponses?: number | null;

    pdiQuestion?: PdiQuestion | null;

    ngpSurveyQuestion?: NgpSurveyQuestion | null;
}

export interface GroSurveyFormOption {
    id: string;

    optionText: string;

    position: number;

    optionType: GroSurveyFormOptionType;

    totalUniqueResponses?: number | null;

    pdiAnswerId?: string | null;

    ngpSurveyResponseId?: number | null;

    ngpActivistCode?: NgpActivistCode | null;
}

export interface AudienceCustomField {
    id: string;

    name?: string | null;

    created?: Date | null;

    position: number;
}

export interface TurfingActionData {
    isTurfingComplete: boolean;

    isInitialTurfingComplete: boolean;

    latestTurfingLog?: TurfingLog | null;
}

export interface TurfingLog {
    id: string;

    unassignedCount?: number | null;

    unassignedListLink?: string | null;

    completedAt?: Date | null;

    created: Date;
}

export interface SupporterPushNotificationTemplate {
    id: string;

    code: SupporterPushNotificationTemplateCode;

    pushNotificationTemplate: PushNotificationTemplate;
}

export interface PushNotificationTemplate {
    id: string;

    title: string;

    body: string;

    created: Date;

    updated?: Date | null;
}

export interface SupporterEmailTemplate {
    id: string;

    emailTemplateId: string;

    outreachCircleId: string;

    code: SupporterEmailTemplateCode;

    name: string;

    fromNameAlias?: string | null;

    subject: string;

    created: Date;

    updated?: Date | null;

    contentForEditor?: string | null;

    contentForPreview?: string | null;
}

export interface EmailNotificationLog {
    created?: Date | null;

    code: SupporterEmailTemplateCode;

    count: number;
}

export interface PushNotificationLog {
    created?: Date | null;

    code: SupporterPushNotificationTemplateCode;

    count: number;
}

export interface SyndicatedActionInfo {
    organizationName: string;
}

export interface P2PPhoneNumberInfo {
    phoneNumber: string;

    areaCode: number;

    status: PurchasedPhoneNumberStatus;

    purchaseDate: Date;
}

export interface SharableLinks {
    shortUrl?: string | null;

    longUrl?: string | null;
}

export interface ActionSurvey {
    id: string;

    priority?: number | null;

    type?: ListSelectionType | null;

    survey: Survey;
}

export interface Survey {
    id?: string | null;

    name?: string | null;

    type: SurveyType;

    status: SurveyStatus;

    created: Date;

    segments?: SurveySegment[] | null;

    segmentsCount: number;

    isSurveyEditable?: boolean | null;
}

export interface SurveySegment {
    id: string;

    surveyId: string;

    script?: string | null;

    position: number;

    linkedQuestion?: SurveyQuestion | null;

    segmentBranches?: (SurveySegmentBranch | null)[] | null;

    attachments?: Media[] | null;
}

export interface SurveyQuestion {
    id: string;

    text: string;

    label?: string | null;

    description?: string | null;

    status: SurveyQuestionStatus;

    responses: (SurveyQuestionResponse | null)[];

    type: SurveyQuestionType;

    purpose?: Purpose | null;

    isQuestionEditable?: boolean | null;
}

export interface SurveyQuestionResponse {
    id: string;

    flag: Flag;

    code?: string | null;

    description?: string | null;

    position: number;
}

export interface Flag {
    id: string;

    value: string;

    description?: string | null;

    status: FlagStatus;

    flagAssociation?: FlagAssociation | null;

    isDefault?: boolean | null;

    isContact?: boolean | null;

    isFlagEditable?: boolean | null;
}

export interface FlagAssociation {
    id: string;

    displayName: string;

    shortName?: string | null;
}

export interface Purpose {
    id: string;

    candidate?: Candidate | null;

    ballotMeasure?: BallotMeasure | null;

    issue?: Issue | null;

    description?: string | null;

    electionCycleDates?: Date[] | null;

    status: PurposeStatus;

    isDefault: boolean;

    isPurposeEditable: boolean;
}

export interface Candidate {
    id: string;

    firstName?: string | null;

    lastName?: string | null;

    enabled?: boolean | null;

    image?: Media | null;

    name: string;

    candidateOffice?: CandidateOffice | null;

    description?: string | null;

    electionCycles: ElectionCycle[];

    isDefault: boolean;
}

export interface CandidateOffice {
    id: string;

    value: string;
}

export interface ElectionCycle {
    id: string;

    date: Date;
}

export interface BallotMeasure {
    id: string;

    campaignName: string;

    issueCategory?: IssueCategory | null;

    progressiveResponseIndicator?: ProgressiveResponseIndicator | null;

    description: string;

    electionCycles: ElectionCycle[];

    isDefault: boolean;
}

export interface IssueCategory {
    id: string;

    value: string;
}

export interface ProgressiveResponseIndicator {
    id: string;

    value: string;
}

export interface Issue {
    id: string;

    issueCategory?: IssueCategory | null;

    progressiveResponseIndicator?: ProgressiveResponseIndicator | null;

    description?: string | null;

    electionCycles: ElectionCycle[];

    isDefault: boolean;
}

export interface SurveySegmentBranch {
    segmentId: string;

    surveyQuestionResponseId?: string | null;

    nextSegmentId?: string | null;
}

export interface ActionTextingInfo {
    totalTextsCount: number;

    textsSentCount: number;

    repliesCount: number;

    textDeliveredCount: number;

    textFailedCount: number;

    textingStatus: TextingStatus;
}

export interface ActionPhoneBankInfo {
    callsMadeCount: number;

    contactFlagsCount: number;

    nonContactFlagsCount: number;

    accountSetupFailed?: boolean | null;

    listImportFailed?: boolean | null;
}

export interface EventInformation {
    locationType?: LocationType | null;

    venue?: string | null;

    locationId?: string | null;

    location?: OutreachCircleLocation | null;

    contactNumber?: string | null;

    contactEmail?: string | null;

    locationCoordinates?: Location | null;
}

export interface OutreachCircleLocation {
    id?: string | null;

    outreachCircleId: string;

    name: string;

    address?: string | null;

    lat?: number | null;

    lon?: number | null;

    landmark?: string | null;
}

export interface Location {
    lat?: number | null;

    lon?: number | null;
}

export interface EventSchedule {
    eventSeries: EventSeries;

    eventDate: Date;

    startTime: TimeWithTimezone;

    endTime: TimeWithTimezone;
}

export interface EventSeries {
    id: string;

    name: string;

    title: string;

    description?: string | null;

    image?: Media | null;

    status: ActionStatus;

    eventInformation: EventInformation;

    eventSchedule: EventSeriesSchedule;

    isLocationPublic?: boolean | null;

    isNonWorkingGuestsAllowed?: boolean | null;

    shiftAssignmentAvailability?: ShiftAssignmentAvailability | null;

    eventVisibility?: Visibility | null;

    shiftSeries: ShiftSeries[];

    eventConfig: EventConfig;

    created: Date;

    updated?: Date | null;
}

export interface EventSeriesSchedule {
    frequency?: EventFrequency | null;

    startDate?: Date | null;

    endDate?: Date | null;

    recurrenceNumber?: number | null;

    dayOfTheWeek?: DayOfTheWeek | null;

    eventTime?: EventTime | null;
}

export interface EventTime {
    startTime?: TimeWithTimezone | null;

    endTime?: TimeWithTimezone | null;
}

export interface TimeWithTimezone {
    hour: number;

    minute: number;

    timezone: string;
}

export interface ShiftSeries {
    id: string;

    manager?: SupporterView | null;

    schedule?: ShiftSchedule | null;

    description?: string | null;

    activity?: Activity | null;

    goalCount?: number | null;

    created: Date;

    updated?: Date | null;

    linkedTurfGroups: TurfGroup[];
}

export interface ShiftSchedule {
    startTime: TimeWithTimezone;

    endTime: TimeWithTimezone;
}

export interface Activity {
    id: string;

    name: string;

    color?: string | null;
}

export interface TurfGroup {
    id: string;

    name: string;

    description?: string | null;

    turfs: Turf[];

    geom: Json;

    color?: string | null;

    center: Location;

    actionId: string;
}

export interface Turf {
    id: string;

    name?: string | null;

    type: TurfType;

    assignedSupporters: SupporterView[];

    geom: Json;

    displayId: number;

    center: Location;

    actionId: string;

    isExpired: boolean;

    status: CanvassingMemberStatus;

    isAssigned: boolean;

    assignmentSource?: TurfAssignmentSource | null;

    source?: TurfSource | null;

    color?: string | null;

    memberCount: number;

    householdCount: number;
}

export interface EventConfig {
    id: string;

    name: string;

    title: string;

    description?: string | null;

    image?: Media | null;

    status: ActionStatus;

    eventInformation?: EventInformation | null;

    eventSchedule?: EventConfigSchedule | null;

    isLocationPublic?: boolean | null;

    isNonWorkingGuestsAllowed?: boolean | null;

    shiftAssignmentAvailability?: ShiftAssignmentAvailability | null;

    eventVisibility?: Visibility | null;

    shifts: EventConfigShift[];

    created: Date;

    updated?: Date | null;

    hasShiftInCurrentWeek?: boolean | null;
}

export interface EventConfigSchedule {
    frequency?: EventFrequency | null;

    startDate?: Date | null;

    endDate?: Date | null;

    recurrenceNumber?: number | null;

    customConfigs?: CustomEventConfigSchedule[] | null;

    eventTime?: EventTime | null;
}

export interface CustomEventConfigSchedule {
    dayOfTheWeek?: DayOfTheWeek | null;

    startTime?: TimeWithTimezone | null;

    endTime?: TimeWithTimezone | null;
}

export interface EventConfigShift {
    id: string;

    manager?: SupporterView | null;

    schedule?: ShiftSchedule | null;

    description?: string | null;

    goalCount?: number | null;

    dayOfTheWeek?: DayOfTheWeek | null;

    activity?: Activity | null;
}

export interface Shift {
    id: string;

    manager?: SupporterView | null;

    schedule?: ShiftSchedule | null;

    goalCount?: number | null;

    activity?: Activity | null;

    created: Date;

    updated?: Date | null;

    shiftGuestAttendanceCount: ShiftGuestAttendanceCount;

    workingVolunteersAttendanceCount: WorkingVolunteersAttendanceCount;

    linkedTurfGroups?: TurfGroup[] | null;

    linkedActions?: Action[] | null;

    startAt: Date;

    endAt: Date;

    assignmentAvailability?: ShiftAssignmentAvailability | null;

    canTakeAssignment: boolean;

    description?: string | null;

    action?: Action | null;

    linkedAlias?: Alias | null;

    isSupporterAssignedToShift: boolean;
}

export interface ShiftGuestAttendanceCount {
    totalCount: number;

    yesCount: number;

    noCount: number;

    maybeCount: number;
}

export interface WorkingVolunteersAttendanceCount {
    totalCount: number;

    yesCount: number;

    noCount: number;
}

export interface ActionAudienceAction {
    id: string;

    status?: AudienceActionStatus | null;

    priority?: number | null;

    type?: ListSelectionType | null;

    audienceAction?: AudienceAction | null;

    tags: Tag[];

    latestListImportLog?: ListImportLog | null;
}

export interface ActionSurveyQuestion {
    type: ActionSurveyQuestionType;

    surveyQuestion: SurveyQuestion;
}

export interface DoorToDoorMapFunction {
    functionName: string;

    parameters: Json;
}

export interface AliasAdditionalDetails {
    applyNationBuilderTagsToAllCustomAliases?: boolean | null;

    nationBuilderPeopleTags?: (NationBuilderPeopleTags | null)[] | null;

    applyNgpActivistCodeToAllCustomAliases?: boolean | null;

    pdiFlags?: (PdiFlagId | null)[] | null;

    ngpActivistCodes?: (NgpActivistCode | null)[] | null;

    applyPDIFlagsToAllCustomAliases?: boolean | null;
}

export interface NationBuilderPeopleTags {
    name: string;
}

export interface Audience {
    id: string;

    customfields?: (AudienceCustomField | null)[] | null;

    created: Date;

    updated?: Date | null;

    audienceActionInProgress?: AudienceAction | null;
}

export interface OutreachCircleUser {
    id: string;

    userRole?: string | null;

    additionalDetails?: OutreachCircleUserAdditionalDetails | null;

    created: Date;

    invitedOn?: Date | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    joinedOn?: Date | null;

    invitedBy?: string | null;

    isExistingUser?: boolean | null;

    phoneNumber?: string | null;

    isOwner?: boolean | null;

    isAccountOwner?: boolean | null;

    isMFAEnabled?: boolean | null;

    isAddedByOrganization: boolean;

    isAddedByOutreachCircle: boolean;

    hasReadOnlyAccessToOutreachCircle: boolean;

    organizations?: (Organization | null)[] | null;

    isEmailBounced?: boolean | null;

    geographyList: Geography[];

    workingGeographyList: Geography[];

    roleList: Role[];

    status: OutreachCircleUserStatus;

    landingCoordinates?: Location | null;
}

export interface OutreachCircleUserAdditionalDetails {
    invitationDetails?: OutreachCircleUserInvitationDetails | null;

    advancedSettings?: UserAdvanceSettings | null;
}

export interface OutreachCircleUserInvitationDetails {
    email: string;

    firstName: string;

    lastName: string;
}

export interface UserAdvanceSettings {
    accessExpiry?: Date | null;

    accessToVoters?: string | null;
}

export interface Organization {
    id: string;

    name: string;

    defaultAlias: OrganizationAlias;

    totalOutreachCircles: number;

    totalSupporters: number;

    totalAdminCount: number;

    totalActionCount: number;

    currentAdminInfo?: OrganizationUser | null;

    orgUrl?: string | null;

    mainOutreachCircle?: OutreachCircle | null;

    mainOCButtonText?: string | null;

    logo?: Media | null;

    introduction?: string | null;

    emailTemplates: EmailTemplateList;

    textTemplates: TextTemplateList;

    disclaimer?: string | null;

    additionalDetails?: OrganizationAdditionalDetails | null;

    totalUniqueSupportersRespondedToForms: number;

    totalUniqueRecipientsRespondedToSurvey: number;

    totalUniqueRecipientsRespondedToSearchAndSurvey: number;

    totalUniqueRecipientsRespondedToGroSurvey: number;
}

export interface OrganizationAlias {
    id: string;

    value: string;

    isDefault?: boolean | null;
}

export interface OrganizationUser {
    id: string;

    additionalDetails?: OrganizationUserAdditionalDetails | null;

    created: Date;

    invitedOn?: Date | null;

    firstName?: string | null;

    lastName?: string | null;

    email?: string | null;

    joinedOn?: Date | null;

    invitedBy?: string | null;

    isExistingUser?: boolean | null;

    phoneNumber?: string | null;

    isOwner: boolean;

    hasReadOnlyAccessToOutreachCircle: boolean;
}

export interface OrganizationUserAdditionalDetails {
    invitationDetails?: OrganizationUserInvitationDetails | null;

    uiPreferences?: Json | null;
}

export interface OrganizationUserInvitationDetails {
    email: string;

    firstName: string;

    lastName: string;
}

export interface EmailTemplateList {
    total?: number | null;

    items?: (EmailTemplate | null)[] | null;
}

export interface TextTemplateList {
    total?: number | null;

    items?: (TextTemplate | null)[] | null;
}

export interface OrganizationAdditionalDetails {
    whileLabelInfo?: OrganizationWhileLabelInfo | null;
}

export interface OrganizationWhileLabelInfo {
    isWhiteLabeled?: boolean | null;

    appDownloadUrl?: OrganizationAppDownloadUrl | null;

    deepLinkUrl?: string | null;
}

export interface OrganizationAppDownloadUrl {
    android?: string | null;

    iOS?: string | null;
}

export interface Geography {
    id: string;

    label: string;

    stateCode?: string | null;
}

export interface Role {
    id: string;

    label: string;

    isDefault?: boolean | null;

    isRoleEditable?: boolean | null;

    permissions?: MasterPermission[] | null;
}

export interface MasterPermission {
    id: string;

    label: string;

    type: MasterPermissionType;
}

export interface OutreachCircleStatus {
    createdAndActivate?: boolean | null;

    setup?: OutreachCircleSetup | null;
}

export interface OutreachCircleSetup {
    isAudienceCreated?: boolean | null;

    isActionCreated?: boolean | null;

    hasSupporter?: boolean | null;
}

export interface ProductPlanInfo {
    canChangeVisibility: boolean;

    canIntegrateVAN: boolean;

    hasOneWayVANIntegration: boolean;

    hasTwoWayVANIntegration: boolean;

    canCreateSurveys: boolean;

    canGenerateReports: boolean;

    canScheduleActions: boolean;

    canCreateAliasNameOnReminders: boolean;

    canRemoveBranding: boolean;

    canImportVoterFile: boolean;

    canAddUniqueTrackingLinks: boolean;

    voterFileLimit: number;

    uniqueTrackingLinksLimit: number;

    invitedSupportersLimit: number;

    canFilterVoter: boolean;

    canTextAList: boolean;

    canIntegrateEveryAction: boolean;

    canIntegrateNationBuilder: boolean;

    canIntegratePDI: boolean;

    canIntegrateTCR: boolean;

    canCreateGroup: boolean;

    canIntegratePhone2ActionForm: boolean;

    canIntegrateGoogleBigQuery: boolean;

    canRapidlyOnboard: boolean;

    canCreateSupporterForms: boolean;

    canIntegrateFacebookPixel: boolean;

    canLinkActionTags: boolean;

    canCreateP2PAction: boolean;

    canCreateSearchAndSurveyAction: boolean;

    canAssignSupportersToAnyAction: boolean;

    canIntegrateSalesforce: boolean;

    canCreateGrassRootOrganizingAction: boolean;

    canCustomizeActionNotification: boolean;

    canCreateGRORelationalAction: boolean;
}

export interface GroupList {
    items: (Group | null)[];

    total: number;
}

export interface OutreachCircleAdditionalDetails {
    whileLabelInfo?: OutreachCircleWhileLabelInfo | null;

    allowContactImport?: boolean | null;

    supporterSettings?: OutreachCircleSupporterSettings | null;

    allowCampaigns?: boolean | null;

    headquarterInfo?: HeadquarterInfo | null;

    p2pConfig?: P2PConfig | null;

    canIntegrateSalesforce?: boolean | null;

    grassRootOrganizingConfig?: GrassRootOrganizingConfig | null;

    groRelationalConfig?: GroRelationalConfig | null;

    specialActionsAllowed?: (ActionType | null)[] | null;

    nonRelationalDataProviderSource?: AudienceActionSource | null;

    timeZone?: string | null;

    d2dConfig?: D2DConfig | null;

    actionVisibility?: ActionVisibility[] | null;

    generalSettings?: GeneralSetting | null;
}

export interface OutreachCircleWhileLabelInfo {
    isWhiteLabeled?: boolean | null;

    appDownloadUrl?: OutreachCircleAppDownloadUrl | null;

    contactTypeLabelPosition?: OutreachCircleContactTypeLabelPosition | null;

    deepLinkUrl?: string | null;

    mobileAppBundleId?: string | null;

    appName?: string | null;

    appLogoURL?: string | null;

    faviconURL?: string | null;

    isWhiteLabeledOrg?: boolean | null;
}

export interface OutreachCircleAppDownloadUrl {
    android?: string | null;

    iOS?: string | null;
}

export interface OutreachCircleContactTypeLabelPosition {
    matched?: number | null;

    unmatched?: number | null;

    ambiguous?: number | null;
}

export interface OutreachCircleSupporterSettings {
    suppressSupporterWelcomeEmails?: boolean | null;

    suppressActionLiveEmails?: boolean | null;

    suppressThankYouOnFirstActionTakenEmails?: boolean | null;

    suppressSupporterWeeklyDigestEmail?: boolean | null;

    suppressTemporaryPasswordAndMagicLinkEmail?: boolean | null;

    supporterAutoEmailsFromName?: string | null;
}

export interface HeadquarterInfo {
    latitude: number;

    longitude: number;
}

export interface P2PConfig {
    isP2PActionEnabled?: boolean | null;

    isA2P10DLCRegistrationComplete?: boolean | null;

    architecture?: P2PMessagingServiceArchitecture | null;
}

export interface GrassRootOrganizingConfig {
    isEnabled?: boolean | null;
}

export interface GroRelationalConfig {
    isEnabled?: boolean | null;
}

export interface D2DConfig {
    autoApproveEnabled?: boolean | null;
}

export interface ActionVisibility {
    type?: ActionHeaderType | null;

    enabled?: boolean | null;
}

export interface GeneralSetting {
    bypassFilePickupPassword?: boolean | null;

    bypassBillingCode?: boolean | null;

    yunMode?: boolean | null;

    processWebFormTransAutomatically?: boolean | null;

    matchPercentage?: number | null;

    dataSharePassword?: string | null;

    campaignName?: string | null;

    campaignNameForBilling?: string | null;

    campaignWebsite?: string | null;

    campaignEmail?: string | null;
}

export interface IntegrationList {
    total: number;

    aggregateTotal: number;

    items?: (Integration | null)[] | null;
}

export interface Integration {
    id: string;

    data: Json;

    type: IntegrationType;

    created: Date;

    updated?: Date | null;
}

export interface KeyDate {
    id: string;

    date?: Date | null;

    name: string;

    isApplicable: boolean;

    isSystemAdded: boolean;
}

export interface SupporterTurfAssignment {
    id: string;

    turf: Turf;

    expiresAt?: Date | null;

    isCaptainMode?: boolean | null;
}

export interface AssignedMemberList {
    items: (AssignedMember | null)[];

    total: number;
}

export interface AssignedMember {
    contact: AssignedContact;

    assignedMemberCanvassedInfo?: AssignedMemberCanvassedInfo | null;

    searchCustomFields?: (CustomField | null)[] | null;

    surveyCustomFields?: (CustomField | null)[] | null;

    customFields?: (CustomField | null)[] | null;
}

export interface AssignedContact {
    id?: string | null;

    member_id: string;

    display_member_id: string;

    first_name?: string | null;

    middle_name?: string | null;

    last_name?: string | null;

    emails?: (ContactEmail | null)[] | null;

    mobiles?: (ContactMobile | null)[] | null;

    apt_number?: string | null;

    street_name?: string | null;

    street_number?: string | null;

    street_type?: string | null;

    full_address?: string | null;

    city?: string | null;

    state?: string | null;

    zip?: string | null;

    location?: Location | null;
}

export interface ContactEmail {
    type: ContactEmailTypeEnum;

    value?: string | null;

    isInvalid?: boolean | null;
}

export interface ContactMobile {
    type: ContactMobileTypeEnum;

    value?: string | null;
}

export interface AssignedMemberCanvassedInfo {
    canvassedDate?: Date | null;

    contactedChannels?: OutreachChannel[] | null;
}

export interface CustomField {
    key: string;

    value?: string | null;
}

export interface MemberFormFieldValidations {
    name: string;

    maxLength: number;

    type: string;

    editable: boolean;
}

export interface PublicAction {
    id: string;

    type: string;

    title: string;

    description?: string | null;

    outreachChannels: (OutreachChannel | null)[];

    link?: string | null;

    completed: boolean;

    taken?: boolean | null;

    totalCompletions: number;

    totalTakenCount: number;

    liveAt?: Date | null;

    status?: ActionStatus | null;

    created?: Date | null;

    updated?: Date | null;

    emailTemplate?: EmailTemplateForSupporter | null;

    textTemplate?: TextTemplateForSupporter | null;

    socialTemplate?: SocialTemplateForSupporter | null;

    callTemplate?: string | null;

    visitTemplate?: string | null;

    callToActionText?: string | null;

    postActionText?: string | null;

    additionalDetails?: Json | null;

    isOutreachMode?: boolean | null;

    allowNonMappedContacts?: boolean | null;

    audienceActions?: (string | null)[] | null;

    position?: number | null;

    media?: Media | null;

    outreachCircle: PublicOutreachCircleDetail;

    emailsSentCountForAction: number;

    textsSentCountForAction: number;

    emailsSentCountByLoggedInUser: number;

    textsSentCountByLoggedInUser: number;

    textSupporterSetCount: number;

    canTakeAction?: boolean | null;

    facebookData?: PublicFacebookData | null;

    supporterForm?: PublicSupporterForm | null;

    searchAndSurveyForm?: PublicSearchAndSurveyForm | null;

    groSurveyForm?: PublicGroSurveyForm | null;

    totalP2PConversations: number;

    totalP2PRepliesPending: number;

    totalP2PInitalTextSent: number;

    canSendP2PReply: boolean;

    takenConfirmationText?: string | null;

    canSkipTakenConfirmation: boolean;

    assignedMemberData?: AssignedMemberData | null;

    canEditGROMember: boolean;

    linkedTags?: PublicTagList | null;

    hasExistingPdiOnTheGoAssignment: boolean;

    surveys?: (PublicActionSurvey | null)[] | null;

    phoneBankInfo?: ActionPhoneBankInfo | null;

    eventConfigSchedule?: PublicEventConfigSchedule | null;

    eventSchedule?: PublicEventSchedule | null;

    eventInformation?: PublicEventInformation | null;

    eventShifts?: PublicShift[] | null;

    activeShifts?: PublicShift[] | null;

    futureShifts?: PublicShift[] | null;

    sharableLinks?: SharableLinks | null;

    eventGuestAttendanceCount?: EventGuestAttendanceCount | null;

    eventAttendeeStatus?: AttendeeStatus | null;

    actionSurveyQuestions: PublicActionSurveyQuestion[];

    gotvSurvey?: PublicSurvey | null;
}

export interface EmailTemplateForSupporter {
    id: string;

    subject: string;

    created: Date;

    updated?: Date | null;

    contentForEditor: string;

    contentForPreview: string;

    contentForSending?: string | null;
}

export interface TextTemplateForSupporter {
    id: string;

    created: Date;

    updated?: Date | null;

    contentForEditor: string;

    contentForPreview: string;

    contentForSending?: string | null;
}

export interface SocialTemplateForSupporter {
    id: string;

    contentForSharing?: SocialTextContentForSharing | null;
}

export interface SocialTextContentForSharing {
    text: string;
}

export interface PublicOutreachCircleDetail {
    id: string;

    name: string;

    city?: string | null;

    state?: string | null;

    stateName?: string | null;

    country?: string | null;

    zip?: string | null;

    phoneNumber?: string | null;

    introduction: string;

    introductionPlainText?: string | null;

    logo?: Media | null;

    code?: Alias | null;

    supporting?: boolean | null;

    supporterReplyToEmail?: string | null;

    archived?: boolean | null;

    additionalDetails?: OutreachCircleAdditionalDetails | null;
}

export interface PublicFacebookData {
    page?: Json | null;

    post?: Json | null;
}

export interface PublicSupporterForm {
    id: string;

    name: string;

    supporterFormQuestions: SupporterFormQuestion[];
}

export interface PublicSearchAndSurveyForm {
    id: string;

    name: string;

    searchAndSurveyFormQuestions: SearchAndSurveyFormQuestion[];
}

export interface PublicGroSurveyForm {
    id: string;

    name: string;

    groSurveyFormQuestions: GroSurveyFormQuestion[];
}

export interface AssignedMemberData {
    assignedMemberCount?: number | null;

    contactedMemberCount?: number | null;
}

export interface PublicTagList {
    items: PublicTag[];

    total: number;
}

export interface PublicTag {
    id: string;

    name: string;

    cannedResponse?: CannedResponse | null;

    type: TagType;
}

export interface PublicActionSurvey {
    id: string;

    priority?: number | null;

    type?: ListSelectionType | null;

    survey: PublicSurvey;
}

export interface PublicSurvey {
    id: string;

    name: string;

    type: SurveyType;

    status: SurveyStatus;

    created: Date;

    segments: PublicSurveySegment[];
}

export interface PublicSurveySegment {
    id: string;

    script: string;

    position: number;

    linkedQuestion?: PublicSurveyQuestion | null;

    attachments?: Media[] | null;

    nextSegmentId?: string | null;
}

export interface PublicSurveyQuestion {
    id: string;

    text: string;

    canSkip?: boolean | null;

    type: SurveyQuestionType;

    skipNextSegmentId?: string | null;

    responses: (PublicSurveyQuestionResponse | null)[];
}

export interface PublicSurveyQuestionResponse {
    id: string;

    flag: Flag;

    code?: string | null;

    description?: string | null;

    position: number;

    nextSegmentId?: string | null;
}

export interface PublicEventConfigSchedule {
    frequency?: EventFrequency | null;

    startDate?: Date | null;

    endDate?: Date | null;

    recurrenceNumber?: number | null;

    customConfigs?: CustomEventConfigSchedule[] | null;

    eventTime?: EventTime | null;
}

export interface PublicEventSchedule {
    eventDate: Date;

    startTime: TimeWithTimezone;

    endTime: TimeWithTimezone;

    startAt: Date;

    endAt: Date;
}

export interface PublicEventInformation {
    locationType?: LocationType | null;

    venue?: string | null;

    locationId?: string | null;

    location?: OutreachCircleLocation | null;

    contactNumber?: string | null;

    contactEmail?: string | null;

    locationCoordinates?: Location | null;
}

export interface PublicShift {
    id: string;

    manager?: SupporterView | null;

    activity?: Activity | null;

    volunteerCount: number;

    schedule?: PublicShiftSchedule | null;

    isAssigned: boolean;

    status?: AttendeeStatus | null;

    linkedActions?: PublicAction[] | null;

    action?: PublicAction | null;

    assignmentAvailability?: ShiftAssignmentAvailability | null;

    canTakeAssignment: boolean;

    isActive: boolean;

    canRsvp: boolean;

    startAt: Date;

    endAt: Date;
}

export interface PublicShiftSchedule {
    startTime: TimeWithTimezone;

    endTime: TimeWithTimezone;
}

export interface EventGuestAttendanceCount {
    totalCount: number;

    yesCount: number;

    noCount: number;

    maybeCount: number;
}

export interface PublicActionSurveyQuestion {
    type: ActionSurveyQuestionType;

    surveyQuestion: PublicSurveyQuestion;
}

export interface PublicActionList {
    items: (PublicAction | null)[];

    total: number;
}

export interface SupporterActionStatus {
    supporterId: string;

    actionId: string;

    contactImportInProgress?: boolean | null;

    matchingJobInProgress?: boolean | null;

    additionalDetails?: SupporterActionAdditionalDetails | null;

    complete?: boolean | null;

    matchingJobId?: string | null;
}

export interface SupporterActionAdditionalDetails {
    importContactsCompleted?: boolean | null;

    importContactsCompleted_updatedOn?: Date | null;

    manageRecipientsCompleted?: boolean | null;

    manageRecipientsCompleted_updatedOn?: Date | null;

    p2pNUXCompletedForText?: boolean | null;

    p2pNUXCompletedForText_updatedOn?: Date | null;

    manageRecipientsCompletedForText?: boolean | null;

    manageRecipientsCompletedForText_updatedOn?: Date | null;

    importContactsCompletedForText?: boolean | null;

    importContactsCompletedForText_updatedOn?: Date | null;

    personalizeMessageCompleted?: boolean | null;

    personalizeMessageCompleted_updatedOn?: Date | null;

    personalizeMessageCompletedForText?: boolean | null;

    personalizeMessageCompletedForText_updatedOn?: Date | null;

    nuxCompletedForActionTagForText?: boolean | null;

    nuxCompletedForActionTagForText_updatedOn?: Date | null;
}

export interface TextingContactsList {
    items?: (TextingContact | null)[] | null;

    total?: number | null;
}

export interface TextingContact {
    id?: string | null;

    member_id: string;

    first_name?: string | null;

    last_name?: string | null;

    middle_name?: string | null;

    street_name?: string | null;

    street_type?: string | null;

    age_range?: string | null;

    city?: string | null;

    state?: string | null;

    country?: string | null;

    zip?: string | null;

    gender?: string | null;

    isContacted?: boolean | null;

    primary_phone?: string | null;

    text_content?: string | null;

    applied_tags?: (PublicTag | null)[] | null;
}

export interface TextSupporterSetList {
    total?: number | null;

    items?: (TextSupporterSet | null)[] | null;
}

export interface TextSupporterSet {
    supporterId: string;

    fullName?: string | null;

    phoneNumber?: string | null;

    email?: string | null;

    zip?: string | null;

    location?: string | null;

    isUnsubscribed?: boolean | null;

    optInForText?: boolean | null;

    textSentOn?: Date | null;

    hasTextSent?: boolean | null;
}

export interface AssignedSupportersForActionList {
    total: number;

    items: AssignedSupportersForAction[];
}

export interface AssignedSupportersForAction {
    supporterView?: SupporterView | null;

    audienceAction?: AudienceAction | null;

    group?: Group | null;
}

export interface ActionSupporterList {
    total: number;

    filteredTotal: number;

    items: ActionSupporter[];
}

export interface ActionSupporter {
    supporterView: SupporterView;

    supporterId: string;

    actionId: string;

    disabled: boolean;

    p2pActionData?: P2PActionData | null;

    groActionData?: GroActionData | null;

    baseActionData?: BaseActionData | null;

    groRelationalActionData?: GroRelationalActionData | null;

    baseGroActionData?: BaseGroActionData | null;

    eventActionData?: EventActionData | null;
}

export interface P2PActionData {
    totalP2PInitialTextSent: number;

    totalP2PConversations: number;

    totalP2PRepliesPending: number;

    replyReassignedTo?: SupporterView | null;
}

export interface GroActionData {
    assignedContactsCount?: number | null;
}

export interface BaseActionData {
    emailsSentCount?: number | null;

    textsSentCount?: number | null;
}

export interface GroRelationalActionData {
    contactsImported: number;

    matchedCount: number;

    ambiguousCount: number;

    unmatchedCount: number;
}

export interface BaseGroActionData {
    emailCount?: number | null;

    textCount?: number | null;

    callCount?: number | null;

    visitCount?: number | null;

    canvassedCount?: number | null;
}

export interface EventActionData {
    shifts: Shift[];

    status?: AttendeeStatus | null;
}

export interface ActionEstimatedCostAck {
    acceptedOn: Date;

    acceptedBy: string;
}

export interface ActionGroupList {
    total: number;

    items: ActionGroup[];
}

export interface ActionGroup {
    group: Group;

    groupId: string;

    actionId: string;

    disabled: boolean;

    actionsTakenCount?: number | null;

    emailsSentCount?: number | null;

    textsSentCount?: number | null;
}

export interface MemberNoteList {
    total: number;

    items: MemberNote[];
}

export interface MemberNote {
    id: string;

    note: string;

    recordedBy: string;

    created: Date;
}

export interface DeviceToken {
    identity: string;

    token: string;
}

export interface PhoneBankMember {
    id: string;

    memberId: string;

    firstName?: string | null;

    lastName?: string | null;

    phones?: Phone[] | null;

    emails?: string[] | null;

    language?: string | null;

    pronouns?: Pronouns | null;

    memberDetailDisplayFields: (CustomField | null)[];

    survey: PublicSurvey;

    tags?: Tag[] | null;
}

export interface Phone {
    number: string;

    type: PhoneType;

    optIn: boolean;
}

export interface TextingRecipient {
    id: string;

    firstName?: string | null;

    lastName?: string | null;

    memberId: string;

    textBody?: string | null;
}

export interface InitialTextingScript {
    message: string;

    attachments?: Media[] | null;
}

export interface TextingConversationList {
    items: TextingConversation[];

    total: number;
}

export interface TextingConversation {
    id: string;

    recipient: TextingRecipient;

    supporter?: SupporterView | null;

    transferLog?: TextingConversationTransferLog | null;
}

export interface TextingConversationTransferLog {
    id: string;

    fromSupporter: SupporterView;

    toSupporter: SupporterView;

    fromDate: Date;

    toDate: Date;
}

export interface TextingConversationInboxUnReadCount {
    unAssignedUnReadCount: number;

    assignedUnReadCount: number;
}

export interface TextingConversationInbox {
    items: TextingInboxItem[];

    total: number;
}

export interface TextingInboxItem {
    id: string;

    actionId: string;

    recipient: TextingRecipient;

    supporter?: SupporterView | null;

    memberId: string;

    latestMessage: string;

    hasUnreadReply: boolean;

    isUnsubscribed: boolean;

    updated: Date;

    isViewing: boolean;
}

export interface RecipientTextingConversation {
    id: string;

    memberId: string;

    recipient: TextingRecipient;

    items: RecipientTextingConversationItem[];

    questions: PublicSurveyQuestion[];

    supporter?: SupporterView | null;

    recordedQuestionResponses: PublicSurveyResponse[];

    isViewing: boolean;
}

export interface RecipientTextingConversationItem {
    id: string;

    sentBy: TextingConversationLogSentBy;

    message?: TextingConversationLogMessage | null;

    recordedSurveyResponse?: PublicSurveyResponse | null;

    created: Date;

    viewingSupporterName?: string | null;
}

export interface TextingConversationLogMessage {
    body: string;

    mediaUrls?: string[] | null;
}

export interface PublicSurveyResponse {
    id: string;

    surveyId: string;

    surveyQuestionId: string;

    surveyQuestionResponseId: string;

    memberId: string;

    created: Date;
}

export interface AddressBasedCanvassingHouseHold {
    id: string;

    houseNumber?: string | null;

    apt?: string | null;

    streetName?: string | null;

    streetNumber?: string | null;

    streetType?: string | null;

    oddEven?: string | null;

    fullAddress?: string | null;

    city?: string | null;

    state?: string | null;

    zipcode?: string | null;

    location?: Location | null;

    members: SearchAndSurveyMember[];

    canvassStatus?: AddressBasedCanvassStatus | null;
}

export interface SearchAndSurveyMember {
    contact: SearchAndSurveyContact;

    customFields: SearchAndSurveyMemberCustomField[];

    canvassStatus?: AddressBasedCanvassStatus | null;

    addedByMe?: boolean | null;

    notInVoterfile?: boolean | null;
}

export interface SearchAndSurveyContact {
    first_name?: string | null;

    middle_name?: string | null;

    last_name?: string | null;

    emails?: (ContactEmail | null)[] | null;

    mobiles?: (ContactMobile | null)[] | null;

    street_number?: string | null;

    street_name?: string | null;

    street_type?: string | null;

    city?: string | null;

    state?: string | null;

    country?: string | null;

    zip?: string | null;

    id?: string | null;

    member_id: string;

    age_range?: string | null;

    full_address?: string | null;

    gender?: string | null;

    political_party?: string | null;

    dob?: Date | null;

    apt_number?: string | null;
}

export interface SearchAndSurveyMemberCustomField {
    key: string;

    value?: string | null;
}

export interface SearchAndSurveyMemberList {
    items: (SearchAndSurveyMember | null)[];

    total: number;
}

export interface AmbiguousHouseholdMembersList {
    total: number;

    items: (AmbiguousHouseholdMember | null)[];
}

export interface AmbiguousHouseholdMember {
    id: string;

    firstName?: string | null;

    lastName?: string | null;

    householdId: string;

    totalMatches: number;

    matches?: (SearchAndSurveyMember | null)[] | null;
}

export interface AudienceActionFileUploadUrl {
    result: Json;

    audienceActionId: string;
}

export interface MemberSearchResult {
    items: Json;

    total: number;
}

export interface AudienceInfo {
    outreachCircleId: string;

    totalSize?: number | null;

    listType: ListType;

    audienceId?: string | null;

    activeAudienceActions: AudienceActionList;

    inactiveAudienceActions: AudienceActionList;

    listTypeLimit?: number | null;
}

export interface AudienceActionList {
    total: number;

    items: AudienceAction[];
}

export interface AudienceMappingList {
    total: number;

    items: AudienceMapping[];
}

export interface AudienceMapping {
    name: string;

    mappingData: Json;

    created: Date;
}

export interface BlackbaudConstituentList {
    id?: string | null;

    name?: string | null;

    record_count?: number | null;

    date_modified?: string | null;

    last_modified_by_user_name?: string | null;

    last_modified_by_user_id?: string | null;

    is_public?: boolean | null;
}

export interface SupporterContact {
    contact: Contact;

    supporter?: Supporter | null;

    matchingRuleDescription?: string | null;

    rule?: MatchingRule | null;

    score?: number | null;

    members?: (Member | null)[] | null;

    isEmailRecipient?: boolean | null;

    isTextRecipient?: boolean | null;

    isDomainBlocked?: boolean | null;

    isUnsubscribed?: boolean | null;

    isBlocked?: boolean | null;

    isOutsidePrecinct?: boolean | null;

    isTextOutsidePrecinct?: boolean | null;

    isAmbiguousResolved?: boolean | null;

    isAudienceFiltered?: boolean | null;

    isTreatmentFiltered?: boolean | null;

    isTextOptOut?: boolean | null;

    isWrongPhoneNumber?: boolean | null;

    isEmailSuppressed?: boolean | null;

    isEmailBounced?: boolean | null;

    lastEmailedDate?: Date | null;

    lastTextedDate?: Date | null;
}

export interface Contact {
    id?: string | null;

    firstName?: string | null;

    middleName?: string | null;

    lastName?: string | null;

    primaryEmail?: string | null;

    primaryPhone?: string | null;

    emails?: (ContactEmail | null)[] | null;

    mobiles?: (ContactMobile | null)[] | null;

    normalizedName?: string | null;

    photo?: string | null;

    sources?: (ContactSource | null)[] | null;

    created?: Date | null;

    streetNumber?: string | null;

    streetName?: string | null;

    city?: string | null;

    state?: string | null;

    country?: string | null;

    zip?: string | null;

    duplicateEmailStatus?: DuplicateStatus | null;
}

export interface ContactSource {
    id?: string | null;

    name?: string | null;

    mapping?: Json | null;

    platform?: ContactSourcePlatform | null;

    userId?: string | null;

    type?: string | null;

    created?: Date | null;

    count?: number | null;

    importComplete?: boolean | null;
}

export interface Supporter {
    id: string;

    origin: Origin;

    created: Date;

    rerunMatching: boolean;

    outreachCircle: PublicOutreachCircle;

    status?: VolunteerStatus | null;

    isSuspiciousMatching: boolean;

    actionForCurrentMatchingJob?: PublicAction | null;

    email?: string | null;

    additionalDetails?: SupporterAdditionalDetails | null;
}

export interface PublicOutreachCircle {
    id: string;

    name: string;

    city?: string | null;

    state?: string | null;

    stateName?: string | null;

    country?: string | null;

    zip?: string | null;

    phoneNumber?: string | null;

    introduction: string;

    logo?: Media | null;

    code?: Alias | null;

    candidate?: Candidate | null;

    supporterCount: number;

    actions: PublicActionList;

    supporting: boolean;

    isPublic?: boolean | null;

    disclaimer?: string | null;

    liveActionCount: number;

    introductionPlainText: string;

    archived?: boolean | null;

    archivedOn?: Date | null;

    additionalDetails?: OutreachCircleAdditionalDetails | null;

    supporterReplyToEmail?: string | null;

    facebookPixelCode?: string | null;

    actionsForPreview: (PublicAction | null)[];

    totalProjects: number;

    totalEvents: number;

    eventConfigs: PublicEventConfig[];

    fullAddress?: string | null;

    linkedActionId?: string | null;
}

export interface PublicEventConfig {
    id: string;

    name: string;

    title: string;

    description?: string | null;

    image?: Media | null;

    eventInformation?: EventInformation | null;

    eventSchedule?: EventConfigSchedule | null;

    created: Date;

    updated?: Date | null;

    hasShiftInCurrentWeek?: boolean | null;

    firstInstanceId?: string | null;
}

export interface SupporterAdditionalDetails {
    first_name?: string | null;

    last_name?: string | null;

    mobile_number?: string | null;

    zip?: string | null;

    externalId?: string | null;

    addressLine1?: string | null;

    city?: string | null;

    state?: string | null;

    primaryLanguage?: string | null;

    customFields?: Json | null;
}

export interface MatchingRule {
    id: string;

    name: string;

    description: string;

    score: string;
}

export interface Member {
    id?: string | null;

    member_id: string;

    first_name?: string | null;

    last_name?: string | null;

    middle_name?: string | null;

    street_name?: string | null;

    street_type?: string | null;

    age_range?: string | null;

    age?: number | null;

    city?: string | null;

    state?: string | null;

    country?: string | null;

    zip?: string | null;

    gender?: string | null;

    is_email_recipient?: boolean | null;

    is_text_recipient?: boolean | null;

    primary_phone?: string | null;

    applied_tags?: (PublicTag | null)[] | null;

    full_address?: string | null;

    political_party?: string | null;
}

export interface MappedContactsList {
    total: number;

    items: (MappedContact | null)[];
}

export interface MappedContact {
    id: string;

    name?: string | null;

    email?: string | null;

    emailCount?: number | null;

    phoneNumber?: string | null;

    contactId: string;

    memberId: string;

    isEmailRecipient?: boolean | null;

    isTextRecipient?: boolean | null;

    mobiles?: Json | null;

    appliedTags?: (PublicTag | null)[] | null;

    address?: string | null;

    matchingDescription?: string | null;
}

export interface AmbiguousMappedContactList {
    total?: number | null;

    items?: (AmbiguousMappedContact | null)[] | null;
}

export interface AmbiguousMappedContact {
    contact: Contact;

    rule: MatchingRule;

    score: number;

    totalMatches: number;

    created: Date;

    members?: (Member | null)[] | null;

    matchingRuleDescription: string;

    isA1CRecipient?: boolean | null;
}

export interface MappedContactsCountResponse {
    mappedContactsCount: number;

    emailRecipientsCount: number;

    ambiguousContactsCount: number;

    unmappedContactsCount: number;

    contactsCount: number;

    selectedNonMappedContactsCount: number;
}

export interface MappedContactsCountResponseForText {
    mappedContactsCount: number;

    ambiguousContactsCount: number;

    unmappedContactsCount: number;

    contactsCount: number;

    selectedNonMappedContactsCount: number;
}

export interface MappedContactsCountResponseForOutreachMode {
    mappedContactsCount: number;

    emailRecipientsCount: number;

    contactsCount: number;
}

export interface MappedContactsCountResponseForTextOutreachMode {
    contactsCount: number;

    mappedContactsCount: number;
}

export interface MatchingJob {
    id: string;

    totalContacts?: number | null;

    completedContacts?: number | null;

    status: MatchingJobStatusEnum;

    actionId?: string | null;

    completed?: Date | null;

    failureReason?: string | null;

    created: Date;
}

export interface ContactEmailRecipientList {
    total?: number | null;

    items?: (ContactEmailRecipient | null)[] | null;
}

export interface ContactEmailRecipient {
    id?: string | null;

    contact?: Contact | null;

    isEmailRecipient?: boolean | null;

    appliedTags?: (PublicTag | null)[] | null;
}

export interface ContactTextRecipientList {
    total?: number | null;

    items?: (ContactTextRecipient | null)[] | null;
}

export interface ContactTextRecipient {
    contact?: Contact | null;

    isTextRecipient?: boolean | null;

    appliedTags?: (PublicTag | null)[] | null;
}

export interface ContactList {
    total?: number | null;

    items?: (Contact | null)[] | null;
}

export interface MappedContactsListForAction {
    total: number;

    items: (MappedContactForAction | null)[];
}

export interface MappedContactForAction {
    id: string;

    name?: string | null;

    address?: string | null;

    matchingDescription?: string | null;

    matchingLabel?: string | null;

    score?: number | null;

    memberId?: string | null;

    isA1CRecipient?: boolean | null;

    contact?: Contact | null;

    contactCanvassedInfo?: ContactCanvassedInfo | null;
}

export interface ContactCanvassedInfo {
    canvassedDate?: Date | null;

    contactedChannels?: OutreachChannel[] | null;
}

export interface NonMappedContactList {
    total?: number | null;

    items?: (NonMappedContact | null)[] | null;
}

export interface NonMappedContact {
    id?: string | null;

    contact?: Contact | null;

    isA1CRecipient?: boolean | null;
}

export interface DuplicateEmailRecipientList {
    total: number;

    items?: DuplicateEmailRecipient[] | null;
}

export interface DuplicateEmailRecipient {
    email: string;

    totalMatches: number;

    contacts?: Contact[] | null;
}

export interface MemberList {
    items: (Member | null)[];

    total: number;

    isAmbiguous: boolean;
}

export interface SupporterCountByRecruitmentLink {
    lastRequested?: Date | null;

    data: (SupporterCountByRecruitmentLinkData | null)[];
}

export interface SupporterCountByRecruitmentLinkData {
    name: string;

    value: string;

    count: number;
}

export interface MostEngagedSupportersResponse {
    lastRequested?: Date | null;

    data: (MostEngagedSupportersData | null)[];
}

export interface MostEngagedSupportersData {
    supporterFirstName?: string | null;

    supporterLastName?: string | null;

    recruitmentLinkName: string;

    recruitmentLinkValue: string;

    actionsTaken: number;
}

export interface ActionsTakenBySupportersResponse {
    lastRequested?: Date | null;

    data: (ActionsTakenBySupportersData | null)[];
}

export interface ActionsTakenBySupportersData {
    actionName: string;

    actionType: string;

    actionsTaken: number;
}

export interface EmailSummaryResponse {
    lastRequested?: Date | null;

    data: EmailSummaryData;
}

export interface EmailSummaryData {
    sent: number;

    delivered: number;

    opened: number;

    bounced: number;
}

export interface ActionsByTypeResponse {
    lastRequested?: Date | null;

    data: (ActionsByTypeData | null)[];
}

export interface ActionsByTypeData {
    type: string;

    ready: number;

    live: number;

    total: number;

    inactiveOrExpired: number;
}

export interface SurveyResponseSummary {
    lastRequested?: Date | null;

    data: (SurveyResponseSummaryData | null)[];
}

export interface SurveyResponseSummaryData {
    optionText: string;

    responseCount: number;
}

export interface OrganizationUpdate {
    id?: string | null;

    outreachCircleId: string;

    title: string;

    description: string;

    linkText?: string | null;

    targetLink?: string | null;

    type: OrganizationUpdatesType;

    cancellable: boolean;

    status: OrganizationUpdatesStatus;

    expiresAt: Date;
}

export interface DailySupportIds {
    monthDay: string;

    sortOrder: string;

    supportIds?: number | null;

    strongSupportIds?: number | null;

    leanSupportIds?: number | null;

    undecidedIds?: number | null;

    leanOpposeIds?: number | null;

    strongOpposeIds?: number | null;

    yesIds?: number | null;

    noIds?: number | null;
}

export interface SupportIdsCount {
    name: string;

    count: number;

    percentage: number;

    sortOrder: number;
}

export interface SystemUpdate {
    status: string;

    date: Date;

    countyName: string;

    type: string;
}

export interface TodaysActivityForOutreachCircle {
    count: number;

    type: TodaysActivityType;

    percentage: number;
}

export interface PdiListsItemList {
    total: number;

    items: PdiListsItem[];
}

export interface PdiListsItem {
    id: number;

    name: string;

    listType?: PdiListType | null;

    status?: PdiListStatus | null;

    created?: Date | null;

    linkedProjectCount?: number | null;

    voterCounts?: number | null;

    houseCounts?: number | null;

    outreachCircleId: string;

    audienceAction?: AudienceAction | null;

    isMyList?: boolean | null;

    isPeopleList?: boolean | null;
}

export interface PublicTurfGroup {
    id: string;

    name: string;

    description?: string | null;

    center: Location;

    availableBlocks: number;
}

export interface CanvassingAssignment {
    id: string;

    name?: string | null;

    turf: Turf;

    supporter: Supporter;

    action: Action;

    status: CanvassingMemberStatus;

    households: CanvassingHousehold[];

    householdCount: number;

    memberCount: number;

    center: Location;

    originalAssignment?: CanvassingAssignment | null;

    sharedAssignments?: CanvassingAssignment[] | null;

    sharableLink: string;
}

export interface CanvassingHousehold {
    id: string;

    location: Location;

    status: CanvassingMemberStatus;

    city?: string | null;

    state?: string | null;

    zip?: string | null;

    fullAddress?: string | null;

    streetNumber?: string | null;

    streetName?: string | null;

    streetType?: string | null;

    apartment?: string | null;

    oddEven?: string | null;

    rangeSort?: string | null;

    aptSort?: string | null;

    canvassingMembers: CanvassingMember[];

    previousResponses?: (HouseholdPreviousSurveyResponses | null)[] | null;

    addressPreDirectional?: string | null;

    addressPostDirectional?: string | null;

    prefix?: string | null;

    locationDescription?: string | null;
}

export interface CanvassingMember {
    id: string;

    firstName: string;

    lastName?: string | null;

    status: CanvassingMemberStatus;

    householdId?: string | null;

    location: Location;

    city?: string | null;

    state?: string | null;

    zip?: string | null;

    precinct?: string | null;

    fullAddress?: string | null;

    streetNumber?: string | null;

    streetName?: string | null;

    streetType?: string | null;

    apartment?: string | null;

    survey?: PublicSurvey | null;

    age?: number | null;

    gender?: string | null;

    rangeSort?: string | null;

    aptSort?: string | null;

    politicalParty?: string | null;

    previousResponses?: PublicSurveyResponse[] | null;

    tags?: Tag[] | null;

    isPartOfList?: boolean | null;

    mobile?: string | null;

    email?: string | null;

    phones?: Phone[] | null;

    emails?: CanvassingMemberEmail[] | null;

    editedDetails?: UnapprovedMember | null;

    voteCount?: number | null;

    addressPostDirectional?: string | null;

    addressPreDirectional?: string | null;

    isPvbm?: boolean | null;

    source?: CanvassingMemberSource | null;
}

export interface CanvassingMemberEmail {
    email: string;

    optIn: boolean;
}

export interface UnapprovedMember {
    id?: string | null;

    memberId: string;

    emails: (CanvassingMemberEmail | null)[];

    phones: (Phone | null)[];

    language?: string | null;

    pronouns?: Pronouns | null;

    created?: Date | null;

    updated?: Date | null;
}

export interface HouseholdPreviousSurveyResponses {
    surveyQuestionId: string;

    surveyResponseIds: string[];
}

export interface Place {
    name?: string | null;

    formattedAddress?: string | null;

    location: Location;
}

export interface TurfList {
    items: Turf[];

    total: number;
}

export interface DefaultTurfList {
    items: DefaultTurf[];

    total: number;
}

export interface DefaultTurf {
    id: string;

    name?: string | null;

    type: TurfType;

    displayId: number;

    assignments?: TurfAssignment[] | null;

    turfGroup?: TurfGroup | null;

    expiresAt?: Date | null;

    householdCount: number;

    canvassedHouseholdCount: number;

    derivedTurf?: Turf | null;
}

export interface TurfAssignment {
    id: string;

    supporterView: SupporterView;

    isCaptainMode: boolean;

    expiresAt?: Date | null;
}

export interface CanvassingMemberCommunicationHistory {
    total: number;

    items?: (CanvassingMemberCommunication | null)[] | null;
}

export interface CanvassingMemberCommunication {
    type?: string | null;

    name?: string | null;

    date?: Date | null;

    responseStatus?: string | null;
}

export interface CanvassingMemberSupportHistory {
    total: number;

    items?: (CanvassingMemberSupport | null)[] | null;
}

export interface CanvassingMemberSupport {
    date?: Date | null;

    supportLevel?: string | null;

    supportLevelId?: number | null;

    supportSentiment?: SupportSentiment | null;
}

export interface CanvassedSurveyResponseSummaryBySupportersList {
    total?: number | null;

    items?: (CanvassedSurveyResponseSummaryBySupporters | null)[] | null;
}

export interface CanvassedSurveyResponseSummaryBySupporters {
    supporterId?: string | null;

    supporterName?: string | null;

    turfIds?: (string | null)[] | null;

    turfNames?: (string | null)[] | null;

    geoCheckedCount?: number | null;

    timeCheckedCount?: number | null;

    totalSurveyResponses?: number | null;

    lastSynced?: Date | null;

    knockedDoorsCount?: number | null;

    remainingDoorsCount?: number | null;

    totalDoorsCount?: number | null;

    contactedMembersCount?: number | null;

    totalMembersCount?: number | null;
}

export interface CanvassedSurveyResponseSummaryByAssignmentsList {
    total?: number | null;

    items?: (CanvassedSurveyResponseSummaryByAssignments | null)[] | null;
}

export interface CanvassedSurveyResponseSummaryByAssignments {
    supporterIds?: (string | null)[] | null;

    supporterNames?: (string | null)[] | null;

    turfId?: string | null;

    turfName?: string | null;

    geoCheckedCount?: number | null;

    timeCheckedCount?: number | null;

    totalSurveyResponses?: number | null;

    lastSynced?: Date | null;

    knockedDoorsCount?: number | null;

    remainingDoorsCount?: number | null;

    totalDoorsCount?: number | null;

    contactedMembersCount?: number | null;

    totalMembersCount?: number | null;
}

export interface CanvassedSurveyResponsesBySupporterList {
    total?: number | null;

    items?: (CanvassedSurveyResponseBySupporter | null)[] | null;
}

export interface CanvassedSurveyResponseBySupporter {
    memberId?: string | null;

    memberName?: string | null;

    memberEmail?: string | null;

    memberMobile?: string | null;

    memberAddress?: string | null;

    totalSurveyResponses?: number | null;

    geoCheckedCount?: number | null;

    timeCheckedCount?: number | null;

    collectedAddress?: string | null;

    supporterId?: string | null;

    surveyResponseIds?: (string | null)[] | null;

    comments?: MemberNote[] | null;

    surveyResponses?: (CanvassedSurveyResponse | null)[] | null;

    lastSynced?: Date | null;
}

export interface CanvassedSurveyResponse {
    question?: string | null;

    response?: string | null;
}

export interface VoterDensityBucket {
    min: number;

    max: number;

    turfCount: number;

    turfGroup: TurfGroup;
}

export interface EmailTemplateForOrganizationList {
    total?: number | null;

    items?: (EmailTemplateForOrganization | null)[] | null;
}

export interface EmailTemplateForOrganization {
    id?: string | null;

    name: string;

    subject: string;

    created: Date;

    updated?: Date | null;

    contentForPreview?: string | null;

    contentForEditor?: string | null;

    fromNameAlias?: string | null;
}

export interface ExternalLinkList {
    items: (Engagement | null)[];

    total?: number | null;
}

export interface PublicEngagement {
    id: string;

    name: string;

    type: EngagementType;

    url?: string | null;

    description?: string | null;

    questions: PublicEngagementQuestion[];

    message?: string | null;

    outreachCircle: PublicOutreachCircle;
}

export interface PublicEngagementQuestion {
    id: string;

    questionText: string;

    type?: EngagementQuestionType | null;

    options: PublicEngagementOption[];

    isMultipleOptionsAllowed?: boolean | null;

    position?: number | null;

    isMandatory: boolean;
}

export interface PublicEngagementOption {
    id: string;

    optionText: string;

    linkedEngagement?: PublicEngagement | null;

    linkedEngagementId?: string | null;

    linkedEngagementQuestionId?: string | null;

    type: EngagementOptionType;
}

export interface EngagementList {
    items: Engagement[];

    total: number;
}

export interface EventConfigList {
    total: number;

    items?: EventConfig[] | null;
}

export interface PublicEventConfigList {
    total: number;

    items: PublicEventConfig[];
}

export interface AssignedSupporterListForShift {
    total: number;

    items: ShiftSupporter[];
}

export interface ShiftSupporter {
    supporterView: SupporterView;

    shiftId: string;

    status?: AttendeeStatus | null;
}

export interface PublicShiftList {
    total: number;

    items: PublicShift[];
}

export interface ShiftList {
    total: number;

    items: Shift[];
}

export interface ShiftsListByActionType {
    total: number;

    items: PublicShift[];
}

export interface FlagList {
    items: (Flag | null)[];

    total: number;
}

export interface GroSurveyFormList {
    total: number;

    items: (GroSurveyForm | null)[];
}

export interface GroSurveyFormResponse {
    groSurveyFormQuestionId: string;

    selectedOptions: (GroSurveySelectedOption | null)[];
}

export interface GroSurveySelectedOption {
    groSurveyFormOptionId: string;

    response: GroSurveyOptionResponse;
}

export interface GroSurveyOptionResponse {
    text?: string | null;

    addressField?: OptionAddressFields | null;
}

export interface OptionAddressFields {
    address: string;

    cityAndState: string;

    zip: string;
}

export interface GroSurveyFormResponseList {
    total: number;

    items: PastGroSurveyFormResponse[];
}

export interface PastGroSurveyFormResponse {
    id: string;

    questionText: string;

    responseText: string;

    recordedBy: string;

    recordedAt: Date;
}

export interface NationBuilderPeopleTagsList {
    items: (NationBuilderPeopleTags | null)[];

    total: number;
}

export interface TestNgpResponse {
    success?: boolean | null;

    errors?: string | null;
}

export interface NgpLists {
    total: number;

    items: (NgpList | null)[];
}

export interface NgpList {
    savedListId: number;

    name: string;

    description?: string | null;

    listCount: number;

    doorCount: number;

    lastUploadedOn?: Date | null;
}

export interface NgpActivistCodeList {
    total: number;

    items: (NgpActivistCode | null)[];
}

export interface NgpSurveyList {
    total: number;

    items: (NgpSurveyQuestion | null)[];
}

export interface PdiAssignmentResponse {
    id: number;

    households: AddressBasedCanvassingHouseHold[];
}

export interface PdiAssignmentZone {
    id: number;

    name: string;
}

export interface PdiQuestionList {
    total: number;

    items: PdiQuestion[];
}

export interface PdiProjectInfo {
    id: string;

    name: string;
}

export interface GetTcrRegistrationDetailsOutput {
    brand?: TcrBrand | null;

    vetting?: TcrBrandVetting | null;

    campaign?: TcrCampaign | null;

    optInUrl?: string | null;
}

export interface TcrBrand {
    organizationType: TcrBrandOrganizationType;

    brandId: string;

    companyName: string;

    ein: string;

    phone: string;

    street: string;

    city: string;

    state: string;

    postalCode: string;

    country: string;

    email: string;

    website: string;

    vertical: TcrBrandVertical;

    referenceId: string;

    identityStatus: TcrBrandIdentityStatus;

    createDate: string;
}

export interface TcrBrandVetting {
    evpId: string;

    vettingId: string;

    vettingToken?: string | null;

    vettingScore?: number | null;

    vettingClass: string;

    vettingStatus: TcrVettingStatus;

    vettedDate: string;

    createDate: string;
}

export interface TcrCampaign {
    campaignId: string;

    status: TcrCampaignStatus;

    createDate: string;

    usecase: TcrCampaignUsecase;

    subUsecases?: (TcrCampaignUsecase | null)[] | null;

    description: string;

    sample1: string;

    sample2: string;
}

export interface TcrBrandApplicableUsecase {
    usecase: string;

    displayName: string;

    minSubUsecases: number;

    maxSubUsecases: number;
}

export interface MemberSurveyList {
    total: number;

    items: MemberSurvey[];
}

export interface NativeAppVersion {
    version: string;

    buildNumber: number;

    isMandatory: boolean;

    isUpToDate: boolean;

    appDownloadUrl: string;
}

export interface PublicOrganizationAction {
    id: string;

    type: string;

    name: string;

    title?: string | null;

    description?: string | null;

    status: OrganizationActionStatus;

    callToActionText?: string | null;

    created?: Date | null;

    updated?: Date | null;

    link?: string | null;

    postActionText?: string | null;

    additionalDetails?: OrganizationActionAdditionalDetails | null;

    isOutreachMode?: boolean | null;

    allowNonMappedContacts?: boolean | null;

    media?: Media | null;

    facebookData?: PublicFacebookData | null;

    totalTakenCount: number;

    firstOutreachCircle?: PublicOutreachCircle | null;

    emailsSentCountForAction?: number | null;

    textsSentCountForAction?: number | null;
}

export interface OrganizationActionAdditionalDetails {
    playVideoInAppEnabled?: boolean | null;

    videoId?: string | null;

    videoSource?: string | null;

    eventLink?: string | null;

    eventName?: string | null;

    isSharingEnabled?: boolean | null;

    shareButtonText?: string | null;

    facebookConfig?: FacebookConfig | null;
}

export interface OrganizationAction {
    id: string;

    type: string;

    name: string;

    title?: string | null;

    description?: string | null;

    status: OrganizationActionStatus;

    callToActionText?: string | null;

    created?: Date | null;

    updated?: Date | null;

    link?: string | null;

    adminsNotifiedOn?: Date | null;

    notifyAdmins?: boolean | null;

    publishedAt?: Date | null;

    terminatedAt?: Date | null;

    postActionText?: string | null;

    additionalDetails?: OrganizationActionAdditionalDetails | null;

    isOutreachMode?: boolean | null;

    allowNonMappedContacts?: boolean | null;

    media?: Media | null;

    facebookData?: PublicFacebookData | null;

    outreachChannels?: (OutreachChannel | null)[] | null;

    integrationType?: AllowedIntegrationDatabaseType | null;

    emailTemplate?: EmailTemplateForOrganization | null;

    textTemplate?: TextTemplateForOrganization | null;

    socialTemplate?: SocialTemplateForOrganization | null;

    outreachCircleActionStatusesCount?: OutreachCircleActionStatusesCount | null;

    isHidden: boolean;

    totalTakenCount: number;

    isVoterFileSetupRequired: boolean;

    takenConfirmationText?: string | null;

    canSkipTakenConfirmation: boolean;
}

export interface TextTemplateForOrganization {
    id?: string | null;

    name: string;

    created: Date;

    updated?: Date | null;

    contentForEditor?: string | null;

    contentForPreview?: string | null;
}

export interface SocialTemplateForOrganization {
    id: string;

    name: string;

    created: Date;

    updated?: Date | null;

    contentForEditor: string;

    contentForPreview: string;
}

export interface OutreachCircleActionStatusesCount {
    active?: number | null;

    ready?: number | null;

    incomplete?: number | null;

    inactive?: number | null;
}

export interface OrganizationActionList {
    items: (OrganizationAction | null)[];

    total: number;
}

export interface OrganizationList {
    total: number;

    items: Organization[];
}

export interface PublicOrganization {
    id: string;

    name: string;

    defaultAlias: OrganizationAlias;

    orgUrl?: string | null;

    mainOutreachCircle?: PublicOutreachCircle | null;

    mainOCButtonText?: string | null;

    logo?: Media | null;

    introduction?: string | null;

    disclaimer?: string | null;

    additionalDetails?: OrganizationAdditionalDetails | null;
}

export interface OutreachCircleDataList {
    total: number;

    items: (OutreachCircleData | null)[];
}

export interface OutreachCircleData {
    id: string;

    name: string;

    city?: string | null;

    state?: string | null;

    stateName?: string | null;

    country?: string | null;

    zip?: string | null;

    admins?: (OutreachCircleUser | null)[] | null;

    supporterCount: number;

    defaultAlias?: Alias | null;

    archived?: boolean | null;

    created: Date;
}

export interface OrganizationDashboardFilter {
    id: string;

    name: string;

    outreachCircleIds?: string[] | null;
}

export interface ActionsTakenCountByOutreachcircleForOrganization {
    count: number;

    outreachCircleName: string;
}

export interface MostEngagedSupportersForOrganization {
    supporterFullName?: string | null;

    actionTakenCount: number;

    outreachCircleName: string;
}

export interface SupporterCountByOutreachcircleForOrganization {
    count: number;

    outreachCircleName: string;
}

export interface PublicOutreachCircleList {
    items: (PublicOutreachCircle | null)[];

    total?: number | null;
}

export interface OutreachCircleUserEmailTemplateList {
    total: number;

    items: OutreachCircleUserEmailTemplate[];
}

export interface OutreachCircleUserEmailTemplate {
    id: string;

    emailTemplate: EmailTemplateForOutreachCircleUser;

    code: OutreachCircleUserEmailTemplateCode;
}

export interface EmailTemplateForOutreachCircleUser {
    id?: string | null;

    name: string;

    subject?: string | null;

    contentForPreview?: string | null;

    contentForEditor?: string | null;

    fromNameAlias?: string | null;

    created?: Date | null;
}

export interface OutreachCircleUserList {
    total: number;

    items: OutreachCircleUser[];
}

export interface GeographyList {
    total: number;

    items: Geography[];
}

export interface PublicOutreachCircleSearchResultList {
    items: (PublicOutreachCircleSearchResult | null)[];

    total?: number | null;
}

export interface PublicOutreachCircleSearchResult {
    id: string;

    name: string;

    city?: string | null;

    state?: string | null;

    stateName?: string | null;

    country?: string | null;

    zip?: string | null;

    phoneNumber?: string | null;

    introduction: string;

    logo?: Media | null;

    code?: Alias | null;

    candidate?: Candidate | null;

    supporterCount: number;

    actions: PublicActionList;

    supporting: boolean;

    isPublic?: boolean | null;

    disclaimer?: string | null;

    liveActionCount: number;

    introductionPlainText: string;

    archived?: boolean | null;

    archivedOn?: Date | null;
}

export interface AliasList {
    total: number;

    items: (Alias | null)[];
}

export interface OutreachCircleEmailAlias {
    id: string;

    name: string;

    outreachCircleId: string;
}

export interface OutreachCircleAccessInfo {
    hasAccess: boolean;

    outreachCircleReplyToEmail: string;
}

export interface PublicAlias {
    id: string;

    value: string;

    name: string;

    description?: string | null;

    logo?: Media | null;

    isRapidOnboardingEnabled?: boolean | null;

    isLandingPage: boolean;

    isDefault: boolean;

    languagePreference?: LanguagePreferenceType | null;

    isAppOnly: boolean;

    longURL: string;

    shortURL: string;
}

export interface ArchivedPublicOutreachCircle {
    id: string;

    name: string;

    city?: string | null;

    state?: string | null;

    country?: string | null;

    zip?: string | null;

    logo?: Media | null;

    archived?: boolean | null;
}

export interface DeepLinkObject {
    outreachCircleId?: string | null;

    outreachCircleAlias?: string | null;

    actionId?: string | null;

    shiftId?: string | null;

    shiftActionId?: string | null;
}

export interface BillingCardDetails {
    id: string;

    brand: string;

    country: string;

    expiryMonth: number;

    expiryYear: number;

    funding: string;

    last4: string;

    isDefault: boolean;
}

export interface OutreachCircleUpcomingInvoiceDetails {
    invoice: OutreachCircleUpcomingInvoice;

    subscriptionEndDate?: Date | null;
}

export interface OutreachCircleUpcomingInvoice {
    amountDue?: number | null;

    daysRemainingTillRenewal?: number | null;

    outreachCircleId: string;

    renewalDate?: Date | null;

    subscriptionStatus?: SubscriptionStatus | null;
}

export interface OutreachCircleInvoice {
    id: string;

    invoiceNumber: string;

    receiptNumber: string;

    invoicePdfUrl: string;

    receiptPdfUrl: string;

    couponCode?: string | null;

    couponDiscount?: number | null;

    product?: string | null;

    plan?: string | null;

    planAmount: number;

    created: Date;

    periodStartDate: Date;

    periodEndDate: Date;

    amountPaid: number;

    cardLast4: string;

    cardBrand: string;

    outreachCircleId: string;
}

export interface PdiVoterList {
    total: number;

    items: PdiVoter[];
}

export interface PdiVoter {
    id: string;

    firstName?: string | null;

    lastName?: string | null;

    name?: string | null;

    address?: string | null;

    streetName?: string | null;

    city?: string | null;

    precinct?: string | null;

    zipCode?: string | null;

    registrationDate?: string | null;

    precinctName?: string | null;

    stateId?: string | null;

    countyId?: string | null;

    permanentVoteByMail?: boolean | null;

    householdId?: string | null;

    earlyVoteBehavior?: string | null;

    county?: string | null;

    ethnicity?: string | null;

    state?: string | null;

    email?: string | null;

    party?: string | null;

    birthdate?: string | null;

    gender?: string | null;

    supportStatus?: string | null;

    flags?: Flag[] | null;

    contactIds?: PdiContactId[] | null;

    contactInformation?: PdiVoterContactInformation | null;

    volunteerInfo?: PdiVolunteerInfo | null;

    eventInfo?: PublicShift[] | null;

    communicationInfo?: PdiVoterCommunicationInfo[] | null;

    comments?: PdiVoterComment[] | null;

    donorInfo?: PdiDonorInfo[] | null;

    supportIds?: PdiVoterSupportStatus[] | null;

    age?: number | null;

    vsMatchKey?: string | null;

    outreachCircleId: string;

    votersInHousehold?: (PdiVoter | null)[] | null;

    votingHistory?: PdiVoterVotingHistory[] | null;

    districts?: (string | null)[] | null;

    modelScores?: PdiVoterModelScore[] | null;

    memberInfo?: PdiMemberInfo | null;
}

export interface PdiContactId {
    id?: number | null;

    question?: string | null;

    flag?: string | null;

    date?: Date | null;

    acquisitionCode?: string | null;

    candidateBallotMeasure?: string | null;

    canvasser?: string | null;

    organizationName?: string | null;
}

export interface PdiVoterContactInformation {
    residenceAddress?: string | null;

    mailingAddress?: string | null;

    residenceType?: string | null;

    primaryHomePhone?: string | null;

    primaryMobilePhone?: string | null;

    primaryEmailAddress?: string | null;

    hasBadHomePhoneNumberFlag?: boolean | null;

    hasBadMobilePhoneNumberFlag?: boolean | null;
}

export interface PdiVolunteerInfo {
    activities?: SupporterActivity[] | null;

    skills?: SupporterSkill[] | null;

    teams?: SupporterTeam[] | null;

    availabillity?: SupporterAvailability[] | null;

    supporterView?: SupporterView | null;
}

export interface SupporterActivity {
    type?: SupporterActivityType | null;

    id: string;

    name: string;

    date: Date;

    fromTime: TimeWithTimezone;

    toTime: TimeWithTimezone;

    isActive: boolean;

    updated?: Date | null;
}

export interface SupporterSkill {
    id: string;

    name: string;

    isActive: boolean;

    updated?: Date | null;
}

export interface SupporterTeam {
    id: string;

    name: string;

    organizerId: string;

    isActive: boolean;

    updated?: Date | null;
}

export interface SupporterAvailability {
    id: string;

    name: string;

    isActive: boolean;

    updated?: Date | null;
}

export interface PdiVoterCommunicationInfo {
    type: string;

    name: string;

    date: Date;

    responseStatus?: string | null;
}

export interface PdiVoterComment {
    id: number;

    date?: Date | null;

    commentText?: string | null;
}

export interface PdiDonorInfo {
    id?: string | null;
}

export interface PdiVoterSupportStatus {
    candidateBallotMeasure?: string | null;

    mostRecentSupportId?: string | null;

    supportIdSequence?: string | null;
}

export interface PdiVoterVotingHistory {
    electionDate: Date;

    voteMethod: string;
}

export interface PdiVoterModelScore {
    id: string;

    name: string;

    score: number;
}

export interface PdiMemberInfo {
    info?: PdiMemberInfoInfo[] | null;

    memberships?: PdiMembership[] | null;
}

export interface PdiMemberInfoInfo {
    field?: string | null;

    value?: string | null;
}

export interface PdiMembership {
    level?: string | null;

    cost?: number | null;

    validUntil?: Date | null;

    purchase?: string | null;
}

export interface P2PInbox {
    items: P2PInboxItem[];

    total: number;

    getTotalUnreadCount: number;
}

export interface P2PInboxItem {
    actionId: string;

    hasUnreadReply: boolean;

    latestMessage: string;

    updated: Date;

    recipientName: string;

    memberId: string;

    conversationId: string;

    isUnsubscribed: boolean;
}

export interface P2PConversation {
    id: string;

    recipientName: string;

    memberId: string;

    items: P2PConversationItem[];

    questions: PublicP2PScriptSurveyQuestion[];

    tags: P2PWorkspaceTag[];

    supporter?: SupporterView | null;

    linkedP2PScripts?: LinkedP2PScript[] | null;

    p2pScripts: PublicP2PScript[];

    recordedTags: PublicP2PConversationLogTag[];

    recordedQuestionResponses: PublicP2PConversationLogQuestionResponse[];

    nextConversationId?: string | null;

    previousConversationId?: string | null;

    isUnsubscribed: boolean;
}

export interface P2PConversationItem {
    id: string;

    sentBy: P2PConversationLogSentBy;

    message?: PublicP2PConversationLogMessage | null;

    questionResponses?: (PublicP2PConversationLogQuestionResponse | null)[] | null;

    tags?: (PublicP2PConversationLogTag | null)[] | null;

    closure?: PublicP2PConversationLogClosure | null;

    created?: Date | null;

    supporter?: PublicSupporter | null;

    p2pScriptId: string;

    isUnsubscribed: boolean;

    isFirstReply?: boolean | null;
}

export interface PublicP2PConversationLogMessage {
    body: string;

    mediaUrls?: string[] | null;
}

export interface PublicP2PConversationLogQuestionResponse {
    p2pScriptSurveyQuestionId: string;

    p2pScriptSurveyQuestionOptionId: string;
}

export interface PublicP2PConversationLogTag {
    tagId: string;
}

export interface PublicP2PConversationLogClosure {
    supporter: PublicSupporter;
}

export interface PublicSupporter {
    supporterId: string;

    firstName?: string | null;

    lastName?: string | null;

    fullName?: string | null;
}

export interface PublicP2PScriptSurveyQuestion {
    id: string;

    name: string;

    questionText: string;

    options: PublicP2PScriptSurveyQuestionOption[];
}

export interface PublicP2PScriptSurveyQuestionOption {
    optionText: string;

    id: string;
}

export interface PublicP2PScript {
    id: string;

    name?: string | null;

    message?: string | null;

    p2pScriptSurveyQuestionId?: string | null;

    attachments?: Media[] | null;
}

export interface P2PScriptSurveyQuestionList {
    total: number;

    items: P2PScriptSurveyQuestion[];
}

export interface P2PRecipientsForTexting {
    recipientList: P2PRecipientList;

    listCompleted: boolean;
}

export interface P2PRecipientList {
    total: number;

    items: (P2PRecipient | null)[];
}

export interface P2PRecipient {
    id: string;

    firstName?: string | null;

    lastName?: string | null;

    memberId: string;

    textBody?: string | null;
}

export interface InitialP2PItem {
    message: string;

    attachments?: Media[] | null;
}

export interface P2PWorkspaceList {
    total: number;

    items: P2PWorkspace[];
}

export interface PurposeList {
    items: (Purpose | null)[];

    total: number;
}

export interface OutreachCircleElectionCycle {
    id: string;

    date: Date;

    isLatest: boolean;
}

export interface ReportInfo {
    id: string;

    name: string;

    description?: string | null;

    code: ReportCode;

    lastCreated?: Date | null;

    applicableActions?: (ActionType | null)[] | null;

    reportLink?: string | null;

    position: number;

    isActionFilterAllowed: boolean;
}

export interface SearchAndSurveyFormList {
    total: number;

    items: (SearchAndSurveyForm | null)[];
}

export interface SocialTemplateList {
    total?: number | null;

    items?: (SocialTemplate | null)[] | null;
}

export interface SocialTemplateForOrganizationList {
    total?: number | null;

    items?: (SocialTemplateForOrganization | null)[] | null;
}

export interface SourcingLinkList {
    total: number;

    items: SourcingLink[];
}

export interface SourcingLink {
    id: string;

    name: string;

    link: string;

    updated?: string | null;

    created: string;

    useShortLink: boolean;
}

export interface SupporterActivityList {
    total: number;

    items: SupporterActivity[];
}

export interface SupporterEmailTemplateList {
    total: number;

    items: (SupporterEmailTemplate | null)[];
}

export interface SupporterFormList {
    total: number;

    items: (SupporterForm | null)[];
}

export interface SupporterViewFilterStateList {
    items: SupporterViewFilterState[];

    total: number;
}

export interface SupporterViewFilterState {
    id: string;

    name: string;

    state: SupporterViewFilter;
}

export interface SupporterViewFilter {
    actionTakenFilter?: SupporterActionTakenFilter | null;

    supporterJoinedDateFilter?: SupporterJoinedFilter | null;

    actionFilter?: SupporterActionFilter | null;

    groupFilter?: SupporterGroupFilter | null;

    appNotificationFilter?: SupporterAppNotificationFilter | null;

    aliasFilter?: SupporterAliasFilter | null;

    supporterStatusFilter?: SupporterStatusFilter | null;

    lastEmailStatusFilter?: SupporterLastEmailStatusFilter | null;

    supporterEmailSubscriptionFilter?: SupporterEmailSubscriptionFilter | null;
}

export interface SupporterActionTakenFilter {
    minimum?: number | null;

    maximum?: number | null;
}

export interface SupporterJoinedFilter {
    from: Date;

    to: Date;

    isExclude: boolean;
}

export interface SupporterActionFilter {
    all: boolean;

    actionIds?: string[] | null;

    isExclude: boolean;
}

export interface SupporterGroupFilter {
    all: boolean;

    groupIds?: string[] | null;

    isExclude: boolean;
}

export interface SupporterAppNotificationFilter {
    appNotificationEnabled: boolean;
}

export interface SupporterAliasFilter {
    all: boolean;

    aliasIds?: string[] | null;

    isExclude: boolean;
}

export interface SupporterStatusFilter {
    isExclude: boolean;

    statusList?: VolunteerStatus[] | null;
}

export interface SupporterLastEmailStatusFilter {
    emailStatusList: LastEmailStatus[];

    isExclude: boolean;
}

export interface SupporterEmailSubscriptionFilter {
    isUnsubscribed?: boolean | null;
}

export interface SupporterLevel {
    id: string;

    name: string;

    isActive: boolean;

    updated?: Date | null;
}

export interface SupporterOrganization {
    id: string;

    name: string;

    isActive: boolean;

    updated?: Date | null;
}

export interface OrganizerList {
    items: Organizer[];

    total: number;
}

export interface Organizer {
    id: string;

    supporterView: SupporterView;
}

export interface SupporterZone {
    id: string;

    name: string;

    isActive: boolean;

    updated?: Date | null;
}

export interface SupporterSummaryList {
    total: number;

    items: (SupporterSummary | null)[];
}

export interface SupporterSummary {
    id: string;

    fullName?: string | null;

    email: string;

    phoneNumber?: string | null;

    zipCode?: string | null;

    joinLink: string;

    location?: string | null;

    origin: Origin;

    invitedOn?: Date | null;

    joinedOn?: Date | null;

    totalActionCompleted: number;

    totalActionInProgress: number;

    totalUniqueContactsInvited: number;

    totalUniqueContactsJoined: number;
}

export interface InvitedSupporterInformation {
    invitedSupporterId: string;

    firstName?: string | null;

    lastName?: string | null;

    email: string;

    isJoined?: boolean | null;
}

export interface ActionEmailSummaryForSupporterData {
    actionEmailsCountSummary: EmailSummaryData;

    emailRecipientsList: (ActionEmailRecipientsList | null)[];
}

export interface ActionEmailRecipientsList {
    recipientName?: string | null;

    email: string;

    status: string;

    statusDate: Date;

    hasRespondedToSurvey: boolean;

    created: Date;
}

export interface ActionEmailRecipientsLists {
    total: number;

    items: ActionEmailRecipientsList[];
}

export interface ActionTextMessageSummaryForSupporterData {
    actionTextMessagesCount: number;

    textRecipientsList: (ActionTextRecipientsList | null)[];
}

export interface ActionTextRecipientsList {
    recipientName: string;

    phoneNumber: string;

    created: Date;

    hasRespondedToSurvey: boolean;
}

export interface RecentInviteSupporterMappingWithAllCustomFields {
    mapping?: Json | null;

    customFields?: (string | null)[] | null;
}

export interface SupporterImpactData {
    supporterSince: Date;

    supportIdsCount: number;

    shiftsWorkedCount: number;

    callsMadeCount: number;

    texting: number;

    doorsKnockedCount: number;

    conversationsCount: number;

    walkingHoursCount: number;

    callingHoursCount: number;
}

export interface SurveyList {
    items: (Survey | null)[];

    total: number;
}

export interface SurveyQuestionList {
    items: (SurveyQuestion | null)[];

    total: number;
}

export interface CannedResponseList {
    total: number;

    items: CannedResponse[];
}

export interface TextTemplateForOrganizationList {
    total?: number | null;

    items?: (TextTemplateForOrganization | null)[] | null;
}

export interface OutreachCircleList {
    items: (OutreachCircle | null)[];

    total: number;

    filteredTotal: number;
}

export interface SupporterList {
    total: number;

    items: (Supporter | null)[];
}

export interface BookmarkList {
    total: number;

    items?: Bookmark[] | null;
}

export interface Bookmark {
    id: string;

    path: string;

    createdAt: Date;
}

export interface Mutation {
    /** Create an acquisition type for a given outreach circle */
    createAcquisitionType: AcquisitionType;
    /** Update an acquisition type */
    updateAcquisitionType: AcquisitionType;
    /** Update an acquisition type's status */
    updateAcquisitionTypeStatus: AcquisitionType;
    /** Copy acquisition types from parent */
    copyAcquisitionTypesFromParentOutreachCircle: boolean;
    /** Update acquisition type for action */
    updateAcquisitionTypeForAction: Action;
    /** Create Action View Filter state */
    createActionViewFilter: ActionViewFilterState;
    /** Update Action View Filter state */
    updateActionViewFilter: ActionViewFilterState;
    /** Delete Action View Filter State */
    deleteActionViewFilter: boolean;
    /** Get Action View Filter State */
    getActionViewFilterStates: ActionViewFilterStateList;
    /** Updating PDI List to an action */
    updatePdiListForAction: Action;
    /** Updating Audience List to an action */
    updateAudienceActionForAction: Action;
    /** Delete all supporter assignments for an action */
    deleteAllSupporterAssignments: Action;
    /** Mark assigned member as contacted */
    markAssignedMemberAsContacted?: boolean | null;
    /** Mark assigned contact as contactedErrors Thrown:ACTION_NOT_IN_LIVE_STATEACTION_INVALID_TYPE: When action type is not GROSUPPORTER_NOT_FOUND: When supporter not found */
    markAssignedContactAsContacted?: boolean | null;
    /** Save GRO supporter personalized email templateErrors Thrown:ACTION_NOT_IN_LIVE_STATEACTION_INVALID_TYPE: When action type is not GROSUPPORTER_NOT_FOUND: When supporter not foundINVALID_MERGE_FIELDS_IN_TEMPLATE: When invalid merge fields exist in the template body */
    saveGROSupporterEmailTemplate: EmailTemplateForSupporter;
    /** Save GRO supporter personalized text templateErrors Thrown:ACTION_NOT_IN_LIVE_STATEACTION_INVALID_TYPE: When action type is not GROSUPPORTER_NOT_FOUND: When supporter not foundINVALID_MERGE_FIELDS_IN_TEMPLATE: When invalid merge fields exist in the template body */
    saveGROSupporterTextTemplate: TextTemplateForSupporter;
    /** Save GRO supporter personalized call templateErrors Thrown:ACTION_NOT_IN_LIVE_STATEACTION_INVALID_TYPE: When action type is not GROSUPPORTER_NOT_FOUND: When supporter not foundINVALID_MERGE_FIELDS_IN_TEMPLATE: When invalid merge fields exist in the template body */
    saveGROSupporterCallTemplate: boolean;
    /** Save GRO supporter personalized visit templateErrors Thrown:ACTION_NOT_IN_LIVE_STATEACTION_INVALID_TYPE: When action type is not GROSUPPORTER_NOT_FOUND: When supporter not foundINVALID_MERGE_FIELDS_IN_TEMPLATE: When invalid merge fields exist in the template body */
    saveGROSupporterVisitTemplate: boolean;
    /** Update GRO member Errors Thrown: INTEGRATION_NOT_FOUND: salesforce integration is not present even though the list type is salesforce */
    updateGROMember: AssignedMember;
    /** Assign action to supporter */
    assignPublicActionToSupporter: boolean;
    /** Assign supporter to an actionThrows error:ACTION_ASSIGNMENT_ERROR - When assigning action to supporter who is either inactive or blockedSUPPORTER_NOT_FOUND - When supporter being assigned is not found */
    assignSupporterToAction: Action;
    /** Delete action assigned to a supporter */
    deleteActionAssignment: Action;
    /** Archive actionErrors thrown:ACTION_CANNOT_BE_ARCHIVED - When status of action is not INACTIVE/TERMINATED */
    archiveAction: Action;
    /** Create an actionthrows error:OUTREACH_CIRCLE_NOT_FOUND - When outreach circle is not foundACTION_ALREADY_EXISTS - When action with given name already existsINVALID_ACTION_NAME - When action name is empty or it has more than 50 charactersINVALID_ACTION_TYPE - When action type is invalidINVALID_END_DATE - End date of the action should always be in the future while creationEND_DATE_BEFORE_START_DATE - End date of the action is earlier than the start dateINVALID_ACTION_TITLE - Empty string for title in the inputINVALID_ACTION_DESCRIPTION - Empty string for description in the inputINVALID_ACTION_LINK - link field should be a valid urlUPGRADE_PLAN - Based on the product plan to upgrade plan */
    createAction: Action;
    /** Update action start date and end dateBoth start date and end date are optionalIf either of them is not passed, that particular field will be set to NULLthrows error:INVALID_END_DATE - End date of the action should always be in the future while creationEND_DATE_BEFORE_START_DATE - End date of the action is earlier than the start dateACTION_NOT_FOUNDACTION_CANNOT_BE_UPDATED - Action cannot be updated as it is ARCHIVED StateUPGRADE_PLAN - Based on the product plan to upgrade plan */
    updateActionDates: Action;
    /** Link email template to actionthrows error:EMAIL_TEMPLATE_NOT_FOUNDACTION_NOT_FOUNDACTION_CANNOT_BE_UPDATED - Action cannot be updated as it is LIVE/ARCHIVED StateOUTREACH_CIRCLES_MISMATCH - emailTemplate and action belongs to different outreach circles */
    linkEmailTemplateToAction: Action;
    /** Link text template to actionthrows error:TEXT_TEMPLATE_NOT_FOUNDACTION_NOT_FOUNDACTION_CANNOT_BE_UPDATED - Action cannot be updated as it is LIVE/ARCHIVED StateOUTREACH_CIRCLES_MISMATCH - textTemplate and action belongs to different outreach circles */
    linkTextTemplateToAction: Action;
    /** Link a social share template with an actionthrows error:ACTION_NOT_FOUND - invalid actionIdSOCIAL_TEMPLATE_DOES_NOT_EXIST - invalid socialTemplateIdACTION_CANNOT_BE_UPDATED - Action cannot be updated as it is LIVE/ARCHIVED StateOUTREACH_CIRCLES_MISMATCH - socialTemplate and action belongs to different outreach circles */
    linkSocialTemplateToAction: boolean;
    /** Update title and descriptionthrows errors:ACTION_NOT_FOUND - Invalid actionId in the inputINVALID_ACTION_TITLE - Empty string for title in the inputACTION_CANNOT_BE_UPDATED - action is in invalid status */
    updateActionTitleAndDescription: Action;
    /** Update link for the actionthrows errors:INVALID_ACTION_LINK - link field should be a valid urlACTION_NOT_FOUND - given actionId does not exist in the databaseACTION_CANNOT_BE_UPDATED - action is in invalid status */
    updateActionLink?: boolean | null;
    /** Update the action status to LIVEthrows errors:ACTION_NOT_FOUND - given actionId does not exist in the databaseACTION_CANNOT_BE_UPDATED - action is in invalid status */
    activateAction: Action;
    /** Update the action namethrows errors:ACTION_NOT_FOUND - given actionId does not exist in the databaseACTION_CANNOT_BE_UPDATED - action is in invalid statusINVALID_ACTION_NAME - Invalid action name */
    updateActionName: Action;
    /** Duplicate an existing action with a new namethrows errors:INVALID_ACTION_NAME - invalid input for the action nameACTION_ALREADY_EXISTS - if the given action name already exists */
    duplicateAction: Action;
    /** Send action emailErrors thrown:PENDING_SEND_EMAIL_JOB_EXISTSACTION_NOT_FOUNDEMAIL_OUTREACH_CHANNEL_NOT_FOUNDNO_EMAIL_TEMPLATE_LINKED_TO_ACTIONACTION_NOT_IN_LIVE_STATESUSPICIOUS_MATCHING_FOUND */
    sendActionEmail?: boolean | null;
    /** Send action email as perview to adminErrors thrown:ACTION_NOT_FOUNDACTION_NOT_IN_LIVE_STATENO_EMAIL_TEMPLATE_LINKED_TO_ACTIONEMAIL_OUTREACH_CHANNEL_NOT_FOUND */
    sendActionPreviewEmail?: boolean | null;
    /** Update outreach circle channelsErrors thrown:ACTION_NOT_FOUND - given actionId does not exist in the database */
    updateOutreachChannelsForAction: Action;
    /** Update audience actionsErrors thrown:ACTION_NOT_FOUND - given actionId does not exist in the database */
    updateAudienceActionsForAction: Action;
    /** Mark the action as completed by the supporterNote: This creates a supporter entry if the user is not yetsupporting the outreach circle of the actionErrors thrown:ACTION_NOT_FOUND - Action id in the input is invalid */
    markActionAsCompleted: Supporter;
    /** Increment Action ImpressionErrors thrown:ACTION_NOT_FOUND - Action id in the input is invalid */
    incrementActionImpressionV1?: boolean | null;
    /** Increment Action ImpressionErrors thrown:ACTION_NOT_FOUND - Action id in the input is invalid */
    incrementActionImpression?: boolean | null;
    /** Update personalize message as completed for actionErrors thrown: */
    personalizeMessageCompletedForAction?: boolean | null;
    /** Update nux display completed for recipient screen for that action by supporter */
    nuxCompletedForActionTag?: boolean | null;
    /** Update import contacts as completed for actionErrors thrown: */
    importContactsCompletedForAction?: boolean | null;
    /** Update manage recipients as completed for actionErrors thrown: */
    manageRecipientsCompletedForAction?: boolean | null;
    /** Update manage recipients as completed for actionErrors thrown: */
    updateP2PNUXCompletedForAction?: boolean | null;
    /** Update the action namethrows errors:ACTION_NOT_FOUND - given actionId does not exist in the databaseACTION_CANNOT_BE_UPDATED - action is in invalid statusINVALID_CALL_TO_ACTION_TEXT - Invalid call to action text */
    updateCallToActionText: Action;
    /** Update the action namethrows errors:ACTION_NOT_FOUND - given actionId does not exist in the databaseACTION_CANNOT_BE_UPDATED - action is in invalid statusINVALID_POST_ACTION_TEXT - Invalid call to action text */
    updatePostActionText: Action;
    /** Update the action namethrows errors:ACTION_NOT_FOUND - given actionId does not exist in the databaseACTION_CANNOT_BE_UPDATED - action is in invalid status */
    updateActionOutreachMode: Action;
    /** Update the action namethrows errors:ACTION_CANNOT_BE_UPDATED - action is in invalid statusCANNOT_TOGGLE_ALLOW_NON_MAPPED_CONTACTS - cannot toggle allowNonMappedContacts if outreach_mode is true */
    updateAllowNonMappedContacts: Action;
    /** Link a survey with an actionthrows error:SURVEY_NOT_FOUND - invalid surveyIdACTION_CANNOT_BE_UPDATED - Action cannot be updated as it is LIVE/EXPIRED StateOUTREACH_CIRCLES_MISMATCH - survey and action belongs to different outreach circles */
    linkSurveyToAction: Action;
    /** unLink the survey with an actionthrows error:SURVEY_NOT_FOUND - invalid surveyIdACTION_CANNOT_BE_UPDATED - Action cannot be updated as it is LIVE/EXPIRED State */
    unlinkSurveyToAction: Action;
    /** Update position of an actionthrows error:INVALID_ACTION_POSITION - If position is < 1 or more than the total count of actions */
    updateActionPosition: Action;
    /** Update image for an action */
    updateImageForAction: Action;
    /** Pause an actionErrors thrown:ACTION_CANNOT_BE_PAUSED - Action is in a state which cannot be pausedINVALID_ACTION_TYPE - When trying to pause action other than p2p */
    pauseAction: Action;
    /** Deactivate an actionErrors thrown:ACTION_CANNOT_BE_DEACTIVATED - Action is in a state which cannot be deactivated */
    deactivateAction: Action;
    /** Update isEngagementRequired falg for actionErrors thrown:ACTION_CANNOT_BE_UPDATED - Action can not be updated */
    updateIsEngagementRequired: Action;
    /** Mark the affinity contact as sentErrors thrown:INVALID_TEXT_RECIPIENT - Invalid text recipient */
    markAffinityTextContactAsSent: boolean;
    /** Mark the affinity contact as sentErrors thrown:TEXT_SUPPORTER_SET_NOT_FOUND - text supporter set not foundACTION_TEXT_ALREADY_SENT_TO_SUPPORTER - action text already sent to supporter */
    markTextSentToSupporter: boolean;
    /** Update treatment and isTreatmentEnabled for an actionErrors thrown:ACTION_CANNOT_BE_UPDATED - Action can not be updatedACTION_TREATMENT_IS_MANDATORY - When isTreatmentEnabled is true and no value is passed for treatment */
    updateTreatmentForAction: Action;
    /** Update permission to import contacts by supporter for an actionErrors thrown:ACTION_CANNOT_BE_UPDATED - Action can not be updated */
    updateContactsImportPermissionForSupporter: Action;
    /** Supporter acknowledgement for importing contact for an Action to his address bookErrors thrown:ACTION_ALLOW_CONTACT_IMPORT_DISABLED - Action allow contact import disabled */
    acknowledgeContactImportForAction: boolean;
    /** Set campaign for given action */
    setCampaignForAction: boolean;
    /** Update the share action button textthrows errors:ACTION_NOT_FOUND - given actionId does not exist in the databaseACTION_CANNOT_BE_UPDATED - action is in invalid statusINVALID_SHARE_BUTTON_TEXT - Invalid share action button text */
    updateShareActionButtonText: Action;
    /** Update the share action button textthrows errors:ACTION_NOT_FOUND - given actionId does not exist in the database */
    removeActionReviewWarning: Action;
    /** Assign Supporter to the P2P and GRO Actionthrows errors:SUPPORTER_NOT_FOUND - Supporter Id is invalid */
    assignSupportersToAction: Action;
    /** Assign all supporter linked to invited supporter source id to the P2P Actionthrows errors:INVITED_SUPPORTER_SOURCE_NOT_FOUND - When invited supporter source id is invalid */
    assignSupportersFromInvitedSupporterSourceToAction: Action;
    /** Unassign supporter from p2p actionthrows errors:SUPPORTER_NOT_FOUND - Supporter Id is invalidUNABLE_TO_UNASSIGN_P2P_SUPPORTER_FROM_ACTION - When there are replies pending for p2p action by supporter */
    unassignSupporterFromAction: Action;
    /** Update the action status to TERMINATEDthrows errors:ACTION_NOT_FOUND - given actionId does not exist in the databaseACTION_CANNOT_BE_UPDATED - action is in invalid status */
    terminateAction: Action;
    /** Acknowledge estimated cost for p2p actionthrows errors:ACTION_CANNOT_BE_UPDATED - action is in invalid status */
    acknowledgeEstimatedCostForP2PAction: Action;
    /** Assign supporters from group to action */
    assignSupportersFromGroupToAction: Action;
    /** Update search and survey audience actions for action */
    updateSearchAndSurveyAudienceActionsForAction: Action;
    /** Update search and survey new contacts list for actionThrows errors:INVALID_AUDIENCE_ACTION - When the list type is invalidINVALID_ACTION - When trying to update new contacts list for action type other than search and survey */
    updateSearchAndSurveyNewContactsListForAction: Action;
    /** Select audience custom field to be displayed for an action either in search or surveythrows errors:ACTION_MAXIMUM_CUSTOM_FIELD_LIMIT_REACHED - Maximum custom field limit reached for action */
    addCustomFieldForSearchAndSurveyAction?: AudienceCustomField | null;
    /** Deselect audience custom field to be displayed for an action either in search or survey */
    removeCustomFieldForSearchAndSurveyAction: boolean;
    /** Update action confirmationErrors thrown:INVALID_ACTION_CONFIRMATION_TEXTINVALID_ACTION_TYPE */
    updateActionConfirmation: Action;
    /** Update call or visit templateErrors thrown:INVALID_ACTION_TYPE - When trying to update call or visit template for action other than GROINVALID_OUTREACH_CHANNEL_TEMPLATE_TEXT - When call or visit template text is invalidINVALID_MERGE_FIELDS_IN_TEMPLATE - When it contains an invalid merge field */
    updateCallOrVisitTemplate: Action;
    /** Unlink/Remove template from action for outreachChannelErrors thrown:ACTION_CANNOT_BE_UPDATED - When trying to remove template from action with invalid status */
    removeTemplateFromActionForOutreachChannel: Action;
    /** Send email notification for actionErrors thrown:NO_ELIGIBLE_SUPPORTERS_AVAILABLE_FOR_NOTIFICATION - When no eligible supporters are found to send notification */
    sendEmailNotificationForAction: EmailNotificationLog;
    /** Send push notification for actionErrors thrown:NO_ELIGIBLE_SUPPORTERS_AVAILABLE_FOR_NOTIFICATION - When no eligible supporters are found to send notification */
    sendPushNotificationForAction: PushNotificationLog;
    /** Reassign unassigned supporter back to P2P actionErrors thrown:ACTION_NOT_ASSIGNED_TO_SUPPORTER - When action hasn't been assigned to supporterACTION_ALREADY_ASSIGNED_TO_SUPPORTER - When action has already been assigned to supporter */
    reassignUnassignedSupporterForP2PAction: boolean;
    /** Reset reply reassignment back to original supporter for P2P actionErrors thrown:ACTION_NOT_ASSIGNED_TO_SUPPORTER - When action hasn't been assigned to supporterACTION_ALREADY_ASSIGNED_TO_SUPPORTER - When action has already been assigned to supporter */
    resetReplyReassignmentForP2PAction: boolean;
    /** Assign selected and remove unselected groups from action */
    assignGroupToAction: ActionGroupList;
    /** Update GRO config for action */
    updateGroConfig: Action;
    /** Update address based config for action */
    updateAddressBasedCanvassingConfig: Action;
    /** Update on the go canvassing config for action */
    updatePdiOnTheGoCanvassingConfig: Action;
    /** Update Contact Type Labels */
    updateContactTypeLabel: Action;
    /** Create Member Notes */
    createMemberNote: MemberNote;
    /** Update survey for Action */
    updateSurveyForAction: Action;
    /** Remove linked survey from Action */
    removeSurveyFromAction: Action;
    /** Update survey question for Action */
    updateSurveyQuestionForAction: Action;
    /** Update member display fields for Action */
    updateMemberDisplayFields: Action;
    /** Update action Visibility */
    updateActionVisibility: Action;
    /** Update Volunteer Attendees Status */
    updateVolunteerAttendeesStatus: boolean;
    /** Update action to outreachcirle alias linkingErrors thrown:ALIAS_NOT_ASSOCIATED_TO_OUTREACH_CIRCLE - alias should be linked to outreachcircle before associating ACTION_CANNOT_BE_UPDATED - When trying to update linking for action with invalid status */
    updateActionAliasLink: Action;
    /** Update p2p configuration for actionerrors:ACTION_CANNOT_BE_UPDATED */
    updateP2PConfigForAction: Action;
    /** Purchase a phone number based on area code provided for p2p actionthrows errors:INVALID_AREA_CODE - When any areaCode is not of length 3ACTION_CANNOT_BE_UPDATED - When action cannot be updatedNO_PHONE_NUMBER_FOUND_FOR_AREA_CODE - When either any areaCode is invalid or does not have phone numberP2P_MAXIMUM_PHONE_NUMBER_CAMPAIGN_REGISTRATION_LIMIT_REACHED - P2P Maximum phone number registration limit reachedP2P_SCRIPT_MISSING - P2P script is missing. Please link/create a texting script */
    purchasePhoneNumberForP2PAction: Action;
    /** Update the P2P action available timingsthrows errors:ACTION_NOT_FOUND - given actionId does not exist in the databaseACTION_CANNOT_BE_UPDATED - action is in invalid status */
    updateP2PActionAvailableTimings: Action;
    /** Update the dialer type of an action. */
    updateActionDialerType: Action;
    /** Connect to a phone bank. */
    connectToPhoneBank: string;
    /** Call a phone bank member. */
    callPhoneBankMember: string;
    /** Wrap up a phone bank call and dial the next one. */
    wrapAndDialNextPhoneCall: PhoneBankCallResponse;
    /** End a phone bank call. */
    hangupCall: boolean;
    /** mute/unmute for a phone bank call. */
    toggleMuteForCall: ToggleMuteForCallResponse;
    /** update phonebank member details */
    updatePhoneBankMemberDetails: PhoneBankMember;
    /** Update IsAnonymousSearchAndSurveyEnabled for action */
    updateIsAnonymousSearchAndSurveyForAction: Action;
    /** Purchase a phone number based on area code provided for texting actionthrows errors:INVALID_AREA_CODE - When any areaCode is not of length 3ACTION_CANNOT_BE_UPDATED - When action cannot be updatedNO_PHONE_NUMBER_FOUND_FOR_AREA_CODE - When either any areaCode is invalid or does not have phone numberP2P_MAXIMUM_PHONE_NUMBER_CAMPAIGN_REGISTRATION_LIMIT_REACHED - P2P Maximum phone number registration limit reached */
    purchasePhoneNumberForTextingAction: Action;
    /** Update texting configuration for actionerrors:ACTION_CANNOT_BE_UPDATED */
    updateTextingConfigForAction: Action;
    /** Update initial texting script for actionErrors Thrown:INVALID_MERGE_FIELDS_IN_TEMPLATE - When merge fields in template are invalidINVALID_OPTOUT_TEXT_IN_SEGMENT - When optout text in text is invalidINVALID_TEXTING_SCRIPT - When texting script is invalidACTION_NOT_IN_LIVE_STATE - When action is not in live stateTEXTING_SCRIPT_UPDATE_DISABLED - When texting script update is disabled */
    updateInitialTextingScript: Action;
    /** send bulk initial texts for manual texting event */
    sendBulkInitialTexts: boolean;
    /** UnAssign volunteers from texting conversations */
    unAssignVolunteerFromTextingConversation: boolean;
    /** Transfer texting conversations to a volunteer */
    transferTextingConversationToVolunteer: boolean;
    /** Update texting conversation transfer to a volunteer */
    updateTextingConversationTransferToVolunteer: boolean;
    /** Create recipient texting conversation item */
    createRecipientTextingConversationItem: RecipientTextingConversationItem;
    /** Un Assign texting conversations */
    unAssignTextingConversation: boolean;
    /** Volunteer enters texting conversation */
    volunteerEntersTextingConversation: boolean;
    /** Volunteer leaves texting conversation */
    volunteerLeavesTextingConversation: boolean;
    /** Create a member for address based canvasing */
    addMemberForAddressBasedCanvassing: SearchAndSurveyContact;
    /** Add member to the household */
    addMemberToHouseHold: boolean;
    /** Record tag for household */
    recordTagForHousehold: boolean;
    /** Record tag for search and survey member */
    recordTagForSearchAndSurveyMember: boolean;
    /** Reslove ambiguous household members */
    resolveAmbiguousHouseholdMember?: boolean | null;
    /** Retry Sync for household members */
    retrySyncForHouseholdMembers: boolean;
    /** Creating new search and survey audience actionThrows error:AUDIENCE_ACTION_WITH_SAME_NAME_ALREADY_EXISTS - When another search and survey list with same name exists. */
    createSearchAndSurveyList: AudienceAction;
    /** Delete audience actionThrows error:INVALID_AUDIENCE_ACTION - When trying to delete audience action other than type SALESFORCE_LISTUNABLE_TO_DELETE_AUDIENCE_ACTION - When trying to delete audience action linked to non terminated action */
    deleteAudienceAction: boolean;
    /** Prepare PDI List for projectThrows error:PDI_INTEGRATION_NOT_FOUND - When PDI org is not foundPDI_LIST_NOT_FOUND - When pdi list id or type is invalid and not available in OCTPDI_LIST_IMPORT_ALREADY_IN_PROGRESS - When pdi list import is already in progressPDI_LIST_IMPORT_NOT_ALLOWED - When list is not active */
    importPDIList: boolean;
    /** Import project list from file */
    importProjectListFromFile: AudienceAction;
    /** Add audience membersErrors thrown:AUDIENCE_NOT_FOUND -  When outreachcircle doesn't have audienceINVALID_AUDIENCE_MAPPING_NAME - When the audience mapping name already exist in db */
    addMembers?: AudienceAction | null;
    /** Update members of audienceErrors thrown:AUDIENCE_NOT_FOUND - When outreachcircle doesn't have audience */
    updateMembers?: AudienceAction | null;
    /** Delete members from audienceErrors thrown:AUDIENCE_NOT_FOUND - When outreachcircle doesn't have audience */
    deleteMembers?: AudienceAction | null;
    /** Delete all audience and it's related data for an outreach circle */
    createAudience: Audience;
    /** Delete all audience and it's related data for an outreach circle */
    deleteAudienceByListType?: boolean | null;
    /** Block a member from the audience */
    blockAudienceMember?: boolean | null;
    /** unBlock a member from the audience */
    unBlockAudienceMember?: boolean | null;
    /** Update audience action */
    updateAudienceAction: AudienceAction;
    /** Create single search and survey list contact */
    createSearchAndSurveyContact: SearchAndSurveyContact;
    /** Add members from saved list of blackbaud */
    addMembersFromBlackbaudImport: AudienceAction;
    /** Update members from saved list of blackbaud */
    updateMembersFromBlackbaudImport: AudienceAction;
    /** Delete members from saved list of blackbaud */
    deleteMembersFromBlackbaudImport: AudienceAction;
    /** Mark a mapped contact as an email / don't email recipientErrors Thrown:MAPPED_CONTACT_NOT_FOUND: no mapped contact exists for atleast one of the given input */
    setEmailRecipientV1?: (MappedContact | null)[] | null;
    /** Mark a mapped contact as an email / don't email recipientErrors Thrown:MAPPED_CONTACT_NOT_FOUND: no mapped contact exists for atleast one of the given input */
    setEmailRecipient?: boolean | null;
    /** Set all contacts as email recipientsErrors thrown:ACTION_NOT_FOUND */
    setAllEmailRecipients?: boolean | null;
    /** Unset all contacts as email recipientsErrors thrown:ACTION_NOT_FOUND */
    unsetAllEmailRecipients?: boolean | null;
    /** Resolve the ambiguity of an ambiguous mapped contact */
    resolveAmbiguousMappedContact?: boolean | null;
    /** Create matching job for supporterReturns matching job idErrors Thrown:SUPPORTER_NOT_FOUND: supporter in the input does not exist */
    createMatchingJob: MatchingJob;
    /** Start matching jobErrors Thrown:MATCHING_JOB_NOT_FOUND */
    startMatchingJob: boolean;
    /** Mark text recipient as sentErrors thrown:INVALID_TEXT_RECIPIENT - Invalid text recipient */
    markTextRecipientAsSent?: boolean | null;
    /** Errors thrown:INVALID_ACTION - If actionId in input is not VFM with NVFM enabled */
    toggleEligibleContactAsEmailRecipient: boolean;
    /** Set or Unset Mapped Contact as A1C Recipient */
    toggleA1CRecipientMapped: boolean;
    /** Set or Unset all mapped contact as A1C Recipient */
    toggleAllA1CRecipientMapped: boolean;
    /** Set or Unset Non-mapped Contact as A1C Recipient */
    toggleA1CRecipientNonMapped: boolean;
    /** Set or Unset all non-mapped contact as A1C Recipient */
    toggleAllA1CRecipientNonMapped: boolean;
    /** Set Ambiguous Contact as Matched or unmatched and toggle it as A1C Recipient */
    toggleAmbiguousA1CRecipient: boolean;
    /** Delete Mapped contact */
    deleteMappedContact: boolean;
    /** Sends contact-us email to outreach circle support teamThrows - OUTREACH_CIRCLE_NOT_FOUND for invalid outreachCircleId- INVALID_EMAIL_ID for invalid email id */
    contactUs: boolean;

    importContactFile?: boolean | null;

    deleteContactSource?: boolean | null;
    /** Create single contactErrors Thrown:CONTACT_WITH_EMAIL_ALREADY_EXISTS - There is a contact with the same emailCONTACT_WITH_PHONE_NUMBER_ALREADY_EXISTS - There is a contact with same phone numberEMAIL_OR_PHONE_IS_MANDATORY - Either primary email or primary phone number must be providedINVALID_NAME_INPUT - When first name or last name is more than 25 charactersINVALID_ZIP_CODE - When zip code is more than 10 characters */
    createContact: Contact;
    /** Create Contact With Matching */
    createContactWithMatching: Contact;
    /** Update single contactErrors Thrown:CONTACT_WITH_EMAIL_ALREADY_EXISTS - There is a contact with the same emailCONTACT_WITH_PHONE_NUMBER_ALREADY_EXISTS - There is a contact with same phone numberEMAIL_OR_PHONE_IS_MANDATORY - Either primary email or primary phone number must be providedINVALID_NAME_INPUT - When first name or last name is more than 25 charactersINVALID_ZIP_CODE - When zip code is more than 10 characters */
    updateContact: Contact;
    /** Update Contact With Matching */
    updateContactWithMatching: Contact;
    /** Update priamry email for the contactErrors thrown:INVALID_EMAIL_INPUT */
    updatePrimaryEmailForContact: Contact;
    /** Delete single contactErrors Thrown:CONTACT_NOT_FOUND */
    deleteContact: boolean;
    /** Resolve Duplicate Email Recipient */
    resolveDuplicateEmailContact: boolean;
    /** mark an organization update as completed */
    markOrganizationUpdateAsCompleted: boolean;
    /** update static or dynamic list active field status Errors thrown:CANNOT_CHANGE_PDI_LIST - When list import is in progress */
    updatePDIListStatus: boolean;
    /** Create a new turf group */
    createTurfGroup: TurfGroup;
    /** Update a turf group */
    updateTurfGroup: TurfGroup;
    /** Add turf to turf group */
    addTurfToTurfGroup: TurfGroup;
    /** Remove turf from turf group */
    removeTurfFromTurfGroup: boolean;
    /** Delete a turf group */
    deleteTurfGroup: boolean;
    /** Assign supporters to a turf */
    assignSupportersToTurf: Turf;
    /** Remove supporters from a turf */
    removeSupportersFromTurf: Turf;
    /** Copy a turf for an action */
    copyTurfForAction: Turf;
    /** Submit Survey Response for Household */
    submitSurveyResponseForHousehold: boolean;
    /** Delete Turfs */
    deleteTurfs: boolean;
    /** Create a turf assignment */
    createTurfAssignment: CanvassingAssignment;
    /** Mark Survey as Complete */
    markSurveyAsComplete: boolean;
    /** Extend Turf Assignment */
    extendTurfAssignment: SupporterTurfAssignment;
    /** Extend turf expiry */
    extendTurfExpiry: Date;
    /** Release Turf Assignment */
    releaseTurfAssignment: boolean;
    /** Store Turf Assignment in Firebase */
    storeTurfAssignmentInFirebase: boolean;
    /** Update Voter Contact */
    editVoterContactDetails: UnapprovedMember;
    /** Share Turf Assignment */
    shareTurfAssignment: CanvassingAssignment;
    /** Share Turf Assignment for Supporters */
    shareTurfAssignmentForSupporters: boolean;
    /** Create Canvassing Member Contact */
    createCanvassingMember: CanvassingMember;
    /** Create Canvassing Household */
    createCanvassingHousehold: CanvassingHousehold;
    /** Apply not home on household */
    applyNotHomeOnHousehold: boolean;
    /** Create Custom Turf */
    createCustomTurf: Turf;
    /** Update Custom Turf */
    updateTurf: Turf;
    /** Submit Canvassing Survey Response */
    submitCanvassingSurveyResponse: boolean;
    /** Approve Survey Response For Member */
    approveCanvassedSurveyResponseForMember: boolean;
    /** Reject Survey Response For Member */
    rejectCanvassedSurveyResponseForMember: boolean;
    /** Approve Survey Responses By Supporters */
    approveCanvassedSurveyResponsesBySupporters: boolean;
    /** Reject Survey Responses By Supporters */
    rejectCanvassedSurveyResponsesBySupporters: boolean;
    /** Approve Survey Responses By Turfs */
    approveCanvassedSurveyResponsesByTurfs: boolean;
    /** Reject Survey Responses By Turfs */
    rejectCanvassedSurveyResponsesByTurfs: boolean;
    /** Approve or Reject All Canvassed Survey Responses */
    approveOrRejectAllCanvassedSurveyResponses: boolean;
    /** Import the pdi list for canvassing / voter density */
    importPdiListForCanvassing: AudienceAction;
    /** Import audience action for canvassing voter density */
    importAudienceActionForCanvassingVoterDensity: AudienceAction;
    /** Create new email templateErrors thrown:EMAIL_TEMPLATE_WITH_SAME_NAME_EXISTS */
    createEmailTemplate: EmailTemplate;
    /** Duplicate email templateErrors thrown:EMAIL_TEMPLATE_WITH_SAME_NAME_EXISTS */
    duplicateEmailTemplate: EmailTemplate;
    /** Update email templateErrors thrown:EMAIL_TEMPLATE_NOT_FOUND - Email template not found */
    updateEmailTemplate: EmailTemplate;
    /** Update email template for supporterErrors thrown:EMAIL_TEMPLATE_NOT_FOUND - Email template not found */
    updateEmailTemplateForSupporter: EmailTemplateForSupporter;
    /** Send edited email preview */
    sendEditedEmailPreview?: boolean | null;
    /** Send preview email for supporterErrors thrown:ACTION_NOT_IN_LIVE_STATE */
    sendPreviewEmailForSupporter: boolean;
    /** Create new email template for organizationErrors thrown:EMAIL_TEMPLATE_WITH_SAME_NAME_EXISTS */
    createOrganizationEmailTemplate: EmailTemplateForOrganization;
    /** Update email template for organizationErrors thrown:EMAIL_TEMPLATE_NOT_FOUND - Email template not found */
    updateOrganizationEmailTemplate: EmailTemplateForOrganization;
    /** Returns HTML with updated recruitment link text */
    updateEmailTemplateForOrgWithRecruitmentLink: string;
    /** Send edited organization template email preview */
    sendEditedOrganizationTemplateEmailPreview?: boolean | null;
    /** Creates an survey engagementthrows error:INVALID_ENGAGEMENT_INPUT - Invalid engagement input if any of the required feild is missing or not provided correctlyENGAGEMENT_NAME_ALREADY_EXIST - Survey engagement with given name already exist */
    createSurveyEngagement: Engagement;
    /** Updates an survey engagementthrows error:INVALID_ENGAGEMENT_INPUT - Invalid engagement input if any of the required feild is missing or not provided correctlySURVEY_ENGAGEMENT_NOT_FOUND - When survey engagement is not foundENGAGEMENT_NAME_ALREADY_EXIST - Survey engagement with given name already existPDI_QUESTION_NOT_FOUND - When trying to link a PDI question that does not exist */
    updateSurveyEngagement: Engagement;
    /** Deletes engagements */
    deleteEngagements: boolean;
    /** Deletes survey by id */
    deleteSurvey: boolean;
    /** Save Survey responsethrows error:ENGAGEMENT_OPTION_NOT_FOUND - When survey engagement option is not foundINVALID_SURVEY_RESPONSE_INPUT - When invalid emailRecipient or textRecipient id is assigned */
    saveSurveyResponse?: PublicEngagement | null;
    /** Create a duplicate survey from given survey */
    duplicateSurvey: Engagement;
    /** Add a new activity for an Outreach circleErrors:ACTIVITY_WITH_NAME_ALREADY_EXISTS: Activity with the same name already exists */
    addActivity: Activity;
    /** Create a new event configErrors:INVALID_EVENT_CONFIG_NAME - Max length is 100 characters */
    createEventConfig: EventConfig;
    /** Update an existing event configErrors:EVENT_CONFIG_CANNOT_BE_UPDATED - Event config cannot be updated after it is published or terminated */
    updateEventConfig: EventConfig;
    /** Publish events from event config for an outreach circleErrors:EVENT_CONFIG_CANNOT_BE_PUBLISHED - Event config cannot be published if it is already published or terminated */
    publishEventConfig: EventConfig;
    /** Terminate events from event config for an outreach circleErrors:EVENT_CONFIG_CANNOT_BE_TERMINATED - Event config cannot be terminated if it is already terminated */
    terminateEventConfig: EventConfig;
    /** Update image for an event configErrors:EVENT_CONFIG_CANNOT_BE_UPDATED - Event config cannot be updated after it is published or terminated */
    updateEventConfigImage: EventConfig;
    /** Update event information for an action */
    updateActionEventInformation: Action;
    /** Update event schedule for an actionErrors:INVALID_EVENT_SCHEDULE: If start time is after end time */
    updateActionEventSchedule: Action;
    /** Update event location visibility for an action */
    updateActionEventLocationVisibility: Action;
    /** Update event participation for an action */
    updateActionEventParticipation: Action;
    /** Send email notification to supporters for event or shift assignment */
    notifyAssignedSupportersViaEmailForEvent: boolean;
    /** Update Shift Assignment Availability */
    updateShiftAssignmentAvailability: Action;
    /** Terminate event series */
    terminateEventSeries: EventSeries;
    /** Update event series */
    updateEventSeries: UpdateEventSeriesOutput;
    /** Update image for event series */
    updateEventSeriesImage: EventSeries;
    /** Add a new shift series to an event series */
    addShiftSeries: ShiftSeries;
    /** Update an existing shift series */
    updateShiftSeries: ShiftSeries;
    /** Delete an existing shift series */
    deleteShiftSeries: boolean;
    /** Assign turf groups to a shift series */
    assignTurfGroupsToShiftSeries: boolean;
    /** Add a new shift to an actionErrors:SHIFT_TIME_IS_OUTSIDE_EVENT_TIME: The shift time is outside the event time */
    addShift: Shift;
    /** Update an existing shiftErrors:SHIFT_TIME_IS_OUTSIDE_EVENT_TIME: The shift time is outside the event time */
    updateShift: Shift;
    /** Delete a shift */
    deleteShift: boolean;
    /** Assign turf groups to a shift */
    assignTurfGroupsToShift: boolean;
    /** Assign actions to a shift */
    assignActionsToShift: boolean;
    /** Assign volunteer is not already assigned. Then update status as per inputErrors:SHIFT_SUPPORTER_ASSIGNMENT_NOT_FOUND  - volunteer is not registered / assigned for the shift */
    updateAttendanceStatusForShift: PublicShift;
    /** Register logged in volunteer for shift */
    registerForShift: PublicShift;
    /** Save or update attendance status for supporter based on typeErrors:INVALID_INPUT_FOR_UPDATE_VOLUNTEER_ATTENDANCE - for type guest actionId is required and for volunteer shiftId is requiredACTION_NOT_FOUND - Action not found because of invalid actionIdSHIFT_NOT_FOUND - Shift not found because of invalid shiftId */
    saveAttendanceStatusForSupporter: boolean;
    /** Update Attendance Status For Shift For Embed */
    updateAttendanceStatusForShiftForEmbed: PublicShift;
    /** Update shift to outreachcirle alias linkingErrors thrown:ALIAS_NOT_ASSOCIATED_TO_OUTREACH_CIRCLE - alias should be linked to outreachcircle before associating SHIFT_NOT_FOUND - shift not found because of invalid shiftId */
    updateShiftAliasLink: Shift;
    /** Create a new survey flagThrow Errors:FLAG_ALREADY_EXISTS: Flag with same value already existsINVALID_CREATE_FLAG_INPUT: Invalid create flag input */
    createFlag: Flag;
    /** Update an existing survey flagThrow Errors:FLAG_ALREADY_EXISTS: Flag with same value already existsINVALID_UPDATE_FLAG_INPUT: Invalid update flag input */
    updateFlag: Flag;
    /** Update flag status by id */
    updateFlagStatusById: Flag;
    /** Copy flag from parent */
    copyFlagsFromParentOutreachCircle: boolean;
    /** Add flags to alias */
    addFlagsToAlias: Alias;
    /** Remove flags from alias */
    removeFlagsFromAlias: Alias;
    /** Update gro survey formErrors thrown:GRO_SURVEY_FORM_NOT_FOUNDINVALID_GRO_SURVEY_FORM_QUESTION_TYPEGRO_SURVEY_FORM_WITH_SAME_NAME_EXISTSINVALID_OPTIONS_INPUT_FOR_GRO_SURVEY_FORM_QUESTIONINVALID_QUESTION_TYPE_FOR_GRO_SURVEY_FORM_QUESTION_INTEGRATIONGRO_SURVEY_FORM_QUESTION_TEXT_LENGTH_EXCEEDED */
    updateGroSurveyForm?: GroSurveyForm | null;
    /** Create gro survey formErrors thrown:GRO_SURVEY_FORM_WITH_SAME_NAME_EXISTS */
    createGroSurveyForm?: GroSurveyForm | null;
    /** Link the gro survey form to an action */
    linkGroSurveyFormToAction: Action;
    /** Unlink the gro survey form from an action */
    unlinkGroSurveyFormFromAction: Action;
    /** Duplicate a gro survey form with a new name */
    duplicateGroSurveyForm: GroSurveyForm;
    /** Submit response for gro survey formErrors Thrown:GRO_SURVEY_FORM_QUESTION_NOT_FOUNDINVALID_OPTION_SELECTED_FOR_QUESTIONINVALID_RESPONSE_FOR_MANDATORY_QUESTIONINVALID_GRO_SURVEY_FORM_QUESTIONGRO_SURVEY_FORM_OPTION_NOT_FOUNDGRO_SURVEY_FORM_NOT_FOUNDACTION_NOT_IN_LIVE_STATE */
    submitGroSurveyFormQuestionResponse: boolean;
    /** Submit response of gro survey form for contactsErrors Thrown:GRO_SURVEY_FORM_QUESTION_NOT_FOUNDINVALID_OPTION_SELECTED_FOR_QUESTIONINVALID_RESPONSE_FOR_MANDATORY_QUESTIONINVALID_GRO_SURVEY_FORM_QUESTIONGRO_SURVEY_FORM_OPTION_NOT_FOUNDGRO_SURVEY_FORM_NOT_FOUNDACTION_NOT_IN_LIVE_STATE */
    submitGroSurveyFormQuestionResponseForContact: boolean;
    /** Delete gro survey form */
    deleteGroSurveyForm: boolean;
    /** Create GroupErrors thrown:GROUP_NAME_ALREADY_EXISTS - Group with the same name already exists */
    createGroup: Group;
    /** Update GroupErrors thrown:GROUP_NAME_ALREADY_EXISTS - Group with the same name already exists */
    updateGroup: Group;
    /** Delete groupErrors thrown:GROUP_CANNOT_BE_DELETED - Group cannot be deleted if supporter form action with group linking is live */
    deleteGroup: boolean;
    /** Assign an action to a groupErrors thrown:EXPIRED_ACTION_CANNOT_BE_LINKED_ACTION */
    assignActionToGroup: Group;
    /** Assign an alias to a groupErrors thrown:DEFAULT_ALIAS_CANNOT_BE_LINKED_TO_GROUP */
    assignAliasToGroup: Group;
    /** UnAssign an action to a group */
    unAssignActionFromGroup: Group;
    /** UnAssign an alias to a group */
    unAssignAliasFromGroup: Group;
    /** Assign an supporter to a group */
    assignSupportersToGroups: boolean;
    /** UnAssign an supporter to a group */
    unAssignSupporterFromGroup: boolean;
    /** Integrates FacebookPixel to OutreachCircle */
    createOrUpdateFacebookPixelIntegration: boolean;
    /** Enable phone2Action integration */
    enablePhone2ActionIntegration: boolean;
    /** Enable EveryAction integration */
    enableEveryActionIntegration: boolean;
    /** Create or update NationBuilder IntegrationErrors thrown:INVALID_SLUG_FOR_NATION_BUILDER_INTEGRATION - When the slug is invalidINVALID_API_TOKEN_FOR_NATION_BUILDER_INTEGRATION - When the API Token in invalid */
    createOrUpdateNationBuilderIntegration?: Integration | null;
    /** Sync supporters to NationBuilderErrors thrown:NATION_BUILDER_INTEGRATION_NOT_FOUND - When the OutreachCircle doesnot have a NationBuilder integration */
    updateSyncSupportersToNationBuilder?: boolean | null;
    /** Add or update NationBuilder People Tags for a recruitment linkErrors thrown:NATION_BUILDER_INTEGRATION_NOT_FOUND - When the OutreachCircle doesnot have a NationBuilder integrationALIAS_DOES_NOT_EXIST - When the alias does not existINVALID_APPLY_TO_ALL_CUSTOM_ALIASES_OPTION - When applyToAllCustomAliases is selected for an alias which is not default */
    addOrUpdateNationBuilderTagsForAlias?: boolean | null;
    /** Save ngp configuration for an outreach circleErrors thrown:UPGRADE_PLAN - Based on the product plan to upgrade plan */
    saveNgpConfiguration?: boolean | null;
    /** Add audience members using NGPErrors thrown:NGP_CREDENTIALS_NOT_FOUND - NGP credentials not foundAUDIENCE_NOT_FOUND - Audience not foundINVALID_NGP_SAVED_LIST_ID - Invalid saved list idUPGRADE_PLAN - Based on the product plan to upgrade planINVALID_AUDIENCE_MAPPING_NAME - Invalid Audience Mapping NameAFFINITY_VOTER_FILE_LIMIT_EXCEEDED - If voter file record size for affinity texting exceedsP2P_CONTACT_FILE_LIMIT_EXCEEDED, - If voter file record size for p2p texting exceeds */
    addMembersFromNGP: AudienceAction;
    /** Update audience members using NGPErrors thrown:NGP_CREDENTIALS_NOT_FOUND - NGP credentials not foundAUDIENCE_NOT_FOUND - Audience not foundINVALID_NGP_SAVED_LIST_ID - Invalid saved list id */
    updateMembersFromNGP: AudienceAction;
    /** Delete audience members using NGPErrors thrown:NGP_CREDENTIALS_NOT_FOUND - NGP credentials not foundAUDIENCE_NOT_FOUND - Audience not foundINVALID_NGP_SAVED_LIST_ID - Invalid saved list id */
    deleteMembersFromNGP: AudienceAction;
    /** Sync supporters to NGP MyCampaignErrors thrown:NGP_CREDENTIALS_NOT_FOUND - When the OutreachCircle doesnot have a NGP MyCampaign integration */
    updateSyncSupportersToNgpMyCampaign?: boolean | null;
    /** Add activistCodes for an aliasErrors thrown:NGP_MY_CAMPAIGN_INTEGRATION_NOT_FOUND - When the OutreachCircle doesnot have a NGP MyCampaign integrationALIAS_DOES_NOT_EXIST - When the alias does not exist */
    addNgpActivistCodesForAlias?: boolean | null;
    /** Add activistCodes for an aliasErrors thrown:NGP_MY_CAMPAIGN_INTEGRATION_NOT_FOUND - When the OutreachCircle doesnot have a NGP MyCampaign integrationALIAS_DOES_NOT_EXIST - When the alias does not exist */
    removeNgpActivistCodesForAlias?: boolean | null;
    /** Update applyToAllCustomAliases for ngp activistCodesErrors thrown:NGP_MY_CAMPAIGN_INTEGRATION_NOT_FOUND - When the OutreachCircle doesnot have a NGP MyCampaign integrationINVALID_APPLY_TO_ALL_CUSTOM_ALIASES_OPTION - When applyToAllCustomAliases is selected for an alias which is not default */
    updateApplyToAllCustomAliasesForNgpActivistCode?: boolean | null;
    /** Create a member in PDI */
    addPDIMember: SearchAndSurveyContact;
    /** Assign zones to supporters */
    assignAreaInPDIAssignmentZone: boolean;
    /** Release PDI assignment */
    releasePdiAssignment: boolean;
    /** Create or update PDI IntegrationErrors thrown:UPGRADE_PLAN - Based on the product plan to upgrade planINVALID_PDI_CREDENTIALS - When either username or password or API Token is invalid */
    createOrUpdatePDIIntegration?: Integration | null;
    /** Sync supporters to PDIErrors thrown:PDI_INTEGRATION_NOT_FOUND - When the OutreachCircle doesnot have a PDI integration */
    updateSyncSupportersToPDI?: boolean | null;
    /** Add PDI flags for recruitment link */
    addPDIFlagsForAlias: Alias;
    /** Remove PDI flags for recruitment link */
    removePDIFlagsForAlias: Alias;
    /** Update apply globally field for default recruitment link */
    updateApplyPDIFlagsGlobally: boolean;
    /** Register TCR Brand */
    registerTCRBrand: RegisterTcrBrandOutput;
    /** Create TCR Brand Vetting */
    createTCRBrandPoliticalVetting: TcrBrandVetting;
    /** Register TCR Campaign */
    registerTCRCampaign: RegisterTcrCampaignOutput;
    /** Get the presigned request fields for uploading outreach circle landing page logo to S3 */
    presignedLogoUploadRequest?: PreSignedUploadUrl | null;
    /** Get the presigned request fields for uploading organization logo to S3 */
    presignedOrgLogoUploadRequest?: PreSignedUploadUrl | null;
    /** Get the presigned request fields for uploading candidate image to S3 */
    presignedCandidateImageUploadRequest?: PreSignedUploadUrl | null;
    /** Get the presigned request fields for uploading p2p script media to S3 */
    presignedP2PScriptMediaUploadRequest?: PreSignedUploadUrl[] | null;
    /** Get the presigned request fields for uploading social share image to S3 */
    presignedSocialImageUploadRequest?: PreSignedUploadUrl | null;
    /** Get the presigned request fields for uploading social share image to S3 */
    presignedActionImageUploadRequest?: PreSignedUploadUrl | null;
    /** Get the presigned request fields for uploading social share image to S3 */
    presignedOrganizationActionImageUploadRequest?: PreSignedUploadUrl | null;
    /** Get the presigned request fields for uploading editor image to S3 for outreach circle */
    presignedEditorImageUploadRequest?: PreSignedUploadUrl | null;
    /** Get the presigned request fields for uploading editor image to S3 for organization */
    presignedOrgEditorImageUploadRequest?: PreSignedUploadUrl | null;
    /** Get the presigned request fields for uploading texting script media to S3Throw Errors:MAXIMUM_ATTACHMENTS_FOR_TEXTING_SCRIPT_EXCEEDED: Maximum attachments for texting script exceededMAXIMUM_FILE_SIZE_FOR_TEXTING_SCRIPT_EXCEEDED: Maximum file size for texting script exceeded */
    presignedTextingScriptMediaUploadRequest: PreSignedUploadUrl[];
    /** Get the presigned request fields for uploading event config image to S3 */
    presignedEventConfigImageUploadRequest: PreSignedUploadUrl;
    /** Get the presigned request fields for uploading event series image to S3 */
    presignedEventSeriesImageUploadRequest: PreSignedUploadUrl;
    /** Get the presigned request fields for uploading user profile image to S3 */
    presignedUserProfileImageUploadRequest: PreSignedUploadUrl;
    /** Get the presigned request fields for uploading push notification image to S3 */
    presignedPushNotificationImageUploadRequest: PreSignedUploadUrl;
    /** Create an organization action */
    createOrganizationAction: OrganizationAction;
    /** Update name for organization actionthrows errors:ACTION_NOT_FOUND - given actionId does not exist in the databaseINVALID_ACTION_NAME - the name is not validACTION_ALREADY_EXISTS - when action with same name exists */
    updateOrganizationActionName: OrganizationAction;
    /** Update link for the actionthrows errors:INVALID_ACTION_LINK - link field should be a valid urlACTION_NOT_FOUND - given actionId does not exist in the databaseACTION_CANNOT_BE_UPDATED - action is in invalid status */
    updateOrganizationActionLink?: OrganizationAction | null;
    /** Update title and descriptionthrows errors:ACTION_NOT_FOUND - Invalid actionId in the inputINVALID_ACTION_TITLE - Empty string for title in the inputACTION_CANNOT_BE_UPDATED - action is in invalid status */
    updateOrganizationActionTitleAndDescription?: OrganizationAction | null;
    /** Update the action call to action textthrows errors:ACTION_NOT_FOUND - given actionId does not exist in the databaseACTION_CANNOT_BE_UPDATED - action is in invalid statusINVALID_CALL_TO_ACTION_TEXT - Invalid call to action text */
    updateCallToActionTextForOrganizationAction: OrganizationAction;
    /** Update the share action button textthrows errors:ACTION_NOT_FOUND - given actionId does not exist in the databaseACTION_CANNOT_BE_UPDATED - action is in invalid statusINVALID_SHARE_BUTTON_TEXT - Invalid share action button text */
    updateShareActionButtonTextForOrganizationAction: OrganizationAction;
    /** Update the action namethrows errors:ACTION_NOT_FOUND - given actionId does not exist in the databaseACTION_CANNOT_BE_UPDATED - action is in invalid statusINVALID_POST_ACTION_TEXT - Invalid call to action text */
    updatePostActionTextForOrganizationAction: OrganizationAction;
    /** Update image for an organization action */
    updateImageForOrganizationAction: OrganizationAction;
    /** Duplicate an existing organization action with a new namethrows errors:ACTION_NOT_FOUND - if organizationAction with input id does not exist in the database */
    duplicateOrganizationAction: OrganizationAction;
    /** Link email template to organization actionthrows error:EMAIL_TEMPLATE_NOT_FOUNDACTION_NOT_FOUNDACTION_CANNOT_BE_UPDATED - Action cannot be updated as it is terminated/published State */
    linkEmailTemplateToOrganizationAction: OrganizationAction;
    /** Unlink email template to organization actionthrows error:EMAIL_TEMPLATE_NOT_FOUNDACTION_NOT_FOUNDACTION_CANNOT_BE_UPDATED - Action cannot be updated as it is terminated/published StateEMAIL_TEMPLATE_NOT_LINKED - When the given email template is not linked to given action */
    unlinkEmailTemplateFromOrganizationAction: OrganizationAction;
    /** Link text template to organization actionthrows error:TEXT_TEMPLATE_NOT_FOUNDACTION_NOT_FOUNDACTION_CANNOT_BE_UPDATED - Action cannot be updated as it is LIVE/ARCHIVED State */
    linkTextTemplateToOrganizationAction: OrganizationAction;
    /** Unlink text template to organization actionthrows error:TEXT_TEMPLATE_NOT_FOUNDACTION_NOT_FOUNDACTION_CANNOT_BE_UPDATED - Action cannot be updated as it is LIVE/ARCHIVED StateTEXT_TEMPLATE_NOT_LINKED - When the given text template is not linked to given action */
    unlinkTextTemplateFromOrganizationAction: OrganizationAction;
    /** Link a social share template with an organization actionthrows error:ACTION_NOT_FOUND - invalid actionIdSOCIAL_TEMPLATE_DOES_NOT_EXIST - invalid socialTemplateIdACTION_CANNOT_BE_UPDATED - Action cannot be updated as it is LIVE/ARCHIVED State */
    linkSocialTemplateToOrganizationAction: OrganizationAction;
    /** Unlink a social template from an organization action (which will not delete the social template)throws errors:ACTION_NOT_FOUND - invalid actionIdSOCIAL_TEMPLATE_DOES_NOT_EXIST - invalid socialTemplateIdSOCIAL_TEMPLATE_NOT_LINKED - social template is not linked to the given action */
    unlinkSocialTemplateFromOrganizationAction: OrganizationAction;
    /** Publish action to outreachCirclesthrows erros:ORGANIZATION_ACTION_CANNOT_BE_PUBLISHED */
    publishOrganizationAction: OrganizationAction;
    /** Terminate organization action */
    terminateOrganizationAction?: OrganizationAction | null;
    /** Update organization action allowNonMappedContactsthrows errors:ACTION_CANNOT_BE_UPDATED - action is in invalid statusCANNOT_TOGGLE_ALLOW_NON_MAPPED_CONTACTS - cannot toggle allowNonMappedContacts if outreach_mode is true */
    updateOrganizationActionAllowNonMappedContacts: OrganizationAction;
    /** Hide organization actionErrors thrown:ACTION_NOT_FOUND - given actionId does not exist in the database */
    hideOrganizationAction: OrganizationAction;
    /** Update outreach channelsErrors thrown:ACTION_NOT_FOUND - given actionId does not exist in the database */
    updateOutreachChannelsForOrganizationAction: OrganizationAction;
    /** Update organization action's integration typeThis will automatically update isOutreachMode to- true, if value is passed for integration type- false, if value is not passed for integration typeErrors thrown:ACTION_CANNOT_BE_UPDATED - when action status is either TERMINATED or PUBLISHED */
    updateOrganizationActionIntegrationType: OrganizationAction;
    /** Update action confirmationErrors thrown:INVALID_ACTION_CONFIRMATION_TEXTINVALID_ACTION_TYPE */
    updateOrganizationActionConfirmation: OrganizationAction;
    /** Invite to become an orzanization userErrors thrown:INVALID_EMAIL_INPUT - Invalid email in the inputINVALID_NAME_INPUT - Invalid name in the inputINVITE_ALREADY_EXISTS - There is an existing invite with the same email */
    inviteOrganizationUser: OrganizationUser;
    /** Delete an orzanization userErrors thrown:INVALID_ORGANIZATION_USER - Invalid orzanization userCANT_REMOVE_LAST_ORGANIZATION_USER - Cant remove last orzanization user */
    deleteOrganizationUser: boolean;
    /** Resend invite */
    resendInviteToOrganizationUser: boolean;
    /** Join an orzanization user to the orzanizationErrors thrown:ONBOARD_ORGANIZATION_USER_ALREADY_ONBOARDED - Invite has already been onboardedDELETED_ORGANIZATION_USER_CANNOT_ONBOARD_WITH_OLD_INVITE - User is deleted and trying to onboard */
    onboardOrganizationUser: boolean;
    /** Update organization user details before he has joinedErrors thrown:INVALID_ORGANIZATION_USER - Organization user with the given id not foundCANNOT_UPDATE_ORGANIZATION_USER - Organization user cannot be updated, as he has already onboarded */
    updateOrganizationUser?: OrganizationUser | null;
    /** Update organization user's outreach circle accessErrors thrown:INVALID_ORGANIZATION_USER - Organization user with the given id not found */
    updateReadOnlyAccessToOutreachCircleForOrgUser: boolean;
    /** Update UI preference for organization user */
    updateUIPreferenceForOrganizationUser: OrganizationUser;
    /** Create an organizationErrors thrown:INVALID_ORGANIZATION_NAME - If name is empty or name has more than 50 characters */
    createOrganization: Organization;
    /** Update an organizationErrors thrown:INVALID_ORGANIZATION_NAME - If name is empty or name has more than 50 characters */
    updateOrganization: Organization;
    /** Save organization filterErrors thrown:INVALID_ORGANIZATION_FILTER_NAMEOUTREACH_CIRCLES_CANNOT_BE_EMPTYORGANIZATION_FILTER_WITH_SAME_NAME_EXISTS */
    saveOrganizationDashboardFilter: OrganizationDashboardFilter;
    /** Update organization filterErrors thrown:OUTREACH_CIRCLES_CANNOT_BE_EMPTY */
    updateOrganizationDashboardFilter: OrganizationDashboardFilter;
    /** Delete a saved organization dashboard filter */
    deleteOrganizationDashboardFilter: boolean;
    /** Set main outreach circle for an organizationErrors thrown:ORGANIZATION_NOT_FOUND - Organization not foundOUTREACH_CIRCLE_IS_NOT_PRESENT_IN_ORGANIZATION - organization does not have given outreach circle */
    setMainOutreachCircleForOrganization: boolean;
    /** Set introduction text for an organizationErrors thrown:ORGANIZATION_NOT_FOUND - Organization not found */
    setIntroductionForOrganization: boolean;
    /** Add logo for an organizationErrors thrown:ORGANIZATION_NOT_FOUND - Organization not found */
    addOrganizationLogo: boolean;
    /** Update logo for an organizationErrors thrown:ORGANIZATION_NOT_FOUND - Organization not found */
    updateOrganizationLogo: Organization;
    /** Delete logo for an organizationErrors thrown:ORGANIZATION_NOT_FOUND - Organization not foundORGANIZATION_LOGO_DOES_NOT_EXIST - Organization Logo does not exist */
    deleteOrganizationLogo: boolean;
    /** Update disclaimer for organizationthrows error:ORGANIZATION_NOT_FOUND - Organization not found */
    updateDisclaimerForOrganization: boolean;
    /** Update an organization aliasErrors thrown:INVALID_ORGANIZATION_ALIAS_VALUE - If name is empty or name has spacesORGANIZATION_ALIAS_ALREADY_EXIST - Organization alias already exist */
    updateOrganizationAlias: OrganizationAlias;
    /** Create organization email templates for sendingfrom Organization to Outreach circle adminsErrors thrown:OUTREACH_CIRCLE_USER_EMAIL_TEMPALTE_ALREADY_EXISTS */
    createOutreachCircleUserEmailTemplate: OutreachCircleUserEmailTemplate;
    /** Update organization email templates for sendingfrom Organization to Outreach circle adminsErrors thrown:OUTREACH_CIRCLE_USER_EMAIL_TEMPALTE_ALREADY_EXISTS */
    updateOutreachCircleUserEmailTemplate: OutreachCircleUserEmailTemplate;
    /** Duplicate organization email templates for outreach circle adminsErrors thrown:OUTREACH_CIRCLE_USER_EMAIL_TEMPALTE_ALREADY_EXISTS */
    duplicateOutreachCircleUserEmailTemplate: OutreachCircleUserEmailTemplate;
    /** Send organization action notification email to outreach circle adminsErrors thrown:INVALID_OUTREACH_CIRCLE_USER_EMAIL_TEMPLATE - When invalid template id is passed in input */
    notifyOutreachCircleAdminsForOrganizationAction: boolean;
    /** Send emails oc admins from organizationErrors thrown:INVALID_OUTREACH_CIRCLE_USER_EMAIL_TEMPLATE - When invalid template id is passed in input */
    notifyOutreachCircleAdminsForOrganization: boolean;
    /** Create role for outreachcircleErrors thrown: INVALID_INPUT_TO_CREATE_OUTREACH_CIRCLE_ROLE - invalid input to create APIOUTREACH_CIRCLE_ROLE_WITH_SAME_NAME_ALREADY_EXISTS - role with same name exists for outreachcircle */
    createOutreachCircleRole: Role;
    /** Update outreachcircle roleErrors thrown: */
    updateOutreachCircleRole: Role;
    /** Invite to become an outreach circle userErrors thrown:INVALID_EMAIL_INPUT - Invalid email in the inputINVALID_NAME_INPUT - Invalid name in the inputINVITE_ALREADY_EXISTS - There is an existing invite with the same email */
    inviteOutreachCircleUser?: OutreachCircleUser | null;
    /** Update outreach circle user details before he has joinedErrors thrown:INVALID_OUTREACH_CIRCLE_USER - Outreach circle user with the given id not foundCANNOT_UPDATE_OUTREACH_CIRCLE_USER - Outreach circle user cannot be updated, as he has already onboarded */
    updateOutreachCircleUser?: OutreachCircleUser | null;
    /** Delete an outreach circle userErrors thrown:INVALID_OUTREACH_CIRCLE_USER - Invalid outreach circle userCANT_REMOVE_LAST_OUTREACH_CIRCLE_USER - Cant remove last outreach circle user */
    deleteOutreachCircleUser?: boolean | null;
    /** Resend invite */
    resendInvite?: boolean | null;
    /** Join an outreach circle user to the outreach circleErrors thrown:OUTREACH_CIRCLE_USER_ALREADY_ONBOARDED - Invite has already been onboardedDELETED_OUTREACH_CIRCLE_USER_CANNOT_ONBOARD_WITH_OLD_INVITE - User is deleted and trying to onboard */
    onboardOutreachCircleUser?: boolean | null;
    /** Unsubscribe email for specific email template by outreach circle admin.throws error:EMAIL_RECIPIENT_NOT_FOUND - Email recipient not foundEMAIL_TEMPLATE_NOT_FOUND - Email template not found */
    unsubcribeEmailByOutreachCircleAdmin?: OutreachCircleAdminEmailUnsubscribeOutput | null;
    /** Enable mfa for outreachcircle userthrows error:INVALID_OUTREACH_CIRCLE_USER -  Invalid outreach circle user found */
    enableOutreachCircleUsersMFA: boolean;
    /** Disable mfa for outreachcircle userthrows error:INVALID_OUTREACH_CIRCLE_USER -  Invalid outreach circle user found */
    disableOutreachCircleUsersMFA: boolean;
    /** De-Activate outreachcircle userthrows error:INVALID_OUTREACH_CIRCLE_USER -  Invalid outreach circle user found */
    deactivateOutreachCircleUsers: boolean;
    /** Re-Activate outreachcircle userthrows error:INVALID_OUTREACH_CIRCLE_USER -  Invalid outreach circle user found */
    reactivateOutreachCircleUsers: boolean;
    /** Update working geography for outreachcircle userthrows error: OUTREACH_CIRCLE_USER_DOES_NOT_EXIST: Outreach circle user not exists */
    updateWorkingGeographiesForOutreachCircle: OutreachCircleUser;
    /** Create a new outreach circleErrors thrown:INVALID_NAME_INPUT - When name is invalid (Min 3 chars and Max 50 chars)INVALID_INDUSTRY_INPUT - When industry is empty string */
    createOutreachCircle: OutreachCircle;
    /** Update the outreach circle basic information: name, industry and categoryErrors thrown:INVALID_NAME_INPUT - When name is invalid (Min 3 chars and Max 50 chars)INVALID_INDUSTRY_INPUT - When industry is empty stringALIAS_NAME_ALREADY_EXISTS - When an alias with same name already exists */
    updateOutreachCircleBasicInfo: OutreachCircle;
    /** Update a outreach circleErrors thrown:OUTREACH_CIRCLE_NOT_FOUND - Outreach circle not foundINVALID_EMAIL_INPUT - Invalid email in the input */
    updateOutreachCircleContactAndLocation: OutreachCircle;
    /** Update outreach circle timezoneErrors thrown:OUTREACH_CIRCLE_NOT_FOUND - Outreach circle not found */
    updateOutreachCircleTimezone: OutreachCircle;
    /** Add a user role to an admin */
    updateOutreachCircleUserRole: OutreachCircleUser;
    /** Add logo to default alias of outreachCirclethrows error:OUTREACH_CIRCLE_DEFAULT_ALIAS_LOGO_EXISTS - Outreach circle default alias logo already exists */
    addLogoForDefaultAliasOfOutreachCircle?: boolean | null;
    /** Update logo of default alias of outreachCircle */
    updateLogoForDefaultAliasOfOutreachCircle?: Alias | null;
    /** Delete logo of default alias of outreachCirclethrows error:OUTREACH_CIRCLE_DEFAULT_ALIAS_LOGO_DOES_NOT_EXIST - Outreach circle default alias logo does not exist */
    deleteLogoForDefaultAliasOfOutreachCircle?: boolean | null;
    /** Update blocked domains for the outreach circlethrows error:INVALID_DOMAIN - One of the domain in the input is invalidDUPLICATES_IN_BLOCK_DOMAINS - When input has one or more duplicates in them */
    updateBlockedDomains?: boolean | null;
    /** Update disclaimer for outreach circlethrows error:OUTREACH_CIRCLE_NOT_FOUND - Outreach circle not found */
    updateDisclaimerForOutreachCircle?: boolean | null;
    /** Unsubscribe all email from an Outreach Circle.throws error:EMAIL_RECIPIENT_NOT_FOUND - Email recipient not foundOUTREACH_CIRCLE_NOT_FOUND - Outreach circle not found */
    unsubcribeAllEmailFromOutreachCircle?: OutreachCircleEmailUnsubscribeOutput | null;
    /** Update referred by for outreach circlethrows error:OUTREACH_CIRCLE_NOT_FOUND - Outreach circle not found */
    updateReferredByForOutreachCircle?: boolean | null;
    /** Update visibility for outreach circle.When isVisible is true, Outreach circle is public otherwise its private.Private Outreach circle can only be accessed by Outreach Circle code.throws error:OUTREACH_CIRCLE_NOT_FOUND - Outreach circle not foundUPGRADE_PLAN - Based on the product plan to upgrade plan */
    updateVisibilityForOutreachCircle?: boolean | null;
    /** Add new outreachcircle aliasthrows error:UPGRADE_PLAN - when create alias not allowed or its has reached to the limit based on the product plan to upgrade planALIAS_NAME_ALREADY_EXISTSINVALID_ALIAS_DESCRIPTION - when description for alias is invalid */
    createNewAlias?: Alias | null;
    /** Edit outreachcircle aliasthrows error:ALIAS_NAME_ALREADY_EXISTSINVALID_ALIAS_NAMEINVALID_ALIAS_DESCRIPTION - when description for alias is invalidUPGRADE_PLAN - Based on the product plan to upgrade plan */
    updateAlias: Alias;
    /** Increment outreach circle impression */
    incrementOutreachCircleImpression: boolean;
    /** Add new outreachcircle email aliasthrows error:UPGRADE_PLAN - when create alias not allowed or its has reached to the limit based on the product plan to upgrade planOUTREACH_CIRCLE_EMAIL_ALIAS_EXISTS - When email alias already exists */
    createEmailAlias: OutreachCircleEmailAlias;
    /** Update referred by for outreach circlethrows error:OUTREACH_CIRCLE_NOT_FOUND - Outreach circle not found */
    updateSupporterReplyToEmailForOutreachCircle?: boolean | null;
    /** Update referred by for outreach circlethrows error:OUTREACH_CIRCLE_NOT_FOUND - Outreach circle not found */
    updateSupporterAutoEmailsFromNameForOutreachCircle: OutreachCircle;
    /** Archive outreach circlethrows error:OUTREACH_CIRCLE_ALREADY_ARCHIVED - When Outreach Circle is already archived */
    archiveOutreachCircle?: boolean | null;
    /** Collect response from outreach circle user on archiving the outreach circlethrows error:ARCHIVE_FEEDBACK_TEXT_EXCEEDS_ALLOWED_LENGTH_LIMIT - Any text field in response should not have more than 500 chars */
    collectSurveyResponseOnArchiveOutreachCircle?: boolean | null;
    /** Update dates for outreach circlethrows error:INVALID_KEY_DATENAME_CANNOT_BE_EMPTY_FOR_KEY_DATENAME_CANNOT_BE_UPDATED_FOR_SYSTEM_ADDED_KEY_DATEDATE_CANNOT_BE_NULL_FOR_KEY_DATE */
    updateKeyDates?: KeyDate[] | null;
    /** Toggle MFA For outreachCircle */
    toggleMFAForOutreachCircle: boolean;
    /** Set non relational data provider to outreachCircle */
    linkNonRelationalDataProviderSourceForOutreachCircle: boolean;
    /** unlink non relational data provider from outreachCircle */
    unlinkNonRelationalDataProviderSourceFormOutreachCircle: boolean;
    /** Create outreach circle loctionErrors thrown:OUTREACH_CIRCLE_LOCATION_WITH_SAME_NAME_EXISTS - Outreach circle location with same name exists */
    createOutreachCircleLocation: OutreachCircleLocation;
    /** Update outreach circle loctionErrors thrown:OUTREACH_CIRCLE_LOCATION_NOT_FOUND - Outreach circle location not found */
    updateOutreachCircleLocation: OutreachCircleLocation;
    /** Toggle Auto Approval Config For Door to Door Canvassing */
    toggleAutoApprovalConfigForD2DCanvassing: OutreachCircle;
    /** Update outreach circle campaign details */
    updateOutreachCircleCampaignDetails: OutreachCircle;
    /** Update outreach circle election dateErrors thrown:MASTER_ELECTION_CYCLE_NOT_FOUND - Master election cycle not found */
    updateOutreachCircleElectionDate: OutreachCircle;
    /** Update outreach circle miscellaneous setting */
    updateOutreachCircleMiscellaneousSetting: OutreachCircle;
    /** Update outreach circle action visibility */
    updateOutreachCircleActionVisibility: OutreachCircle;
    /** Add billing card for given outreachCircleId */
    addBillingCard: boolean;
    /** Remove billing card for given outreachCircleIdthrows error:REMOVE_CARD_FAILED - If trying to delete default card or providing wrong card or customer id */
    removeBillingCard: boolean;
    /** Set default card for given outreachCircleId */
    defaultPaymentSource: boolean;
    /** Update card for given outreachCircleId */
    updatePaymentSource: boolean;
    /** Change planthrows error:INVALID_PLAN - If trying to downgrade from one paid plan to another paid plan. Not applicable to downgrade from any plan to free plan. */
    changePlan: boolean;
    /** Cancel auto renew of outreach circle product planthrows error:INVALID_CANCEL_AUTO_RENEW_CONFIRMATION: Throws when all the required confirmation is accepted by end user */
    cancelAutoRenewForOutreachCircle: boolean;
    /** Request Outreach circle Invoice email to logged in OC admin */
    requestOutreachCircleInvoice: boolean;
    /** Capture a flag for a list of PDI voters */
    captureFlagForVoters: boolean;
    /** Capture a flag for a list of PDI voters */
    captureFlagForPeople: boolean;
    /** Copy a list of PDI voters to People */
    copyVotersToPeople: boolean;
    /** Add donor info to a list of PDI voters */
    addDonorInfo: boolean;
    /** Edit a PDI voter */
    editPdiVoter: PdiVoter;
    /** Deactivate a PDI member */
    deactivatePdiMember: boolean;
    /** Delete contact id from a PDI voter */
    deleteContactIdFromPdiVoter: boolean;
    /** Add comment to a PDI voter */
    addCommentToPdiVoter: string;
    /** Delete comment from a PDI voter */
    deleteCommentFromPdiVoter: boolean;
    /** create conversation item(s), based on data it will apply tag, create survey response(sync activist code if applicable), add message */
    createP2PConversationItem: P2PConversationItem;
    /** End p2p conversation */
    endP2PConversation: P2PConversationItem;
    /** Assign conversations of one supporter to anotherErrors thrown:P2P_REPLIES_ALREADY_REASSIGNEDP2P_REPLIES_CANNOT_BE_REASSIGNED - Target supporter is not eligible to get replies assigned */
    reassignP2PConversations: boolean;
    /** Un-tag p2p conversation */
    unTagP2PConversation: P2PConversation;
    /** Create p2p script survey questionErrors thrown:INVALID_P2P_SCRIPT_SURVEY_QUESTION_INPUTP2P_SCRIPT_SURVEY_QUESTION_HAS_INVALID_NAMEP2P_SCRIPT_SURVEY_QUESTION_HAS_INVALID_QUESTION_TEXTP2P_SCRIPT_SURVEY_QUESTION_NAME_ALREADY_EXISTSP2P_SCRIPT_SURVEY_QUESTION_OPTION_HAS_INVALID_OPTION_TEXT */
    createP2PScriptSurveyQuestion: P2PScriptSurveyQuestion;
    /** Update p2p script survey questionErrors thrown:INVALID_P2P_SCRIPT_SURVEY_QUESTION_INPUTP2P_SCRIPT_SURVEY_QUESTION_HAS_INVALID_NAMEP2P_SCRIPT_SURVEY_QUESTION_HAS_INVALID_QUESTION_TEXTP2P_SCRIPT_SURVEY_QUESTION_NAME_ALREADY_EXISTSP2P_SCRIPT_SURVEY_QUESTION_OPTION_HAS_INVALID_OPTION_TEXT */
    updateP2PScriptSurveyQuestion: P2PScriptSurveyQuestion;
    /** Delete p2p script survey question */
    deleteP2PScriptSurveyQuestion: boolean;
    /** send initial p2p text */
    sendInitialP2PText: boolean;
    /** save updated initial p2p text, will be useful for re-sending to new recipients */
    updateInitialP2PText: Action;
    /** send bulk initial p2p texts */
    sendBulkInitialP2PTexts: boolean;
    /** Create new p2p workspace along with initial scriptErrors thrown:P2P_WORKSPACE_WITH_SAME_NAME_EXISTSP2P_WORKSPACE_INVALID_INITIAL_SCRIPTP2P_WORKSPACE_HAS_INVALID_NAMEP2P_WORKSPACE_INVALID_INITIAL_SCRIPT_MESSAGEP2P_WORKSPACE_TAG_HAS_INVALID_POSITIONP2P_SCRIPT_INVALID_OPTOUT_TEXT */
    createP2PWorkspace: P2PWorkspace;
    /** Update p2p workspaceErrors thrown:P2P_WORKSPACE_WITH_SAME_NAME_EXISTSP2P_WORKSPACE_EMPTY_INITIAL_SCRIPTP2P_WORKSPACE_HAS_INVALID_NAMEP2P_WORKSPACE_INVALID_INITIAL_SCRIPT_MESSAGEP2P_WORKSPACE_TAG_HAS_INVALID_POSITIONP2P_SCRIPT_INVALID_OPTOUT_TEXT */
    updateP2PWorkspace: P2PWorkspace;
    /** Duplicate p2p workspaceErrors thrown:P2P_WORKSPACE_WITH_SAME_NAME_EXISTSP2P_WORKSPACE_NOT_FOUNDP2P_WORKSPACE_HAS_INVALID_NAMEP2P_WORKSPACE_INVALID_INITIAL_SCRIPT_MESSAGEP2P_SCRIPT_INVALID_OPTOUT_TEXT */
    duplicateP2PWorkspace: P2PWorkspace;
    /** Link P2P Workspace to an action */
    linkP2PWorkspaceToAction: Action;
    /** UnLink P2P Workspace from an action */
    unlinkP2PWorkspaceFromAction: Action;
    /** Create New Ballot Measure */
    createBallotMeasure: BallotMeasure;
    /** Update Existing Ballot Measure */
    updateBallotMeasure: BallotMeasure;
    /** "Update the existing candidatethrows error:CANDIDATE_DOES_NOT_EXIST - If a candidate does not exist for the outreach circlebut still trying to update */
    updateCandidateForOutreachCircle?: Candidate | null;
    /** "Create a new candidatethrows error:CANDIDATE_ALREADY_EXISTS - If a candidate already exists for the campaign */
    addCandidateForOutreachCircle?: Candidate | null;
    /** Add candidate image for an outreach circlethrows error:CANDIDATE_IMAGE_ALREADY_EXISTS - If the candidate image already exists for the candidateCANDIDATE_DOES_NOT_EXIST - If a candidate does not exist for the outreach circlebut still trying to update */
    addCandidateImage: Candidate;
    /** "Delete the candidate logo for a outreach circleCANDIDATE_DOES_NOT_EXIST - If a candidate does not exist for the outreach circlebut still trying to update */
    deleteCandidateImage?: boolean | null;
    /** "Update candidate image of the outreach circlethrows error:INVALID_CANDIDATE_IMAGE - When trying to update a wrong candidate imageCANDIDATE_DOES_NOT_EXIST - If a candidate does not exist for the outreach circlebut still trying to update */
    updateCandidateImage: Candidate;
    /** Create New Candidate */
    createCandidate: Candidate;
    /** Update Candidate */
    updateCandidate: Candidate;
    /** Create New Issue */
    createIssue: Issue;
    /** Update Issue */
    updateIssue: Issue;
    /** Update flag status by idErrors thrown:CANNOT_DEACTIVATE_PURPOSE_LINKED_TO_ACTIVE_QUESTION - Cannot deactivate purpose if it is linked to active questionCANNOT_UPDATE_STATUS_FOR_DEFAULT_PURPOSE - Cannot update status for default purpose */
    updatePurposeStatusById: Purpose;
    /** Copy purposes from parent */
    copyPurposesFromParentOutreachCircle: boolean;
    /** Mark all the push notification as READ to the give registration token */
    markAllPushNotificationsAsRead: boolean;
    /** Generate a report and send an email to the adminthrows error:NOT_ABLE_TO_REQUEST_REPORT - Not able to generate reportUPGRADE_PLAN - Based on the product plan to upgrade plan */
    requestReportForOutreachCircle: Date;
    /** Generate a report and send an email to the admin */
    requestReportForOrganization: Date;
    /** Generate a report for the requested outreachcircle and send an email to the oc adminthrows error:NOT_ABLE_TO_REQUEST_REPORT - Not able to generate report */
    sendOutreachCircleReportToOCAdmin: boolean;
    /** Update search and survey formErrors thrown:SEARCH_AND_SURVEY_FORM_WITH_GIVEN_NAME_ALREADY_EXISTINVALID_OPTIONS_INPUT_FOR_SEARCH_AND_SURVEY_FORM_QUESTION */
    updateSearchAndSurveyForm?: SearchAndSurveyForm | null;
    /** Create search and survey formErrors thrown:SEARCH_AND_SURVEY_FORM_WITH_GIVEN_NAME_ALREADY_EXIST */
    createSearchAndSurveyForm?: SearchAndSurveyForm | null;
    /** Link the search and survey form to an action */
    linkSearchAndSurveyFormToAction: Action;
    /** Unlink the search and survey form from an action */
    unlinkSearchAndSurveyFormFromAction: Action;
    /** Duplicate a search and survey form with a new name */
    duplicateSearchAndSurveyForm: SearchAndSurveyForm;
    /** Submit response for search and survey formErrors Thrown:INVALID_RESPONSE_FOR_MANDATORY_QUESTIONINVALID_SEARCH_AND_SURVEY_FORM_QUESTIONSEARCH_AND_SURVEY_FORM_NOT_FOUNDACTION_NOT_IN_LIVE_STATE */
    submitSearchAndSurveyFormQuestionResponse: boolean;
    /** Delete search and survey form */
    deleteSearchAndSurveyForm: boolean;
    /** Create a social share template */
    createSocialTemplate: SocialTemplate;
    /** Update a social share templatethrows error:SOCIAL_TEMPLATE_DOES_NOT_EXIST - invalid socialTemplateId */
    updateSocialTemplate: SocialTemplate;
    /** Create organization social share template */
    createOrganizationSocialTemplate: SocialTemplateForOrganization;
    /** Update organization social share templatethrows error:SOCIAL_TEMPLATE_DOES_NOT_EXIST - invalid socialTemplateId */
    updateOrganizationSocialTemplate: SocialTemplateForOrganization;
    /** Add new sourcing linkthrows error:SourcingLinkWithSameNameExists - When there is already a sourcing link with the same name for the same outreach circle */
    addSourcingLink: SourcingLink;
    /** Archive Sourcing Link */
    archiveSourcingLink: boolean;
    /** Assign supporters to the action / event */
    assignSupporterWithSourceToAction: boolean;
    /** Assign supporters to the given list of shifts */
    assignSupporterWithSourceToShift: boolean;
    /** Unassign shifts from supporter */
    unassignShiftsFromSupporter: boolean;
    /** Create supporter email template for outreach circle */
    createSupporterEmailTemplateForOutreachCircle: SupporterEmailTemplate;
    /** Update supporter email template for outreach circle */
    updateSupporterEmailTemplateForOutreachCircle: SupporterEmailTemplate;
    /** Duplicate supporter email template */
    duplicateSupporterEmailTemplate: SupporterEmailTemplate;
    /** Update SupporterFormErrors thrown:SUPPORTER_FORM_WITH_GIVEN_NAME_ALREADY_EXISTINVALID_GROUP_IDINVALID_OPTIONS_INPUT_FOR_SUPPORTER_FORM_QUESTION */
    updateSupporterForm?: SupporterForm | null;
    /** Create SupporterFormErrors thrown:SUPPORTER_FORM_WITH_GIVEN_NAME_ALREADY_EXIST */
    createSupporterForm?: SupporterForm | null;
    /** Submit the responses for the supporter form */
    submitSupporterFormQuestionResponse: boolean;
    /** Link the supporter form to an action */
    linkSupporterFormToAction: Action;
    /** Unlink the supporter form from an action */
    unlinkSupporterFormFromAction: Action;
    /** Duplicate a supporter form with a new name */
    duplicateSupporterForm: SupporterForm;
    /** Delete supporter forms */
    deleteSupporterForm: boolean;
    /** Create supporter push notification template */
    createSupporterPushNotificationTemplate: SupporterPushNotificationTemplate;
    /** Update supporter push notification template */
    updateSupporterPushNotificationTemplate: SupporterPushNotificationTemplate;
    /** Create a new supporter filter state for the admin */
    createSupporterViewFilterState: SupporterViewFilterState;
    /** Update the existing supporter filter state for the admin */
    updateSupporterViewFilterState: SupporterViewFilterState;
    /** Delete saved supporter filter state */
    deleteSupporterViewFilterState: boolean;
    /** Creates a new SupporterActivity for an outreach circle */
    createSupporterActivity: SupporterActivity;
    /** Updates a SupporterActivity */
    updateSupporterActivity: SupporterActivity;
    /** Updates the active status of a list of SupporterActivities */
    updateSupporterActivityActiveStatus: boolean;
    /** Creates a new SupporterAvailability for an outreach circle */
    createSupporterAvailability: SupporterAvailability;
    /** Updates a SupporterAvailability */
    updateSupporterAvailability: SupporterAvailability;
    /** Updates the active status of a list of SupporterAvailabilities */
    updateSupporterAvailabilityActiveStatus: boolean;
    /** Creates a new SupporterLevel for an outreach circle */
    createSupporterLevel: SupporterLevel;
    /** Updates a SupporterLevel for an outreach circle */
    updateSupporterLevel: SupporterLevel;
    /** Updates the active status of a list of SupporterLevels for an outreach circle */
    updateSupporterLevelActiveStatus: boolean;
    /** Creates a new SupporterOrganization for an outreach circle */
    createSupporterOrganization: SupporterOrganization;
    /** Updates a SupporterOrganization */
    updateSupporterOrganization: SupporterOrganization;
    /** Updates a SupporterOrganization's active status */
    updateSupporterOrganizationActiveStatus: boolean;
    /** Creates a new SupporterSkill for an outreach circle */
    createSupporterSkill: SupporterSkill;
    /** Updates a SupporterSkill for an outreach circle */
    updateSupporterSkill: SupporterSkill;
    /** Updates the active status of a list of SupporterSkills */
    updateSupporterSkillActiveStatus: boolean;
    /** Creates a new SupporterTeam for an outreach circle */
    createSupporterTeam: SupporterTeam;
    /** Updates a SupporterTeam */
    updateSupporterTeam: SupporterTeam;
    /** Updates the active status of a list of SupporterTeams */
    updateSupporterTeamActiveStatus: boolean;
    /** Creates a new SupporterZone */
    createSupporterZone: SupporterZone;
    /** Update SupporterZone */
    updateSupporterZone: SupporterZone;
    /** Update active status of a SupporterZone */
    updateSupporterZoneActiveStatus: boolean;
    /** Create a supporter entry to keep a map ofthe outreach circles supported by a userthrows error:SUPPORTER_ALREADY_EXISTS: If the user in the input is already supporting the outreach circleINVALID_ORIGIN: When origin is invalidOUTREACH_CIRCLE_NOT_FOUND - Outreach circle not foundINVALID_SOURCE_ALIAS - Invalid source alias */
    supportOutreachCircle: Supporter;
    /** create supporter contact sourceErrors thrown:EMAIL_TEMPLATE_NOT_FOUND: When email template is not available for the specified outreach circle */
    createInvitedSupporterSource?: InvitedSupporterSource | null;
    /** Imports Supporter invite fileErrors thrown:INVITED_SUPPORTER_SOURCE_NOT_FOUND: When invited supporter source id is not foundINVITED_SUPPORTERS_IMPORT_IN_PROGRESS: When import for supporter source id is already in progress */
    startImportingInvitedSupporterFromFile?: boolean | null;
    /** Invite to supporter to join outreach circleErrors thrown:NO_SUPPORTER_CONTACT_FOUND - No supporter contact found to send emailINVITED_SUPPORTER_SOURCE_NOT_FOUND: When invited supporter source id is not foundINVITED_USER_CONSENT: when user does not have permission for sending emails to the imported contacts */
    sendSupporterInviteToImportedInvitedSupporters?: boolean | null;
    /** Invite a single supporter */
    inviteSupporters: Supporter[];
    /** Add supportersErrors thrown:SUPPORTER_ALREADY_EXISTS - Supporter already exists */
    addSupporters: SupporterView[];
    /** Update a single supporterErrors thrown:SUPPORTER_NOT_FOUND - The supporterId provided does not match a supporter */
    updateSupporter: Supporter;
    /** Update multiple supporters */
    updateSupporters: SupporterView[];
    /** Cancel import of Invited supproter fileErrors thrown:INVITED_SUPPORTER_SOURCE_NOT_FOUND: When invited supporter source id is not found */
    cancelInvitedSupporterImportFromFile?: boolean | null;
    /** Send selected email to all supporters based on the filterErrors thrown:EMAIL_TEMPLATE_NOT_FOUND: When email template is not available for the specified outreach circleINVITED_USER_CONSENT: when user does not have permission for sending emails to the imported contacts */
    sendSupporterEmailToFilteredSupporter: number;
    /** Send push notification to all supporters based on the filterErrors thrown:INVALID_PUSH_NOTIFICATION_TITLE: When notification title is invalidINVALID_PUSH_NOTIFICATION_DESCRIPTION: When notification description is invalidNO_ELIGIBLE_SUPPORTERS_FOR_PUSH_NOTIFICATION: When there are zero supporters to receive the push notification */
    sendPushNotificationToFilteredSupporter?: boolean | null;
    /** Block filtered supporter(s) */
    blockFilteredSupporters: boolean;
    /** Unblock supporterErrors thrown:SUPPORTER_NOT_FOUND: When supporter is not found */
    unblockSupporter?: Supporter | null;
    /** Unsupport outreachCircleErrors thrown:SUPPORTER_NOT_FOUND: When supporter is not found */
    unsupportOutreachCircle?: Supporter | null;
    /** Handle recruitment link for supporter */
    supporterVisitedRecruitmentLink: boolean;
    /** Send welcome email to all supporters based on the filterErrors thrown: */
    sendSupporterWelcomeEmailToFilteredSupporter: number;
    /** Create a new surveyThrow Errors:SURVEY_NAME_IS_REQUIRED: Invalid survey name passedSURVEY_NAME_IS_REQUIRED: Survey with same name already exists */
    createSurvey: Survey;
    /** Update an existing surveyThrow Errors:SURVEY_NAME_IS_REQUIRED: Invalid survey name passedSURVEY_NAME_IS_REQUIRED: Survey with same name already existsDUPLICATE_QUESTIONS_IN_SURVEY: Duplicate questions present in survey segmentsDUPLICATE_POSITIONS_IN_SURVEY: Duplicate positions present in survey segmentsINVALID_MERGE_FIELDS_IN_SEGMENT: Invalid merge fields present in survey segmentsDANGLING_BRANCHES_IN_SURVEY: Dangling branches present in survey segmentsINVALID_OPTOUT_TEXT_IN_SEGMENT: Invalid optout text present in survey segmentsMAXIMUM_ATTACHMENTS_FOR_TEXTING_SCRIPT_EXCEEDED: Maximum attachments for texting script exceededMAXIMUM_FILE_SIZE_FOR_TEXTING_SCRIPT_EXCEEDED: Maximum file size for texting script exceeded */
    updateSurvey: Survey;
    /** Update survey statusThrow Errors:SURVEY_HAS_NO_SEGMENTS: Survey has no segments */
    updateSurveyStatusById: Survey;
    /** Create a new survey questionThrow Errors:INVALID_CREATE_SURVEY_QUESTION_INPUT: Invalid create survey question input */
    createSurveyQuestion: SurveyQuestion;
    /** Update an existing survey questionThrow Errors:INVALID_CREATE_SURVEY_QUESTION_INPUT: Invalid create survey question input */
    updateSurveyQuestion: SurveyQuestion;
    /** Update survey question status by id */
    updateSurveyQuestionStatusById: SurveyQuestion;
    /** Submit Survey response */
    submitSurveyResponse: boolean;
    /** Tag/Untag recipientErrors thrown:INVALID_TAG_INPUT - When input is invalid for tagging the selected tagResourceINVALID_TAG_APPLICATION - When the selected tag cannot be applied for the outreachChannel */
    tagRecipient: boolean;
    /** Create tag for an outreachCircleErrors thrown:INVALID_TAG_INPUT - When tag with name and database combination existsNGP_CREDENTIALS_NOT_FOUND - When saving source as NGP but no credentials are foundCANNOT_CREATE_NEW_TAG - When 100 tags exists or when database for default tags is not updated */
    createTag: Tag;
    /** Update a tagErrors thrown:TAG_NOT_FOUND - When tag being updated is not found.DEFAULT_TAG_NAME_UPDATE_ERROR - Default tag's name cannot be updated. */
    updateTag: Tag;
    /** Apply tag to affinity text recipient */
    applyTagToAffinityTextRecipient: boolean;
    /** Apply tag to relational text recipient */
    applyTagToRelationalTextRecipient: boolean;
    /** Apply tag to relational text recipient */
    applyTagToAmbiguousTextRecipient: boolean;
    /** Apply tag to affinity text recipient */
    removeTagToAffinityTextRecipient: boolean;
    /** Apply tag to relational text recipient */
    removeTagToRelationalTextRecipient: boolean;
    /** Link Tag to an Action with optional CannedResponse ID or textErrors thrown:INVALID_CANNED_RESPONSE_FOR_TAG - When passed canned response id is invalidTAG_ALREADY_LINKED_TO_ACTION - When tag is already linked to the action */
    linkTagToAction: Tag;
    /** Remove tag link to an Action */
    unLinkTagToAction: boolean;
    /** Update Canned Response for a tag */
    updateCannedResponseForActionTag?: CannedResponse | null;
    /** Delete a tagErrors thrown:TAG_NOT_FOUND - When tag is not foundDEFAULT_TAG_CANNOT_BE_DELETED - When trying to delete a default tagTAG_LINKED_TO_LIVE_ACTION_CANNOT_BE_DELETED - When deleting a tag which is linked to a live action */
    deleteTag: boolean;
    /** Create new text templateErrors thrown:TEXT_TEMPLATE_WITH_SAME_NAME_EXISTS */
    createTextTemplate: TextTemplate;
    /** Update text templateErrors thrown:TEXT_TEMPLATE_NOT_FOUND - Text template not found */
    updateTextTemplate: TextTemplate;
    /** Update text template for supporterErrors thrown:TEXT_TEMPLATE_NOT_FOUND - Text template not found */
    updateTextTemplateForSupporter: TextTemplateForSupporter;
    /** Create new organization text templateErrors thrown:TEXT_TEMPLATE_WITH_SAME_NAME_EXISTS */
    createOrganizationTextTemplate: TextTemplateForOrganization;
    /** Update organization text templateErrors thrown:TEXT_TEMPLATE_NOT_FOUND - Text template not found */
    updateOrganizationTextTemplate: TextTemplateForOrganization;
    /** Create a new user and return token and user objectErrors thrown:INVALID_PASSWORD - When password is emptyINVALID_USERNAME - When username is an invalid emailUSERNAME_EXISTS - When we already have the user with the same email id */
    signupUser: LoginResponse;
    /** Login an existing userErrors thrown:USE_SOCIAL_LOGIN - User had registered through social loginINVALID_PASSWORD - User exists, but password is incorrectUSER_NOT_FOUND - No user exists with the email idINVALID_NEW_PASSWORD - User is using an invalid password after the password update */
    login: LoginResponse;
    /** Logout API to remove cookies from client */
    logout: boolean;
    /** Create user and login for rapid onboarding flowErrors thrown:USE_SOCIAL_LOGIN - User had registered through social loginUSERNAME_EXISTS - When we already have the user with the same email idRAPID_ONBOARDING_NOT_ALLOWED -  Rapid onboarding not allowedINVALID_EMAIL_ID - Email in the input is not valid */
    loginForRapidOnboarding: LoginResponse;
    /** Create user and login for rapid onboarding flowErrors thrown:USE_SOCIAL_LOGIN - User had registered through social loginUSERNAME_EXISTS - When we already have the user with the same email idRAPID_ONBOARDING_NOT_ALLOWED -  Rapid onboarding not allowedINVALID_EMAIL_ID - Email in the input is not valid */
    loginForRapidOnboardingForEmbeddedActions: EmbeddedLoginResponse;
    /** Update name for logged in userErrors thrown:INVALID_NAME_INPUT - Invalid first name or Invalid last name (Max 25 characters) */
    updateNameForLoggedInUser: User;
    /** Update preferences for logged in user */
    updatePreferencesForLoggedInUser: User;
    /** Update contact details for logged in userErrors thrown:INVALID_LOCATION_DETAILS - Invalid location details (when either of city, state, country or zip is not provided )INVALID_PHONE_NUMBER - Invalid phone number (when phone number is not provided) */
    updateContactDetailsForLoggedInUser: User;
    /** Send a email to supporter with reset password linkErrors thrown:RESET_PASSWORD_FAILED - Failed to send the emailUSER_NOT_FOUND - email given in the input does not belong to any user */
    resetPasswordForSupporter?: boolean | null;
    /** Send a email to client with reset password linkErrors thrown:RESET_PASSWORD_FAILED - Failed to send the emailUSER_NOT_FOUND - email given in the input does not belong to any user */
    resetPasswordForClient?: boolean | null;
    /** Update passwordErrors thrown:INVALID_PASSWORD_RESET_CODE - User is using an invalid or used link */
    updatePassword?: boolean | null;
    /** Update logged in user passwordErrors thrown:INVALID_PASSWORD - When password is empty or less than 5 char */
    updatePasswordForLoggedInUser?: boolean | null;
    /** New Client registrationErrors thrown:INVALID_PASSWORD - When password is emptyINVALID_USERNAME - When username is an invalid emailUSERNAME_EXISTS - When we already have the user with the same email id */
    signupUserWithDetails: LoginResponse;
    /** New Client registration coming from our adsErrors thrown:INVALID_PASSWORD - When password is emptyINVALID_USERNAME - When username is an invalid emailUSERNAME_EXISTS - When we already have the user with the same email id */
    signupUserWithDetailsFromAds: LoginResponse;
    /** Mark user as verified if card token is valid. */
    verifyUserWithValidCard: boolean;
    /** Resend the verification mail for the userErrors thrown:USER_NOT_FOUND - Token in the header does not belong to any userUSER_ALREADY_VERIFIED - User is already verified, no need to send verification mailUSER_VERIFICATION_SENDING_FAILED - Not able to send user verification mail */
    resendVerificationEmail: boolean;
    /** When user clicks on the verification link, mark the user as verifiedErrors thrown:USER_NOT_FOUND - User id in the input does not belong to any userUSER_ALREADY_VERIFIED - User is clicking on the verification link even when he is already verified */
    verifyEmail: boolean;
    /** Update the email of the userErrors thrown:INVALID_EMAIL_ID - When username is an invalid emailUSER_NOT_FOUND - email given in the input does not belong to any userUSERNAME_EXISTS - When we already have the user with the same email id */
    updateUsername: User;
    /** Update the opt-in text of the user */
    updateOptInForText: User;
    /** Email support team to include the logged in user for demo/training invite */
    sendTrainingInvite: boolean;
    /** subscribe to newletter */
    subscribeToNewsletter: boolean;
    /** Unsubscribe email for specific email template.throws error:EMAIL_RECIPIENT_NOT_FOUND - Email recipient not foundEMAIL_TEMPLATE_NOT_FOUND - Email template not found */
    unsubcribeEmailFromEmailTemplate?: UnsubscribeEmailOutput | null;
    /** Upsert the push notification and device info for the user */
    updatePushNotificationToken: boolean;
    /** Update name and contact details for logged in userUpdate password if providedErrors thrown:INVALID_NAME_INPUT - Invalid first name or Invalid last name (Max 25 characters)INVALID_LOCATION_DETAILS - Invalid location details (when either of city, state, country or zip is not provided )INVALID_PHONE_NUMBER - Invalid phone number (when phone number is not provided)INVALID_PASSWORD - When password is empty or less than 5 char */
    updateNameAndContactDetailsForLoggedInUser: User;
    /** Send support email for leads from ad-landing screen */
    sendSupportEmailForLeadFromAdLandingScreen?: boolean | null;
    /** Used only in embed flowCreate a new user and return token and user objectErrors thrown:INVALID_PASSWORD - When password is emptyINVALID_USERNAME - When username is an invalid emailUSERNAME_EXISTS - When we already have the user with the same email id */
    signupUserForEmbed: LoginResponse;
    /** Used only in embed flowLogin an existing userErrors thrown:USE_SOCIAL_LOGIN - User had registered through social loginINVALID_PASSWORD - User exists, but password is incorrectUSER_NOT_FOUND - No user exists with the email idINVALID_NEW_PASSWORD - User is using an invalid password after the password update */
    loginForEmbed: LoginResponse;
    /** Used only in embed flowCreate user and login for rapid onboarding flowErrors thrown:USE_SOCIAL_LOGIN - User had registered through social loginUSERNAME_EXISTS - When we already have the user with the same email idRAPID_ONBOARDING_NOT_ALLOWED -  Rapid onboarding not allowedINVALID_EMAIL_ID - Email in the input is not valid */
    loginForRapidOnboardingForEmbed: LoginResponse;
    /** Request for user data deletion */
    requestForUserDataDeletion: boolean;
    /** Create Firebase Custom Token */
    createFirebaseCustomToken: string;
    /** Create TOTP Secret for the user */
    createTOTPSecret: TotpSecretResponse;
    /** Verify MFA for the user */
    verifyMFACode: string;
    /** Set Default OutreachCircle For User */
    setDefaultOutreachcircleForUser: boolean;
    /** Set Default Volunteer OutreachCircle For User */
    setDefaultVolunteerOutreachCircleForUser: boolean;
    /** Clear Default OutreachCircle For User */
    clearDefaultOutreachcircleForUser: boolean;
    /** Clear Default Volunteer OutreachCircle For User */
    clearDefaultVolunteerOutreachCircleForUser: boolean;
    /** Send one time password to start passwordless loginErrors thrown:USER_NOT_FOUND - Email in the request does not belong to any user */
    passwordlessLoginStart: boolean;
    /** Passwordless login with codeErrors thrown:USER_NOT_FOUND - Email in the request does not belong to any userINVALID_LOGIN_CODE - When login code is incorrect */
    passwordlessLogin: LoginResponse;
    /** Update user profile image */
    updateUserProfileImage: User;
    /** Sign up With Details For Embed */
    signUpWithDetailsForEmbed: LoginResponse;
    /** Create a bookmark for userErrors thrown:BOOKMARK_ALREADY_EXISTS - Bookmark already exists */
    createBookmark: Bookmark;
    /** Remove a bookmark for userErrors thrown:BOOKMARK_NOT_FOUND - Bookmark not found */
    removeBookmark: boolean;
    /** Remove all bookmarks for user */
    removeAllBookmarks: boolean;
}

export interface ActionViewFilterState {
    id: string;

    name: string;

    state: ActionViewFilter;
}

export interface ActionViewFilter {
    actionStatusFilter?: ActionStatusFilter | null;

    searchFilter?: ActionSearchFilter | null;

    typeFilter?: ActionTypeFilter | null;

    groupFilter?: ActionGroupFilter | null;
}

export interface ActionStatusFilter {
    status?: ActionStatus[] | null;

    isExclude: boolean;
}

export interface ActionSearchFilter {
    name?: string | null;

    title?: string | null;
}

export interface ActionTypeFilter {
    actionType?: ActionType[] | null;

    isExclude: boolean;
}

export interface ActionGroupFilter {
    groupIds?: string[] | null;

    isExclude: boolean;
}

export interface ActionViewFilterStateList {
    items?: ActionViewFilterState[] | null;

    total: number;
}

export interface PhoneBankCallResponse {
    member: PhoneBankMember;

    callSid: string;
}

export interface ToggleMuteForCallResponse {
    callSid: string;

    muted: boolean;
}

export interface UpdateEventSeriesOutput {
    eventSeries: EventSeries;

    notUpdatedEvents: (Date | null)[];
}

export interface RegisterTcrBrandOutput {
    organizationType: TcrBrandOrganizationType;

    cspId: string;

    brandId: string;

    displayName: string;

    companyName: string;

    ein: string;

    einIssuingCountry: string;

    phone: string;

    street: string;

    city: string;

    state: string;

    postalCode: string;

    country: string;

    email: string;

    website: string;

    brandRelationship: string;

    vertical: TcrBrandVertical;

    referenceId: string;

    identityStatus?: TcrBrandIdentityStatus | null;

    createDate: string;

    mock: boolean;
}

export interface RegisterTcrCampaignOutput {
    campaignId: string;
}

export interface PreSignedUploadUrl {
    result: Json;

    mediaId: string;
}

export interface OutreachCircleAdminEmailUnsubscribeOutput {
    userEmail: string;

    emailTemplateName: string;

    outreachCircleName: string;
}

export interface OutreachCircleEmailUnsubscribeOutput {
    userEmail: string;

    outreachCircleName: string;

    appName?: string | null;
}

export interface InvitedSupporterSource {
    id: string;

    outreachCircleId: string;

    fileName?: string | null;

    fileType?: string | null;

    mapping?: Json | null;

    supporterEmailTemplateId: string;

    created?: Date | null;

    updated?: Date | null;

    importComplete?: boolean | null;

    supporterEmailTemplate?: SupporterEmailTemplate | null;
}

export interface LoginResponse {
    token: string;

    user: User;
}

export interface EmbeddedLoginResponse {
    embeddedToken: string;

    user: User;
}

export interface UnsubscribeEmailOutput {
    userEmail: string;

    emailTemplateName: string;
}

export interface TotpSecretResponse {
    secret: string;

    qrCode: string;
}

export interface Subscription {
    action: ActionSubscriptionResponse;

    publicAction: PublicActionSubscriptionResponse;
    /** Subscribe to a call status for a phone call */
    callStatus: CallStatusSubscriptionResponse;

    audienceImportStatus?: AudienceImportStatus | null;

    contactImportStatus?: ContactImportStatus | null;

    tcrIntegrationStatus: IntegrationSubscriptionResponse;
    /** outreach circle subscription to update client for some data update */
    outreachCircle: OutreachCircleSubscriptionResponse;
    /** Status of match contacts */
    supporter: SupporterSubscriptionResponse;
    /** Supporter subscription v2 */
    supporterV2: SupporterSubscriptionV2Response;
    /** Subscribe and receive update on the progress of Invited Supporter import */
    invitedSupporterImport: InvitedSupporterImportSubscriptionResponse;

    clientVersion?: number | null;

    supporterVersion?: number | null;
}

export interface ActionSubscriptionResponse {
    actionId: string;

    useCase: ActionUpdateUseCase;
}

export interface PublicActionSubscriptionResponse {
    actionId: string;

    useCase: PublicActionUpdateUseCase;

    data?: Json | null;
}

export interface CallStatusSubscriptionResponse {
    callSid: string;

    status: CallStatus;
}

export interface AudienceImportStatus {
    event: AudienceImportStatusEvent;

    audienceAction: AudienceAction;
}

export interface ContactImportStatus {
    contactSourceId?: string | null;

    count?: number | null;

    completed?: boolean | null;

    error?: boolean | null;
}

export interface IntegrationSubscriptionResponse {
    outreachCircleId: string;

    entityType: TcrEntityType;

    status?: TcrCurrentStatus | null;

    entityId: string;
}

export interface OutreachCircleSubscriptionResponse {
    outreachCircleId: string;

    responseType: OutreachCircleUpdateResponseType;

    data?: Json | null;
}

export interface SupporterSubscriptionResponse {
    supporterId: string;

    contactImportInProgress?: boolean | null;

    matchingJob?: MatchingJob | null;

    rerunMatching?: boolean | null;
}

export interface SupporterSubscriptionV2Response {
    supporterId: string;

    event: SupporterSubscriptionResponseEvent;

    data?: SupporterSubscriptionData | null;
}

export interface P2PConversationSubscriptionResponse {
    conversationItem?: P2PConversationItem | null;

    inboxItem?: P2PInboxItem | null;
}

export interface TextingConversationSubscriptionResponse {
    conversationItem?: RecipientTextingConversationItem | null;

    inboxItem?: TextingInboxItem | null;

    unReadCounts?: TextingConversationInboxUnReadCount | null;
}

export interface InvitedSupporterImportSubscriptionResponse {
    invitedSupporterSourceId: string;

    hasCompleted?: boolean | null;

    hasFailed?: boolean | null;

    failureReason?: string | null;

    totalImportedInvitedSupporterCount?: number | null;

    totalExistingSupporterCount?: number | null;

    currentState?: InvitedSupporterImportProgressState | null;
}

export interface SearchAndSurveyMemberData {
    key: string;

    value?: string | null;
}

export interface SupporterContactList {
    total: number;

    items: (SupporterContact | null)[];
}

export interface DashboardFilter {
    outreachCircleIds?: (string | null)[] | null;

    organizationFilterId?: string | null;

    organizationId?: string | null;

    startDate?: Date | null;

    endDate?: Date | null;

    outreachCircleJoinRequired?: boolean | null;

    skip?: number | null;

    limit?: number | null;
}

export interface Schedule {
    startTime: TimeWithTimezone;

    endTime: TimeWithTimezone;
}

export interface SupporterSearchFilter {
    firstName?: string | null;

    lastName?: string | null;

    emailAddress?: string | null;

    phoneNumber?: string | null;

    nameOrEmail?: string | null;
}

// ====================================================
// Arguments
// ====================================================

export interface GetAcquisitionTypesForOutreachCircleQueryArgs {
    input?: AcquisitionTypesForOutreachCircleFilter | null;
}
export interface GetAcquisitionTypesFromParentOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetGroCanvassSummaryQueryArgs {
    actionId: string;
}
export interface GetSupportersForActionAssignmentQueryArgs {
    input: GetSupportersForActionAssignmentInput;
}
export interface GetAssignedMembersQueryArgs {
    input?: GetAssignedMembersForActionInput | null;
}
export interface GetCanvassedMembersQueryArgs {
    input?: GetAssignedMembersForActionInput | null;
}
export interface GetContentForSendingToRecipientBySupporterQueryArgs {
    input: ContentForSendingToRecipientBySupporterInput;
}
export interface GetMemberFormFieldValidationsQueryArgs {
    input: MemberFormFieldValidationsInput;
}
export interface DoesActionExistsQueryArgs {
    input: ActionExistsInput;
}
export interface GetActionForPreviewQueryArgs {
    id: string;
}
export interface GetActionsForPreviewByGroupQueryArgs {
    input: GetActionPreviewByGroupInput;
}
export interface GetActionsForPreviewForSupporterQueryArgs {
    input: GetActionsPreviewForSupporterInput;
}
export interface GetActionsForOutreachCircleQueryArgs {
    input: ActionsForOutreachCircleInput;
}
export interface GetActionsForOrganizationActionQueryArgs {
    input: ActionsForOrgActionInput;
}
export interface GetCurrentActionsForOutreachCircleQueryArgs {
    input: ActionsForOutreachCircleInput;
}
export interface GetP2PActionsWhereInitialTextIsSentQueryArgs {
    input: ActionsForOutreachCircleInput;
}
export interface GetInactiveUnarchivedActionsForOutreachCircleQueryArgs {
    input: ActionsForOutreachCircleInput;
}
export interface GetArchivedActionsForOutreachCircleQueryArgs {
    input: ActionsForOutreachCircleInput;
}
export interface GetPublicActionsForEmbedQueryArgs {
    input: PublicActionForEmbedInput;
}
export interface GetPublicActionsForOutreachCircleQueryArgs {
    input: PublicActionForOutreachCircleInput;
}
export interface GetPublicActionsForSupporterQueryArgs {
    input: PublicActionForSupporterInput;
}
export interface GetPublicActionQueryArgs {
    id: string;
}
export interface GetActionQueryArgs {
    id: string;
}
export interface GetSupporterActionStatusQueryArgs {
    input: SupporterActionInput;
}
export interface GetFacebookDataQueryArgs {
    input: FacebookRequestInput;
}
export interface GetContactsForAffinityTextingQueryArgs {
    input: GetContactsForAffinityTextingInput;
}
export interface GetContactsForAffinityTextingV1QueryArgs {
    input: GetContactsForAffinityTextingInput;
}
export interface GetTextContentForAffinityTextingQueryArgs {
    input: GetTextContentForAffinityTextingInput;
}
export interface GetSupporterTextContentForTextingSupporterQueryArgs {
    input: SupporterTextContentForTextingSupporterInput;
}
export interface GetActionsForWhichEmailsSentBySupporterQueryArgs {
    input: PublicActionForSupporterInput;
}
export interface GetActionsForWhichTextMessagesSentBySupporterQueryArgs {
    input: PublicActionForSupporterInput;
}
export interface GetActionsTakenBySupporterQueryArgs {
    input: PublicActionForSupporterInput;
}
export interface GetTextSupporterSetListQueryArgs {
    input: TextSupporterSetInput;
}
export interface GetEligibleSupporterCountForSupporterTextingQueryArgs {
    input: EligibleSupporterForSupporterTextingInput;
}
export interface GetEligibleActionsForAssigningToGroupQueryArgs {
    input: GetEligibleActionForAssigningToGroupInput;
}
export interface GetEmbedActionQueryArgs {
    actionId: string;
}
export interface GetAssignedSupportersForActionQueryArgs {
    input: GetAssignedSupportersForActionInput;
}
export interface FetchAssignedSupportersToActionQueryArgs {
    input: FetchAssignedSupportersToActionInput;
}
export interface GetEstimatedCostAcceptanceInfoForActionQueryArgs {
    actionId: string;
}
export interface GetAudienceCustomFieldListForActionQueryArgs {
    actionId: string;
}
export interface HasSmsSentToAllEligibleRecipientsQueryArgs {
    actionId: string;
}
export interface PublicActionByAliasQueryArgs {
    input: PublicActionByAliasInput;
}
export interface GetActionsLinkedToAudienceActionQueryArgs {
    input: GetActionsLinkedToAudienceActionInput;
}
export interface SendActionEmailNotificationPreviewQueryArgs {
    input: ActionEmailNotificationPreviewInput;
}
export interface GetOriginalActionCallTemplateForSupporterQueryArgs {
    actionId: string;
}
export interface GetOriginalActionVisitTemplateForSupporterQueryArgs {
    actionId: string;
}
export interface FetchGroupsAssignedToActionQueryArgs {
    input: FetchGroupsAssignedToActionInput;
}
export interface GetMemberNotesQueryArgs {
    input?: GetMemberNotesInput | null;
}
export interface GetAudienceActionHeadersQueryArgs {
    actionId: string;
}
export interface GetPublicEventsQueryArgs {
    input: GetPublicEventsInput;
}
export interface GetDeviceTokenForPhoneBankQueryArgs {
    input?: GetDeviceTokenForPhoneBankInput | null;
}
export interface GetMemberForPhoneBankQueryArgs {
    input: GetMemberForPhoneBankInput;
}
export interface GetRecipientsForTextingActionQueryArgs {
    input: GetRecipientsForTextingActionInput;
}
export interface GetInitialTextingScriptQueryArgs {
    actionId: string;
}
export interface GetInitialTextingScriptForRestoreQueryArgs {
    actionId: string;
}
export interface GetNumberOfRecipientsTextedQueryArgs {
    actionId: string;
}
export interface GetTextingConversationsByActionQueryArgs {
    input: GetTextingConversationsByActionInput;
}
export interface GetTextingConversationInboxUnReadCountQueryArgs {
    actionId: string;
}
export interface GetTextingConversationInboxQueryArgs {
    input: GetTextingConversationInboxInput;
}
export interface GetRecipientTextingConversationQueryArgs {
    id: string;
}
export interface GetHouseholdsBasedonCurrentLocationQueryArgs {
    input: GetHouseholdsBasedonCurrentLocationInput;
}
export interface GetMatchedMembersForAddressBasedCanvassingQueryArgs {
    input: CreateSearchAndSurveyContactInput;
}
export interface GetAmbiguousHouseholdMembersQueryArgs {
    input: GetAmbiguousHouseholdMembersInput;
}
export interface GetAudienceActionQueryArgs {
    id: string;
}
export interface PresignedAudienceActionUploadUrlQueryArgs {
    input?: AudienceActionUrlRequestInput | null;
}
export interface AudienceMemberByNameQueryArgs {
    input?: MemberSearchByNameInput | null;
}
export interface DoesAudienceMappingNameExistQueryArgs {
    input: MemberSearchByNameInput;
}
export interface GetAudienceActionsForOutreachCircleByIntegrationTypeQueryArgs {
    input: GetAudienceActionsForOutreachCircleByIntegrationTypeInput;
}
export interface GetAudienceActionsForActionByIntegrationTypeQueryArgs {
    input: GetAudienceActionsForActionByIntegrationTypeInput;
}
export interface GetAudienceInfoByListTypeQueryArgs {
    input: GetAudienceInfoByListTypeInput;
}
export interface GetAudienceActionsForOutreachCircleByListTypeQueryArgs {
    input: GetAudienceActionsForOutreachCircleByListTypeInput;
}
export interface GetLiveActionsByAudienceActionQueryArgs {
    input: GetLiveActionsByAudienceActionInput;
}
export interface GetMappingsByAudienceIdQueryArgs {
    input: GetMappingsByAudienceIdInput;
}
export interface GetSearchAndSurveyMemberByIdQueryArgs {
    input: GetSearchAndSurveyMemberInput;
}
export interface GetMatchedMembersForSearchAndSurveyActionQueryArgs {
    input: SearchAndSurveyMemberInput;
}
export interface GetBlackbaudConstituentListsQueryArgs {
    outreachCircleId: string;
}
export interface SupporterContactQueryArgs {
    input?: SupporterContactInput | null;
}
export interface MappedContactsQueryArgs {
    input: MappedContactsInput;
}
export interface MappedContactsForTextQueryArgs {
    input: MappedContactsInput;
}
export interface EmailRecipientsQueryArgs {
    input: MappedContactsInput;
}
export interface AmbiguousMappedContactsQueryArgs {
    input: MappedContactsInput;
}
export interface AmbiguousMappedContactsForTextQueryArgs {
    input: MappedContactsInput;
}
export interface GetContentForTextRecipientQueryArgs {
    input: TextRecipientInput;
}
export interface MarkTextRecipientAsSentQueryArgs {
    input: TextRecipientInput;
}
export interface MappedContactsCountQueryArgs {
    input: SupporterActionInput;
}
export interface MappedContactsCountForTextQueryArgs {
    input: SupporterActionInput;
}
export interface MappedContactsCountForOutreachModeQueryArgs {
    input: SupporterActionInput;
}
export interface MappedContactsCountForTextOutreachModeQueryArgs {
    input: SupporterActionInput;
}
export interface MatchingJobQueryArgs {
    id: string;
}
export interface ContactSourcesWithMappedContactCountQueryArgs {
    input: SupporterActionInput;
}
export interface ContactSourcesWithAmbiguousMappedContactCountQueryArgs {
    input: SupporterActionInput;
}
export interface ContactSourcesWithMappedContactCountForTextQueryArgs {
    input: SupporterActionInput;
}
export interface ContactSourcesWithAmbiguousMappedContactCountForTextQueryArgs {
    input: SupporterActionInput;
}
export interface GetSelectedNonMappedContactsForEmailQueryArgs {
    input: MappedContactsInput;
}
export interface GetSelectedNonMappedContactsForTextQueryArgs {
    input: MappedContactsInput;
}
export interface GetEligibleOutreachModeRecipientsForEmailQueryArgs {
    input: MappedContactsInput;
}
export interface GetEligibleOutreachModeRecipientsForTextQueryArgs {
    input: MappedContactsInput;
}
export interface GetUnavailableContactsForEmailOutreachModeQueryArgs {
    input: MappedContactsInput;
}
export interface GetUnavailableContactsForTextOutreachModeQueryArgs {
    input: MappedContactsInput;
}
export interface GetMappedContactsForActionQueryArgs {
    input: GetContactsForActionInput;
}
export interface GetNonMappedContactsForActionQueryArgs {
    input: GetContactsForActionInput;
}
export interface GetAmbiguousContactsForActionQueryArgs {
    input: GetContactsForActionInput;
}
export interface GetA1CRecipientsQueryArgs {
    input: GetContactsForActionInput;
}
export interface GetDuplicateEmailRecipientsQueryArgs {
    input: DuplicateEmailRecipientInput;
}
export interface GetMatchesForContactQueryArgs {
    input: GetMatchesForContactInput;
}
export interface GetContactFileUploadUrlQueryArgs {
    input: ContactFileUrlInput;
}
export interface DoesContactExistsForEmailQueryArgs {
    email: string;
}
export interface DoesContactExistsForPhoneNumberQueryArgs {
    phoneNumber: string;
}
export interface GetContactsQueryArgs {
    input: ContactQueryInput;
}
export interface GetContactsWithEmailQueryArgs {
    input: ContactQueryForOutreachMode;
}
export interface GetContactsWithPhoneQueryArgs {
    input: ContactQueryForOutreachMode;
}
export interface GetContactByIdQueryArgs {
    id: string;
}
export interface GetTotalSupportersByRecruitmentLinkQueryArgs {
    input: OutreachCircleDashboardFilterInput;
}
export interface TotalActionsTakenQueryArgs {
    input: OutreachCircleDashboardFilterInput;
}
export interface TotalSupportersQueryArgs {
    input: OutreachCircleDashboardFilterInput;
}
export interface TotalActionImpressionsQueryArgs {
    input: OutreachCircleDashboardFilterInput;
}
export interface MostEngagedSupportersQueryArgs {
    input: OutreachCircleDashboardFilterInput;
}
export interface ActionsTakenBySupportersQueryArgs {
    input: OutreachCircleDashboardFilterInput;
}
export interface EmailSummaryQueryArgs {
    input: OutreachCircleDashboardFilterInput;
}
export interface TextMessagesSentQueryArgs {
    input: OutreachCircleDashboardFilterInput;
}
export interface ActionsByTypeQueryArgs {
    input: OutreachCircleDashboardFilterInput;
}
export interface SurveyResponseSummaryQueryArgs {
    engagementId: string;
}
export interface GetOrganizationUpdatesQueryArgs {
    outreachCircleId: string;
}
export interface GetDailySupportIdsForOutreachCircleQueryArgs {
    input: GetDailySupportIdsInput;
}
export interface GetSupportIdsCountForOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetSystemUpdatesForOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface TodaysActivityForOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetPdiListsForOutreachCircleQueryArgs {
    input: GetPdiListsForOutreachCircleInput;
}
export interface GetTurfGroupsByActionQueryArgs {
    actionId: string;
}
export interface GetTurfsByActionQueryArgs {
    actionId: string;
}
export interface GetTurfGroupByIdQueryArgs {
    turfGroupId: string;
}
export interface GetTurfByIdQueryArgs {
    turfId: string;
}
export interface GetPublicTurfGroupsByActionQueryArgs {
    actionId: string;
}
export interface GetTurfGroupsByOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetTurfsByOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetPublicTurfAssignmentsByActionQueryArgs {
    actionId: string;
}
export interface GetPublicTurfAssignmentByIdQueryArgs {
    canvassingAssignmentId: string;
}
export interface GetPublicTurfAssignmentsByOutreachCircleQueryArgs {
    input: GetPublicTurfAssignmentsByOutreachCircleInput;
}
export interface GetPublicTurfGroupsByOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetPublicTurfGroupsByShiftQueryArgs {
    shiftId: string;
}
export interface SearchPlacesQueryArgs {
    searchText: string;
}
export interface GetRecentlyCanvassedTurfsQueryArgs {
    actionId: string;
}
export interface SearchTurfsQueryArgs {
    input: SearchTurfsInput;
}
export interface GetDefaultTurfsByActionQueryArgs {
    input: GetDefaultTurfsByActionInput;
}
export interface GetDefaultTurfByIdQueryArgs {
    input: GetDefaultTursByActionInput;
}
export interface GetCanvassingMemberCommunicationHistoryQueryArgs {
    input: GetHistoryForCanvassingMemberInput;
}
export interface GetCanvassingMemberSupportHistoryQueryArgs {
    input: GetHistoryForCanvassingMemberInput;
}
export interface GetCustomTurfsByActionQueryArgs {
    actionId: string;
}
export interface GetCustomTurfsByOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetMembersByHouseholdQueryArgs {
    input: GetMembersByHouseholdInput;
}
export interface GetCanvassedSurveyResponseSummaryBySupportersQueryArgs {
    input: GetCanvassedSurveyResponseSummaryInput;
}
export interface GetCanvassedSurveyResponseSummaryByAssignmentQueryArgs {
    input: GetCanvassedSurveyResponseSummaryInput;
}
export interface GetCanvassedSurveyResponsesBySupporterQueryArgs {
    input: GetCanvassedSurveyResponsesBySupporterInput;
}
export interface GetVoterDensityBucketsQueryArgs {
    input: VoterDensityBucketInput;
}
export interface GetOriginalActionEmailTemplateSubjectForSupporterQueryArgs {
    input: SupporterEmailTemplateInput;
}
export interface GetOriginalActionEmailTemplateContentForSupporterQueryArgs {
    input: SupporterEmailTemplateInput;
}
export interface EditHtmlForEngagementByIdQueryArgs {
    engagementId: string;
}
export interface GetContentForPreviewQueryArgs {
    editorContent: string;

    emailTemplateId: string;

    actionId: string;
}
export interface EmailTemplateQueryArgs {
    id: string;
}
export interface EmailTemplatesByOutreachCircleQueryArgs {
    input: EmailTemplateForOutreachCircleInput;
}
export interface DefaultEditHtmlForRecruitmentLinkQueryArgs {
    emailTemplateId: string;

    outreachcircleCode?: string | null;

    linkText?: string | null;
}
export interface GetDefaultEditorContentForActionLinkQueryArgs {
    actionId: string;
}
export interface UpdatedEmailTemplateWithRecruitmentLinkQueryArgs {
    input: UpdateEmailTemplateWithRecruitmentLinkInput;
}
export interface UpdatedEmailTemplateWithOutreachCircleBrandingQueryArgs {
    content: string;

    hasBranding: boolean;
}
export interface EditHtmlForDisclaimerQueryArgs {
    disclaimerText: string;
}
export interface UpdatedEmailTemplateWithDisclaimerQueryArgs {
    content: string;

    disclaimerText: string;
}
export interface GetEmailTemplateForShiftCardQueryArgs {
    shiftCardText: string;
}
export interface GetEmailTemplateForEventCardQueryArgs {
    eventCardText: string;
}
export interface GetEmailContentForUiEditorWithUpdatedSurveyQueryArgs {
    input: UpdateEmailContentWithSurveyDetailsInput;
}
export interface ValidateEmailTemplateNameForActionQueryArgs {
    input?: ValidateEmailTemplateNameForActionInput | null;
}
export interface EmailTemplatesByOrganizationQueryArgs {
    input: EmailTemplatesForOrganization;
}
export interface DefaultHtmlForRecruitmentLinkForOrgEmailTemplateQueryArgs {
    emailTemplateId: string;

    linkText?: string | null;
}
export interface DefaultHtmlForDisclaimerForOrgEmailTemplateQueryArgs {
    emailTemplateId: string;
}
export interface ValidateOrganizationEmailTemplateNameQueryArgs {
    input?: ValidateOrganizationEmailTemplateNameInput | null;
}
export interface GetContentForUiPreviewByUiEditorContentForOrganizationQueryArgs {
    editorContent: string;

    emailTemplateId: string;
}
export interface SurveyByIdQueryArgs {
    surveyId: string;
}
export interface SurveysByOutreachCircleIdQueryArgs {
    input: SurveysByOutreachCircleIdInput;
}
export interface GetSurveysEligibleForForActionByIntegrationQueryArgs {
    input: GetSurveysEligibleForForActionByIntegrationInput;
}
export interface SearchExternalLinksByOutreachCircleIdQueryArgs {
    input: SearchExternalLinkInput;
}
export interface SurveyByIdForTextRecipientQueryArgs {
    engagementId: string;

    textRecipientId: string;
}
export interface SurveyByIdForEmailRecipientQueryArgs {
    engagementId: string;

    emailRecipientId: string;
}
export interface SurveyEngagementForPreviewQueryArgs {
    engagementId: string;
}
export interface GetSupporterPreviewForSurveyQuestionQueryArgs {
    surveyQuestionId: string;
}
export interface GetSupporterPreviewForSurveyQueryArgs {
    surveyId: string;
}
export interface DoesSurveyByNameAndOutreachCircleIdExistQueryArgs {
    input: SurveyExistsInput;
}
export interface GetSurveysForOrganizationQueryArgs {
    input: SurveysForOrganizationInput;
}
export interface GetActivitiesQueryArgs {
    input: GetActivitiesInput;
}
export interface GetEventConfigQueryArgs {
    id: string;
}
export interface GetEventConfigsByOutreachCircleQueryArgs {
    input: GetEventConfigsForOutreachCircleInput;
}
export interface GetPublicEventConfigsByOutreachCircleQueryArgs {
    input: GetPublicEventConfigsForOutreachCircleInput;
}
export interface GetAssignedEventsForNonWorkingGuestQueryArgs {
    input: GetAssignedEventsForNonWorkingGuestInput;
}
export interface GetEventSeriesByActionQueryArgs {
    actionId: string;
}
export interface GetEventSeriesByIdQueryArgs {
    eventSeriesId: string;
}
export interface GetAssignedSupportersForShiftQueryArgs {
    input: AssignedSupportersForShiftInput;
}
export interface GetPublicShiftsByOutreachCircleQueryArgs {
    input: GetPublicShiftsByOutreachCircleInput;
}
export interface GetShiftsByOutreachCircleQueryArgs {
    input: GetShiftsByOutreachCircleInput;
}
export interface GetAttendeesForShiftQueryArgs {
    input: GetAttendeesForShiftInput;
}
export interface GetShiftsByActionTypeQueryArgs {
    input: GetShiftsByActionTypeInput;
}
export interface GetPublicShiftByIdQueryArgs {
    shiftId: string;
}
export interface GetFilteredFlagsQueryArgs {
    input?: GetFilteredFlagsInput | null;
}
export interface GetFlagsFromParentOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetFlagAssociationsQueryArgs {
    input?: GetFlagAssociationsInput | null;
}
export interface GroSurveyFormsByOutreachCircleQueryArgs {
    input: GroSurveyFormsByOutreachCircleInput;
}
export interface GetSupporterPreviewForGroSurveyFormQuestionQueryArgs {
    groSurveyFormQuestionId: string;
}
export interface GetSupporterPreviewForGroSurveyFormQueryArgs {
    groSurveyFormId: string;
}
export interface GetGroSurveyFormByIdQueryArgs {
    groSurveyFormId: string;
}
export interface ValidateGroSurveyFormNameQueryArgs {
    input?: ValidateGroSurveyFormNameInput | null;
}
export interface GetGroSurveyResponseForMemberQueryArgs {
    input: GetGroSurveyResponseForMemberInput;
}
export interface GetPastGroSurveyResponsesForMemberQueryArgs {
    input: GetPastGroSurveyResponseInput;
}
export interface GetGroupsByOutreachCircleQueryArgs {
    input: GetGroupsInput;
}
export interface GetActionsForGroupQueryArgs {
    input: GetActionsForGroupInput;
}
export interface GroupQueryArgs {
    id: string;
}
export interface GetEligibleGroupsForActionQueryArgs {
    actionId: string;
}
export interface GetAllGroupsForActionQueryArgs {
    input: GetAllGroupsForActionInput;
}
export interface FetchIntegrationsQueryArgs {
    outreachCircleId: string;
}
export interface FetchNationBuilderPeopleTagsQueryArgs {
    outreachCircleId: string;
}
export interface TestNgpIntegrationQueryArgs {
    input: TestNgpIntegrationInput;
}
export interface FetchNgpListsQueryArgs {
    outreachCircleId: string;

    database: AudienceActionDatabase;
}
export interface FetchNgpActivistCodesQueryArgs {
    outreachCircleId: string;

    integrationType: NgpIntegrationType;
}
export interface FetchNgpSurveyListQueryArgs {
    outreachCircleId: string;

    integrationType: NgpIntegrationType;
}
export interface FetchNgpSurveyByIdQueryArgs {
    outreachCircleId: string;

    ngpSurveyId: number;

    database: AudienceActionDatabase;
}
export interface GetPdiOnTheGoAssignmentsQueryArgs {
    input: OnTheGoAssignmentsInput;
}
export interface GetPdiAssignmentZonesQueryArgs {
    actionId: string;
}
export interface GetMatchedPdiMembersQueryArgs {
    input: CreateSearchAndSurveyContactInput;
}
export interface GetPdiQuestionsQueryArgs {
    input: GetQuestionsFromPdiInput;
}
export interface GetPdiFlagIdsQueryArgs {
    outreachCircleId: string;
}
export interface GetPdiProjectsForOutreachcircleQueryArgs {
    outreachCircleId: string;
}
export interface ValidateSoqlQueryArgs {
    input?: ValidateSoqlInput | null;
}
export interface GetTcrRegistrationDetailsQueryArgs {
    outreachCircleId: string;
}
export interface GetTcrBrandApplicableUsecasesQueryArgs {
    input: GetTcrBrandApplicableUsecasesInput;
}
export interface GetBase64ForAnImageQueryArgs {
    mediaId: string;
}
export interface MemberSurveysByOutreachCircleIdQueryArgs {
    input: SurveysByOutreachCircleIdInput;
}
export interface MemberSurveysByOrganizationIdQueryArgs {
    input: SurveysForOrganizationInput;
}
export interface GetLatestNativeAppVersionQueryArgs {
    input: GetLatestNativeAppVersionInput;
}
export interface GetOrganizationActionForPreviewQueryArgs {
    id: string;
}
export interface GetOrganizationActionQueryArgs {
    id: string;
}
export interface GetActionsForOrganizationQueryArgs {
    input: ActionsForOrganizationInput;
}
export interface GetOutreachCirclesByOrganizationActionPublishedQueryArgs {
    organizationActionId: string;
}
export interface GetTerminatedAndHiddenOrganizationActionsQueryArgs {
    input: ActionsForOrganizationInput;
}
export interface GetCurrentOrganizationActionsQueryArgs {
    input: ActionsForOrganizationInput;
}
export interface DoesOrganizationActionWithSameNameExistQueryArgs {
    input: DoesOrganizationActionWithSameNameExistInput;
}
export interface GetAllOrganizationUsersQueryArgs {
    organizationId: string;
}
export interface OrganizationByIdQueryArgs {
    id: string;
}
export interface PublicOrganizationByAliasQueryArgs {
    alias: string;
}
export interface GetOutreachCirclesByOrganizationQueryArgs {
    input: GetOutreachCirclesByOrganizationInput;
}
export interface GetActiveOutreachCirclesByOrganizationQueryArgs {
    input: GetOutreachCirclesByOrganizationInput;
}
export interface GetFiltersByOrganizationQueryArgs {
    organizationId: string;
}
export interface TotalActionsTakenForOrganizationQueryArgs {
    filter: GetOrganizationDashboardDataInput;
}
export interface ActionsTakenCountByOutreachcircleForOrganizationQueryArgs {
    filter: GetOrganizationDashboardDataInput;
}
export interface MostEngagedSupportersForOrganizationQueryArgs {
    filter: GetOrganizationDashboardDataInput;
}
export interface GetSupporterCountByOutreachcircleForOrganizationQueryArgs {
    filter: GetOrganizationDashboardDataInput;
}
export interface ActionsByTypeForOrganizationQueryArgs {
    filter: GetOrganizationDashboardDataInput;
}
export interface EmailSummaryForOrganizationQueryArgs {
    filter: GetOrganizationDashboardDataInput;
}
export interface TextMessagesSentForOrganizationQueryArgs {
    filter: GetOrganizationDashboardDataInput;
}
export interface EmailOpenRateForOrganizationQueryArgs {
    filter: GetOrganizationDashboardDataInput;
}
export interface TotalSupportersCountForOrganizationQueryArgs {
    filter: GetOrganizationDashboardDataInput;
}
export interface GetOutreachCirclesForUserByOrganizationQueryArgs {
    userId: string;

    organizationId: string;
}
export interface PublicOrganizationQueryArgs {
    organizationId: string;
}
export interface GetPublicOutreachCirclesByOrganizationQueryArgs {
    input: PublicOutreachCircleByOrganizationInput;
}
export interface DoesOutreachCircleUserEmailTemplateExistsQueryArgs {
    input: DoesOutreachCircleUserEmailTemplateExistsInput;
}
export interface GetAllEmailAliasesForOrganizationQueryArgs {
    organizationId: string;
}
export interface GetOutreachCircleUserEmailTemplatesForOrganizationQueryArgs {
    input: GetOutreachCircleUserEmailTemplatesForOrganizationInput;
}
export interface GetPreviewForOutreachCircleUserEmailTemplateQueryArgs {
    input: GetPreviewForOutreachCircleUserEmailTemplateInput;
}
export interface GetUpdatedContentForOutreachCircleUserEmailTemplateWithActionLinkTextQueryArgs {
    input: GetUpdatedContentForOutreachCircleUserEmailTemplateWithActionLinkTextInput;
}
export interface GetEmailTemplateForOrganizationActionQueryArgs {
    organizationActionId: string;

    code: OutreachCircleUserEmailTemplateCode;
}
export interface GetEligibleCountForOrganizationActionNotificationQueryArgs {
    input: GetEligibleCountForOrganizationSendActionNotificationInput;
}
export interface GetEligibleCountForNotifyOcAdminsForOrganizationQueryArgs {
    input: GetEligibleCountForNotifyOcAdminsForOrganizationInput;
}
export interface GetOutreachCircleRolesQueryArgs {
    outreachCircleId: string;
}
export interface GetOutreachCirclePermissionsQueryArgs {
    outreachCircleId: string;
}
export interface OutreachCircleUserQueryArgs {
    id: string;
}
export interface GetOutreachcircleUsersWithFiltersQueryArgs {
    input: OutreachcircleUsersWithFiltersInput;
}
export interface GetOutreachCircleUserGeographyListWithFilterQueryArgs {
    input?: OutreachCircleUserGeographyListWithFilterInput | null;
}
export interface PublicOutreachCircleByAliasQueryArgs {
    alias: string;
}
export interface PublicOutreachCircleByAliasOrNameQueryArgs {
    input: PublicOutreachCircleByAliasOrNameInput;
}
export interface PublicOutreachCircleForEmbedQueryArgs {
    alias: string;
}
export interface SearchPublicOutreachCircleQueryArgs {
    input: SearchPublicOutreachCircleInput;
}
export interface OutreachCircleByAliasQueryArgs {
    alias: string;
}
export interface OutreachCircleByIdQueryArgs {
    id: string;
}
export interface AllPublicOutreachCirclesQueryArgs {
    input: AllPublicOutreachCirclesInput;
}
export interface AllAliasesForOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface AllEmailAliasesForOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetAllCampaignsForOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetOutreachCircleAccessInfoQueryArgs {
    outreachCircleId: string;
}
export interface GetPublicAliasDetailsQueryArgs {
    aliasValue: string;
}
export interface GetAppLogoByAliasQueryArgs {
    aliasValue: string;
}
export interface GetArchivedPublicOutreachCircleQueryArgs {
    id: string;
}
export interface GetDeepLinkObjectQueryArgs {
    input: DeepLinkInput;
}
export interface GetOutreachCircleLocationsQueryArgs {
    outreachCircleId: string;
}
export interface GetOutreachCircleLocationQueryArgs {
    outreachCircleLocationId: string;
}
export interface GetGeographyListForOutreachCircleQueryArgs {
    input: GetGeographyListForOutreachCircleInput;
}
export interface BillingCardsByOutreachCircleIdQueryArgs {
    outreachCircleId: string;
}
export interface CouponDiscountedAmountQueryArgs {
    plan: string;

    coupon: string;

    outreachCircleId?: string | null;
}
export interface UpcomingInvoiceDetailsOfOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface OldInvoicesOfOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface PdiVoterSearchQueryArgs {
    input: PdiVoterSearchInput;
}
export interface GetPdiVoterByIdQueryArgs {
    input: GetPdiVoterByIdInput;
}
export interface GetFilteredP2PInboxQueryArgs {
    input: P2PInboxFilterInput;
}
export interface GetP2PConversationQueryArgs {
    conversationId: string;
}
export interface GetEligibleSupporterForP2PReplyAssignmentQueryArgs {
    input: EligibleSupporterForP2PReplyAssignmentInput;
}
export interface GetP2PScriptSurveyQuestionsByOutreachCircleQueryArgs {
    input: P2PScriptSurveyQuestionsByOutreachCircleInput;
}
export interface GetP2PScriptSurveyQuestionByIdQueryArgs {
    p2pScriptSurveyQuestionId: string;
}
export interface IsValidP2PScriptSurveyQuestionNameQueryArgs {
    input: ValidateP2PScriptSurveyQuestionNameInput;
}
export interface GetRecipientsForP2PTextingQueryArgs {
    input: RecipientsForP2PTextingInput;
}
export interface GetNumberOfRecipientsP2PTextedQueryArgs {
    actionId: string;
}
export interface GetInitialP2PItemQueryArgs {
    actionId: string;
}
export interface GetInitialP2PTextForRestoreQueryArgs {
    actionId: string;
}
export interface CanSendP2PTextMessageQueryArgs {
    actionId: string;
}
export interface GetP2PWorkspacesByOutreachCircleQueryArgs {
    input: P2PWorkspacesByOutreachCircleInput;
}
export interface GetP2PWorkspaceByIdQueryArgs {
    p2pWorkspaceId: string;
}
export interface IsValidP2PWorkspaceNameQueryArgs {
    input: ValidateP2PWorkspaceNameInput;
}
export interface IsP2PWorkspaceLinkedToActionAndAcknowledgedCostQueryArgs {
    p2pWorkspaceId: string;
}
export interface GetBallotMeasureByIdQueryArgs {
    input: GetBallotMeasureByIdInput;
}
export interface GetCandidateByIdQueryArgs {
    input: GetCandidateByIdInput;
}
export interface GetIssueByIdQueryArgs {
    input: GetIssueByIdInput;
}
export interface GetFilteredPurposeListQueryArgs {
    input?: GetFilteredPurposeListInput | null;
}
export interface GetElectionCycleListForOutreachCircleQueryArgs {
    input: GetElectionCycleListForOutreachCircleInput;
}
export interface GetPurposeListFromParentOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetReportsByOutreachCircleQueryArgs {
    outreachCircleId: string;

    reportType?: ReportType | null;
}
export interface GetReportsByOrganizationQueryArgs {
    organizationId: string;
}
export interface SearchAndSurveyFormsByOutreachCircleQueryArgs {
    input: SearchAndSurveyFormsByOutreachCircleInput;
}
export interface GetSupporterPreviewForSearchAndSurveyFormQuestionQueryArgs {
    searchAndSurveyFormQuestionId: string;
}
export interface GetSupporterPreviewForSearchAndSurveyFormQueryArgs {
    searchAndSurveyFormId: string;
}
export interface GetSearchAndSurveyFormByIdQueryArgs {
    searchAndSurveyFormId: string;
}
export interface ValidateSearchAndSurveyFormNameQueryArgs {
    input?: ValidateSearchAndSurveyFormNameInput | null;
}
export interface DefaultEditHtmlForRecruitmentLinkForSocialTextQueryArgs {
    outreachCircleId: string;

    actionId: string;

    outreachcircleCode?: string | null;
}
export interface GetSocialTextContentForPreviewQueryArgs {
    input: GetSocialTextContentForPreviewInput;
}
export interface SocialTemplatesByOutreachCircleQueryArgs {
    outreachCircleId: string;

    searchKey?: string | null;
}
export interface ValidateSocialTemplateNameQueryArgs {
    input?: ValidateSocialTemplateNameInput | null;
}
export interface SocialTemplatesByOrganizationQueryArgs {
    organizationId: string;

    searchKey?: string | null;
}
export interface ValidateOrganizationSocialTemplateNameQueryArgs {
    input?: ValidateOrganizationSocialTemplateNameInput | null;
}
export interface SocialTemplateByIdForOrganizationQueryArgs {
    id: string;
}
export interface SocialTemplateQueryArgs {
    id: string;
}
export interface GetOrganizationSocialTextContentForPreviewQueryArgs {
    input: GetOrganizationSocialTextContentForPreviewInput;
}
export interface GetSourcingLinkForOutreachCircleQueryArgs {
    input: GetSourcingLinkInput;
}
export interface GetSupporterActivityListQueryArgs {
    input: SupporterActivityListInput;
}
export interface GetUpdatedReminderContentQueryArgs {
    input: UpdateReminderContentInput;
}
export interface GetSupporterTemplatesForOutreachCircleQueryArgs {
    input: SupporterEmailTemplateForOutreachCircle;
}
export interface GetDefaultEditorContentForSupporterRecruitmentLinkQueryArgs {
    outreachCircleId: string;

    outreachCircleCode?: string | null;

    linkText?: string | null;
}
export interface GetContentForUiPreviewByUiEditorContentForOutreachCircleSupporterEmailTemplateQueryArgs {
    input: ContentForUiPreviewByUiEditorContentForOutreachCircleSupporterEmailTemplateInput;
}
export interface GetUpdatedEditorContentForSupporterRecruitmentLinkQueryArgs {
    input: UpdateSupporterEmailTemplateWithRecruitmentLinkInput;
}
export interface ValidateSupporterEmailTemplateNameQueryArgs {
    input?: ValidateSupporterEmailTemplateNameInput | null;
}
export interface GetDefaultContentForActionEmailNotificationQueryArgs {
    input: DefaultSupporterEmailTemplateInput;
}
export interface SupporterFormsByOutreachCircleQueryArgs {
    input: SupporterFormsByOutreachCircleInput;
}
export interface GetSupporterPreviewForSupporterFormQuestionQueryArgs {
    supporterFormQuestionId: string;
}
export interface GetSupporterPreviewForSupporterFormQueryArgs {
    supporterFormId: string;
}
export interface GetSupporterFormByIdQueryArgs {
    supporterFormId: string;
}
export interface ValidateSupporterFormNameQueryArgs {
    input?: ValidateSupporterFormNameInput | null;
}
export interface GetSupporterFormsForOrganizationQueryArgs {
    input: GetSupporterFormsForOrganizationInput;
}
export interface GetDefaultContentForActionPushNotificationQueryArgs {
    code: SupporterPushNotificationTemplateCode;
}
export interface GetSupporterViewFilterStatesQueryArgs {
    input: GetSupporterViewFilterStatesInput;
}
export interface DoesSupporterViewFilterStateExistsQueryArgs {
    input: DoesSupporterViewFilterStateExistsInput;
}
export interface GetSupporterActivitiesByOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetSupporterAvailabilitiesByOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetSupporterLevelsByOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetSupporterOrganizationsByOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetOrganizersByOutreachCircleQueryArgs {
    input: GetOrganizersByOutreachCircleInput;
}
export interface GetSupporterSkillsByOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetSupporterTeamsByOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetSupporterZonesByOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetSupporterByOutreachCircleAliasQueryArgs {
    alias: string;
}
export interface GetSupporterByIdQueryArgs {
    supporterId: string;
}
export interface GetSupporterViewByIdQueryArgs {
    supporterId: string;
}
export interface GetSupporterSummaryListQueryArgs {
    input: SupporterListInput;
}
export interface GetFilteredSupporterViewQueryArgs {
    input: GetSupporterViewFilterInput;
}
export interface GetInvitedSupporterFileUploadUrlQueryArgs {
    input: InvitedSupporterFileUrlInput;
}
export interface GetTotalInvitedSupportersByOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetInvitedSupporterInformationQueryArgs {
    input?: InvitedSupporterInformationInput | null;
}
export interface GetTotalTextMessagesSentByLoggedInUserQueryArgs {
    appCode?: string | null;

    organizationId?: string | null;
}
export interface GetTotalActionsTakenByLoggedInUserQueryArgs {
    appCode?: string | null;
}
export interface SupporterEmailSummaryQueryArgs {
    appCode?: string | null;

    organizationId?: string | null;
}
export interface GetActionEmailSummaryForSupporterQueryArgs {
    actionId: string;

    organizationId?: string | null;
}
export interface GetEmailRecipientsForActionQueryArgs {
    input: ActionSummaryInput;
}
export interface GetEmailSummaryForActionQueryArgs {
    actionId: string;
}
export interface GetActionTextMessageSummaryForSupporterQueryArgs {
    actionId: string;

    skip?: number | null;

    limit?: number | null;

    organizationId?: string | null;
}
export interface GetEligibleSupporterCountForSendingPushNotificationQueryArgs {
    input: SupporterFilterForPushNotificationInput;
}
export interface GetEligibleSupporterCountForSendingEmailNotificationQueryArgs {
    input: SupporterEmailFilterInput;
}
export interface GetNumberOfSupportersWhoCanBeBlockedQueryArgs {
    input: BlockFilteredSupportersInput;
}
export interface GetOutreachCircleSharableLinkQueryArgs {
    outreachCircleId: string;

    outreachcircleCode?: string | null;
}
export interface IsSupportingOutreachCircleQueryArgs {
    input: IsSupportingOutreachCircleInput;
}
export interface GetRecentInviteSupporterMappingWithAllCustomFieldsQueryArgs {
    outreachCircleId: string;
}
export interface SupporterImpactDataQueryArgs {
    outreachCircleId: string;
}
export interface GetSurveyListQueryArgs {
    input: GetSurveyListInput;
}
export interface GetSurveyByIdQueryArgs {
    input: GetSurveyByIdInput;
}
export interface GetFilteredSurveyQuestionsQueryArgs {
    input: GetFilteredSurveyQuestionInput;
}
export interface GetSurveyQuestionByIdQueryArgs {
    input: GetSurveyQuestionByIdInput;
}
export interface GetAllTagsForOutreachCircleQueryArgs {
    outreachCircleId: string;
}
export interface GetTagsForPublicActionQueryArgs {
    actionId: string;

    outreachChannel?: OutreachChannel | null;
}
export interface GetTagInfoForActionQueryArgs {
    actionId: string;

    tagId: string;
}
export interface GetCannedResponsesForTagQueryArgs {
    tagId: string;
}
export interface GetTagsEligibleToBeLinkedToActionQueryArgs {
    actionId: string;
}
export interface TestDelayQueryArgs {
    message: string;

    delay: string;
}
export interface GetOriginalActionTextTemplateContentForSupporterQueryArgs {
    input: SupporterTextTemplateInput;
}
export interface TextTemplateQueryArgs {
    id: string;
}
export interface TextTemplatesByOutreachCircleQueryArgs {
    input: TextTemplateForOutreachCircleInput;
}
export interface TextTemplateByIdForOrganizationQueryArgs {
    id: string;
}
export interface TextTemplatesByOrganizationQueryArgs {
    organizationId: string;

    searchKey?: string | null;
}
export interface DefaultHtmlForRecruitmentLinkForOrgTextTemplateQueryArgs {
    textTemplateId: string;
}
export interface ValidateOrganizationTextTemplateNameQueryArgs {
    input?: ValidateOrganizationTextTemplateNameInput | null;
}
export interface SupporterTextTemplatesByOutreachCircleQueryArgs {
    outreachCircleId: string;

    actionId: string;

    searchKey?: string | null;
}
export interface DefaultEditHtmlForRecruitmentLinkForTextQueryArgs {
    outreachCircleId: string;

    outreachcircleCode?: string | null;
}
export interface GetTextContentForPreviewQueryArgs {
    input: GetTextContentForPreviewInput;
}
export interface ValidateTextTemplateNameQueryArgs {
    input?: ValidateTextTemplateNameInput | null;
}
export interface GetOrganizationTextContentForPreviewQueryArgs {
    input: GetOrganizationTextContentForPreviewInput;
}
export interface UsernameExistsQueryArgs {
    username: string;
}
export interface ValidateResetCodeQueryArgs {
    validateResetCodeInput: ValidateResetCodeInput;
}
export interface UserByTokenQueryArgs {
    token: string;
}
export interface ValidateTokenQueryArgs {
    token: string;
}
export interface ValidateTokenAndReturnUserIdQueryArgs {
    token: string;
}
export interface OutreachCirclesByLoggedInUserQueryArgs {
    input?: AllOutreachCirclesInput | null;
}
export interface ArchivedOutreachCirclesByLoggedInUserQueryArgs {
    skip?: number | null;

    limit?: number | null;
}
export interface SupportingOutreachCirclesByLoggedInUserQueryArgs {
    skip?: number | null;

    limit?: number | null;

    appCode?: string | null;

    organizationId?: string | null;
}
export interface VerifyNewSupporterEmailQueryArgs {
    email: string;

    alias?: string | null;
}
export interface VerifyNewSupporterEmailForEmbeddedActionQueryArgs {
    email: string;

    alias?: string | null;
}
export interface GetBookmarksQueryArgs {
    input: PaginationInput;
}
export interface IsAcquisitionTypeEditableAcquisitionTypeArgs {
    outreachCircleId?: string | null;
}
export interface IsAssignedToActionSupporterViewArgs {
    actionId: string;
}
export interface TurfAssignmentsSupporterViewArgs {
    actionId: string;
}
export interface IsAssignedToShiftSupporterViewArgs {
    shiftId: string;
}
export interface ParticipantTypeSupporterViewArgs {
    actionId: string;
}
export interface EmailTemplatesOutreachCircleArgs {
    actionId: string;
}
export interface TextTemplatesOutreachCircleArgs {
    actionId: string;
}
export interface SupporterTextTemplatesOutreachCircleArgs {
    actionId: string;
}
export interface TotalUniqueSupportersRespondedToFormsOutreachCircleArgs {
    dateFilter?: DateFilter | null;
}
export interface TotalUniqueRecipientsRespondedToSurveyOutreachCircleArgs {
    dateFilter?: DateFilter | null;
}
export interface TotalUniqueRecipientsRespondedToSearchAndSurveyOutreachCircleArgs {
    dateFilter?: DateFilter | null;
}
export interface TotalUniqueRecipientsRespondedToGroSurveyOutreachCircleArgs {
    dateFilter?: DateFilter | null;
}
export interface ContentForEditorEmailTemplateArgs {
    actionId: string;
}
export interface ContentForPreviewEmailTemplateArgs {
    actionId: string;
}
export interface ContentForEditorTextTemplateArgs {
    actionId?: string | null;
}
export interface ContentForPreviewTextTemplateArgs {
    actionId?: string | null;
}
export interface ContentForEditorSocialTemplateArgs {
    actionId?: string | null;
}
export interface ContentForPreviewSocialTemplateArgs {
    actionId?: string | null;
}
export interface SupporterSubmissionsCountSupporterFormArgs {
    dateFilter?: DateFilter | null;
}
export interface TotalUniqueSupportersRespondedSupporterFormQuestionArgs {
    dateFilter?: DateFilter | null;
}
export interface TotalUniqueSupportersRespondedSupporterFormOptionArgs {
    dateFilter?: DateFilter | null;
}
export interface RecipientSubmissionsCountEngagementArgs {
    dateFilter?: DateFilter | null;
}
export interface TotalUniqueRecipientsRespondedEngagementQuestionArgs {
    dateFilter?: DateFilter | null;
}
export interface TotalUniqueRecipientsRespondedEngagementOptionArgs {
    dateFilter?: DateFilter | null;
}
export interface LinkedCannedResponseTagArgs {
    actionId: string;
}
export interface TotalUniqueResponsesSearchAndSurveyFormArgs {
    dateFilter?: DateFilter | null;
}
export interface TotalUniqueResponsesSearchAndSurveyFormQuestionArgs {
    dateFilter?: DateFilter | null;
}
export interface TotalUniqueResponsesSearchAndSurveyFormOptionArgs {
    dateFilter?: DateFilter | null;
}
export interface TotalUniqueResponsesGroSurveyFormArgs {
    dateFilter?: DateFilter | null;
}
export interface TotalUniqueResponsesGroSurveyFormQuestionArgs {
    dateFilter?: DateFilter | null;
}
export interface TotalUniqueResponsesGroSurveyFormOptionArgs {
    dateFilter?: DateFilter | null;
}
export interface IsSurveyEditableSurveyArgs {
    outreachCircleId?: string | null;
}
export interface IsQuestionEditableSurveyQuestionArgs {
    outreachCircleId?: string | null;
}
export interface IsFlagEditableFlagArgs {
    outreachCircleId?: string | null;
}
export interface IsPurposeEditablePurposeArgs {
    outreachCircleId?: string | null;
}
export interface MemberCountTurfArgs {
    audienceActionId?: string | null;

    actionId?: string | null;
}
export interface HouseholdCountTurfArgs {
    audienceActionId?: string | null;

    actionId?: string | null;
}
export interface IsSupporterAssignedToShiftShiftArgs {
    supporterId: string;
}
export interface TotalUniqueSupportersRespondedToFormsOrganizationArgs {
    dateFilter?: DateFilter | null;

    organizationFilter?: OrganizationFilterInput | null;
}
export interface TotalUniqueRecipientsRespondedToSurveyOrganizationArgs {
    dateFilter?: DateFilter | null;

    organizationFilter?: OrganizationFilterInput | null;
}
export interface TotalUniqueRecipientsRespondedToSearchAndSurveyOrganizationArgs {
    dateFilter?: DateFilter | null;

    organizationFilter?: OrganizationFilterInput | null;
}
export interface TotalUniqueRecipientsRespondedToGroSurveyOrganizationArgs {
    dateFilter?: DateFilter | null;

    organizationFilter?: OrganizationFilterInput | null;
}
export interface IsRoleEditableRoleArgs {
    outreachCircleId: string;
}
export interface ContentForEditorEmailTemplateForSupporterArgs {
    actionId: string;
}
export interface ContentForPreviewEmailTemplateForSupporterArgs {
    actionId: string;
}
export interface ContentForSendingEmailTemplateForSupporterArgs {
    actionId: string;
}
export interface ContentForEditorTextTemplateForSupporterArgs {
    actionId?: string | null;
}
export interface ContentForPreviewTextTemplateForSupporterArgs {
    actionId?: string | null;
}
export interface ContentForSendingTextTemplateForSupporterArgs {
    actionId?: string | null;
}
export interface ActiveAudienceActionsAudienceInfoArgs {
    skip: number;

    limit: number;

    searchText?: string | null;
}
export interface InactiveAudienceActionsAudienceInfoArgs {
    skip: number;

    limit: number;
}
export interface ActionsPublicOutreachCircleArgs {
    skip?: number | null;

    limit?: number | null;

    filter?: ActionTypeFilterInput | null;
}
export interface LinkedActionIdPublicOutreachCircleArgs {
    alias: string;
}
export interface ContentForPreviewEmailTemplateForOutreachCircleUserArgs {
    organizationActionId?: string | null;
}
export interface ContentForEditorEmailTemplateForOutreachCircleUserArgs {
    organizationActionId?: string | null;
}
export interface ActionsPublicOutreachCircleSearchResultArgs {
    skip?: number | null;

    limit?: number | null;
}
export interface NextConversationIdP2PConversationArgs {
    showUnreadOnly?: boolean | null;
}
export interface PreviousConversationIdP2PConversationArgs {
    showUnreadOnly?: boolean | null;
}
export interface CreateAcquisitionTypeMutationArgs {
    input: CreateAcquisitionTypeInput;
}
export interface UpdateAcquisitionTypeMutationArgs {
    input: UpdateAcquisitionTypeInput;
}
export interface UpdateAcquisitionTypeStatusMutationArgs {
    input: UpdateAcquisitionTypeStatusInput;
}
export interface CopyAcquisitionTypesFromParentOutreachCircleMutationArgs {
    input: CopyAcquisitionTypesFromParentOutreachCircleInput;
}
export interface UpdateAcquisitionTypeForActionMutationArgs {
    input: UpdateAcquisitionTypeForActionInput;
}
export interface CreateActionViewFilterMutationArgs {
    input: CreateActionViewFilterStateInput;
}
export interface UpdateActionViewFilterMutationArgs {
    input: UpdateActionViewFilterStateInput;
}
export interface DeleteActionViewFilterMutationArgs {
    id: string;
}
export interface GetActionViewFilterStatesMutationArgs {
    input: GetActionViewFilterStateInput;
}
export interface UpdatePdiListForActionMutationArgs {
    input: UpdateListForActionInput;
}
export interface UpdateAudienceActionForActionMutationArgs {
    input: UpdateAudienceActionForActionInput;
}
export interface DeleteAllSupporterAssignmentsMutationArgs {
    actionId: string;
}
export interface MarkAssignedMemberAsContactedMutationArgs {
    input?: MarkAssignedMemberAsContactedInput | null;
}
export interface MarkAssignedContactAsContactedMutationArgs {
    input: MarkAssignedContactAsContactedInput;
}
export interface SaveGroSupporterEmailTemplateMutationArgs {
    input: SaveGroSupporterEmailTemplateInput;
}
export interface SaveGroSupporterTextTemplateMutationArgs {
    input: SaveGroSupporterTextTemplateInput;
}
export interface SaveGroSupporterCallTemplateMutationArgs {
    input: SaveGroSupporterCallOrVisitTemplateInput;
}
export interface SaveGroSupporterVisitTemplateMutationArgs {
    input: SaveGroSupporterCallOrVisitTemplateInput;
}
export interface UpdateGroMemberMutationArgs {
    input: UpdateGroMemberInput;
}
export interface AssignPublicActionToSupporterMutationArgs {
    actionId: string;
}
export interface AssignSupporterToActionMutationArgs {
    input: AssignSupporterToActionInput;
}
export interface DeleteActionAssignmentMutationArgs {
    input: AssignSupporterToActionInput;
}
export interface ArchiveActionMutationArgs {
    actionId: string;
}
export interface CreateActionMutationArgs {
    input: ActionInput;
}
export interface UpdateActionDatesMutationArgs {
    input?: ActionDatesUpdateInput | null;
}
export interface LinkEmailTemplateToActionMutationArgs {
    input?: ActionEmailTemplateInput | null;
}
export interface LinkTextTemplateToActionMutationArgs {
    input: ActionTextTemplateInput;
}
export interface LinkSocialTemplateToActionMutationArgs {
    socialTemplateId: string;

    actionId: string;
}
export interface UpdateActionTitleAndDescriptionMutationArgs {
    input?: UpdateActionTitleAndDescription | null;
}
export interface UpdateActionLinkMutationArgs {
    input?: UpdateActionLinkInput | null;
}
export interface ActivateActionMutationArgs {
    actionId: string;
}
export interface UpdateActionNameMutationArgs {
    input: UpdateActionNameInput;
}
export interface DuplicateActionMutationArgs {
    actionId: string;
}
export interface SendActionEmailMutationArgs {
    input?: SendActionEmailInput | null;
}
export interface SendActionPreviewEmailMutationArgs {
    actionId?: string | null;
}
export interface UpdateOutreachChannelsForActionMutationArgs {
    input: UpdateOutreachChannelsInput;
}
export interface UpdateAudienceActionsForActionMutationArgs {
    input: AudienceActionFilterUpdateInput;
}
export interface MarkActionAsCompletedMutationArgs {
    actionId: string;

    isSocialShare?: boolean | null;
}
export interface IncrementActionImpressionV1MutationArgs {
    input: ActionImpressionInput;
}
export interface IncrementActionImpressionMutationArgs {
    actionId: string;
}
export interface PersonalizeMessageCompletedForActionMutationArgs {
    input: ActionStatusUpdateInput;
}
export interface NuxCompletedForActionTagMutationArgs {
    input: ActionStatusUpdateInput;
}
export interface ImportContactsCompletedForActionMutationArgs {
    input: ActionStatusUpdateInput;
}
export interface ManageRecipientsCompletedForActionMutationArgs {
    input: ActionStatusUpdateInput;
}
export interface UpdateP2PnuxCompletedForActionMutationArgs {
    input: ActionStatusUpdateInput;
}
export interface UpdateCallToActionTextMutationArgs {
    input: UpdateCallToActionTextInput;
}
export interface UpdatePostActionTextMutationArgs {
    input: UpdatePostActionTextInput;
}
export interface UpdateActionOutreachModeMutationArgs {
    input: UpdateActionOutreachModeInput;
}
export interface UpdateAllowNonMappedContactsMutationArgs {
    input: UpdateAllowNonMappedContactsInput;
}
export interface LinkSurveyToActionMutationArgs {
    input: ActionSurveyLinkInput;
}
export interface UnlinkSurveyToActionMutationArgs {
    actionId: string;
}
export interface UpdateActionPositionMutationArgs {
    input: UpdateActionPositionInput;
}
export interface UpdateImageForActionMutationArgs {
    input: ActionImageInput;
}
export interface PauseActionMutationArgs {
    actionId: string;
}
export interface DeactivateActionMutationArgs {
    actionId: string;
}
export interface UpdateIsEngagementRequiredMutationArgs {
    input?: UpdateIsEngagementRequiredInput | null;
}
export interface MarkAffinityTextContactAsSentMutationArgs {
    input: GetTextContentForAffinityTextingInput;
}
export interface MarkTextSentToSupporterMutationArgs {
    input: TextSentToSupporterInput;
}
export interface UpdateTreatmentForActionMutationArgs {
    input: UpdateTreatmentForActionInput;
}
export interface UpdateContactsImportPermissionForSupporterMutationArgs {
    input: UpdateContactImportPermissionInput;
}
export interface AcknowledgeContactImportForActionMutationArgs {
    actionId?: string | null;
}
export interface SetCampaignForActionMutationArgs {
    input: ActionCampaignInput;
}
export interface UpdateShareActionButtonTextMutationArgs {
    input: UpdateShareActionButtonTextInput;
}
export interface RemoveActionReviewWarningMutationArgs {
    input: RemoveReviewWarningInput;
}
export interface AssignSupportersToActionMutationArgs {
    input: AssignSupportersToActionInput;
}
export interface AssignSupportersFromInvitedSupporterSourceToActionMutationArgs {
    actionId: string;

    invitedSupporterSourceId: string;
}
export interface UnassignSupporterFromActionMutationArgs {
    actionId: string;

    supporterId: string;
}
export interface TerminateActionMutationArgs {
    actionId: string;
}
export interface AcknowledgeEstimatedCostForP2PActionMutationArgs {
    actionId: string;
}
export interface AssignSupportersFromGroupToActionMutationArgs {
    input: AssignSupportersFromGroupToActionInput;
}
export interface UpdateSearchAndSurveyAudienceActionsForActionMutationArgs {
    input: SearchAndSurveyAudienceActionFilterUpdateInput;
}
export interface UpdateSearchAndSurveyNewContactsListForActionMutationArgs {
    input: UpdateSearchAndSurveyNewContactsListForActionInput;
}
export interface AddCustomFieldForSearchAndSurveyActionMutationArgs {
    input?: CustomFieldForSearchAndSurveyActionInput | null;
}
export interface RemoveCustomFieldForSearchAndSurveyActionMutationArgs {
    input?: CustomFieldForSearchAndSurveyActionInput | null;
}
export interface UpdateActionConfirmationMutationArgs {
    input: UpdateActionConfirmationInput;
}
export interface UpdateCallOrVisitTemplateMutationArgs {
    input: UpdateCallOrVisitTemplate;
}
export interface RemoveTemplateFromActionForOutreachChannelMutationArgs {
    input: RemoveTemplateFromActionForOutreachChannelInput;
}
export interface SendEmailNotificationForActionMutationArgs {
    input?: SendEmailNotificationForActionInput | null;
}
export interface SendPushNotificationForActionMutationArgs {
    input?: SendPushNotificationForActionInput | null;
}
export interface ReassignUnassignedSupporterForP2PActionMutationArgs {
    actionId: string;

    supporterId: string;
}
export interface ResetReplyReassignmentForP2PActionMutationArgs {
    actionId: string;

    supporterId: string;
}
export interface AssignGroupToActionMutationArgs {
    input: AssignGroupToActionInput;
}
export interface UpdateGroConfigMutationArgs {
    input: UpdateGroConfigInput;
}
export interface UpdateAddressBasedCanvassingConfigMutationArgs {
    input: UpdateAddressBasedCanvassingConfigInput;
}
export interface UpdatePdiOnTheGoCanvassingConfigMutationArgs {
    input: UpdatePdiOnTheGoCanvassingConfigInput;
}
export interface UpdateContactTypeLabelMutationArgs {
    input: UpdateContactTypeLabelInput;
}
export interface CreateMemberNoteMutationArgs {
    input: CreateMemberNoteInput;
}
export interface UpdateSurveyForActionMutationArgs {
    input: UpdateSurveyForActionInput;
}
export interface RemoveSurveyFromActionMutationArgs {
    input: RemoveSurveyFromActionInput;
}
export interface UpdateSurveyQuestionForActionMutationArgs {
    input: UpdateSurveyQuestionForActionInput;
}
export interface UpdateMemberDisplayFieldsMutationArgs {
    input: UpdateMemberDisplayFieldsInput;
}
export interface UpdateActionVisibilityMutationArgs {
    input: UpdateActionVisibilityInput;
}
export interface UpdateVolunteerAttendeesStatusMutationArgs {
    input: UpdateVolunteerAttendeesAttendanceInput;
}
export interface UpdateActionAliasLinkMutationArgs {
    input?: UpdateActionAliasLinkInput | null;
}
export interface UpdateP2PConfigForActionMutationArgs {
    input: UpdateP2PConfigForActionInput;
}
export interface PurchasePhoneNumberForP2PActionMutationArgs {
    input: PurchasePhoneNumberForP2PActionInput;
}
export interface UpdateP2PActionAvailableTimingsMutationArgs {
    input: P2PScheduleInput;
}
export interface UpdateActionDialerTypeMutationArgs {
    input: UpdateActionDialerTypeInput;
}
export interface ConnectToPhoneBankMutationArgs {
    input: ConnectToPhoneBankInput;
}
export interface CallPhoneBankMemberMutationArgs {
    input?: CallPhoneBankMemberInput | null;
}
export interface WrapAndDialNextPhoneCallMutationArgs {
    actionId: string;
}
export interface HangupCallMutationArgs {
    input: HangupCallInput;
}
export interface ToggleMuteForCallMutationArgs {
    input: ToggleMuteForCallInput;
}
export interface UpdatePhoneBankMemberDetailsMutationArgs {
    input: UpdatePhoneBankMemberDetailsInput;
}
export interface UpdateIsAnonymousSearchAndSurveyForActionMutationArgs {
    input: UpdateIsAnonymousSearchAndSurveyForActionInput;
}
export interface PurchasePhoneNumberForTextingActionMutationArgs {
    input: PurchasePhoneNumberForP2PActionInput;
}
export interface UpdateTextingConfigForActionMutationArgs {
    input: UpdateTextingConfigForActionInput;
}
export interface UpdateInitialTextingScriptMutationArgs {
    input: UpdateInitialTextingScriptInput;
}
export interface SendBulkInitialTextsMutationArgs {
    input: SendBulkInitialTextsInput;
}
export interface UnAssignVolunteerFromTextingConversationMutationArgs {
    input: UnAssignVolunteerFromTextingConversationInput;
}
export interface TransferTextingConversationToVolunteerMutationArgs {
    input: TransferTextingConversationToVolunteerInput;
}
export interface UpdateTextingConversationTransferToVolunteerMutationArgs {
    input: UpdateTextingConversationTransferToVolunteerInput;
}
export interface CreateRecipientTextingConversationItemMutationArgs {
    input: CreateRecipientTextingConversationItemInput;
}
export interface UnAssignTextingConversationMutationArgs {
    id: string;
}
export interface VolunteerEntersTextingConversationMutationArgs {
    id: string;
}
export interface VolunteerLeavesTextingConversationMutationArgs {
    id: string;
}
export interface AddMemberForAddressBasedCanvassingMutationArgs {
    input: CreateSearchAndSurveyContactInput;
}
export interface AddMemberToHouseHoldMutationArgs {
    input: AddMemberToHouseHoldInput;
}
export interface RecordTagForHouseholdMutationArgs {
    input: RecordTagForHouseholdInput;
}
export interface RecordTagForSearchAndSurveyMemberMutationArgs {
    input: RecordTagForSearchAndSurveyMemberInput;
}
export interface ResolveAmbiguousHouseholdMemberMutationArgs {
    input: ResolveAmbiguousHouseholdMemberInput;
}
export interface RetrySyncForHouseholdMembersMutationArgs {
    input: RetrySyncForHouseholdMembersInput;
}
export interface CreateSearchAndSurveyListMutationArgs {
    input: CreateSearchAndSurveyListInput;
}
export interface DeleteAudienceActionMutationArgs {
    audienceActionId: string;
}
export interface ImportPdiListMutationArgs {
    input: ImportPdiListInput;
}
export interface ImportProjectListFromFileMutationArgs {
    input: ImportProjectListFromFileInput;
}
export interface AddMembersMutationArgs {
    input?: AudienceActionInput | null;
}
export interface UpdateMembersMutationArgs {
    input?: AudienceActionInput | null;
}
export interface DeleteMembersMutationArgs {
    input?: AudienceActionInput | null;
}
export interface CreateAudienceMutationArgs {
    outreachCircleId?: string | null;
}
export interface DeleteAudienceByListTypeMutationArgs {
    input: DeleteAudienceByListTypeInput;
}
export interface BlockAudienceMemberMutationArgs {
    input?: BlockMemberInput | null;
}
export interface UnBlockAudienceMemberMutationArgs {
    input?: BlockMemberInput | null;
}
export interface UpdateAudienceActionMutationArgs {
    input: UpdateAudienceActionInput;
}
export interface CreateSearchAndSurveyContactMutationArgs {
    input: CreateSearchAndSurveyContactInput;
}
export interface AddMembersFromBlackbaudImportMutationArgs {
    input: ActionOnMembersFromBlackbaudImportInput;
}
export interface UpdateMembersFromBlackbaudImportMutationArgs {
    input: ActionOnMembersFromBlackbaudImportInput;
}
export interface DeleteMembersFromBlackbaudImportMutationArgs {
    input: ActionOnMembersFromBlackbaudImportInput;
}
export interface SetEmailRecipientV1MutationArgs {
    input: SetEmailRecipientInput[];
}
export interface SetEmailRecipientMutationArgs {
    input: SetEmailRecipientInput[];
}
export interface SetAllEmailRecipientsMutationArgs {
    input: SupporterActionInput;
}
export interface UnsetAllEmailRecipientsMutationArgs {
    input: SupporterActionInput;
}
export interface ResolveAmbiguousMappedContactMutationArgs {
    input?: ResolveAmbiguousMappedContactInput | null;
}
export interface CreateMatchingJobMutationArgs {
    input: CreateMatchingJobInput;
}
export interface StartMatchingJobMutationArgs {
    matchingJobId: string;
}
export interface MarkTextRecipientAsSentMutationArgs {
    input: TextRecipientInput;
}
export interface ToggleEligibleContactAsEmailRecipientMutationArgs {
    input: MarkEligibleContactAsEmailRecipientInput;
}
export interface ToggleA1CRecipientMappedMutationArgs {
    input: ToggleA1CRecipientMappedInput;
}
export interface ToggleAllA1CRecipientMappedMutationArgs {
    input?: ToggleAllA1CRecipientInput | null;
}
export interface ToggleA1CRecipientNonMappedMutationArgs {
    input: ToggleA1CRecipientNonMappedInput;
}
export interface ToggleAllA1CRecipientNonMappedMutationArgs {
    input?: ToggleAllA1CRecipientInput | null;
}
export interface ToggleAmbiguousA1CRecipientMutationArgs {
    input: ToggleAmbiguousA1CRecipientInput;
}
export interface DeleteMappedContactMutationArgs {
    input: DeleteMappedContactInput;
}
export interface ContactUsMutationArgs {
    input?: ContactUsInput | null;
}
export interface ImportContactFileMutationArgs {
    input: ContactFileImportInput;
}
export interface DeleteContactSourceMutationArgs {
    contactSourceId: string;
}
export interface CreateContactMutationArgs {
    input: CreateContactInput;
}
export interface CreateContactWithMatchingMutationArgs {
    input: CreateContactInput;
}
export interface UpdateContactMutationArgs {
    input: UpdateContactInput;
}
export interface UpdateContactWithMatchingMutationArgs {
    input: UpdateContactInput;
}
export interface UpdatePrimaryEmailForContactMutationArgs {
    input: UpdatePrimaryEmailInput;
}
export interface DeleteContactMutationArgs {
    id: string;
}
export interface ResolveDuplicateEmailContactMutationArgs {
    input: ResolveDuplicateEmailContactInput;
}
export interface MarkOrganizationUpdateAsCompletedMutationArgs {
    id: string;
}
export interface UpdatePdiListStatusMutationArgs {
    input: UpdatePdiListStatusInput;
}
export interface CreateTurfGroupMutationArgs {
    input: CreateTurfGroupInput;
}
export interface UpdateTurfGroupMutationArgs {
    input: UpdateTurfGroupInput;
}
export interface AddTurfToTurfGroupMutationArgs {
    input: AddTurfToTurfGroupInput;
}
export interface RemoveTurfFromTurfGroupMutationArgs {
    turfIds: string[];
}
export interface DeleteTurfGroupMutationArgs {
    turfGroupId: string;
}
export interface AssignSupportersToTurfMutationArgs {
    input: AssignSupportersToTurfInput;
}
export interface RemoveSupportersFromTurfMutationArgs {
    input: RemoveSupportersFromTurfInput;
}
export interface CopyTurfForActionMutationArgs {
    input: CopyTurfForActionInput;
}
export interface SubmitSurveyResponseForHouseholdMutationArgs {
    input: SubmitSurveyResponseForHouseholdInput;
}
export interface DeleteTurfsMutationArgs {
    turfIds: string[];
}
export interface CreateTurfAssignmentMutationArgs {
    input: CreateTurfAssignmentInput;
}
export interface MarkSurveyAsCompleteMutationArgs {
    input: MarkSurveyAsCompleteInput;
}
export interface ExtendTurfAssignmentMutationArgs {
    turfAssignmentId: string;
}
export interface ExtendTurfExpiryMutationArgs {
    turfId: string;
}
export interface ReleaseTurfAssignmentMutationArgs {
    turfAssignmentId: string;
}
export interface StoreTurfAssignmentInFirebaseMutationArgs {
    turfAssignmentId: string;
}
export interface EditVoterContactDetailsMutationArgs {
    input: EditVoterContactDetailsInput;
}
export interface ShareTurfAssignmentMutationArgs {
    turfAssignmentId: string;
}
export interface ShareTurfAssignmentForSupportersMutationArgs {
    input?: ShareTurfAssignmentInput | null;
}
export interface CreateCanvassingMemberMutationArgs {
    input: CreateCanvassingMemberInput;
}
export interface CreateCanvassingHouseholdMutationArgs {
    input: CreateCanvassingHouseholdInput;
}
export interface ApplyNotHomeOnHouseholdMutationArgs {
    input: ApplyNotHomeOnHouseholdInput;
}
export interface CreateCustomTurfMutationArgs {
    input: CreateCustomTurfInput;
}
export interface UpdateTurfMutationArgs {
    input: UpdateTurfInput;
}
export interface SubmitCanvassingSurveyResponseMutationArgs {
    input: SubmitCanvassingSurveyResponseInput;
}
export interface ApproveCanvassedSurveyResponseForMemberMutationArgs {
    input?: ApproveOrRejectCanvassedSurveyResponseForMemberInput | null;
}
export interface RejectCanvassedSurveyResponseForMemberMutationArgs {
    input?: ApproveOrRejectCanvassedSurveyResponseForMemberInput | null;
}
export interface ApproveCanvassedSurveyResponsesBySupportersMutationArgs {
    supporterIds?: string[] | null;
}
export interface RejectCanvassedSurveyResponsesBySupportersMutationArgs {
    supporterIds?: string[] | null;
}
export interface ApproveCanvassedSurveyResponsesByTurfsMutationArgs {
    turfIds?: string[] | null;
}
export interface RejectCanvassedSurveyResponsesByTurfsMutationArgs {
    turfIds?: string[] | null;
}
export interface ApproveOrRejectAllCanvassedSurveyResponsesMutationArgs {
    input: ApproveOrRejectAllCanvassedSurveyResponsesInput;
}
export interface ImportPdiListForCanvassingMutationArgs {
    input: ImportPdiListForCanvassingInput;
}
export interface ImportAudienceActionForCanvassingVoterDensityMutationArgs {
    audienceActionId: string;
}
export interface CreateEmailTemplateMutationArgs {
    input: CreateEmailTemplateInput;
}
export interface DuplicateEmailTemplateMutationArgs {
    input: DuplicateEmailTemplateInput;
}
export interface UpdateEmailTemplateMutationArgs {
    input: UpdateEmailTemplateInput;
}
export interface UpdateEmailTemplateForSupporterMutationArgs {
    input: UpdateEmailTemplateForSupporterInput;
}
export interface SendEditedEmailPreviewMutationArgs {
    input: EditedEmailPreviewInput;
}
export interface SendPreviewEmailForSupporterMutationArgs {
    actionId: string;
}
export interface CreateOrganizationEmailTemplateMutationArgs {
    input: CreateOrganizationEmailTemplateInput;
}
export interface UpdateOrganizationEmailTemplateMutationArgs {
    input: UpdateOrganizationEmailTemplateInput;
}
export interface UpdateEmailTemplateForOrgWithRecruitmentLinkMutationArgs {
    input: UpdateEmailTemplateForOrgWithRecruitmentLinkInput;
}
export interface SendEditedOrganizationTemplateEmailPreviewMutationArgs {
    input: EditedOrganizationTemplateEmailPreviewInput;
}
export interface CreateSurveyEngagementMutationArgs {
    surveyEngagementInput: SurveyEngagementInput;
}
export interface UpdateSurveyEngagementMutationArgs {
    updateSurveyEngagementInput: SurveyEngagementInput;
}
export interface DeleteEngagementsMutationArgs {
    engagementIds: string[];
}
export interface DeleteSurveyMutationArgs {
    surveyId: string;
}
export interface SaveSurveyResponseMutationArgs {
    input: SaveSurveyResponseInput;
}
export interface DuplicateSurveyMutationArgs {
    input: DuplicateSurveyEngagementInput;
}
export interface AddActivityMutationArgs {
    input: AddActivityInput;
}
export interface CreateEventConfigMutationArgs {
    input: CreateEventConfigInput;
}
export interface UpdateEventConfigMutationArgs {
    input: UpdateEventConfigInput;
}
export interface PublishEventConfigMutationArgs {
    eventConfigId: string;
}
export interface TerminateEventConfigMutationArgs {
    eventConfigId: string;
}
export interface UpdateEventConfigImageMutationArgs {
    input: EventConfigImageInput;
}
export interface UpdateActionEventInformationMutationArgs {
    input: UpdateActionEventInformationInput;
}
export interface UpdateActionEventScheduleMutationArgs {
    input: UpdateActionEventScheduleInput;
}
export interface UpdateActionEventLocationVisibilityMutationArgs {
    input: UpdateEventLocationVisibilityInput;
}
export interface UpdateActionEventParticipationMutationArgs {
    input: UpdateActionEventParticipationInput;
}
export interface NotifyAssignedSupportersViaEmailForEventMutationArgs {
    input: NotifyAssignedSupportersViaEmailForEventInput;
}
export interface UpdateShiftAssignmentAvailabilityMutationArgs {
    input: UpdateShiftAssignmentAvailabilityInput;
}
export interface TerminateEventSeriesMutationArgs {
    eventSeriesId: string;
}
export interface UpdateEventSeriesMutationArgs {
    input: UpdateEventSeriesInput;
}
export interface UpdateEventSeriesImageMutationArgs {
    input: EventSeriesImageInput;
}
export interface AddShiftSeriesMutationArgs {
    input: AddShiftSeriesInput;
}
export interface UpdateShiftSeriesMutationArgs {
    input?: UpdateShiftSeriesInput | null;
}
export interface DeleteShiftSeriesMutationArgs {
    shiftSeriesId: string;
}
export interface AssignTurfGroupsToShiftSeriesMutationArgs {
    input: AssignTurfGroupToShiftSeriesInput;
}
export interface AddShiftMutationArgs {
    input: AddShiftInput;
}
export interface UpdateShiftMutationArgs {
    input?: UpdateShiftInput | null;
}
export interface DeleteShiftMutationArgs {
    shiftId: string;
}
export interface AssignTurfGroupsToShiftMutationArgs {
    input: AssignTurfGroupToShiftInput;
}
export interface AssignActionsToShiftMutationArgs {
    input: AssignActionsToShiftInput;
}
export interface UpdateAttendanceStatusForShiftMutationArgs {
    input?: UpdateAttendanceStatusForShiftInput | null;
}
export interface RegisterForShiftMutationArgs {
    shiftId: string;
}
export interface SaveAttendanceStatusForSupporterMutationArgs {
    input?: SaveAttendanceStatusForSupporterInput | null;
}
export interface UpdateAttendanceStatusForShiftForEmbedMutationArgs {
    input: UpdateAttendanceStatusForShiftEmbedInput;
}
export interface UpdateShiftAliasLinkMutationArgs {
    input: UpdateShiftAliasLinkInput;
}
export interface CreateFlagMutationArgs {
    input: CreateFlagInput;
}
export interface UpdateFlagMutationArgs {
    input: UpdateFlagInput;
}
export interface UpdateFlagStatusByIdMutationArgs {
    input: UpdateFlagStatusInput;
}
export interface CopyFlagsFromParentOutreachCircleMutationArgs {
    input: CopyFlagsFromParentOutreachCircleInput;
}
export interface AddFlagsToAliasMutationArgs {
    input: AddFlagsToAliasInput;
}
export interface RemoveFlagsFromAliasMutationArgs {
    input: RemoveFlagsFromAliasInput;
}
export interface UpdateGroSurveyFormMutationArgs {
    input?: UpdateGroSurveyFormInput | null;
}
export interface CreateGroSurveyFormMutationArgs {
    input: CreateGroSurveyFormInput;
}
export interface LinkGroSurveyFormToActionMutationArgs {
    input: GroSurveyFormActionInput;
}
export interface UnlinkGroSurveyFormFromActionMutationArgs {
    actionId: string;
}
export interface DuplicateGroSurveyFormMutationArgs {
    input: DuplicateGroSurveyFormInput;
}
export interface SubmitGroSurveyFormQuestionResponseMutationArgs {
    input: SubmitGroSurveyFormQuestionResponseInput;
}
export interface SubmitGroSurveyFormQuestionResponseForContactMutationArgs {
    input: SubmitGroSurveyFormQuestionResponseForContactInput;
}
export interface DeleteGroSurveyFormMutationArgs {
    groSurveyFormId: string;
}
export interface CreateGroupMutationArgs {
    input: CreateGroupInput;
}
export interface UpdateGroupMutationArgs {
    input: UpdateGroupInput;
}
export interface DeleteGroupMutationArgs {
    id: string;
}
export interface AssignActionToGroupMutationArgs {
    input: AssignActionToGroupInput;
}
export interface AssignAliasToGroupMutationArgs {
    input: AssignAliasToGroupInput;
}
export interface UnAssignActionFromGroupMutationArgs {
    input: UnAssignActionFromGroupInput;
}
export interface UnAssignAliasFromGroupMutationArgs {
    input: UnAssignAliasForGroupInput;
}
export interface AssignSupportersToGroupsMutationArgs {
    input: AssignSupportersToGroupsInput;
}
export interface UnAssignSupporterFromGroupMutationArgs {
    input: UnAssignSupporterFromGroupInput;
}
export interface CreateOrUpdateFacebookPixelIntegrationMutationArgs {
    input: FacebookPixelIntegrationInput;
}
export interface EnablePhone2ActionIntegrationMutationArgs {
    outreachCircleId: string;
}
export interface EnableEveryActionIntegrationMutationArgs {
    outreachCircleId: string;
}
export interface CreateOrUpdateNationBuilderIntegrationMutationArgs {
    input: CreateOrUpdateNationBuilderIntegrationInput;
}
export interface UpdateSyncSupportersToNationBuilderMutationArgs {
    outreachCircleId: string;

    sync: boolean;
}
export interface AddOrUpdateNationBuilderTagsForAliasMutationArgs {
    input?: AddOrUpdateNationBuilderTagsForAliasInput | null;
}
export interface SaveNgpConfigurationMutationArgs {
    input: SaveNgpIntegrationInput;
}
export interface AddMembersFromNgpMutationArgs {
    input: ActionOnNgpMembersInput;
}
export interface UpdateMembersFromNgpMutationArgs {
    input: ActionOnNgpMembersInput;
}
export interface DeleteMembersFromNgpMutationArgs {
    input: ActionOnNgpMembersInput;
}
export interface UpdateSyncSupportersToNgpMyCampaignMutationArgs {
    outreachCircleId: string;

    sync: boolean;
}
export interface AddNgpActivistCodesForAliasMutationArgs {
    input?: AddNgpActivistCodesForAliasInput | null;
}
export interface RemoveNgpActivistCodesForAliasMutationArgs {
    input?: RemoveNgpActivistCodesForAliasInput | null;
}
export interface UpdateApplyToAllCustomAliasesForNgpActivistCodeMutationArgs {
    input?: UpdateApplyToAllCustomAliasesForNgpActivistCodeInput | null;
}
export interface AddPdiMemberMutationArgs {
    input: CreateSearchAndSurveyContactInput;
}
export interface AssignAreaInPdiAssignmentZoneMutationArgs {
    input: AssignPdiZoneInput;
}
export interface ReleasePdiAssignmentMutationArgs {
    actionId: string;
}
export interface CreateOrUpdatePdiIntegrationMutationArgs {
    input: CreateOrUpdatePdiIntegrationInput;
}
export interface UpdateSyncSupportersToPdiMutationArgs {
    outreachCircleId: string;

    sync: boolean;
}
export interface AddPdiFlagsForAliasMutationArgs {
    input?: AddPdiFlagIdForAliasInput | null;
}
export interface RemovePdiFlagsForAliasMutationArgs {
    input?: RemovePdiFlagIdForAliasInput | null;
}
export interface UpdateApplyPdiFlagsGloballyMutationArgs {
    input: UpdateApplyPdiFlagsGloballyInput;
}
export interface RegisterTcrBrandMutationArgs {
    input: RegisterTcrBrandInput;
}
export interface CreateTcrBrandPoliticalVettingMutationArgs {
    input: CreateTcrBrandPoliticalVettingInput;
}
export interface RegisterTcrCampaignMutationArgs {
    input: RegisterTcrCampaignInput;
}
export interface PresignedLogoUploadRequestMutationArgs {
    input?: MediaInputType | null;
}
export interface PresignedOrgLogoUploadRequestMutationArgs {
    input?: OrgMediaInputType | null;
}
export interface PresignedCandidateImageUploadRequestMutationArgs {
    input?: MediaInputType | null;
}
export interface PresignedP2PScriptMediaUploadRequestMutationArgs {
    input?: PresignedP2PScriptMediaUploadRequestInput | null;
}
export interface PresignedSocialImageUploadRequestMutationArgs {
    input?: MediaInputType | null;
}
export interface PresignedActionImageUploadRequestMutationArgs {
    input?: MediaInputType | null;
}
export interface PresignedOrganizationActionImageUploadRequestMutationArgs {
    input?: OrganizationActionMediaInputType | null;
}
export interface PresignedEditorImageUploadRequestMutationArgs {
    input?: EditorMediaInputType | null;
}
export interface PresignedOrgEditorImageUploadRequestMutationArgs {
    input?: OrgEditorMediaInputType | null;
}
export interface PresignedTextingScriptMediaUploadRequestMutationArgs {
    input?: PresignedTextingScriptMediaUploadRequestInput | null;
}
export interface PresignedEventConfigImageUploadRequestMutationArgs {
    input: EventConfigMediaInputType;
}
export interface PresignedEventSeriesImageUploadRequestMutationArgs {
    input: EventSeriesMediaInputType;
}
export interface PresignedUserProfileImageUploadRequestMutationArgs {
    input: UserProfileImageMediaInputType;
}
export interface PresignedPushNotificationImageUploadRequestMutationArgs {
    input: PushNotificationImageMediaInputType;
}
export interface CreateOrganizationActionMutationArgs {
    input: OrganizationActionInput;
}
export interface UpdateOrganizationActionNameMutationArgs {
    input: UpdateOrganizationActionNameInput;
}
export interface UpdateOrganizationActionLinkMutationArgs {
    input?: UpdateOrganizationActionLinkInput | null;
}
export interface UpdateOrganizationActionTitleAndDescriptionMutationArgs {
    input?: UpdateOrganizationActionTitleAndDescriptionInput | null;
}
export interface UpdateCallToActionTextForOrganizationActionMutationArgs {
    input: UpdateCallToActionTextForOrganizationActionInput;
}
export interface UpdateShareActionButtonTextForOrganizationActionMutationArgs {
    input: UpdateShareActionButtonTextForOrgActionInput;
}
export interface UpdatePostActionTextForOrganizationActionMutationArgs {
    input: UpdatePostActionTextForOrganizationActionInput;
}
export interface UpdateImageForOrganizationActionMutationArgs {
    input: ActionImageInput;
}
export interface DuplicateOrganizationActionMutationArgs {
    organizationActionId: string;
}
export interface LinkEmailTemplateToOrganizationActionMutationArgs {
    input?: OrganizationActionEmailTemplateInput | null;
}
export interface UnlinkEmailTemplateFromOrganizationActionMutationArgs {
    input?: OrganizationActionEmailTemplateInput | null;
}
export interface LinkTextTemplateToOrganizationActionMutationArgs {
    input: OrganizationActionTextTemplateInput;
}
export interface UnlinkTextTemplateFromOrganizationActionMutationArgs {
    organizationActionId: string;
}
export interface LinkSocialTemplateToOrganizationActionMutationArgs {
    organizationSocialTemplateId: string;

    organizationActionId: string;
}
export interface UnlinkSocialTemplateFromOrganizationActionMutationArgs {
    organizationSocialTemplateId: string;

    organizationActionId: string;
}
export interface PublishOrganizationActionMutationArgs {
    input: PublishOrganizationActionInput;
}
export interface TerminateOrganizationActionMutationArgs {
    organizationActionId: string;
}
export interface UpdateOrganizationActionAllowNonMappedContactsMutationArgs {
    input: UpdateOrganizationActionAllowNonMappedContactsInput;
}
export interface HideOrganizationActionMutationArgs {
    organizationActionId: string;
}
export interface UpdateOutreachChannelsForOrganizationActionMutationArgs {
    input: UpdateOrganizationActionOutreachChannelsInput;
}
export interface UpdateOrganizationActionIntegrationTypeMutationArgs {
    input: UpdateOrganizationActionIntegrationTypeInput;
}
export interface UpdateOrganizationActionConfirmationMutationArgs {
    input: UpdateOrganizationActionConfirmationInput;
}
export interface InviteOrganizationUserMutationArgs {
    input?: InviteOrganizationUserInput | null;
}
export interface DeleteOrganizationUserMutationArgs {
    organizationUserId: string;
}
export interface ResendInviteToOrganizationUserMutationArgs {
    input: ResendInviteToOrgUserInput;
}
export interface OnboardOrganizationUserMutationArgs {
    organizationUserId: string;
}
export interface UpdateOrganizationUserMutationArgs {
    input: UpdateOrganizationUserInput;
}
export interface UpdateReadOnlyAccessToOutreachCircleForOrgUserMutationArgs {
    input: UpdateOcAccessForOrgUserInput;
}
export interface UpdateUiPreferenceForOrganizationUserMutationArgs {
    input: UpdateOrganizationUserUiPreferenceInput;
}
export interface CreateOrganizationMutationArgs {
    name: string;
}
export interface UpdateOrganizationMutationArgs {
    input: UpdateOrganizationInput;
}
export interface SaveOrganizationDashboardFilterMutationArgs {
    input: OrganizationDashboardFilterInput;
}
export interface UpdateOrganizationDashboardFilterMutationArgs {
    input: UpdateOrganizationDashboardFilterInput;
}
export interface DeleteOrganizationDashboardFilterMutationArgs {
    organizationFilterId: string;
}
export interface SetMainOutreachCircleForOrganizationMutationArgs {
    input: MainOutreachCircleInput;
}
export interface SetIntroductionForOrganizationMutationArgs {
    input: OrgIntroductionInput;
}
export interface AddOrganizationLogoMutationArgs {
    input: OrgLogoInput;
}
export interface UpdateOrganizationLogoMutationArgs {
    input: OrgLogoInput;
}
export interface DeleteOrganizationLogoMutationArgs {
    organizationId: string;
}
export interface UpdateDisclaimerForOrganizationMutationArgs {
    input: OrgDisclaimerInput;
}
export interface UpdateOrganizationAliasMutationArgs {
    input: UpdateOrganizationAliasInput;
}
export interface CreateOutreachCircleUserEmailTemplateMutationArgs {
    input: CreateOutreachCircleUserEmailTemplateInput;
}
export interface UpdateOutreachCircleUserEmailTemplateMutationArgs {
    input: UpdateOutreachCircleUserEmailTemplateInput;
}
export interface DuplicateOutreachCircleUserEmailTemplateMutationArgs {
    input: DuplicateOutreachCircleUserEmailTemplateInput;
}
export interface NotifyOutreachCircleAdminsForOrganizationActionMutationArgs {
    input: NotifyOutreachCircleAdminsForOrganizationActionInput;
}
export interface NotifyOutreachCircleAdminsForOrganizationMutationArgs {
    input: NotifyOutreachCircleAdminsForOrganizationInput;
}
export interface CreateOutreachCircleRoleMutationArgs {
    input: CreateOutreachCircleRoleInput;
}
export interface UpdateOutreachCircleRoleMutationArgs {
    input: UpdateOutreachCircleRoleInput;
}
export interface InviteOutreachCircleUserMutationArgs {
    input: OutreachCircleUserInput;
}
export interface UpdateOutreachCircleUserMutationArgs {
    input: UpdateOutreachCircleUserInput;
}
export interface DeleteOutreachCircleUserMutationArgs {
    outreachCircleUserId: string;
}
export interface ResendInviteMutationArgs {
    outreachCircleUserId: string;
}
export interface OnboardOutreachCircleUserMutationArgs {
    outreachCircleUserId: string;
}
export interface UnsubcribeEmailByOutreachCircleAdminMutationArgs {
    input: OutreachCircleAdminEmailUnsubscribeInput;
}
export interface EnableOutreachCircleUsersMfaMutationArgs {
    input: ToggleMfaInput;
}
export interface DisableOutreachCircleUsersMfaMutationArgs {
    input: ToggleMfaInput;
}
export interface DeactivateOutreachCircleUsersMutationArgs {
    input: ToggleUserInput;
}
export interface ReactivateOutreachCircleUsersMutationArgs {
    input: ToggleUserInput;
}
export interface UpdateWorkingGeographiesForOutreachCircleMutationArgs {
    input?: UpdateWorkingGeographiesForOutreachCircleInput | null;
}
export interface CreateOutreachCircleMutationArgs {
    outreachCircleInput: OutreachCircleInput;
}
export interface UpdateOutreachCircleBasicInfoMutationArgs {
    outreachCircleInput: UpdateOutreachCircleBasicInfoInput;
}
export interface UpdateOutreachCircleContactAndLocationMutationArgs {
    outreachCircleInput: UpdateOutreachCircleContactAndLocationInput;
}
export interface UpdateOutreachCircleTimezoneMutationArgs {
    outreachCircleId: string;

    timezone: string;
}
export interface UpdateOutreachCircleUserRoleMutationArgs {
    outreachCircleId?: string | null;

    userRole?: string | null;
}
export interface AddLogoForDefaultAliasOfOutreachCircleMutationArgs {
    input?: OutreachCircleLogoInput | null;
}
export interface UpdateLogoForDefaultAliasOfOutreachCircleMutationArgs {
    input?: OutreachCircleLogoInput | null;
}
export interface DeleteLogoForDefaultAliasOfOutreachCircleMutationArgs {
    outreachCircleId: string;
}
export interface UpdateBlockedDomainsMutationArgs {
    input?: BlockedDomainsInput | null;
}
export interface UpdateDisclaimerForOutreachCircleMutationArgs {
    input: DisclaimerOutreachCircleInput;
}
export interface UnsubcribeAllEmailFromOutreachCircleMutationArgs {
    input: OutreachCircleEmailUnsubscribeInput;
}
export interface UpdateReferredByForOutreachCircleMutationArgs {
    input: ReferredByOutreachCircleInput;
}
export interface UpdateVisibilityForOutreachCircleMutationArgs {
    input: VisibilityOutreachCircleInput;
}
export interface CreateNewAliasMutationArgs {
    input?: OutreachCircleAliasInput | null;
}
export interface UpdateAliasMutationArgs {
    input: EditOutreachCircleAliasInput;
}
export interface IncrementOutreachCircleImpressionMutationArgs {
    input: IncrementOutreachCircleImpressionsInput;
}
export interface CreateEmailAliasMutationArgs {
    input?: OutreachCircleEmailAliasInput | null;
}
export interface UpdateSupporterReplyToEmailForOutreachCircleMutationArgs {
    input: SupporterReplyToEmailOutreachCircleInput;
}
export interface UpdateSupporterAutoEmailsFromNameForOutreachCircleMutationArgs {
    input: SupporterFromNameEmailOutreachCircleInput;
}
export interface ArchiveOutreachCircleMutationArgs {
    outreachCircleId: string;
}
export interface CollectSurveyResponseOnArchiveOutreachCircleMutationArgs {
    input: ArchiveSurveyResponseInput;
}
export interface UpdateKeyDatesMutationArgs {
    input: UpdateKeyDateInput;
}
export interface ToggleMfaForOutreachCircleMutationArgs {
    input: ToggleMfaForOutreachCircleInput;
}
export interface LinkNonRelationalDataProviderSourceForOutreachCircleMutationArgs {
    input?: LinkNonRelationalDataProviderSourceForOutreachCircleInput | null;
}
export interface UnlinkNonRelationalDataProviderSourceFormOutreachCircleMutationArgs {
    outreachCircleId: string;
}
export interface CreateOutreachCircleLocationMutationArgs {
    input: OutreachCircleLocationInput;
}
export interface UpdateOutreachCircleLocationMutationArgs {
    input: UpdateOutreachCircleLocationInput;
}
export interface ToggleAutoApprovalConfigForD2DCanvassingMutationArgs {
    outreachCircleId: string;
}
export interface UpdateOutreachCircleCampaignDetailsMutationArgs {
    input: UpdateOutreachCircleCampaignDetailsInput;
}
export interface UpdateOutreachCircleElectionDateMutationArgs {
    input: UpdateOutreachCircleElectionDateInput;
}
export interface UpdateOutreachCircleMiscellaneousSettingMutationArgs {
    input?: UpdateOutreachCircleMiscellaneousSettingInput | null;
}
export interface UpdateOutreachCircleActionVisibilityMutationArgs {
    input: UpdateOutreachCircleActionVisibilityInput;
}
export interface AddBillingCardMutationArgs {
    outreachCircleId: string;

    cardToken: string;
}
export interface RemoveBillingCardMutationArgs {
    outreachCircleId: string;

    cardId: string;
}
export interface DefaultPaymentSourceMutationArgs {
    outreachCircleId: string;

    sourceId: string;
}
export interface UpdatePaymentSourceMutationArgs {
    input: UpdatePaymentSourceInput;
}
export interface ChangePlanMutationArgs {
    input?: ChangePlanInput | null;
}
export interface CancelAutoRenewForOutreachCircleMutationArgs {
    input: CancelAutoRenewForOutreachCircleInput;
}
export interface RequestOutreachCircleInvoiceMutationArgs {
    outreachCircleInvoiceId: string;
}
export interface CaptureFlagForVotersMutationArgs {
    input: CaptureFlagForVotersInput;
}
export interface CaptureFlagForPeopleMutationArgs {
    input: CaptureFlagForPeopleInput;
}
export interface CopyVotersToPeopleMutationArgs {
    input: CopyVotersToPeopleInput;
}
export interface AddDonorInfoMutationArgs {
    input: AddDonorInfoInput;
}
export interface EditPdiVoterMutationArgs {
    input: EditPdiVoterInput;
}
export interface DeactivatePdiMemberMutationArgs {
    input: DeactivatePdiMemberInput;
}
export interface DeleteContactIdFromPdiVoterMutationArgs {
    input: GetPdiVoterByIdInput;
}
export interface AddCommentToPdiVoterMutationArgs {
    input: AddCommentToPdiVoterInput;
}
export interface DeleteCommentFromPdiVoterMutationArgs {
    input: DeleteCommentFromPdiVoterInput;
}
export interface CreateP2PConversationItemMutationArgs {
    input: CreateP2PConversationItemInput;
}
export interface EndP2PConversationMutationArgs {
    conversationId: string;
}
export interface ReassignP2PConversationsMutationArgs {
    input: ReassignP2PConversationsInput;
}
export interface UnTagP2PConversationMutationArgs {
    input: UnTagP2PConversationInput;
}
export interface CreateP2PScriptSurveyQuestionMutationArgs {
    input: P2PScriptSurveyQuestionInput;
}
export interface UpdateP2PScriptSurveyQuestionMutationArgs {
    input: P2PScriptSurveyQuestionInput;
}
export interface DeleteP2PScriptSurveyQuestionMutationArgs {
    p2pScriptSurveyQuestionId: string;
}
export interface SendInitialP2PTextMutationArgs {
    input: SendInitialP2PTextInput;
}
export interface UpdateInitialP2PTextMutationArgs {
    input: UpdateInitialP2PTextInput;
}
export interface SendBulkInitialP2PTextsMutationArgs {
    input: SendBulkInitialP2PTextsInput;
}
export interface CreateP2PWorkspaceMutationArgs {
    input: P2PWorkspaceInput;
}
export interface UpdateP2PWorkspaceMutationArgs {
    input: P2PWorkspaceInput;
}
export interface DuplicateP2PWorkspaceMutationArgs {
    input: DuplicateP2PWorkspaceInput;
}
export interface LinkP2PWorkspaceToActionMutationArgs {
    actionId: string;

    p2pWorkspaceId: string;
}
export interface UnlinkP2PWorkspaceFromActionMutationArgs {
    actionId: string;
}
export interface CreateBallotMeasureMutationArgs {
    input: CreateBallotMeasureInput;
}
export interface UpdateBallotMeasureMutationArgs {
    input: UpdateBallotMeasureInput;
}
export interface UpdateCandidateForOutreachCircleMutationArgs {
    input?: UpdateCandidateForOutreachCircleInput | null;
}
export interface AddCandidateForOutreachCircleMutationArgs {
    input?: AddCandidateInput | null;
}
export interface AddCandidateImageMutationArgs {
    input?: CandidateImageInput | null;
}
export interface DeleteCandidateImageMutationArgs {
    candidateId?: string | null;
}
export interface UpdateCandidateImageMutationArgs {
    input?: CandidateImageInput | null;
}
export interface CreateCandidateMutationArgs {
    input: CreateCandidateInput;
}
export interface UpdateCandidateMutationArgs {
    input: UpdateCandidateInput;
}
export interface CreateIssueMutationArgs {
    input?: CreateIssueInput | null;
}
export interface UpdateIssueMutationArgs {
    input?: UpdateIssueInput | null;
}
export interface UpdatePurposeStatusByIdMutationArgs {
    input: UpdatePurposeStatusInput;
}
export interface CopyPurposesFromParentOutreachCircleMutationArgs {
    input: CopyPurposesFromParentOutreachCircleInput;
}
export interface MarkAllPushNotificationsAsReadMutationArgs {
    registrationToken: string;
}
export interface RequestReportForOutreachCircleMutationArgs {
    input: RequestReport;
}
export interface RequestReportForOrganizationMutationArgs {
    input: OrganizationReportInput;
}
export interface SendOutreachCircleReportToOcAdminMutationArgs {
    outreachCircleId: string;

    reportCode: ReportCode;
}
export interface UpdateSearchAndSurveyFormMutationArgs {
    input?: UpdateSearchAndSurveyFormInput | null;
}
export interface CreateSearchAndSurveyFormMutationArgs {
    input: CreateSearchAndSurveyFormInput;
}
export interface LinkSearchAndSurveyFormToActionMutationArgs {
    input: SearchAndSurveyFormActionInput;
}
export interface UnlinkSearchAndSurveyFormFromActionMutationArgs {
    actionId: string;
}
export interface DuplicateSearchAndSurveyFormMutationArgs {
    input: DuplicateSearchAndSurveyFormInput;
}
export interface SubmitSearchAndSurveyFormQuestionResponseMutationArgs {
    input: SubmitSearchAndSurveyFormQuestionResponseInput;
}
export interface DeleteSearchAndSurveyFormMutationArgs {
    searchAndSurveyFormId: string;
}
export interface CreateSocialTemplateMutationArgs {
    input: CreateSocialTemplateInput;
}
export interface UpdateSocialTemplateMutationArgs {
    input: UpdateSocialTemplateInput;
}
export interface CreateOrganizationSocialTemplateMutationArgs {
    input: CreateOrganizationSocialTemplateInput;
}
export interface UpdateOrganizationSocialTemplateMutationArgs {
    input: UpdateOrganizationSocialTemplateInput;
}
export interface AddSourcingLinkMutationArgs {
    input: SourcingLinkInput;
}
export interface ArchiveSourcingLinkMutationArgs {
    sourcingLinkId: string;
}
export interface AssignSupporterWithSourceToActionMutationArgs {
    input: AssignSupporterWithSourceToActionInput;
}
export interface AssignSupporterWithSourceToShiftMutationArgs {
    input: AssignSupporterWithSourceToShiftInput;
}
export interface UnassignShiftsFromSupporterMutationArgs {
    input: UnassignShiftsFromSupporterInput;
}
export interface CreateSupporterEmailTemplateForOutreachCircleMutationArgs {
    input: CreateSupporterEmailTemplateInput;
}
export interface UpdateSupporterEmailTemplateForOutreachCircleMutationArgs {
    input: UpdateSupporterEmailTemplateInput;
}
export interface DuplicateSupporterEmailTemplateMutationArgs {
    input: DuplicateSupporterEmailTemplateInput;
}
export interface UpdateSupporterFormMutationArgs {
    input?: UpdateSupporterFormInput | null;
}
export interface CreateSupporterFormMutationArgs {
    input: CreateSupporterFormInput;
}
export interface SubmitSupporterFormQuestionResponseMutationArgs {
    input: SubmitSupporterFormQuestionResponseInput;
}
export interface LinkSupporterFormToActionMutationArgs {
    input: SupporterFormActionInput;
}
export interface UnlinkSupporterFormFromActionMutationArgs {
    actionId: string;
}
export interface DuplicateSupporterFormMutationArgs {
    input: DuplicateSupporterFormInput;
}
export interface DeleteSupporterFormMutationArgs {
    supporterFormId: string;
}
export interface CreateSupporterPushNotificationTemplateMutationArgs {
    input: CreateSupporterPushNotificationTemplateInput;
}
export interface UpdateSupporterPushNotificationTemplateMutationArgs {
    input: UpdateSupporterPushNotificationTemplateInput;
}
export interface CreateSupporterViewFilterStateMutationArgs {
    input: CreateSupporterViewFilterStateInput;
}
export interface UpdateSupporterViewFilterStateMutationArgs {
    input: UpdateSupporterViewFilterStateInput;
}
export interface DeleteSupporterViewFilterStateMutationArgs {
    filterId: string;
}
export interface CreateSupporterActivityMutationArgs {
    input: CreateSupporterActivityInput;
}
export interface UpdateSupporterActivityMutationArgs {
    input: UpdateSupporterActivityInput;
}
export interface UpdateSupporterActivityActiveStatusMutationArgs {
    input: UpdateSupporterActivityActiveStatus;
}
export interface CreateSupporterAvailabilityMutationArgs {
    input: CreateSupporterAvailabilityInput;
}
export interface UpdateSupporterAvailabilityMutationArgs {
    input: UpdateSupporterAvailabilityInput;
}
export interface UpdateSupporterAvailabilityActiveStatusMutationArgs {
    input: UpdateSupporterAvailabilityActiveStatus;
}
export interface CreateSupporterLevelMutationArgs {
    input: CreateSupporterLevelInput;
}
export interface UpdateSupporterLevelMutationArgs {
    input: UpdateSupporterLevelInput;
}
export interface UpdateSupporterLevelActiveStatusMutationArgs {
    input: UpdateSupporterLevelActiveStatus;
}
export interface CreateSupporterOrganizationMutationArgs {
    input: CreateSupporterOrganizationInput;
}
export interface UpdateSupporterOrganizationMutationArgs {
    input: UpdateSupporterOrganizationInput;
}
export interface UpdateSupporterOrganizationActiveStatusMutationArgs {
    input: UpdateSupporterOrganizationActiveStatus;
}
export interface CreateSupporterSkillMutationArgs {
    input: CreateSupporterSkillInput;
}
export interface UpdateSupporterSkillMutationArgs {
    input: UpdateSupporterSkillInput;
}
export interface UpdateSupporterSkillActiveStatusMutationArgs {
    input: UpdateSupporterSkillActiveStatus;
}
export interface CreateSupporterTeamMutationArgs {
    input: CreateSupporterTeamInput;
}
export interface UpdateSupporterTeamMutationArgs {
    input: UpdateSupporterTeamInput;
}
export interface UpdateSupporterTeamActiveStatusMutationArgs {
    input: UpdateSupporterTeamActiveStatus;
}
export interface CreateSupporterZoneMutationArgs {
    input: CreateSupporterZoneInput;
}
export interface UpdateSupporterZoneMutationArgs {
    input: UpdateSupporterZoneInput;
}
export interface UpdateSupporterZoneActiveStatusMutationArgs {
    input: UpdateSupporterZoneActiveStatus;
}
export interface SupportOutreachCircleMutationArgs {
    input: SupportOutreachCircleInput;
}
export interface CreateInvitedSupporterSourceMutationArgs {
    input?: CreateInvitedSupporterSourceInput | null;
}
export interface StartImportingInvitedSupporterFromFileMutationArgs {
    input: ImportInvitedSupporterFromFileInput;
}
export interface SendSupporterInviteToImportedInvitedSupportersMutationArgs {
    invitedSupporterSourceId: string;

    hasEmailConsent: boolean;

    dontSendEmail?: boolean | null;
}
export interface InviteSupportersMutationArgs {
    input: InviteSupporterInput;
}
export interface AddSupportersMutationArgs {
    input: AddSupporterInput;
}
export interface UpdateSupporterMutationArgs {
    input: UpdateSupporterInput;
}
export interface UpdateSupportersMutationArgs {
    input: UpdateSupporterInput[];
}
export interface CancelInvitedSupporterImportFromFileMutationArgs {
    invitedSupporterSourceId: string;
}
export interface SendSupporterEmailToFilteredSupporterMutationArgs {
    input: SupporterEmailFilterInput;

    hasEmailConsent: boolean;
}
export interface SendPushNotificationToFilteredSupporterMutationArgs {
    input: EligibleSupporterForSendingPushNotificationInput;
}
export interface BlockFilteredSupportersMutationArgs {
    input: BlockFilteredSupportersInput;
}
export interface UnblockSupporterMutationArgs {
    supporterId: string;
}
export interface UnsupportOutreachCircleMutationArgs {
    outreachCircleId: string;
}
export interface SupporterVisitedRecruitmentLinkMutationArgs {
    alias: string;
}
export interface SendSupporterWelcomeEmailToFilteredSupporterMutationArgs {
    input: SupporterWelcomeEmailFilterInput;
}
export interface CreateSurveyMutationArgs {
    input: CreateSurveyInput;
}
export interface UpdateSurveyMutationArgs {
    input: UpdateSurveyInput;
}
export interface UpdateSurveyStatusByIdMutationArgs {
    input: UpdateSurveyStatusInput;
}
export interface CreateSurveyQuestionMutationArgs {
    input: CreateSurveyQuestionInput;
}
export interface UpdateSurveyQuestionMutationArgs {
    input: UpdateSurveyQuestionInput;
}
export interface UpdateSurveyQuestionStatusByIdMutationArgs {
    input: UpdateSurveyQuestionStatusInput;
}
export interface SubmitSurveyResponseMutationArgs {
    input: SubmitSurveyResponseInput;
}
export interface TagRecipientMutationArgs {
    input?: (TagRecipientInput | null)[] | null;
}
export interface CreateTagMutationArgs {
    input: CreateTagInput;
}
export interface UpdateTagMutationArgs {
    input: UpdateTagInput;
}
export interface ApplyTagToAffinityTextRecipientMutationArgs {
    input: ApplyTagToAffinityTextRecipient;
}
export interface ApplyTagToRelationalTextRecipientMutationArgs {
    input: ApplyTagToRelationalTextRecipient;
}
export interface ApplyTagToAmbiguousTextRecipientMutationArgs {
    input: ApplyTagToAmbiguousTextRecipient;
}
export interface RemoveTagToAffinityTextRecipientMutationArgs {
    input: ApplyTagToAffinityTextRecipient;
}
export interface RemoveTagToRelationalTextRecipientMutationArgs {
    input: ApplyTagToRelationalTextRecipient;
}
export interface LinkTagToActionMutationArgs {
    input: ActionTagLinkInput;
}
export interface UnLinkTagToActionMutationArgs {
    input: ActionTagLinkInput;
}
export interface UpdateCannedResponseForActionTagMutationArgs {
    input: CannedResponseInput;
}
export interface DeleteTagMutationArgs {
    tagId: string;
}
export interface CreateTextTemplateMutationArgs {
    input: CreateTextTemplateInput;
}
export interface UpdateTextTemplateMutationArgs {
    input: UpdateTextTemplateInput;
}
export interface UpdateTextTemplateForSupporterMutationArgs {
    input: UpdateTextTemplateForSupporterInput;
}
export interface CreateOrganizationTextTemplateMutationArgs {
    input: CreateOrganizationTextTemplateInput;
}
export interface UpdateOrganizationTextTemplateMutationArgs {
    input: UpdateOrganizationTextTemplateInput;
}
export interface SignupUserMutationArgs {
    user: SignupOrLoginInput;
}
export interface LoginMutationArgs {
    user: SignupOrLoginInput;
}
export interface LoginForRapidOnboardingMutationArgs {
    input: RapidOnboardingLoginInput;
}
export interface LoginForRapidOnboardingForEmbeddedActionsMutationArgs {
    input: RapidOnboardingLoginInput;
}
export interface UpdateNameForLoggedInUserMutationArgs {
    nameInput?: NameInput | null;
}
export interface UpdatePreferencesForLoggedInUserMutationArgs {
    preferenceInput?: PreferenceInput | null;
}
export interface UpdateContactDetailsForLoggedInUserMutationArgs {
    contactDetailsInput?: ContactDetailsInput | null;
}
export interface ResetPasswordForSupporterMutationArgs {
    username: string;

    outreachCircleId?: string | null;
}
export interface ResetPasswordForClientMutationArgs {
    username: string;
}
export interface UpdatePasswordMutationArgs {
    updatePasswordInput: UpdatePasswordInput;
}
export interface UpdatePasswordForLoggedInUserMutationArgs {
    password: string;
}
export interface SignupUserWithDetailsMutationArgs {
    clientSignupInput: SignupWithDetailsInput;
}
export interface SignupUserWithDetailsFromAdsMutationArgs {
    input: SignupWithDetailsFromAdsInput;
}
export interface VerifyUserWithValidCardMutationArgs {
    input?: VerifyUserWithCardOrCouponInput | null;
}
export interface VerifyEmailMutationArgs {
    emailVerificationInput?: EmailVerificationInput | null;
}
export interface UpdateUsernameMutationArgs {
    username: string;
}
export interface UpdateOptInForTextMutationArgs {
    optInForText: boolean;
}
export interface SubscribeToNewsletterMutationArgs {
    email: string;
}
export interface UnsubcribeEmailFromEmailTemplateMutationArgs {
    input: UserEmailUnsubscribeInput;
}
export interface UpdatePushNotificationTokenMutationArgs {
    input: PushNotificationTokenInput;
}
export interface UpdateNameAndContactDetailsForLoggedInUserMutationArgs {
    input: UserNameAndContactDetailsInput;
}
export interface SendSupportEmailForLeadFromAdLandingScreenMutationArgs {
    input: SendSupportEmailForLeadFromAdLandingScreenInput;
}
export interface SignupUserForEmbedMutationArgs {
    user: SignupOrLoginInput;
}
export interface LoginForEmbedMutationArgs {
    user: SignupOrLoginInput;
}
export interface LoginForRapidOnboardingForEmbedMutationArgs {
    input: RapidOnboardingLoginInput;
}
export interface VerifyMfaCodeMutationArgs {
    code: string;
}
export interface SetDefaultOutreachcircleForUserMutationArgs {
    outreachCircleId: string;
}
export interface SetDefaultVolunteerOutreachCircleForUserMutationArgs {
    outreachCircleId: string;
}
export interface PasswordlessLoginStartMutationArgs {
    input?: PasswordlessLoginStartInput | null;
}
export interface PasswordlessLoginMutationArgs {
    input?: PasswordlessLoginInput | null;
}
export interface UpdateUserProfileImageMutationArgs {
    input: UpdateUserProfileImageInput;
}
export interface SignUpWithDetailsForEmbedMutationArgs {
    input: SignUpWithDetailsForEmbedInput;
}
export interface CreateBookmarkMutationArgs {
    path: string;
}
export interface RemoveBookmarkMutationArgs {
    id: string;
}
export interface ActionSubscriptionArgs {
    id: string;
}
export interface PublicActionSubscriptionArgs {
    id: string;
}
export interface CallStatusSubscriptionArgs {
    callSid: string;
}
export interface AudienceImportStatusSubscriptionArgs {
    audienceId: string;
}
export interface ContactImportStatusSubscriptionArgs {
    userId: string;
}
export interface TcrIntegrationStatusSubscriptionArgs {
    outreachCircleId: string;
}
export interface OutreachCircleSubscriptionArgs {
    id: string;
}
export interface SupporterSubscriptionArgs {
    id: string;
}
export interface SupporterV2SubscriptionArgs {
    id: string;
}
export interface InvitedSupporterImportSubscriptionArgs {
    id: string;
}

// ====================================================
// Unions
// ====================================================

export type ActionNotificationLog = EmailNotificationLog | PushNotificationLog;

export type MemberSurvey = Engagement | SearchAndSurveyForm | GroSurveyForm;

export type SupporterSubscriptionData =
    | MatchingJob
    | P2PConversationSubscriptionResponse
    | P2PInboxItem
    | TextingConversationSubscriptionResponse
    | TextingInboxItem;
