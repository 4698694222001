<div class="circle-container">
    <div class="bg-purple">
        <div class="stars">
            <div class="custom-navbar">
                <div class="brand-logo">
                    <svg-icon
                        src="/assets/svg/oc-logo-light.svg"
                        [svgStyle]="{ 'width.px': 200 }"
                    ></svg-icon>
                </div>
            </div>
            <div class="central-body">
                <img class="image-404" src="/assets/images/404.svg" />
                <!-- <a
                    href="https://client.outreachcircle.com"
                    class="btn-go-home"
                    target="_blank"
                    >GO BACK HOME</a
                > -->
            </div>
            <div class="objects">
                <img class="object_rocket" src="/assets/images/rocket.svg" width="40px" />
                <div class="earth-moon">
                    <img
                        class="object_earth"
                        src="/assets/images/earth.svg"
                        width="100px"
                        style="margin-left: 60px"
                    />
                    <img class="object_moon" src="/assets/images/moon.svg" width="80px" />
                </div>
                <div class="box_astronaut">
                    <img
                        class="object_astronaut"
                        src="/assets/images/astronaut.svg"
                        width="140px"
                    />
                </div>
            </div>
            <div class="glowing_stars">
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
            </div>
        </div>
    </div>
</div>
