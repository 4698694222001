import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppMaterialModule } from './app-material.module';
import { CookieModule } from 'ngx-cookie';
import { AuthInterceptor } from './services/http-interceptor';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonComponentsModule } from './common-components/common-components.module';
import { TurfingHeaderComponent } from './turfing/header/header.component';

@NgModule({
    declarations: [AppComponent, TurfingHeaderComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        AppMaterialModule,
        AngularSvgIconModule,
        CookieModule.forRoot(),
        CommonComponentsModule,
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
    bootstrap: [AppComponent],
})
export class AppModule {}
