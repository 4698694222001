import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { User } from 'types';

@Component({
    selector: 'turfing-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class TurfingHeaderComponent implements OnInit {
    title = 'ocadmin';

    user: User;

    selectedSubNavItem;

    isAdmin = false;

    constructor(private router: Router, private titleService: Title) {
        this.titleService.setTitle('Turfing');
    }

    ngOnInit() {
        this.router.events.subscribe(evt => {});
    }
}
