import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[cardStatusColor]',
})
export class CardStatusColorDirective implements OnInit {
    @Input('cardStatusColor')
    color: string;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.el.nativeElement.style.backgroundColor = this.color;
    }
}
