import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { User } from 'types';
import { Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'ocadmin';

    user: User;

    selectedNavItem = 'circles';

    selectedSubNavItem;

    private readonly navItemsList = new Map([
        [
            'circles',
            {
                urlEndsWith: '/circles',
            },
        ],
        [
            'supporters',
            {
                urlEndsWith: '/supporters',
            },
        ],
        [
            'actions',
            {
                urlEndsWith: '/actions',
            },
        ],
        [
            'pricing',
            {
                urlEndsWith: '/pricing',
            },
        ],
        [
            'users',
            {
                urlEndsWith: '/users',
            },
        ],
    ]);

    constructor(private userService: UserService, private router: Router) {}

    ngOnInit() {
        this.userService.fetchUserByToken().subscribe((user: User) => {
            if (!user) {
                // TODO: take the user to login page in a new tab
            } else {
                this.user = user;
            }
        });

        this.router.events.subscribe(evt => {
            if (evt instanceof NavigationEnd) {
                this.selectedNavItem = evt.urlAfterRedirects.substring(1).split('/')[0];
                this.selectedSubNavItem = evt.urlAfterRedirects.substring(1).split('/')[1];
            }
        });
    }

    openSideNavItem(key) {
        this.router
            .navigateByUrl('/dummy-route', { skipLocationChange: true })
            .then(() => this.router.navigate(key));
    }
}
