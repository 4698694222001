<div class="main-client-container">
    <!-- campaign header -->
    <div class="client-header">
        <!-- The brand name -->
        <div class="brand-name">
            <img src="/assets/svg/oc-logo-light.svg" />
        </div>

        <div class="header-title">
            <p>|&nbsp;&nbsp;&nbsp;Turfing</p>
        </div>
    </div>

    <div class="right-panel">
        <router-outlet></router-outlet>
    </div>
</div>
