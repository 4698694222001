import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    readonly tokenName = '_oca_token';

    constructor(private cookieService: CookieService) {}

    getToken(): string {
        return this.cookieService.get(this.tokenName);
    }

    setToken(itemValue: any): void {
        this.cookieService.put(this.tokenName, itemValue);
    }

    removeToken() {
        this.cookieService.remove(this.tokenName);
    }
}
