// tslint:disable:max-line-length
import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { User } from 'types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private tokenService: TokenService, private httpClient: HttpClient) {}

    /**
     * fetchUserByToken
     * @param token
     * this method makes Graphql query userByToken
     */
    fetchUserByToken(): Observable<User> {
        const token = this.tokenService.getToken();
        if (token) {
            return this.httpClient.get<User>('/api/user', {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            });
        }
        return of(null);
    }

    findUser(searchString: string) {
        return this.httpClient.get(`/api/user/findUser`, {
            params: new HttpParams().set('searchString', searchString),
        });
    }

    login(username: string, password: string) {
        return this.httpClient.post(`/api/user/login`, {
            username,
            password,
        });
    }

    logout() {
        this.tokenService.removeToken();
    }
}
