import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'CountryCodeToCountryName',
})
export class CountryCodeToCountryNamePipe implements PipeTransform {
    constructor() {}

    transform(value: any, args: any[]): any {
        return this.countryCodeToName(value.toUpperCase());
    }

    countryCodeToName(text: string) {
        switch (text) {
            case 'AF':
                return 'AFGHANISTAN';
            case 'AL':
                return 'ALBANIA';
            case 'DZ':
                return 'ALGERIA';
            case 'AS':
                return 'AMERICAN SAMOA';
            case 'AD':
                return 'ANDORRA';
            case 'AO':
                return 'ANGOLA';
            case 'AI':
                return 'ANGUILLA';
            case 'AQ':
                return 'ANTARCTICA';
            case 'AG':
                return 'ANTIGUA AND BARBUDA';
            case 'AR':
                return 'ARGENTINA';
            case 'AM':
                return 'ARMENIA';
            case 'AW':
                return 'ARUBA';
            case 'AU':
                return 'AUSTRALIA';
            case 'AT':
                return 'AUSTRIA';
            case 'AZ':
                return 'AZERBAIJAN';
            case 'BS':
                return 'BAHAMAS';
            case 'BH':
                return 'BAHRAIN';
            case 'BD':
                return 'BANGLADESH';
            case 'BB':
                return 'BARBADOS';
            case 'BY':
                return 'BELARUS';
            case 'BE':
                return 'BELGIUM';
            case 'BZ':
                return 'BELIZE';
            case 'BJ':
                return 'BENIN';
            case 'BM':
                return 'BERMUDA';
            case 'BT':
                return 'BHUTAN';
            case 'BO':
                return 'BOLIVIA';
            case 'BA':
                return 'BOSNIA AND HERZEGOVINA';
            case 'BW':
                return 'BOTSWANA';
            case 'BV':
                return 'BOUVET ISLAND';
            case 'BR':
                return 'BRAZIL';
            case 'IO':
                return 'BRITISH INDIAN OCEAN TERRITORY';
            case 'BN':
                return 'BRUNEI DARUSSALAM';
            case 'BG':
                return 'BULGARIA';
            case 'BF':
                return 'BURKINA FASO';
            case 'BI':
                return 'BURUNDI';
            case 'KH':
                return 'CAMBODIA';
            case 'CM':
                return 'CAMEROON';
            case 'CA':
                return 'CANADA';
            case 'CV':
                return 'CAPE VERDE';
            case 'KY':
                return 'CAYMAN ISLANDS';
            case 'CF':
                return 'CENTRAL AFRICAN REPUBLIC';
            case 'TD':
                return 'CHAD';
            case 'CL':
                return 'CHILE';
            case 'CN':
                return 'CHINA';
            case 'CX':
                return 'CHRISTMAS ISLAND';
            case 'CC':
                return 'COCOS (KEELING) ISLANDS';
            case 'CO':
                return 'COLOMBIA';
            case 'KM':
                return 'COMOROS';
            case 'CG':
                return 'CONGO';
            case 'CD':
                return 'CONGO, THE DEMOCRATIC REPUBLIC OF THE';
            case 'CK':
                return 'COOK ISLANDS';
            case 'CR':
                return 'COSTA RICA';
            case 'CI':
                return 'COTE D IVOIRE';
            case 'HR':
                return 'CROATIA';
            case 'CU':
                return 'CUBA';
            case 'CY':
                return 'CYPRUS';
            case 'CZ':
                return 'CZECH REPUBLIC';
            case 'DK':
                return 'DENMARK';
            case 'DJ':
                return 'DJIBOUTI';
            case 'DM':
                return 'DOMINICA';
            case 'DO':
                return 'DOMINICAN REPUBLIC';
            case 'TP':
                return 'EAST TIMOR';
            case 'EC':
                return 'ECUADOR';
            case 'EG':
                return 'EGYPT';
            case 'SV':
                return 'EL SALVADOR';
            case 'GQ':
                return 'EQUATORIAL GUINEA';
            case 'ER':
                return 'ERITREA';
            case 'EE':
                return 'ESTONIA';
            case 'ET':
                return 'ETHIOPIA';
            case 'FK':
                return 'FALKLAND ISLANDS (MALVINAS)';
            case 'FO':
                return 'FAROE ISLANDS';
            case 'FJ':
                return 'FIJI';
            case 'FI':
                return 'FINLAND';
            case 'FR':
                return 'FRANCE';
            case 'GF':
                return 'FRENCH GUIANA';
            case 'PF':
                return 'FRENCH POLYNESIA';
            case 'TF':
                return 'FRENCH SOUTHERN TERRITORIES';
            case 'GA':
                return 'GABON';
            case 'GM':
                return 'GAMBIA';
            case 'GE':
                return 'GEORGIA';
            case 'DE':
                return 'GERMANY';
            case 'GH':
                return 'GHANA';
            case 'GI':
                return 'GIBRALTAR';
            case 'GR':
                return 'GREECE';
            case 'GL':
                return 'GREENLAND';
            case 'GD':
                return 'GRENADA';
            case 'GP':
                return 'GUADELOUPE';
            case 'GU':
                return 'GUAM';
            case 'GT':
                return 'GUATEMALA';
            case 'GN':
                return 'GUINEA';
            case 'GW':
                return 'GUINEA-BISSAU';
            case 'GY':
                return 'GUYANA';
            case 'HT':
                return 'HAITI';
            case 'HM':
                return 'HEARD ISLAND AND MCDONALD ISLANDS';
            case 'VA':
                return 'HOLY SEE (VATICAN CITY STATE)';
            case 'HN':
                return 'HONDURAS';
            case 'HK':
                return 'HONG KONG';
            case 'HU':
                return 'HUNGARY';
            case 'IS':
                return 'ICELAND';
            case 'IN':
                return 'INDIA';
            case 'ID':
                return 'INDONESIA';
            case 'IR':
                return 'IRAN, ISLAMIC REPUBLIC OF';
            case 'IQ':
                return 'IRAQ';
            case 'IE':
                return 'IRELAND';
            case 'IL':
                return 'ISRAEL';
            case 'IT':
                return 'ITALY';
            case 'JM':
                return 'JAMAICA';
            case 'JP':
                return 'JAPAN';
            case 'JO':
                return 'JORDAN';
            case 'KZ':
                return 'KAZAKSTAN';
            case 'KE':
                return 'KENYA';
            case 'KI':
                return 'KIRIBATI';
            case 'KP':
                return 'KOREA DEMOCRATIC PEOPLES REPUBLIC OF';
            case 'KR':
                return 'KOREA REPUBLIC OF';
            case 'KW':
                return 'KUWAIT';
            case 'KG':
                return 'KYRGYZSTAN';
            case 'LA':
                return 'LAO PEOPLES DEMOCRATIC REPUBLIC';
            case 'LV':
                return 'LATVIA';
            case 'LB':
                return 'LEBANON';
            case 'LS':
                return 'LESOTHO';
            case 'LR':
                return 'LIBERIA';
            case 'LY':
                return 'LIBYAN ARAB JAMAHIRIYA';
            case 'LI':
                return 'LIECHTENSTEIN';
            case 'LT':
                return 'LITHUANIA';
            case 'LU':
                return 'LUXEMBOURG';
            case 'MO':
                return 'MACAU';
            case 'MK':
                return 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF';
            case 'MG':
                return 'MADAGASCAR';
            case 'MW':
                return 'MALAWI';
            case 'MY':
                return 'MALAYSIA';
            case 'MV':
                return 'MALDIVES';
            case 'ML':
                return 'MALI';
            case 'MT':
                return 'MALTA';
            case 'MH':
                return 'MARSHALL ISLANDS';
            case 'MQ':
                return 'MARTINIQUE';
            case 'MR':
                return 'MAURITANIA';
            case 'MU':
                return 'MAURITIUS';
            case 'YT':
                return 'MAYOTTE';
            case 'MX':
                return 'MEXICO';
            case 'FM':
                return 'MICRONESIA, FEDERATED STATES OF';
            case 'MD':
                return 'MOLDOVA, REPUBLIC OF';
            case 'MC':
                return 'MONACO';
            case 'MN':
                return 'MONGOLIA';
            case 'MS':
                return 'MONTSERRAT';
            case 'MA':
                return 'MOROCCO';
            case 'MZ':
                return 'MOZAMBIQUE';
            case 'MM':
                return 'MYANMAR';
            case 'NA':
                return 'NAMIBIA';
            case 'NR':
                return 'NAURU';
            case 'NP':
                return 'NEPAL';
            case 'NL':
                return 'NETHERLANDS';
            case 'AN':
                return 'NETHERLANDS ANTILLES';
            case 'NC':
                return 'NEW CALEDONIA';
            case 'NZ':
                return 'NEW ZEALAND';
            case 'NI':
                return 'NICARAGUA';
            case 'NE':
                return 'NIGER';
            case 'NG':
                return 'NIGERIA';
            case 'NU':
                return 'NIUE';
            case 'NF':
                return 'NORFOLK ISLAND';
            case 'MP':
                return 'NORTHERN MARIANA ISLANDS';
            case 'NO':
                return 'NORWAY';
            case 'OM':
                return 'OMAN';
            case 'PK':
                return 'PAKISTAN';
            case 'PW':
                return 'PALAU';
            case 'PS':
                return 'PALESTINIAN TERRITORY, OCCUPIED';
            case 'PA':
                return 'PANAMA';
            case 'PG':
                return 'PAPUA NEW GUINEA';
            case 'PY':
                return 'PARAGUAY';
            case 'PE':
                return 'PERU';
            case 'PH':
                return 'PHILIPPINES';
            case 'PN':
                return 'PITCAIRN';
            case 'PL':
                return 'POLAND';
            case 'PT':
                return 'PORTUGAL';
            case 'PR':
                return 'PUERTO RICO';
            case 'QA':
                return 'QATAR';
            case 'RE':
                return 'REUNION';
            case 'RO':
                return 'ROMANIA';
            case 'RU':
                return 'RUSSIAN FEDERATION';
            case 'RW':
                return 'RWANDA';
            case 'SH':
                return 'SAINT HELENA';
            case 'KN':
                return 'SAINT KITTS AND NEVIS';
            case 'LC':
                return 'SAINT LUCIA';
            case 'PM':
                return 'SAINT PIERRE AND MIQUELON';
            case 'VC':
                return 'SAINT VINCENT AND THE GRENADINES';
            case 'WS':
                return 'SAMOA';
            case 'SM':
                return 'SAN MARINO';
            case 'ST':
                return 'SAO TOME AND PRINCIPE';
            case 'SA':
                return 'SAUDI ARABIA';
            case 'SN':
                return 'SENEGAL';
            case 'SC':
                return 'SEYCHELLES';
            case 'SL':
                return 'SIERRA LEONE';
            case 'SG':
                return 'SINGAPORE';
            case 'SK':
                return 'SLOVAKIA';
            case 'SI':
                return 'SLOVENIA';
            case 'SB':
                return 'SOLOMON ISLANDS';
            case 'SO':
                return 'SOMALIA';
            case 'ZA':
                return 'SOUTH AFRICA';
            case 'GS':
                return 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS';
            case 'ES':
                return 'SPAIN';
            case 'LK':
                return 'SRI LANKA';
            case 'SD':
                return 'SUDAN';
            case 'SR':
                return 'SURINAME';
            case 'SJ':
                return 'SVALBARD AND JAN MAYEN';
            case 'SZ':
                return 'SWAZILAND';
            case 'SE':
                return 'SWEDEN';
            case 'CH':
                return 'SWITZERLAND';
            case 'SY':
                return 'SYRIAN ARAB REPUBLIC';
            case 'TW':
                return 'TAIWAN, PROVINCE OF CHINA';
            case 'TJ':
                return 'TAJIKISTAN';
            case 'TZ':
                return 'TANZANIA, UNITED REPUBLIC OF';
            case 'TH':
                return 'THAILAND';
            case 'TG':
                return 'TOGO';
            case 'TK':
                return 'TOKELAU';
            case 'TO':
                return 'TONGA';
            case 'TT':
                return 'TRINIDAD AND TOBAGO';
            case 'TN':
                return 'TUNISIA';
            case 'TR':
                return 'TURKEY';
            case 'TM':
                return 'TURKMENISTAN';
            case 'TC':
                return 'TURKS AND CAICOS ISLANDS';
            case 'TV':
                return 'TUVALU';
            case 'UG':
                return 'UGANDA';
            case 'UA':
                return 'UKRAINE';
            case 'AE':
                return 'UNITED ARAB EMIRATES';
            case 'GB':
                return 'UNITED KINGDOM';
            case 'US':
                return 'UNITED STATES';
            case 'UM':
                return 'UNITED STATES MINOR OUTLYING ISLANDS';
            case 'UY':
                return 'URUGUAY';
            case 'UZ':
                return 'UZBEKISTAN';
            case 'VU':
                return 'VANUATU';
            case 'VE':
                return 'VENEZUELA';
            case 'VN':
                return 'VIET NAM';
            case 'VG':
                return 'VIRGIN ISLANDS, BRITISH';
            case 'VI':
                return 'VIRGIN ISLANDS, U.S.';
            case 'WF':
                return 'WALLIS AND FUTUNA';
            case 'EH':
                return 'WESTERN SAHARA';
            case 'YE':
                return 'YEMEN';
            case 'YU':
                return 'YUGOSLAVIA';
            case 'ZM':
                return 'ZAMBIA';
            case 'ZW':
                return 'ZIMBABWE';
            default:
                return '';
        }
    }
}
