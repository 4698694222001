import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './common-components/page-not-found/page-not-found.component';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () =>
            import('./authentication/authentication.module').then(m => m.AuthenticationModule),
    },
    {
        path: '',
        loadChildren: () => import('./index/index.module').then(m => m.IndexModule),
    },
    {
        path: 'dummy-route',
        loadChildren: () => import('./index/index.module').then(m => m.IndexModule),
    },
    {
        path: 'page-not-found',
        component: PageNotFoundComponent,
    },
    {
        path: '**',
        redirectTo: 'page-not-found',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
