<!-- card container -->
<div class="card-container" [ngStyle]="customStyle">
    <!-- card header -->
    <div class="card-header" [cardStatusColor]="color">
        <ng-content select="span"></ng-content>

        <!-- Use either one of these or use the div option to put whatever is needed in there -->
        <ng-content select="a"></ng-content>
        <ng-content select="i"></ng-content>
        <ng-content select="svg-icon"></ng-content>

        <!-- This is for more complex headers which have multiple actions on the right -->
        <ng-content select=".multi-action-panel"></ng-content>
    </div>

    <!-- card body -->
    <div class="card-body">
        <ng-content select=".body"></ng-content>
    </div>
</div>
