import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
    selector: 'vc-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnInit {
    @Input() status: statuses;
    @Input() customStyle: any;
    color: string;

    constructor() {}

    ngOnInit() {
        this.color = this.getColorForStatus();
    }

    getColorForStatus() {
        switch (this.status) {
            case 'active':
                return '#CCF2E2';
            case 'inactive':
                return '#FACEC5';
            default:
                return '#EFEFEF';
        }
    }
}

export type statuses = 'inactive' | 'active' | 'none';
